import React, { useEffect, useRef, useState } from 'react';
import { TextInputProps } from './interfaces';

const TextInput = ({
  text,
  setText,
  userId,
  postText,
}: TextInputProps): JSX.Element => {
  const ref = useRef(null);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const [lastText, setLastText] = useState('');
  const AUTOSAVE_INTERVAL = 1000;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastText !== text) {
        postText('updateMemo', userId, 0, 0, text);
        setLastText(text);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
  }, [text]);

  return (
    <form className="text-input-form">
      <div className="text-input" ref={ref}>
        {/* {editable ? ( */}
        <textarea
          className="edit-textarea"
          value={text}
          onChange={(e) => handleChange(e)}
          name="Text1"
          cols={40}
          rows={5}
        />
        {/* ) : (
          <span className="text-click" onClick={() => editOn()}>
            {text}
          </span>
        )} */}
      </div>
    </form>
  );
};

export default TextInput;
