import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useUserExist } from 'hooks/global';
import { signUp } from 'services/user/getSignUpUser';

const SignupForm = (): JSX.Element => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState(true);
  const [existUser, setExistUser] = useUserExist();
  const [userName, setUserName] = useState('');
  const [userNameCheck, setUserNameCheck] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [checkPassword, setCheckPassword] = useState('');
  const [checkPasswordCheck, setCheckPasswordCheck] = useState(true);
  const [disabled, setDisabled] = useState(true);

  // 이메일 유효성 검사
  const validateEmail = (mail: string) => {
    if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(mail)) {
      return true;
    }
    return false;
  };
  const handleEmailValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validateEmail(e.target.value)) {
      setEmailCheck(false);
    } else {
      setEmailCheck(true);
    }
    setEmail(e.target.value);
  };
  const handleEmailDuplication = (e) => {
    setExistUser(e.target.value);
  };
  const handleUserNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/^[가-힣]{2,4}$/.test(e.target.value)) {
      setUserNameCheck(true);
    } else {
      setUserNameCheck(false);
    }
    setUserName(e.target.value);
  };
  const handlePasswordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 8) {
      setPasswordCheck(false);
    } else {
      setPasswordCheck(true);
    }
    setPassword(e.target.value);
  };
  const handleCheckPasswordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== password) {
      setCheckPasswordCheck(false);
    } else {
      setCheckPasswordCheck(true);
    }
    setCheckPassword(e.target.value);
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled) {
      signUp(email, userName, password, history);
    }
  };

  // 모든 조건 만족시 다음버튼 활성화시킴
  useEffect(() => {
    if (
      email !== '' &&
      userName !== '' &&
      password !== '' &&
      checkPassword !== '' &&
      emailCheck &&
      userNameCheck &&
      passwordCheck &&
      checkPasswordCheck
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    checkPassword,
    checkPasswordCheck,
    email,
    emailCheck,
    password,
    passwordCheck,
    userName,
    userNameCheck,
  ]);

  return (
    <div className="wrapper auth-wrapper">
      <div className="signup-container">
        <div className="cover-box">
          <img src="/assets/images/AI_Onbook_login.png" alt="" />
        </div>
        <div className="item-box register-box">
          <div className="box-inner">
            <form className="form-area register-form">
              <div className="form-title">
                <h1>회원가입</h1>
              </div>
              <div className="input-wrapper">
                <div className="form-input-group">
                  <label className={!emailCheck ? 'not-valid-input' : ''}>
                    이메일
                  </label>
                  <input
                    type="text"
                    className={`text-field-base input-self register-input ${
                      !emailCheck || existUser ? 'not-valid-input' : ''
                    }`}
                    placeholder="ex)coderlee@theailab.com"
                    value={email}
                    onChange={handleEmailValue}
                    onBlur={handleEmailDuplication}
                    name="username"
                    id="username"
                    autoComplete="new-password"
                  />
                  <div
                    className="error-text"
                    style={{
                      display: !emailCheck ? 'flex' : 'none',
                      marginBottom: '0px',
                    }}
                  >
                    이메일 형식이 올바르지 않습니다.
                  </div>
                  <div
                    className="error-text"
                    style={{ display: existUser ? 'flex' : 'none' }}
                  >
                    이미 존재하는 이메일 입니다.
                  </div>
                </div>
                <div className="form-input-group">
                  <label className={!userNameCheck ? 'not-valid-input' : ''}>
                    이름
                  </label>
                  <input
                    type="text"
                    className={`text-field-base input-self register-input ${
                      !userNameCheck ? 'not-valid-input' : ''
                    }`}
                    placeholder="본명"
                    value={userName}
                    onChange={handleUserNameValue}
                    name="name"
                    id="name"
                    autoComplete="new-password"
                  />
                  <div
                    className="error-text"
                    style={{ display: !userNameCheck ? 'flex' : 'none' }}
                  >
                    특수문자 제외 한글 2~4자로 입력해주세요.
                  </div>
                </div>
                <div className="form-input-group">
                  <label className={!passwordCheck ? 'not-valid-input' : ''}>
                    비밀번호 (8자 이상 자율조합)
                  </label>
                  <input
                    type="password"
                    className={`text-field-base input-self register-input ${
                      !passwordCheck ? 'not-valid-input' : ''
                    }`}
                    placeholder="기억하기 쉬운 조합 (숫자,영문,특문) 자유"
                    minLength={8}
                    value={password}
                    onChange={handlePasswordValue}
                    name="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </div>
                <div className="form-input-group">
                  <label
                    className={!checkPasswordCheck ? 'not-valid-input' : ''}
                  >
                    비밀번호 확인
                  </label>
                  <input
                    type="password"
                    className={`text-field-base input-self register-input ${
                      !checkPasswordCheck ? 'not-valid-input' : ''
                    }`}
                    placeholder="비밀번호 확인"
                    minLength={8}
                    value={checkPassword}
                    onChange={handleCheckPasswordValue}
                    name="rePassword"
                    id="rePassword"
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <div className="btn-wrapper">
                <p>
                  <Link to="/terms">이용약관</Link> 및&nbsp;
                  <Link to="/privacy">
                    개인정보 처리방침(수집, 이용, 제3자제공 등)
                  </Link>
                  &nbsp;을 확인하였고 이에 동의합니다.
                </p>
                <button
                  disabled={disabled}
                  className="btn-fill-accent"
                  onClick={handleClick}
                >
                  {' '}
                  다음{' '}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
