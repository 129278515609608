import React from 'react';
import { useHistory } from 'react-router-dom';
import ApplyButton from 'components/global/button/applyButton';
import useUser from 'store/modules/user/userHook';
import { informApplyData } from 'services/notice/notice';

const UsageFeeItem = ({ feeData, isMobile, openDefaultModal }) => {
  const {
    isLimit,
    period,
    amount,
    contentsRignts,
    invitedStudent,
    extraCharge,
    teacherAccount,
    buttonText,
  } = feeData;

  const extraChargeTxt = extraCharge ? extraCharge : '-';
  const bedgeColor =
    isLimit === '학생수 무제한'
      ? 'bedge-yellow'
      : isLimit === '학생수 제한'
      ? 'bedge-gray'
      : '';

  const { userData, isLoggedIn } = useUser();
  const history = useHistory();

  const funcApply = (buttonText) => {
    if (buttonText !== '신청하기') {
      // TODO: 문구(그냥 홈으로 가면 좀..)
      history.push('/course/2');
      return;
    }

    if (!isLoggedIn) {
      history.push({
        pathname: '/login',
        state: { destination: '/usageFee' },
      });
      return;
    }

    if (userData?.userRole === 2) {
      // 교사일 때
      informApplyData(isLimit, period, amount).then(() => {
        openDefaultModal({ text: '신청되었습니다 😆' });
      });
    } else {
      // 학생일 때
      openDefaultModal({
        text: `교사만 신청할 수 있습니다.\n선생님께 AI OnBook 링크를 공유해보세요!\n혹시 학생으로 신청하고 싶은 경우\n채널톡으로 문의주세요!`,
        button: true,
      });
    }
  };

  return (
    <>
      {isMobile || (
        // 데스크톱
        <ul className="usage-fee-item is-desktop">
          <li className="monthly-fee">
            <h3>{period}</h3>
            <strong>{amount}</strong>
          </li>
          <li className="contents-rights">{contentsRignts}</li>
          <li className="invite-students">
            {invitedStudent || (
              <img
                src="/assets/icons/ic-close-normal-line-dk.svg"
                alt="없음"
                aria-hidden
              />
            )}
          </li>
          <li className="extra-charge">{extraChargeTxt}</li>
          <li className="teacher-account">{teacherAccount}</li>
          <li className="button-container">
            <ApplyButton
              onclick={() => funcApply(buttonText)}
              text={buttonText}
            />
          </li>
        </ul>
      )}

      {isMobile && (
        // 모바일
        <ul className="usage-fee-item is-mobile">
          <span className={`bedge ${bedgeColor}`}>{isLimit}</span>
          <li className="monthly-fee">
            <h3>{period}</h3>
            <strong>{amount}</strong>
          </li>
          <li className="contents-rights">
            <dl>
              <dt>콘텐츠 권한</dt>
              <dd>{contentsRignts}</dd>
            </dl>
          </li>
          <li className="invite-students">
            <dl>
              <dt>학생 초대</dt>
              <dd>
                {invitedStudent || (
                  <img
                    src="/assets/icons/ic-close-normal-line-dk.svg"
                    alt="없음"
                    aria-hidden
                  />
                )}
              </dd>
            </dl>
          </li>
          <li className="extra-charge">
            <dl>
              <dt>최대 인원 초과시</dt>
              <dd>{extraChargeTxt}</dd>
            </dl>
          </li>
          <li className="teacher-account">
            <dl>
              <dt>교사 계정</dt>
              <dd>{teacherAccount}</dd>
            </dl>
          </li>
          <li className="button-container">
            <ApplyButton
              onclick={() => funcApply(buttonText)}
              text={buttonText}
            />
          </li>
        </ul>
      )}
    </>
  );
};

export default UsageFeeItem;
