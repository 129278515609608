import { CommonLayout, FooterLayout } from 'components/global';
import React from 'react';
import { Link } from 'react-router-dom';
import 'styles/stylesheets/policy.css';

const Terms = (): JSX.Element => {
  return (
    <>
      <CommonLayout buttonHide />
      <div className="policy-page">
        <div className="policy-content">
          <div className="tab">
            <Link className="ft-medium tab-item" to="/privacy">
              개인정보 처리방침
            </Link>
            <Link className="ft-medium tab-item now-tab" to="/terms">
              이용약관
            </Link>
          </div>
          <div className="tab-panel">
            <h1 className="h1-policy">1장 총칙</h1>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 1조 (목적)
            </h2>
            <ul className="ul-policy contents">
              <li>
                이 약관은 주식회사 더에이아이랩이 운영하는 서비스를 이용함에
                있어 적용되는 이용 조건 및 절차, 회사와 이용자의 권리와 의무 및
                책임사항 등을 규정하고, 이를 통하여 상호 신뢰를 증진시키는 것을
                목적으로 합니다.
              </li>
            </ul>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 2조 (용어 정의)
            </h2>

            <ul className="ul-policy contents">
              <li>
                이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.
                <br />
              </li>
              <li>
                회사: 주식회사 더에이아이랩(이하 “회사”라 합니다)을 의미합니다.
                <br />
              </li>
              <li>
                서비스: 회사가 운영하고 있는 모든 서비스를 의미합니다.
                <br />
              </li>
            </ul>

            <h2 className="contents">
              2조 1항 - 유저의 형태와 용어
              <br />
            </h2>
            <ol className="ol-policy contents">
              <li>
                리더(Leader) : 회사의 서비스를 이용하여, 교육을 제공할 수 있는
                회원을 말합니다.
                <br />
              </li>
              <li>
                프로리더(Pro Leader) : 회사의 서비스를 이용하여, 교육을 제공할
                수 있는 회원중 서비스 내에서 교육자로서 공식 인증을 받은 회원을
                말합니다.
                <br />
              </li>
              <li>
                멤버(Member) : 회사의 서비스를 이용하여, 학습을 제공받을 수 있는
                회원을 말합니다.
                <br />
              </li>
              <li>
                회원 : 회원가입 절차를 통해 이 약관에 동의하고 개인정보를
                제공하여 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
                <br />
              </li>
              <li>
                비회원 : 회원으로 가입하지 않고, 제약이 있는 상태에서 제한된
                서비스를 이용하는 자를 말합니다.
                <br />
              </li>
              <li>
                이용자 : 회사가 운영하는 웹사이트, 어플리케이션 등에 접속하여 이
                약관에 따라 서비스를 받는 회원 및 비회원을 말합니다.
                <br />
              </li>
              <li>
                어드민(Administrator) : 서비스의 전반적인 관리와 원활한 운영을
                위하여 회사가 선정한 자를 말합니다.
                <br />
              </li>
            </ol>
            <h2 className="contents">
              2조 2항 - 공간의 형태와 용어
              <br />
            </h2>
            <ol className="ol-policy contents">
              <li>
                그룹 : 단체(학교, 기업, 기관 등)가 운영하는 교육 공간,
                홈페이지를 말합니다.
                <br />
              </li>
              <li>
                클래스룸 : 특정 교육자가 소유자로서 학습자를 관리하고 운영하는
                수업 공간을 말합니다.
                <br />
              </li>
              <li>
                그룹 클래스룸 : 그룹안에 소속된 클래스룸을 말합니다.
                <br />
              </li>
              <li>
                채널 : 개인이 운영하는 교육공간, 홈페이지를 말합니다.
                <br />
              </li>
              <li>
                채널 클래스룸 : 채널에 소속된 클래스룸을 말합니다.
                <br />
              </li>
              이 약관에서 사용하는 용어의 정의는 전항에서 정한 것 외에는
              회사에서 정한 서비스 이용안내에서 정하는 바에 의하며, 이에 정하지
              아니한 것은 「약관의 규제에 관한 법률」, 「전자상거래 및
              소비자보호에 관한 법률」 등 관계법령 및 일반적인 관례에 따릅니다.
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제3조 (약관의 효력 및 변경)
            </h2>
            <ul className="ul-policy contents">
              <li>
                이 약관은 회사의 서비스를 이용하고자 하는 모든 회원 및
                이용자에게 그 효력이 발생됩니다.
                <br />
              </li>
              <ul
                className="ul-policy contents"
                style={{ listStyleType: 'disc' }}
              >
                <li>
                  회사에서는 서비스 이용 수수료 등을 포함한 서비스 이용안내 및
                  이 약관의 내용을 사전 고지 없이 변경할 수 있습니다. 변경된
                  약관은 홈페이지 게시 또는 전자우편 등의 기타 방법으로
                  공지함으로써 효력이 발생됩니다.
                  <br />
                </li>
                <li>
                  전항에 따라 회사가 변경된 약관을 공지한 경우, 회원은 변경된
                  약관의 효력발생일로부터 (7)일 이내에 거부의사를 표시하지
                  아니하고, 웹사이트, 어플리케이션 또는 서비스를 계속 이용할
                  경우에 변경된 약관에 동의한 것으로 간주합니다. 만일 변경된
                  약관에 대해 동의하지 않는 경우에는 회원은 언제든지 자유롭게
                  회원탈퇴를 통해 약관의 내용을 거부할 수 있으며, 변경된 약관에
                  대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여 회사는
                  책임을 지지 않습니다.
                  <br />
                </li>
                <li>
                  이 약관에 동의하는 것은 회사가 운영하는 서비스의 홈페이지를
                  정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을
                  의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는
                  회원의 피해에 대하여는 회사는 책임지지 않습니다.
                  <br />
                </li>
              </ul>
            </ul>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 4조 (약관 외 사항의 처리)
            </h2>
            <ul className="ul-policy contents">
              <li>
                이 약관과 개인정보취급방침에 명시되지 않은 사항에 대해서는
                「약관의 규제에 관한 법률」, 「전자상거래 및 소비자보호에 관한
                법률」 등 관련법령 및 회사가 정한 서비스 이용 안내에 따릅니다.
                <br />
              </li>
            </ul>

            <h1 className="h1-policy">제2장 회원정보</h1>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제5조 (회원가입 및 인증 관리)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회원가입은 회원이 되고자 하는 이용자가 약관의 내용에 동의를
                하고, 회사가 정한 가입 양식에 따라 회원정보(이용자ID),
                비밀번호를 기입하여 회원가입 신청을 함으로써 완료됩니다.
                <br />
              </li>
              <li>
                회원 중 리더(교육할 수 있는 회원)는 “리더”와 “프로리더”로
                나뉩니다. 모든 리더는 교육 활동을 할 수 있습니다. 다만,
                프로리더는 공식인증 마크를 달게 되는 차이가 있으며, 프로리더가
                되기 위해서는 교육 용역을 제공할 수 있는 자격을 입증하여야
                합니다. 회사는 리더의 인증요청에 응할 의무가 있으며, 다음 각
                호에 해당하는 신청에 대하여는 인증을 승인하지 않을 수 있습니다.
                <br />
              </li>
              <ol className="ol-policy">
                <li>
                  경력이나 역량에 대한 내용을 허위로 기재한 경우
                  <br />
                </li>
                <li>
                  부정한 용도로 서비스를 이용하고자 하는 경우
                  <br />
                </li>
                <li>
                  타인의 저작권을 침해하거나 침해할 소지가 있는 경우
                  <br />
                </li>
                <li>
                  기타 리더 신청자의 귀책사유로 승인이 곤란한 경우
                  <br />
                </li>
              </ol>

              <li>
                회원이 서비스 이용과 관련하여 다음 각 호에 해당하는 행위를 할
                경우, 회사는 메일이나 메시지 전송 등으로 해당 회원에게 내용을
                통보한 후 회원의 자격을 박탈할 수 있습니다.
                <br />
              </li>
              <ol className="ol-policy">
                <li>
                  타인 또는 서비스에 해를 끼친 경우
                  <br />
                </li>
                <li>
                  사회적 물의를 일으킨 경우
                  <br />
                </li>
                <li>
                  욕설 및 음란물 등 서비스에 불건전한 내용을 게시한 경우
                  <br />
                </li>
              </ol>
            </ol>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제6조 (회원 정보의 변경)
            </h2>
            <ul className="ul-policy contents">
              <li>
                회원은 서비스를 이용하면서 관련 개인정보가 변경된 경우, 지체없이
                해당 정보를 수정하여야 합니다. 해당 정보를 수정하지 않음으로
                인하여 발생하는 책임은 회원에게 있습니다.
                <br />
              </li>
            </ul>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 7조 (회사의 의무)
            </h2>
            <ul className="ul-policy contents">
              <li>
                회사는 계속적이고 안정적인 서비스를 제공하기 위하여 최선을
                다하여 노력합니다.
                <br />
              </li>
              <li>
                회사는 회원이 안전하게 서비스를 이용할 수 있도록
                개인정보처리방침을 공시하고 준수합니다.
                <br />
              </li>
            </ul>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 8조 (회원의 의무)
            </h2>
            <ul className="ul-policy contents">
              <li>
                회원은 서비스 이용과 관련하여 다음 각호의 행위를 하여서는
                안됩니다.
              </li>
              <ol className="ol-policy contents">
                <li>
                  회원가입 혹은 변경 시 허위의 내용을 등록하는 행위
                  <br />
                </li>
                <li>
                  회사와 기타 제3자의 저작권 등 지적재산권을 침해하는 행위
                  <br />
                </li>
                <li>
                  다른 이용자의 계정을 부정하게 사용하는 행위
                  <br />
                </li>
                <li>
                  다른 이용자의 개인정보를 해당 이용자의 사전 동의 없이 수집,
                  저장 또는 공개하는 행위
                  <br />
                </li>
                <li>
                  회사 직원, 관리자를 포함한 타인을 사칭하는 행위
                  <br />
                </li>
                <li>
                  폭력적인 메시지, 공서양속에 반하는 정보를 게시하는 행위
                  <br />
                </li>
                <li>
                  기타 법령 위반 등 일체의 불법적인 행위
                  <br />
                </li>
                <li>
                  서비스 운영(회원들의 서비스 이용, 회사의 목적 등)에 해를 줄
                  우려가 있는 모든 행위
                  <br />
                </li>
              </ol>
            </ul>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 9조 (개인정보의 보호 및 이용)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련
                법령을 준수하고, 회원의 개인정보를 보호하기 위하여 노력합니다.
                <br />
              </li>
              <li>
                회원의 개인정보에 대한 수집, 이용, 제공, 위탁 등(이하 ‘처리’라
                합니다)의 구체적인 내용은 회사의 웹사이트, 어플리케이션 등에
                공지된 회사의 개인정보처리방침에 기재되어 있으며, 회사는
                이용자의 회원 가입 신청 시 동 개인정보에 대한 이용자의 동의를
                얻으며, 이에 따라 이용자의 개인정보를 처리합니다.
                <br />
              </li>
              <li>
                회사는 필요에 따라 개인정보처리방침의 내용을 변경할 수 있습니다.
                <br />
              </li>
              <li>
                회원의 귀책사유로 인해 노출된 정보에 대해서 회사는 일체의 책임을
                지지 않습니다.
                <br />
              </li>
            </ol>

            <h1 className="h1-policy">3장 서비스의 이용</h1>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제10 조 (서비스의 종류 및 이용)
              <br />
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 회원에게 아래와 같은 서비스를 제공합니다.
                <br />
              </li>
              <ol className="ol-policy">
                <li>
                  <b className="ft-bold"> 교육 중개 서비스</b>
                  <br />
                  교육 중개 서비스는 회원 각자의 자기결정에 의하여 회원 상호간에
                  강의 거래가 이루어질 수 있도록 온라인으로 회원의 정보를
                  제공하는 서비스입니다. 회사는 회원간 거래의 안전성 및 신뢰성을
                  증진시킬 수 있는 정보를 제공하려고 노력하지만, 회원 간에
                  성립된 거래와 관련된 책임은 거래당사자인 회원들 스스로가
                  부담하여야 합니다. 여기서 말하는 교육은 강의, 멘토링, 튜터링
                  등 모든 교육의 형태를 제한하지 않고 말합니다.
                  <br />
                </li>
                <li>
                  <b className="ft-bold">강의 콘텐츠 제공 서비스</b>
                  <br />
                  강의 콘텐츠 제공 서비스는 회사가 직접 제작한 콘텐츠를 회원에게
                  제공하거나 기타 다른 회사와의 제휴계약 등을 통해 콘텐츠를
                  회원에게 제공하는 것을 말합니다.
                  <br />
                </li>
                <li>
                  <b className="ft-bold">학습 및 교육 관리 시스템</b>
                  <br />
                  회원들이 학습 또는 교육을 더 효과적으로 관리할 수 있도록 돕는
                  관리 시스템을 말합니다.
                  <br />
                </li>
                <li>
                  <b className="ft-bold">각종 교육 관련 서비스</b>
                  <br />
                  그 외 교육에 관련된 다양한 서비스들을 말합니다.
                  <br />
                </li>
                <li>
                  <b className="ft-bold">
                    그 외 회사가 앞으로 제공할 모든 서비스
                  </b>
                  <br />
                  회사는 이 외에도 회원들을 위해 다양한 서비스를 제공할 수
                  있습니다.
                  <br />
                </li>
              </ol>
              <li>
                서비스의 신청 절차 및 결제방식 등은 회사의 정책에 따라 추가,
                변경될 수 있습니다.
                <br />
              </li>
              <li>
                회사는 회원 간의 직거래로 인하여 발생하는 문제에 대해서 책임을
                지지 않습니다. 단, 신고가 접수되거나 회사에 피해를 주는 경우, 이
                약관을 위반한 경우에는 해당 회원은 서비스 이용에 제한을 받을 수
                있습니다. <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제11조 (서비스의 이용 제한)
              <br />
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 서비스의 안전성과 신뢰성을 위하여 이 약관에서 정한 바에
                따라 회원자격의 정지, 서비스의 이용 제한 등 기타 필요한 조치를
                취할 수 있습니다. 위와 같은 조치를 취할 경우, 회사는 회원에게
                유선 또는 기타 방법으로 이를 통보하며, 회원의 개인정보가
                정확하지 않거나, 연락이 두절되는 경우 등 긴급을 요하는 경우 통보
                전에 우선 조치할 수 있습니다.
                <br />
              </li>
              <li>
                회사는 교육 중개 서비스 회원이 다음 각 호의 사유에 해당하는
                경우, 횟수 및 내용의 심각성에 따라 해당 회원에게 개선 요청을
                하거나, 서비스 이용에 제한을 줄 수 있습니다.
                <br />
              </li>
              <ol className="ol-policy">
                <li>회원이 불법홍보로 플랫폼을 사용하는 경우</li>
                <li>
                  일방의 회원이 사전에 연락을 취하지 않고, 정해진 수업 장소,
                  시간에 불참하는 경우
                </li>
                <li>
                  수업 진행에 대해 회원 간 상호 합의가 이루어진 이후, 어느
                  일방이 수업 시작 시각 전 (6)시간 이내에 일방적으로 취소 통보를
                  하는 경우
                </li>
                <li>리더의 평가점수가 현저히 낮은 경우</li>
              </ol>
              <li>
                전항에서 규정한 경우 외에도, 회원이 법령 위반, 불법 행위를 행한
                경우 회사는 회원의 자격을 적절한 방법으로 제한 및 정지, 상실시킬
                수 있습니다.
                <br />
              </li>
              <li>
                회원은 본조에 따른 회사의 이용 제한에 대하여 이의 신청을 할 수
                있습니다. 회원의 이의신청이 정당한 경우 회사는 회원이 서비스를
                이용할 수 있도록 필요한 조치를 취합니다.
                <br />
              </li>

              <li>
                서비스의 신청 절차 및 결제방식 등은 회사의 정책에 따라 추가,
                변경될 수 있습니다. <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제12조 (리더의 의무)
            </h2>
            <ol className="ol-policy contents">
              <li>
                리더는 이 약관과 회사가 정한 정책에 따라, 경력에 대한 정보를
                제공하고 수업을 등록, 수업 내용에 대해 준비한 후 수업을
                진행하며, 만약 멤버와 분쟁이 있다면 이에 응해야 합니다.
                <br />
              </li>
              <li>
                리더는 멤버의 요청 및 질문에 가능한 한 24시간 이내 응대할 것이
                권장됩니다.
                <br />
              </li>
              <li>
                리더는 매칭된 멤버에 대해서는 성심과 성의를 다해야 합니다.
                <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제13조 (멤버의 권리 및 의무)
            </h2>
            <ol className="ol-policy contents">
              <li>
                권리 : 멤버는 리더가 일방적으로 수업을 취소 또는 미이행하거나,
                연락이 두절된 경우, 혹은 수업 내용에 불만 등이 있을 경우, 직접
                리더에게 문제 제기할 수 있고, 필요한 경우 회사를 통해 문제
                제기할 수도 있습니다.
                <br />
              </li>
              <li>
                의무 : 멤버는 리더를 교육자이자 하나의 사람으로서 존중할 수
                있도록 합니다. <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제14조 (권리의 귀속)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 회사의 서비스 제공과 관련하여, 회원이 공개적으로 등록한
                저작물을 사용할 수 있는 권리를 가집니다. 공개적으로 등록한
                저작물은 다른 회원이 서비스 내에서 자유롭게 이용할 수 있습니다.
                <br />
              </li>
              <li>
                회원은 회사의 웹사이트, 어플리케이션 등에 등록된 저작물을 회사의
                동의없이 회사의 서비스 이용 이외의 용도로 복사하거나, 유통할 수
                없습니다.
                <br />
              </li>
              <li>
                회원은 회사 혹은 제3자가 공개적으로 등록한 저작물을 회사의 사전
                서면 동의 없이, 양도, 판매, 처분할 수 없습니다. <br />
              </li>
            </ol>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제15조 (양도의 금지)
            </h2>
            <ul className="ul-policy contents">
              <li>
                회원은 이 약관에 의하여 생긴 서비스 이용 권리 등 권리의무를
                제3자에게 대여, 양도하거나 담보의 제공에 이용할 수 없습니다.
              </li>
            </ul>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제16조 (면책)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사가 천재지변 또는 이에 준하는 불가항력, 정보통신설비의
                보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여 서비스를
                제공할 수 없는 경우, 회사는 고의 또는 중과실이 없는 한 서비스
                제공에 관한 책임이 면제됩니다.
                <br />
              </li>
              <li>
                회사는 이용자 또는 회원의 귀책 사유로 인한 서비스 이용의 장애에
                대하여 책임을 지지 않습니다.
                <br />
              </li>
              <li>
                회사는 회원이 서비스의 이용을 통해 예상했던 성취를 도달하지
                못하거나, 서비스를 통하여 제공되는 교육 내용의 하자, 미비 등으로
                인하여 손해를 입었다고 하더라도, 회사의 고의 또는 중과실이 없는
                한 책임을 지지 않습니다.
                <br />
              </li>
              <li>
                회원이 게재한 정보, 자료, 사실의 허위·위법성 등과 관련하여,
                회사는 해당 정보의 정확성, 신뢰성, 안정성 등을 보증하지 않으며,
                그와 관련하여 회사가 해당 정보가 허위임을 명백히 인지하였다는
                등의 특별한 사정이 없는 한 책임을 지지 않습니다.
                <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제17조 (손해배상)
            </h2>
            <ul className="ul-policy contents">
              <li>
                당사자 일방이 이 약관상 의무를 위반함으로 인하여 상대방에게
                손해가 발생한 경우, 귀책있는 당사자는 상대방이 입은 손해를
                배상합니다.
              </li>
            </ul>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제18조 (분쟁의 해결)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회원은 게시물과 관련된 저작권 침해, 명예훼손 또는 개인정보 및
                서비스 이용과 관련된 사항에 관한 문제제기 등을 원할 경우 회사가
                운영하는 고객센터를 통하여 접수하고 처리를 요청할 수 있습니다.
                <br />
              </li>
              <li>
                서비스 이용과 관련하여 회사와 회원간 발생한 분쟁에 대해 회사를
                당사자로 하는 소송을 제기하는 경우에는 회사의 본사 주소지를
                관할하는 법원을 관할법원으로 합니다. <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제19조 (규정의 준용)
            </h2>
            <ul className="ul-policy contents">
              <li>
                이 약관에 명시되지 않은 사항에 대해서는 대한민국의 관계법령에
                의하고, 법에 명시되지 않은 부분에 대하여는 관례에 의합니다.
              </li>
            </ul>
            <h1 className="h1-policy">4장 거래 및 환불</h1>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제20조 (거래 규정)
            </h2>
            <ol className="ol-policy contents">
              <li>
                서비스의 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는
                규정에 따릅니다. <br />
              </li>
              <li>
                “회사”는 거래되는 상품을 상황에 따라 선택적으로 제공할 수
                있습니다. 그 내용은 다음과 같습니다.
                <ul className="ul-policy">
                  <li>
                    상품은 판매 가능한 무형의 서비스(기능, 콘텐츠 등)와 유형의
                    제품(교재 등) 모두를 말합니다. <br />
                  </li>
                  <li>
                    제공 방식 (기간, 구독, 횟수, 단품, 옵션 등) <br />
                  </li>
                  <li>
                    결제 방식 (쿠폰, 포인트, 금액, 할인율 등) <br />
                  </li>
                </ul>
                <br />
              </li>
              <li>
                할인의 기간은 상황에 따라 사전 공지없이 조기 마감 또는 연장될 수
                있습니다.
                <br />
              </li>
              <li>
                상품은 당사의 일정이나 상황에 따라 추가 또는 업데이트 될 수
                있습니다. <br />
              </li>
              <li>
                “회사”는 거래의 이행을 위하여 반드시 필요한 회원의 개인정보를
                추가적으로 요구할 수 있으며, “회원”은 “회사”가 요구하는
                개인정보를 정확하게 제공해야 합니다. <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 21조 (상품 유형별 환불 규정)
            </h2>
            <ol className="ol-policy contents">
              <li>
                상품 유형별로 환불 규정이 별도로 적용 됩니다. <br />
              </li>
              <li>
                수강기간, 상태, 진도에 따라서도 환불 금액이 별도로 적용됩니다.
                수강상태는 아래와 같습니다.
                <ul className="ul-policy">
                  <li>
                    수강전 : 구매완료 후 7일 이내 + 커리큘럼 열람 전 모두 충족한
                    경우 <br />
                  </li>
                  <li>
                    수강중 : 커리큘럼을 한번이라도 열람했거나 7일 경과했을경우
                    자동 수강시작 <br />
                  </li>
                  <li>
                    수강완료 : 모든 커리큘럼을 수강했을 경우 <br />
                  </li>
                  <li>
                    수강전에는 자동 환불이 가능하며, 수강중에는 이메일로만
                    환불요청이 가능합니다. 환불요청시 환불에 필요한 정보를
                    제공해주어야 하며, 전달하지 않을 경우 책임은 회원에게
                    있습니다.
                    <a
                      href="https://coding-x.channel.io/"
                      target="_blank"
                      style={{
                        marginLeft: '4px',
                        color: '#7682ff',
                        textDecoration: 'underline',
                        // rel = 'noreferrer',
                      }}
                      rel="noreferrer"
                    >
                      문의하기
                    </a>
                    <br />
                  </li>
                </ul>
                <br />
              </li>
            </ol>
            <h2 className="contents">
              1.클래스룸 수강권
              <br />
            </h2>
            <table className="table-policy">
              <thead>
                <tr className="tr-policy">
                  <th className="th-policy">서비스 기간</th>
                  <th className="th-policy">환불 요청일</th>
                  <th className="th-policy">반환금액</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tr-policy">
                  <td className="td-policy td-terms-1" rowSpan={3}>
                    1개월 (30일) 이내
                  </td>
                  <td className="td-policy td-terms-1">수강 전</td>

                  <td className="td-policy td-terms-1">결제대금 전액</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">
                    수강 중, 수강기간 또는 수강진도 ⅓ 경과 전
                  </td>

                  <td className="td-policy">결제대금의 ⅔에 해당하는 금액</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">
                    수강 중, 수강기간 또는 수강진도 ⅓ 경과 후
                  </td>

                  <td className="td-policy">환불 불가</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy" rowSpan={3}>
                    1개월 (30일) 초과
                  </td>
                  <td className="td-policy">수강 전</td>

                  <td className="td-policy">결제대금 전액</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">
                    수강 중, 수강기간 또는 수강진도 1개월 (30일) 이내
                  </td>

                  <td className="td-policy">
                    서비스 기간 1개월 (30일)이내 기준을 동일하게 적용
                  </td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">수강 중 1개월 (30일) 초과</td>

                  <td className="td-policy">환불 불가</td>
                </tr>
              </tbody>
            </table>
            <h2 className="contents">
              2.유형의 제품 (교재 등)
              <br />
            </h2>
            <table className="table-policy">
              <thead>
                <tr className="tr-policy">
                  <th className="th-policy">서비스 기간</th>
                  <th className="th-policy">환불 요청일</th>
                  <th className="th-policy">반환금액</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tr-policy">
                  <td className="td-policy td-terms-1" rowSpan={3}>
                    1개월 (30일) 이내
                  </td>
                  <td className="td-policy td-terms-1">배송시작 전</td>

                  <td className="td-policy td-terms-1">결제대금 전액</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">수령일로부터 7일 경과 전</td>

                  <td className="td-policy">
                    결제대금 전액 - 반송비, 배송비 제외
                  </td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">수령일로부터 7일 경과 후</td>

                  <td className="td-policy">환불 불가</td>
                </tr>
              </tbody>
            </table>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              아래의 경우에는 유형의 제품에 대한 환불이 불가합니다.
            </h2>
            <ol className="ol-policy contents">
              <li>
                “회원"의 귀책으로 인한 제품 훼손으로 재판매가 불가한 경우
                <br />
              </li>
              <li>
                “회원"의 사용 또는 시간경과, 일부 소비로 제품의 가치가 감소한
                경우 <br />
              </li>
              <li>
                “회원"이 제품의 포장을 훼손한 경우 <br />
              </li>
              <li>
                수업 내용의 불만족 및 단순변심의 경우 <br />
              </li>
              <li>
                그 외 거래의 안전 등 법령에 정하여진 사유가 있는 경우 <br />
              </li>
            </ol>
            <h2 className="contents">
              3.캠프 수강권
              <br />
            </h2>
            <table className="table-policy">
              <thead>
                <tr className="tr-policy">
                  <th className="th-policy">개강 여부</th>
                  <th className="th-policy">환불 요청일</th>
                  <th className="th-policy">반환금액</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tr-policy">
                  <td className="td-policy td-terms-1">개강 전</td>
                  <td className="td-policy td-terms-1">-</td>
                  <td className="td-policy td-terms-1">
                    결제금액 전액 - 무료 혜택 비용
                  </td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy" rowSpan={3}>
                    개강 후
                  </td>
                  <td className="td-policy">7일 이내</td>

                  <td className="td-policy">결제금액 ⅔ - 무료 혜택 비용</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">7일 이후</td>
                  <td className="td-policy">결제금액 ½ - 무료 혜택 비용</td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">14일 이후</td>
                  <td className="td-policy">환불 불가</td>
                </tr>
              </tbody>
            </table>
            <ol className="ol-policy contents">
              <li>
                무료 사은품 및 이벤트 혜택을 받은 경우 그 비용을 제하고
                환불됩니다.
                <br />
              </li>
              <li>
                캠프 내부에 포함된 동영상클래스와 라이브클래스는 오로지 캠프
                수강권을 기준으로 환불됩니다.
                <br />
              </li>
            </ol>
            <h2 className="contents">
              4.웨비나 수강권
              <br />
            </h2>
            <table className="table-policy">
              <thead>
                <tr className="tr-policy">
                  <th className="th-policy">개강 여부</th>
                  <th className="th-policy">반환금액</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tr-policy">
                  <td className="td-policy td-terms-2">개강 2일 전</td>
                  <td className="td-policy td-terms-2">
                    결제금액 전액 - 무료 혜택 비용 및 수수료
                  </td>
                </tr>
                <tr className="tr-policy">
                  <td className="td-policy">개강 1일 전</td>
                  <td className="td-policy">환불 불가</td>
                </tr>
              </tbody>
            </table>
            <ol className="ol-policy contents">
              <li>
                무료 사은품 및 이벤트 혜택을 받은 경우 그 비용을 제하고
                환불됩니다. <br />
              </li>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제 22조 (회사의 환불 규정)
            </h2>
            <ol className="ol-policy contents">
              <li>
                “회원"이 유료 서비스에 대한 결제를 완료한 때 유료 서비스 이용
                계약이 성립된 것으로 봅니다. <br />
              </li>
              <li>
                “회원”이 할인(또는 포인트, 쿠폰)에 의해 서비스 대금을 결제하였을
                경우, 서비스 대금 반환금액의 기준은 할인이 적용되어 실제 결제한
                금액에 의하여 정합니다. 다만 이벤트성, 비정규적, 특별기획 서비스
                등의 경우 별도의 수강취소, 변경 및 환불규정이 적용될 수
                있습니다. “회사”는 이 경우 웹사이트에 관련사항을 고지합니다.{' '}
                <br />
              </li>
              <li>
                환불은 “회원"이 결제한 수단과 동일한 방식으로 함을 원칙으로
                합니다. 단, 기술적으로 불가능한 경우에는 “회사”는 “회원"에게
                합리적인 환불방법을 정하여 통지할 수 있습니다. “회원”이 해당
                방법에 협조하지 않아 발생하는 환불의 지연 등에 관한 모든
                불이익의 책임은 “회원”에게 있습니다. <br />
              </li>
              <li>
                결제대행사, 카드사 등에서의 결제 취소 시간 및 금융사의 전산마비
                등 “회사”의 책임영역 외의 사유로 인한 환불 지연에 대해서는
                “회사”가 책임지지 않습니다. <br />
              </li>
              <li>
                결제수단에 따라 실제 발생한 결제수수료 및 취소수수료 등의 금융
                수수료, 세금 기타 환불에 필요한 비용은 “회원”이 부담하며 이는
                환불 금액에서 차감됩니다. 단, “회사”의 귀책사유로 인한 환불시 위
                비용은 “회사”가 부담합니다. <br />
              </li>
              <li>
                회사는 환불과정에서 금융수수료가 발생하는 경우 금융거래수수료,
                제세공과금 등을 공제하고 환불할 수 있습니다.
                <br />
              </li>
              <li>
                회사는 회원이 관계 법령 또는 이용약관 등을 위반한 경우 이용약관
                및 정책에 따라 환불을 거부할 수 있습니다. <br />
              </li>
              <li>
                회원정보(이메일 주소, 실명 등)가 부정확하여 발생하는 환불의 지연
                등의 책임은 “회원”에게 있습니다. <br />
              </li>
              <li>
                “회사”의 환불 예외사항은 다음과 같습니다.
                <ul className="ul-policy">
                  <li>
                    “회원”이 관계법령 및 본 약관의 규정을 위반하여 “회사”로부터
                    강제탈퇴 처리가 되는 경우에는 본 조의 환불규정이 적용되지
                    않습니다. <br />
                  </li>
                  <li>
                    “회원탈퇴" 및 회원의 자격 박탈의 경우 환불이 불가합니다.{' '}
                    <br />
                  </li>
                  <li>
                    부정이용이 확인 된 구매 건에 대해서는 이유와 관계없이 환불이
                    불가합니다. <br />
                  </li>
                  <li>
                    “회원"의 단순 변심의 경우 환불이 되지 않습니다.
                    <br />
                  </li>
                  <li>
                    소모성, 일회성 유료서비스는 환불이 되지 않습니다.
                    <br />
                  </li>
                  <li>
                    “회사”가 “회원”에게 무료로 지급한 사이버머니나 할인쿠폰
                    등으로 인해 “회원”이 획득하게 된 금액에 대해서는 환불되지
                    않을 수 있습니다. <br />
                  </li>
                </ul>
                <br />
              </li>
              <li>
                본 조의 규정에도 불구하고 “회사”는 신뢰도 제고나 이벤트를 위해
                일시적으로 환불 조건을 완화할 수 있습니다. <br />
              </li>
            </ol>
            <h1 className="h1-policy">부칙</h1>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제1조 (시행일)
            </h2>
            <ul className="ul-policy contents">
              <li>이 약관은 2021년 06월 09일부터 적용됩니다.</li>
            </ul>
          </div>
        </div>
      </div>
      <FooterLayout />
    </>
  );
};

export default Terms;
