import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IsetGetCourseData } from './interfaces';
import useUser from 'store/modules/user/userHook';

const useGetCourse = (): IsetGetCourseData => {
  const [courseData, setCourseData] = useState();
  const [error, setError] = useState<string>('');
  const [courseDataIsLoading, setCourseDataIsLoading] = useState<boolean>(true);

  const { userData, logout } = useUser();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(`${process.env.REACT_APP_SERVER_BASE_URL}/courses`, {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          })
          .then((res) => {
            setCourseData(res.data);
            // 로그인 한 경우 + 임시 방편으로 유저의 구매 등급 유료인 경우,
            if (userData?.userPurchase === 2) {
              // 받아온 res.data(course) 내에 courseTime:0이 아닌 것(코스가 있는 것) 중 isLock: true (잠금) 인 경우 로그아웃
              res.data.map((course) => {
                if (course.courseTime !== 0 && course.isLock === true) {
                  // 여기서 로그아웃
                  logout();
                  history.push('/');
                }
              });
            } // End Of if Statement
          })
          .finally(() => {
            setCourseDataIsLoading(false);
          });
      } catch (err) {
        setError(err);
      }
    };
    if (courseDataIsLoading) {
      fetchData();
    }
  }, [courseDataIsLoading]);

  return { courseData, error, courseDataIsLoading };
};

export default useGetCourse;
