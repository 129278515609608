import { Add, Remove } from '@material-ui/icons';

export const OptionBar = ({
  index,
  dispatch,
  isQuiz,
  showContext,
  setShowContext,
}): JSX.Element => {
  const menus = ['텍스트', '박스', '이미지', '에디터'];
  const quizMenus = ['퀴즈 에디터', '퀴즈 객관식'];

  const handleContextToggle = (index) => {
    if (showContext === index) setShowContext(-1);
    else setShowContext(index);
  };

  const handleAddComponent = (index, type) => {
    if (type === 0) {
      dispatch({ type: 'ADD', item: 'text', isQuiz: false, index });
    } else if (type === 1) {
      dispatch({ type: 'ADD', item: 'box', isQuiz: false, index });
    } else if (type === 2) {
      dispatch({ type: 'ADD', item: 'image', isQuiz: false, index });
    } else if (type === 3) {
      dispatch({ type: 'ADD', item: 'editor', isQuiz: false, index });
    }

    setShowContext(-1);
  };

  const handleAddQuizComponent = (index, type) => {
    if (type === 0) {
      dispatch({ type: 'ADD', item: 'editorTest', isQuiz: true, index });
    } else if (type === 1) {
      dispatch({ type: 'ADD', item: 'multipleTest', isQuiz: true, index });
    }

    setShowContext(-1);
  };

  return (
    <div className="option-container">
      <button
        onClick={() => {
          handleContextToggle(index);
        }}
      >
        <Add />
      </button>
      <button
        onClick={() => {
          if (!window.confirm('정말 삭제하시겠습니까?\n되돌릴 수 없습니다.'))
            return;
          dispatch({ type: 'REMOVE', index });
        }}
      >
        <Remove />
      </button>
      {showContext === index && (
        <ul>
          {isQuiz
            ? quizMenus.map((menu, type) => (
                <li
                  onClick={() => {
                    handleAddQuizComponent(index, type);
                  }}
                  key={type}
                >
                  {menu}
                </li>
              ))
            : menus.map((menu, type) => (
                <li
                  onClick={() => {
                    handleAddComponent(index, type);
                  }}
                  key={type}
                >
                  {menu}
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};
