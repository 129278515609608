import axios from 'axios';
import { useState } from 'react';
import { IsetManageQuiz, ManageQuizI } from '../getProgress/interfaces';

const useManageQuiz = (): IsetManageQuiz => {
  const [manageQuiz, setManageQuiz] = useState<ManageQuizI[]>();
  const [manageQuizError, setManageQuizError] = useState<string>('');
  const [manageQuizIsLoading, setManageQuizIsLoading] = useState(-1);

  const fetchData = async (
    userId: number,
    classroomId: number,
    lessonId: number
  ) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/quizzes?classroomId=${classroomId}&lessonId=${lessonId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setManageQuiz(res.data);
        })
        .finally(() => {
          setManageQuizIsLoading(0);
        });
    } catch (err) {
      setManageQuizError(err);
    }
  };

  const getManageQuiz = (
    userId: number,
    classroomId: number,
    lessonId: number
  ) => {
    setManageQuizIsLoading(1);
    fetchData(userId, classroomId, lessonId);
  };

  return { getManageQuiz, manageQuiz, manageQuizError, manageQuizIsLoading };
};

export default useManageQuiz;
