import { useEffect, useReducer, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ComponentContainer } from './ComponentContainer';

export const ContentEditor = ({ lessonId }): JSX.Element => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD':
        return state
          .slice(0, action.index + 1)
          .concat([
            {
              id: 'new-' + uuidv4(),
              item: action.item,
              isQuiz: action.isQuiz,
              data: { questionChoice: [] },
            },
          ])
          .concat(state.slice(action.index + 1))
          .map((component, index) => {
            return {
              ...component,
              weight: index,
            };
          });
      case 'REMOVE':
        return state.filter((s, index) => index !== action.index);
      case 'EDIT':
        return state.map((s) =>
          s.id === action.id ? { ...s, data: action.data } : s
        );
      case 'LOAD':
        return [
          ...state,
          {
            id: action.id,
            item: action.item,
            isQuiz: action.isQuiz,
            weight: action.weight,
            data: action.data,
          },
        ];
      case 'ORDER':
        return action.components.map((component, index) => {
          return {
            ...component,
            weight: index,
          };
        });
      case 'CLEAR':
        return [];
    }
  };
  const [components, dispatch] = useReducer(reducer, []);
  const [loadDatas, setLoadDatas] = useState([]);

  const handleSaveButton = async () => {
    console.log(
      components.map((component) => {
        return {
          ...component,
          data: {
            ...component.data,
            content: component.data.content,
            questionChoice: component.data.questionChoice?.join('||'),
          },
        };
      })
    );

    await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/components/${lessonId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          components: components.map((component) => {
            return {
              ...component,
              data: {
                ...component.data,
                content: component.data.content,
                questionChoice: component.data.questionChoice?.join('||'),
              },
            };
          }),
        }),
      }
    ).then(async (res) => {
      if (res.status === 201) {
        console.log('success');
      } else {
        console.log('fail');
      }
    });
  };

  useEffect(() => {
    loadDatas.forEach((loadData) =>
      dispatch({
        type: 'LOAD',
        id: loadData.id,
        item: loadData.componentName,
        isQuiz: loadData.isQuiz,
        weight: loadData.weight,
        data: {
          title: loadData.title,
          content: loadData.content,
          code: loadData.code,
          questionChoice: loadData.questionChoice?.split('||'),
          answer: loadData.answer,
          explanation: loadData.explanation,
          color: loadData.color,
          image: loadData.imageUrl,
        },
      })
    );
  }, [loadDatas]);

  useEffect(() => {
    if (lessonId === 0) return;

    dispatch({ type: 'CLEAR' });

    const fetchComponent = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/components?lessonId=${Number(
          lessonId
        )}`
      )
        .then((results) => results.json())
        .then((data) => {
          setLoadDatas(data);
        });
    };

    fetchComponent();
  }, [lessonId]);

  const menus = ['텍스트', '박스', '이미지', '에디터'];
  const quizMenus = ['퀴즈 에디터', '퀴즈 객관식'];

  const handleAddComponent = (index, type) => {
    if (type === 0) {
      dispatch({ type: 'ADD', item: 'text', isQuiz: false, index });
    } else if (type === 1) {
      dispatch({ type: 'ADD', item: 'box', isQuiz: false, index });
    } else if (type === 2) {
      dispatch({ type: 'ADD', item: 'image', isQuiz: false, index });
    } else if (type === 3) {
      dispatch({ type: 'ADD', item: 'editor', isQuiz: false, index });
    }
  };

  const handleAddQuizComponent = (index, type) => {
    if (type === 0) {
      dispatch({ type: 'ADD', item: 'editorTest', isQuiz: true, index });
    } else if (type === 1) {
      dispatch({ type: 'ADD', item: 'multipleTest', isQuiz: true, index });
    }
  };

  return (
    <div className="content-container">
      <div className="normal-content-inner">
        <ComponentContainer
          components={components}
          dispatch={dispatch}
          isQuiz={false}
        />
        {components.filter((component) => !component.isQuiz).length === 0 ? (
          <div className="init-menu-container">
            {menus.map((menu, type) => (
              <div
                className="init-menu-inner"
                onClick={() => {
                  handleAddComponent(0, type);
                }}
                key={type}
              >
                {menu}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="quiz-header">연습문제</div>
      <div className="quiz-content-cover">
        <div className="quiz-content-inner">
          <ComponentContainer
            components={components}
            dispatch={dispatch}
            isQuiz={true}
          />
          {components.filter((component) => component.isQuiz).length === 0 ? (
            <div className="init-menu-container">
              {quizMenus.map((quizMenu, type) => (
                <div
                  className="init-menu-inner"
                  onClick={() => {
                    handleAddQuizComponent(components.length, type);
                  }}
                  key={type}
                >
                  {quizMenu}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <button className="save-button" onClick={() => handleSaveButton()}>
        저장
      </button>
    </div>
  );
};
