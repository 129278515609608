import Monaco from '@monaco-editor/react';

export const Editor = ({ id, component, dispatch }): JSX.Element => {
  const options = {
    fontSize: 20,
    selectOnLineNumbers: true,
    roundedSelection: false,
    minimap: {
      enabled: false,
    },
    padding: {
      top: 10,
      bottom: 10,
    },
    renderWhitespace: 'none' as
      | 'none'
      | 'boundary'
      | 'selection'
      | 'trailing'
      | 'all',
  };

  const handleEditorDidMount = (editor) => {
    editor.current = editor;
  };

  return (
    <div className="code-editor-component">
      <input
        className="editor-title"
        value={component.data.content}
        onChange={(e) =>
          dispatch({
            type: 'EDIT',
            id: id,
            data: { ...component.data, content: e.target.value },
          })
        }
        placeholder="에디터 제목을 입력하세요..."
      />
      <Monaco
        height="360px"
        defaultLanguage="python"
        theme="vs-dark"
        options={options}
        onMount={handleEditorDidMount}
        value={component.data.code}
        onChange={(value) =>
          dispatch({
            type: 'EDIT',
            id: id,
            data: { ...component.data, code: value },
          })
        }
      />
      <div className="editor-footer"></div>
      <div className="editor-result">
        <div className="result-header">실행결과</div>
        <div className="result-content">{'실행버튼을 눌러주세요 : )'}</div>
      </div>
    </div>
  );
};
