import { useEffect, useState } from 'react';
import { Confirm, MyPageForm, PasswordForm } from 'components/main';
import useUser from 'store/modules/user/userHook';
import { CommonLayout } from 'components/global';
import useUserInfo from 'hooks/admin/useUsers';

const MyPage = (): JSX.Element => {
  const { userData } = useUser();
  const [userInfo, getUserInfo] = useUserInfo();
  const [pane, setPane] = useState(0);
  useEffect(() => {
    getUserInfo(userData.id);
  }, [userData.id]);

  return (
    <>
      <CommonLayout />
      {userInfo !== undefined ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#ECEEFB',
          }}
        >
          <div className="mypage-container">
            {pane === 0 && <MyPageForm user={userInfo} setPane={setPane} />}
            {pane === 1 && <PasswordForm setPane={setPane} />}
            {pane === 2 && <Confirm />}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MyPage;
