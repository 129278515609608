import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import useUser from 'store/modules/user/userHook';
import { CommonLayout, FooterLayout } from 'components/global';
import { ClassroomCourse, NoClassroomCourse } from 'components/main';
import { useGetMember } from 'hooks/main';

interface MatchParams {
  courseId: string;
}

// props
// course url 뒤에 붙어있는 courseId 값을 가져오기 위함 ex) /course/1
const Course = ({ match }: RouteComponentProps<MatchParams>): JSX.Element => {
  const { isLoggedIn, userData, setClassroomId } = useUser();

  // 반 목록을 가지고 있는데 반이 세팅되지 않은 경우 반을 세팅
  // 반 목록 중 첫번째 반을 현재 반으로 설정
  const { memberResp, memberRespIsLoading } = useGetMember();
  useEffect(() => {
    if (
      isLoggedIn &&
      (userData.userRole === 2 || userData.userRole === 3) &&
      !memberRespIsLoading &&
      memberResp.length !== 0 &&
      (userData.classroomId === null || userData.classroomId === undefined)
    ) {
      setClassroomId(memberResp['0'].classroom.id);
    }
  }, [isLoggedIn, memberResp, memberRespIsLoading, setClassroomId, userData]);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  // 유저가 반을 가지고 있다면 ClassroomCourse가 보여짐
  // 유저가 반을 가지고 있지 않다면 NoClassroomCourse가 보여짐
  return (
    <div>
      <CommonLayout />
      {isLoggedIn &&
      userData.userAuth === 2 &&
      userData.classroomId !== undefined &&
      userData.classroomId !== null ? (
        <ClassroomCourse
          courseId={match.params.courseId}
          userId={userData.id}
          classroomId={userData.classroomId}
          memberResp={memberResp}
          memberRespIsLoading={memberRespIsLoading}
        />
      ) : (
        <NoClassroomCourse courseId={match.params.courseId} />
      )}
      <FooterLayout />
    </div>
  );
};

export default Course;
