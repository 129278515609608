export const CoursePane = ({
  content,
  dispatch,
  handleImageUpload,
}): JSX.Element => {
  const difficulty = ['쉬움', '보통', '어려움'];

  return (
    <>
      <input
        className="pane-item pane-input"
        value={content.title}
        onChange={(e) => {
          dispatch({ type: 'TITLE', title: e.target.value });
        }}
        placeholder="이름"
      />
      <input
        className="pane-item pane-input"
        value={content.summary}
        onChange={(e) => {
          dispatch({ type: 'SUMMARY', summary: e.target.value });
        }}
        placeholder="요약"
      />
      <input
        className="pane-item pane-input"
        value={content.detail}
        onChange={(e) => {
          dispatch({ type: 'DETAIL', detail: e.target.value });
        }}
        placeholder="상세"
      />
      <div style={{ display: 'flex' }}>
        <select
          className="pane-item pane-select"
          value={content.difficulty}
          onChange={(e) => {
            dispatch({ type: 'DIFFICULTY', difficulty: e.target.value });
          }}
          style={{ marginRight: '10px', flex: 1 }}
        >
          <option value={-1} hidden>
            난이도
          </option>
          {difficulty.map((d, index) => (
            <option value={d} key={index}>
              {d}
            </option>
          ))}
        </select>
        <input
          className="pane-item"
          value={content.time}
          onChange={(e) => {
            dispatch({ type: 'TIME', time: e.target.value });
          }}
          style={{ flex: 1 }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '10px',
        }}
      >
        {content.image !== '' && (
          <img
            src={content.image}
            alt=""
            style={{
              height: '300px',
              objectFit: 'contain',
              marginBottom: '10px',
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
          }}
        >
          <input
            className="pane-item pane-input upload-name"
            style={{
              flex: 1,
              marginRight: '10px',
              marginBottom: '0px',
            }}
            value={content.image}
            placeholder="이미지"
            disabled
          />
          <label className="label-pane" htmlFor="image_file">
            업로드
          </label>
          <input
            style={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: '0px',
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0, 0, 0, 0)',
              border: '0',
            }}
            type="file"
            onChange={(e) => handleImageUpload(e)}
            id="image_file"
          />
        </div>
      </div>
    </>
  );
};
