import { useCallback, useEffect, useState } from 'react';
import { IsetIsBookmarkData } from './interfaces';
import axios from 'axios';

// GET /bookmarks 모든 북마크 혹은 검색한 북마크 조회
const useBookmarkData = (
  courseId: number,
  classroomId?: number,
  lessonId?: number,
  setBookmarkState?: React.Dispatch<React.SetStateAction<boolean>>
): IsetIsBookmarkData => {
  const [bookmarkResp, setBookmarkResp] = useState();
  const [bookmarkRespError, setBookmarkRespError] = useState<string>('');
  const [bookmarkRespIsLoading, setBookmarkRespIsLoading] =
    useState<boolean>(true);

  const fetchLessonData = useCallback(() => {
    if (
      courseId !== undefined &&
      classroomId !== undefined &&
      lessonId !== undefined
    ) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/bookmarks?courseId=${courseId}&lessonId=${lessonId}&classroomId=${classroomId}`,
            {
              headers: {
                'Content-Type': 'application/json',
                accept: 'text/html; charset=utf-8',
                authorization: ('Bearer ' +
                  localStorage.getItem('token')) as string,
              },
            }
          )
          .then((res) => {
            if (res.data.length !== 0) {
              setBookmarkState(true);
            } else {
              setBookmarkState(false);
            }
            setBookmarkResp(res.data);
          })
          .finally(() => {
            setBookmarkRespIsLoading(false);
          });
      } catch (err) {
        setBookmarkRespError(err);
      }
    } else if (
      courseId !== undefined &&
      classroomId !== undefined &&
      lessonId === undefined
    ) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/bookmarks?courseId=${courseId}&classroomId=${classroomId}`,
            {
              headers: {
                'Content-Type': 'application/json',
                accept: 'text/html; charset=utf-8',
                authorization: ('Bearer ' +
                  localStorage.getItem('token')) as string,
              },
            }
          )
          .then((res) => {
            setBookmarkResp(res.data);
          })
          .finally(() => {
            setBookmarkRespIsLoading(false);
          });
      } catch (err) {
        setBookmarkRespError(err);
      }
    }
  }, [classroomId, courseId, lessonId, setBookmarkState]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return { bookmarkResp, bookmarkRespError, bookmarkRespIsLoading };
};

export default useBookmarkData;
