import * as styles from 'styles/stylesheets/style.css';
import classNames from 'classnames/bind';
import { SpinnerProps } from './interfaces';

const cn = classNames.bind(styles);

export const Spinner = ({
  width = '',
  height = '',
  isLanding = false,
  isHeader = false,
  isSubheader = false,
  isNoClass = false,
  isContentsDetails = false,
  isAboutCourse = false,
  isProgressBanner = false,
  isProgressResult = false,
  isClassroomResult = false,
  isBreadcrumb = false,
  isLessonContents = false,

  isRunCode = false,
  isSubmit = false,
  isLogin = false,
}: SpinnerProps): JSX.Element => {
  // * : 적용 위치
  // "THE! 인공지능"
  // isHeader
  // -------------------
  // "프로토이 고등학교 1학년 3반(select)"
  // isSubheader
  // -------------------
  // "해당 학급이 없어요"
  // isNoClass
  // -------------------
  // [연습문제] [레슨] [커리큘럼] - course
  // isContentsDetails
  // -------------------
  // "About this course"
  // isAboutCourse
  // --------------------
  // "고등학교 1학년 3반 /br 최근 "데이터 사이언스란?"를 수업했어요" - studentProgressPage
  // isProgressBanner
  // -----------------------
  // 학생 관리 테이블 - studentProgressPage
  // isProgressResult
  // ------------------------
  // 교실 관리 테이블 - studentClassroomPage
  // isClassroomResult
  // ------------------
  // 레슨 breadCrumb 링크
  // isBreadcrumb
  // --------------------
  // 레슨 컨텐츠
  // isLessonContents

  return (
    <>
      {isHeader ? (
        <div className={isHeader && 'is-header-div'}>
          <img
            src="/assets/icons/ic_spinner_line_24.svg"
            className={cn(isHeader && 'is-header', 'spinner-icon')}
            alt=""
          />
        </div>
      ) : (
        <>
          &nbsp;
          <div
            className={cn(
              isLanding && 'is-landing-div',
              isSubheader && 'is-subheader-div',
              isNoClass && 'is-no-class-div',
              isContentsDetails && 'is-contents-details-div',
              isAboutCourse && 'is-about-course-div',
              isProgressBanner && 'is-progress-banner-div',
              isProgressResult && 'is-progress-result-div',
              isClassroomResult && 'is-classroom-result-div',
              isBreadcrumb && 'is-breadcrumb-div',
              isLessonContents && 'is-lesson-contents-div',

              isRunCode && 'is-run-code-div',
              isSubmit && 'is-submit-div',
              isLogin && 'is-login-div'
            )}
          >
            <img
              src="/assets/icons/ic_spinner_line_24.svg"
              className={cn(
                isLanding && 'is-landing',
                isSubheader && 'is-subheader',
                isNoClass && 'is-no-class',
                isContentsDetails && 'is-contents-details',
                isAboutCourse && 'is-about-course',
                isProgressBanner && 'is-progress-banner',
                isProgressResult && 'is-progress-result',
                isClassroomResult && 'is-classroom-result',
                isBreadcrumb && 'is-breadcrumb',
                isLessonContents && 'is-lesson-contents',

                isRunCode && 'is-run-code',
                isSubmit && 'is-submit',
                isLogin && 'is-login',
                'spinner-icon'
              )}
              style={{ width: width, height: height }}
              alt=""
            />
          </div>
        </>
      )}
    </>
  );
};
