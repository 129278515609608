import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ChapterCards } from 'components/global';
import {
  useBookmarkData,
  useExerciseProblemData,
  useGetCorrectQuiz,
  useGetCourseDetails,
  useGetProgresses,
  useSearchLessonData,
} from 'hooks/main';
import useUser from 'store/modules/user/userHook';
import Skeleton from 'react-loading-skeleton';
import { ClassroomCourseProps } from './interfaces';

// 반을 생성한 선생님, 반이 있는 학생

// props
// courseId: coursePage url 뒤에 있는 courseId 값을 넘겨받은 것
// userId: redux userData내의 id 값을 넘겨받은 것
// classroomId: redux userData내의 classroomId 값을 넘겨받은 것
// memberResp: 해당 유저의 반 목록 정보를 넘겨받은 것
// memberRespIsLoading: memberResp 데이터 GET 요청이 로드중인지의 값을 넘겨받은 것
const ClassroomCourse = ({
  courseId,
  userId,
  classroomId,
  memberResp,
  memberRespIsLoading,
}: ClassroomCourseProps): JSX.Element => {
  const { isLoggedIn, userData, setClassroomId } = useUser();

  // 데이터 패치
  const { courseDetailsResp, courseDetailsRespIsLoading } =
    useGetCourseDetails(courseId);
  const { bookmarkResp, bookmarkRespIsLoading } = useBookmarkData(
    Number(courseId),
    classroomId
  );
  // 총 레슨 갯수 데이터를 가져오기 위함
  const { searchLessonResp, searchLessonRespIsLoading } = useSearchLessonData({
    courseId,
  });
  // 총 연습문제 갯수 데이터를 가져오기 위함
  const { exerciseProblemResp, exerciseProblemRespIsLoading } =
    useExerciseProblemData({ courseId });
  // 총 푼 문제 갯수 데이터를 가져오기 위함
  const { correctQuizResp, correctQuizRespIsLoading } =
    useGetCorrectQuiz(courseId);
  const { progressesResp, progressesRespIsLoading } = useGetProgresses(
    userId,
    classroomId,
    courseId
  );

  const history = useHistory();
  const goClassroom = () => {
    history.push('/classrooms');
  };

  const [selectOption, setSelectOption] = useState(
    userData.classroomId.toString()
  );
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectOption(e.target.value);
    setClassroomId(Number(e.target.value));
  };

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  return (
    <div className="body-inner chapter-inner" id="default-body">
      <div className="student-info">
        <div className="info-inner">
          <div className="course-title">
            {courseDetailsRespIsLoading ? (
              <h1>
                <Skeleton
                  width={250}
                  style={{ background: 'rgb(81,88,128)' }}
                />
              </h1>
            ) : (
              <h1>{courseDetailsResp.courseName}</h1>
            )}
          </div>
          {memberRespIsLoading && (
            <Skeleton
              height={32}
              width={250}
              style={{ background: 'rgb(81,88,128)' }}
            />
          )}
          {isLoggedIn &&
          !memberRespIsLoading &&
          (userData.userRole === 2 || userData.userRole === 3) ? (
            // 선생님or관리자의 경우 반 목록 select 박스
            <div className="course-choice">
              <p>
                <span>{userData.userName} 쌤의</span>
              </p>
              <select
                value={selectOption}
                name=""
                id="select-school"
                onChange={handleChange}
              >
                {memberResp.map((student) => (
                  <option
                    key={student.classroom.id}
                    value={student.classroom.id}
                  >
                    {student.classroom.classroomName}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            !memberRespIsLoading && (
              // 학생의 경우 단지 누구쌤의 무슨반인지 정보만 보여줌
              <div className="course-choice">
                <span>
                  {memberResp['0'].classroom.user.userName} 쌤의&nbsp;
                </span>
                <p>{memberResp['0'].classroom.classroomName}</p>
              </div>
            )
          )}
          <ul>
            <li>
              <img src="/assets/images/course_item01.png" alt="연습문제" />
              <p>연습문제</p>
              {exerciseProblemRespIsLoading || correctQuizRespIsLoading ? (
                <Skeleton
                  width={100}
                  height={25}
                  style={{ background: 'rgb(38,46,95)' }}
                />
              ) : (
                <>
                  {correctQuizResp.length}
                  <small>/{exerciseProblemResp.length}</small>
                  &nbsp;(
                  {/* 연습문제 갯수가 0인 경우 */}
                  {exerciseProblemResp.length === 0
                    ? 0
                    : ((correctQuizResp.length / exerciseProblemResp.length) *
                        100) %
                        1 ===
                      0
                    ? // 백분율 변환시 소수점이 존재하지 않는 경우
                      (correctQuizResp.length / exerciseProblemResp.length) *
                      100
                    : // 백분율 변환시 소수점이 존재하는 경우
                      (
                        (correctQuizResp.length / exerciseProblemResp.length) *
                        100
                      ).toFixed(1)}
                  %)
                </>
              )}
            </li>
            <li>
              <img src="/assets/images/course_item02.png" alt="레슨" />
              <p>레슨</p>
              {searchLessonRespIsLoading ? (
                <Skeleton
                  width={100}
                  height={25}
                  style={{ background: 'rgb(38,46,95)' }}
                />
              ) : (
                <>{searchLessonResp.length}개</>
              )}
            </li>
            <li>
              <img src="/assets/images/course_item03.png" alt="커리큘럼" />
              <p>커리큘럼</p>
              {courseDetailsRespIsLoading ? (
                <Skeleton
                  width={100}
                  height={25}
                  style={{ background: 'rgb(38,46,95)' }}
                />
              ) : (
                <>총 {courseDetailsResp.courseTime}시간</>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div className="banner-container">
        <h1>
          <img src="/assets/images/logo-codingx-3.png" alt="" />
          About this course
        </h1>
        {courseDetailsRespIsLoading ? (
          <Skeleton height={22} />
        ) : (
          <p>{courseDetailsResp.courseDetail}</p>
        )}
      </div>
      {/* 선생님or관리자인 경우에만 북마크 관련 정보 보여줌 */}
      {isLoggedIn && (userData.userRole === 2 || userData.userRole === 3) && (
        <div className="bookmark-container">
          <h1>
            <img src="/assets/icons/ic_bookmark_active_ye_24.svg" alt="" />
            Bookmark Lesson
          </h1>
          {bookmarkRespIsLoading ? (
            <Skeleton width={300} height={50} />
          ) : isLoggedIn && bookmarkResp.length !== 0 ? (
            <Link to={`/lesson/${bookmarkResp['0'].lesson.id}`}>
              <div className="card">
                <p>{bookmarkResp['0'].lesson.lessonName}</p>
              </div>
            </Link>
          ) : (
            <div className="card">
              <p>북마크된 레슨이 없습니다.</p>
            </div>
          )}
        </div>
      )}
      {!courseDetailsRespIsLoading &&
        !progressesRespIsLoading &&
        !bookmarkRespIsLoading && (
          <ChapterCards
            course_id={courseId}
            classroomCourseResp={courseDetailsResp}
            bookmarkResp={bookmarkResp}
            progressesResp={progressesResp}
          />
        )}
      {/* 선생님or관리자인 경우에만 반 관리로 이동할 수 있는 버튼 보여줌 */}
      {isLoggedIn && (userData.userRole === 2 || userData.userRole === 3) && (
        <div className="floating-btns">
          <button onClick={goClassroom}>
            <img src="/assets/icons/ic_people_normal_line_24.svg" alt="" />
            <span>반 관리</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ClassroomCourse;
