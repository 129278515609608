// 컴포넌트 정보를 관리하는 redux
// lessonEdit 페이지 hide data 관리를 위해 사용

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IgetComponentState } from './componentStateHook';

export type componentState = {
  componentState: IgetComponentState | undefined;
};

const initialState: componentState = {
  componentState: undefined,
};

const componentStateSlice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    setComponentStateAction(
      state: componentState,
      action: PayloadAction<IgetComponentState>
    ) {
      state.componentState = action.payload;
    },
    setHideTrueAction(state: componentState, { payload }) {
      const data = state.componentState;
      if (data) {
        data[payload].isHide = true;
      }
    },
    setHideFalseAction(state: componentState, { payload }) {
      const data = state.componentState;
      if (data) {
        data[payload].isHide = false;
      }
    },
  },
});

const { reducer, actions } = componentStateSlice;
export const {
  setComponentStateAction,
  setHideTrueAction,
  setHideFalseAction,
} = actions;
export default reducer;
