import React from 'react';

const RequestButton = (props): JSX.Element => {
  return (
    <button
      className="RequestBtn"
      style={{ width: props.width }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default RequestButton;
