import axios from 'axios';
import * as React from 'react';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    accept: 'text/html; charset=utf-8',
    authorization: ('Bearer ' + localStorage.getItem('token')) as string,
  },
};

function usePost(url, postData) {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [error, setError] = React.useState(null);

  const InvitePost = React.useCallback(() => {
    setLoading('loading...');
    setData(null);
    setError(null);
    axios
      .post(url, postData, axiosConfig)
      .then((res) => {
        setLoading(false);
        // checking for multiple responses for more flexibility
        // with the url we send in.
        res.data.content && setData(res.data.content);
        // res.content && setData(res.content);
      })
      .catch((err) => {
        setLoading(false);
        setError('An error occurred. Awkward..');
      });
  }, [url, postData]);

  return { InvitePost, data, loading, error };
}

export default usePost;
