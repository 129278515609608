import { useEffect, useReducer } from 'react';

import { ChapterPane } from './ChapterPane';
import { CoursePane } from './CoursePane';
import { LessonPane } from './LessonPane';

export const Modal = ({
  modal,
  setModal,
  condition,
  cards,
  setCards,
}): JSX.Element => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'LOAD':
        return {
          title: action.title,
          summary: action.summary,
          detail: action.detail,
          difficulty: action.difficulty,
          time: action.time,
          image: action.image,
          parent: action.parent,
        };
      case 'TITLE':
        return { ...state, title: action.title };
      case 'SUMMARY':
        return { ...state, summary: action.summary };
      case 'DETAIL':
        return { ...state, detail: action.detail };
      case 'DIFFICULTY':
        return { ...state, difficulty: action.difficulty };
      case 'TIME':
        return { ...state, time: action.time };
      case 'IMAGE':
        return { ...state, image: action.image };
      case 'PARENT':
        return { ...state, parent: action.parent };
    }
  };
  const [content, dispatch] = useReducer(reducer, {
    title: '',
    summary: '',
    detail: '',
    difficulty: '',
    time: '',
    image: '',
    parent: '',
  });

  useEffect(() => {
    if (condition.pos === 'chapter') {
      dispatch({ type: 'PARENT', parent: condition.course });
    } else if (condition.pos === 'lesson') {
      dispatch({ type: 'PARENT', parent: condition.chapter });
    }
  }, [condition.chapter, condition.course, condition.pos]);

  useEffect(() => {
    if (modal.open && modal.edit) {
      const card = cards.find((card) => card.id === modal.id);

      if (condition.pos === 'course') {
        dispatch({
          type: 'LOAD',
          title: card.courseName,
          summary: card.courseSummary,
          detail: card.courseDetail,
          difficulty: card.courseLevel,
          time: card.courseTime,
          image: card.thumbnailUrl,
        });
      } else if (condition.pos === 'chapter') {
        dispatch({
          type: 'LOAD',
          title: card.chapterName,
          summary: card.summary,
          image: card.thumbnailUrl,
          parent: card.course.id,
        });
      } else if (condition.pos === 'lesson') {
        dispatch({
          type: 'LOAD',
          title: card.lessonName,
          summary: card.summary,
          image: card.thumbnailUrl,
          parent: card.chapter.id,
        });
      }
    }
  }, [modal]);

  const handleSaveButton = async () => {
    if (modal.edit) {
      await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/${condition.pos}s/${modal.id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify(content),
        }
      ).then(async (res) => {
        if (res.status === 200) {
          const result = await res.json();
          setCards(cards.map((card) => (card.id === modal.id ? result : card)));
        } else {
          console.log('fail');
        }
      });
    } else {
      await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/${condition.pos}s`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify(content),
        }
      ).then(async (res) => {
        if (res.status === 201) {
          const result = await res.json();
          setCards([...cards, result]);
        } else {
          console.log('fail');
        }
      });
    }

    setModal({ open: false, edit: false, id: -1 });
  };

  const handleDeleteButton = async () => {
    if (
      !window.confirm(
        '정말 삭제하시겠습니까?\n컴포넌트, 퀴즈 정보, 진도율 정보는 복구할 수 없습니다.'
      )
    )
      return;

    await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${condition.pos}s/${modal.id}`,
      {
        method: 'DELETE',
        headers: {
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
      }
    ).then((res) => {
      setCards(cards.filter((card) => card.id !== modal.id));
    });

    setModal({ open: false, edit: false, id: -1 });
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/components/image`, {
      method: 'POST',
      headers: {
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: formData,
    }).then(async (res) => {
      if (res.status === 201) {
        const { imageUrl } = await res.json();
        dispatch({ type: 'IMAGE', image: imageUrl });

        console.log('success');
      } else {
        console.log('fail');
      }
    });
  };

  return (
    <div
      onClick={(e) => {
        // setModal({ open: false, edit: false, id: -1 });
      }}
      className="fade-pane flex-center"
    >
      <div className="modal-container">
        {condition.pos === 'course' && (
          <CoursePane
            content={content}
            dispatch={dispatch}
            handleImageUpload={handleImageUpload}
          />
        )}
        {condition.pos === 'chapter' && (
          <ChapterPane
            content={content}
            dispatch={dispatch}
            handleImageUpload={handleImageUpload}
          />
        )}
        {condition.pos === 'lesson' && (
          <LessonPane
            content={content}
            dispatch={dispatch}
            handleImageUpload={handleImageUpload}
          />
        )}
        <div className="modal-inner-button-bar">
          {modal.edit ? (
            <button
              className="modal-inner-button"
              style={{
                backgroundColor: '#DF2E2E',
                color: '#FFF',
              }}
              onClick={() => {
                handleDeleteButton();
              }}
            >
              삭제
            </button>
          ) : null}
          <button
            onClick={() => setModal(false)}
            className="modal-inner-button on-close"
            style={{
              backgroundColor: '#fff',
            }}
          >
            취소
          </button>
          <button
            onClick={() => handleSaveButton()}
            className="modal-inner-button"
            style={{
              backgroundColor: '#242E64',
              color: '#FFF',
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
