import React, { useState } from 'react';
import useUser from 'store/modules/user/userHook';
import { IsetMultipleTest, IgetHandleMultipleTest } from './interfaces';

const useHandleMultipleTest = (
  lessonId: number,
  cId: number,
  checkedAnswer: number
): IsetMultipleTest => {
  const { userData, isLoggedIn } = useUser();
  const [reqSuccess, setReqSuccess] = useState<boolean>(false);
  const [correct, setCorrect] = useState<boolean>(false);
  const [answer, setAnswer] = useState<number>(-1);
  const [wrAnswer, setWrAnswer] = useState<number>(-1);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [multipleTestIsLoading, setMultipleTestIsLoading] = useState(-1);
  let correctAns;
  const [loading, setLoading] = useState(false);

  const handleMultipleTest = async ({
    lessonId,
    cId,
    checkedAnswer,
  }: IgetHandleMultipleTest): Promise<void> => {
    if (checkedAnswer !== -1) {
      // 답을 체크한 다음 제출하기를 눌렀을 때만 post를 수행
      setMultipleTestIsLoading(1);
      setLoading(true);
      // # : 채점
      const r = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/components/${cId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      setDisabledSubmit(true);
      const json = await r.json();
      if (r.status === 200) {
        correctAns = checkedAnswer === json.answer;
        setLoading(false);
        if (checkedAnswer === json.answer) {
          setAnswer(Number(json.answer)); // 문제 맞으면 "실제 답"을 받음
          setCorrect(true);
        } else {
          setAnswer(-1);
          setWrAnswer(Number(json.answer)); // wrAnswer에 문제 틀렸을 때 보내는 "실제 답"을 받음
          setCorrect(false);
        }
      }
      // # : submit
      if (!loading && isLoggedIn && userData.userAuth !== 1) {
        // * : admin도 교실 생성해서 classroomId가 있어야 채점이 가능함
        await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/quizzes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify({
            userId: userData.id,
            lessonId: lessonId,
            componentId: cId,
            classroomId: userData.classroomId,
            isCorrect: correctAns,
          }),
        })
          .then(async (res) => {
            if (res.status === 201) {
              setReqSuccess(true);
            }
          })
          .finally(() => {
            setMultipleTestIsLoading(0);
            setReqSuccess(true);
          });
      } else {
        setReqSuccess(true);
        setMultipleTestIsLoading(0);
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleMultipleTest({
      lessonId,
      cId,
      checkedAnswer,
    });
  };

  return {
    reqSuccess,
    correct,
    answer,
    wrAnswer,
    disabledSubmit,
    handleSubmit,
    multipleTestIsLoading,
  };
};

export default useHandleMultipleTest;
