import { CommonLayout, FooterLayout } from 'components/global';
import React from 'react';
import { Link } from 'react-router-dom';
import 'styles/stylesheets/policy.css';

const Privacy = (): JSX.Element => {
  return (
    <>
      <CommonLayout buttonHide />
      <div className="policy-page">
        <div className="policy-content">
          <div className="tab-panel">
            <div className="tab">
              <Link className="tab-item ft-medium now-tab" to="/privacy">
                개인정보 처리방침
              </Link>
              <Link className="tab-item ft-medium " to="/terms">
                이용약관
              </Link>
            </div>
            <h1 className="h1-policy">개인정보처리방침</h1>
            <ul className="ul-policy contents">
              <li>
                주식회사 더에이아이랩(이하 ‘회사’)은 서비스를 제공하면서,
                이용자의 개인정보를 소중하게 생각하고, 보호하기 위하여 항상
                최선을 다해 노력하고 있습니다.
              </li>
              <li>
                회사는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
                법률 등 모든 개인정보보호 관련 법률규정을 준수하고 있으며, 관련
                법령에 의거한 개인정보처리방침을 정하고, 이를 서비스에 공개하여
                이용자가 언제나 용이하게 열람할 수 있도록 하고 있습니다.
                이용자는 개인정보의 수집, 이용, 제공과 관련하여 원하지 않는 경우
                동의를 거부할 수 있습니다. 다만, 이용자가 동의를 거부하는 경우
                서비스의 전부 또는 일부를 이용할 수 없음을 알려드립니다.
              </li>
            </ul>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제1조 (개인정보의 처리 목적)
            </h2>
            <ul className="ul-policy contents">
              <li>
                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
                개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
                목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
                동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </li>
              <ol className="ol-policy">
                <li>
                  서비스 제공
                  <br />
                  콘텐츠 제공, 맞춤 정보 제공, 본인 인증 등
                </li>
                <li>
                  회원 관리
                  <br />
                  회원제 서비스 이용 및 본인 확인, 서비스 이용약관상의 위반 사유
                  등으로 이용 정지된 회원들의 부정 이용방지, 분쟁 조정을 위한
                  기록 보존, 민원처리, 공지사항 전달 등
                </li>
                <li>
                  신규 서비스 개발 및 마케팅 활용
                  <br />
                  신규 서비스 개발, 통계학적 특성, 이용 형태 등에 따른 맞춤형
                  서비스 제공, 광고 게재, 이벤트 참여기회 제공, 접속빈도 파악,
                  회원의 서비스 이용에 대한 통계 등
                </li>
              </ol>
            </ul>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제2조 (처리하는 개인정보 항목 및 이용기간)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사가 처리하는 개인정보 항목과 보유 및 이용기간은 다음과
                같습니다.
                <br />
              </li>
              <ol className="ol-policy">
                <li>보유 항목 및 목적 안내 </li>
                <li>보유 기간 안내</li>
              </ol>
              <h2 className="contents">
                1-A.보유 항목 및 목적 안내
                <br />
              </h2>
              <table className="table-policy">
                <thead>
                  <tr className="tr-policy">
                    <th className="th-policy">분류</th>
                    <th className="th-policy">수집·이용 항목</th>
                    <th className="th-policy">수집·이용 목적</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tr-policy">
                    <td className="td-policy td-1">
                      회원가입 정보
                      <br />
                      [필수]
                    </td>
                    <td className="td-policy td-1">
                      성명 <br />
                      이메일 주소
                      <br />
                      비밀번호
                      <br />
                    </td>
                    <td className="td-policy td-1">
                      회원가입, 중복확인, 비밀번호 재설정, 서비스 제공 및 상담,
                      만족도 조사
                    </td>
                  </tr>

                  <tr className="tr-policy">
                    <td className="td-policy">
                      프로필 정보
                      <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">
                      닉네임 <br />
                      프로필 사진
                      <br />
                      직업
                      <br />
                      전문분야
                      <br />
                      포트폴리오 링크
                      <br />
                      자기소개
                      <br />
                      생년월일
                      <br />
                      성별
                      <br />
                      휴대전화 정보
                      <br />
                      기타
                      <br />
                    </td>
                    <td className="td-policy">
                      맞춤형 서비스 제공을 위한 연구 및 서비스 자료, 부정이용
                      방지, 중복확인
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">
                      리더 정보
                      <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">경력 및 실력 증빙 자료 </td>
                    <td className="td-policy">
                      프로리더로 인증하기 위한 신원 및 자격 확인
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">
                      연동 정보
                      <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">
                      카카오톡 로그인 <br />
                      구글 로그인
                      <br />
                    </td>
                    <td className="td-policy">
                      회원의 로그인 편의를 위한 연동 정보
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">
                      그룹생성 정보
                      <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">
                      그룹명
                      <br />
                      그룹형태
                      <br />
                      그룹장 이메일주소
                      <br />
                      그룹장 역할명
                      <br />
                      그룹 대표 이미지
                      <br />
                      그룹주소
                      <br />
                    </td>
                    {/* td-policy */}
                    <td className="td-policy">
                      그룹 서비스를 편리하게 이용하기 위한 기본 정보
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">
                      그룹개인 정보
                      <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">
                      학번
                      <br />
                      학과
                      <br />
                      회원의 권한
                      <br />
                    </td>
                    <td className="td-policy">
                      그룹 서비스를 편리하게 이용하기 위한 선택 정보
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">
                      이용 및 문의정보
                      <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">
                      본인인증 시 본인 확인 정보(CI/DI), 휴대전화 번호
                      <br />
                    </td>
                    <td className="td-policy">
                      부정이용 방지, 서비스 제공을 위한 본인 확인
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">
                      회원탈퇴 정보 <br />
                      [선택]
                      <br />
                    </td>
                    <td className="td-policy">탈퇴사유</td>
                    <td className="td-policy">서비스 개선</td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">그 외 기타</td>
                    <td className="td-policy">
                      위 모든 정보, 가입기간, 가입경로, 주요분야, 관심분야,
                      경력, 학력, 직업, 결제 및 구매 정보, 설문입력정보, 리뷰,
                      개별 학습 내용, 학습 관리 내용 그 외 기타 등
                    </td>
                    <td className="td-policy">
                      맞춤형 서비스 제공을 위한 연구자료 및 서비스 자료(통계학적
                      분석, 서비스 개발), 개선, 마케팅 전략 수립
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2 className="contents">
                1-B.보유 기간 안내
                <br />
              </h2>
              <ol className="ol-policy" style={{ listStyle: 'decimal' }}>
                <li>
                  보유기간이 별도로 기재되어 있지 않은 경우 아래 사항을
                  따릅니다.
                </li>
                <ol className="ol-policy">
                  <li>
                    공통 : 회원 탈퇴 후 1년까지 (부정거래 및 부정이용 방지)
                  </li>
                  <li>
                    관련 법령에 따른 보관 의무가 있는 경우 해당 법령 종료시까지
                  </li>
                  <li>
                    서비스 이용에 따른 금전적 관계가 남아 있을시 관계가 마무리될
                    때까지 보유하며, 필요시 관계법령에서 정한 일정 기간을
                    따릅니다.
                  </li>
                  <li>
                    수집시 동의 받은 별도 기간이 있는 경우 별도 기간을 따릅니다.
                  </li>
                  <li>개인정보처리방침에 규정된 경우 규정을 따릅니다.</li>
                  <li>수집·이용 목적이 달성될 때까지</li>
                  <li>
                    위 상황 외에도 타당한 사유가 존재할 경우 일정기간 보관
                    가능합니다.
                  </li>
                </ol>
                <li>
                  서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이
                  자동으로 생성, 수집될 수 있습니다.
                </li>
                <ol className="ol-policy">
                  <li>
                    서비스 이용 시 각종 단말기(모바일, 태블릿 등)에 관한
                    정보(단말기 모델, 하드웨어 ID, 운영체제 버전정보), 접속 IP
                    정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용
                    기록 등
                  </li>
                </ol>
                <li>
                  회사는 이용자의 권리를 보장하기 위한 서비스 안내를 목적으로
                  이용자 정보를 이용하여 연락을 취할 수 있습니다.
                </li>
                <li>
                  제1항 및 제2항에서 수집된 정보는 서비스 이용에 따른 통계,
                  분석에 이용될 수 있습니다.
                </li>
                <li>
                  법령 및 회사 방침에 의한 개인정보 항목별 보유 및 이용기간은
                  다음과 같습니다.
                </li>
                <ol className="ol-policy">
                  <li>
                    회원 기본 정보 중 이름, 이메일 주소, 연락처, 주소 <br />
                    보존 이유: 명예훼손 등 권리침해 분쟁 및 수사협조, 불량회원의
                    부정한 이용의 재발 및 재가입 방지, 법령 또는 회사와 이용자
                    간의 서비스 이용약관에 위반하는 거래 관리, 기타 공서양속을
                    해치는 부정한 거래 관리 등 <br />
                    보존 기간: 회원 탈퇴 후 1년 <br />
                  </li>
                  <li>
                    법률1: 전자 상거래 등에서의 소비자보호에 관한 법률
                    <br />
                    계약 또는 청약철회 등에 관한 기록 : 5년 <br />
                    대금결제 및 재화 등의 공급에 관한 기록 : 5년 <br />
                    소비자 불만 또는 분쟁처리에 관한 기록 : 3년
                    <br />
                    표시·광고에 관한 기록 : 6개월 <br />
                  </li>
                  <li>
                    법률2: 통신비밀 보호법 <br />
                    서비스 방문 기록 : 3개월 <br />
                  </li>
                  <li>
                    법률3: 부가가치세법
                    <br />
                    부가가치세의 과세표준과 세액의 신고자료 등 기타 : 5년 <br />
                  </li>
                </ol>
              </ol>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제3조 (개인정보의 수집방법)
            </h2>
            <ul className="ul-policy contents">
              <li>
                모바일 애플리케이션, 웹 페이지, 서면 양식, 팩스, 전화, 이메일,
                이벤트 응모 등.
                <br />
              </li>
            </ul>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제4조 (쿠키 활용)
            </h2>
            <ol className="ol-policy contents decimal">
              <li>
                인터넷 서비스 이용 과정에서 접속 IP 주소, 쿠키, MAC주소, 단말기
                식별번호, 서비스 이용 기록 등이 생성되어 수집될 수 있습니다.
                회사는 이용자의 서비스 이용 과정에서 접속 IP 주소, 방문일시,
                서비스 이용 기록, 브라우저 정보 등 이용자에 관한 정보를 자동화된
                방법으로 생성하여 이를 저장(수집)할 수 있습니다.
                <br />
              </li>
              <li>
                회사는 쿠키 정보를 수집하여 이용자가 방문한 각 서비스 접속여부,
                이용자 문의에 대한 확인 및 안내, 이용 형태 분석, 개인 맞춤
                서비스 제공 등에 사용합니다.
                <br />
              </li>
              <li>
                이용자는 쿠키 설치 및 사용에 대한 선택권이 있습니다. 웹 브라우저
                종류에 따라 다소 다를 수 있지만, 대부분 웹브라우저의 환경 설정을
                통해 쿠키허용 여부를 결정하거나 기존의 쿠키 데이터 일체를 삭제할
                수 있습니다. 다만 쿠키의 저장을 거부할 경우, 로그인(Log in)이
                필요한 일부 서비스의 이용에 제한이 생길 수 있습니다.
                <br />
              </li>
            </ol>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제5조 (개인정보의 제3자 제공)
            </h2>
            <ul className="ul-policy contents decimal">
              <li>
                회사는 이용자의 개인정보를 이 개인정보 처리방침에서 고지한 범위
                내에서 처리하며, 이용자의 사전 동의 없이는 동 범위를 초과하여
                이용하거나, 이용자의 개인정보를 외부 또는 제3자에게 공개 또는
                제공하지 않습니다.
                <br />
              </li>
              <li>
                회사는 회원의 개인정보를 제휴사 등에 제공하는 경우에는 사전에
                이용자에게 업체명, 제공되는 개인정보 항목, 제공 목적, 보유기간
                등에 대해서 고지하고 개별적으로 동의를 구하는 절차를 제공합니다.
                위 사항 중 어느 하나의 사항을 변경하는 경우에도 이를 알리고
                동의를 구합니다.
                <br />
              </li>

              <li>
                이용자의 동의가 있는 경우, 서비스 제공 및 상담 등의 원활한
                이행을 위하여 관련된 이용자의 아래 개인정보를 필요한 범위 내에서
                제3자에게 제공합니다.
                <br />
              </li>
              <table className="table-policy">
                <thead>
                  <tr className="tr-policy">
                    <th className="th-policy">개인정보 제공받는 자</th>
                    <th className="th-policy">제공 정보</th>
                    <th className="th-policy">목적</th>
                    <th className="th-policy">보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tr-policy">
                    <td className="td-policy td-2">
                      리더(교육을 제공하는 회원)
                    </td>
                    <td className="td-policy td-2">
                      멤버(교육을 제공받는 회원)의 성명, 닉네임, 휴대전화 번호,
                      이메일 주소, 학번, 학과 등
                      <br />
                    </td>
                    <td className="td-policy td-2">
                      수업 진행을 위해 필요한 경우 본인 확인을 통한 부정이용
                      방지
                    </td>
                    <td className="td-policy td-2">사용자가 동의한 기간동안</td>
                  </tr>
                </tbody>
              </table>
              <li>
                이용자의 동의가 없는 경우 회사는 이용자의 개인정보를 제3자에게
                제공하지 않습니다. 하지만 이 경우 서비스 이용이 제한될 수
                있습니다.
                <br />
              </li>
              <li>
                제1항 내지 제4항에도 불구하고 회사는 다음 각 호의 어느 하나에
                해당하는 경우에는 이용자의 정보를 제3자에게 제공할 수 있습니다.
                <br />
              </li>
              <ul className="ul-policy">
                <li>정보주체로부터 별도의 동의를 받은 경우</li>
                <li>도용방지를 위하여 본인확인이 필요한 경우</li>
                <li>
                  법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여
                  불가피한 경우
                </li>
                <li>
                  수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의
                  요청이 있는 경우
                </li>
                <li>서비스 제공에 따른 요금 정산을 위하여 필요한 경우</li>
                <li>
                  통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
                  개인을 식별할 수 없는 형태로 제공하는 경우
                </li>
                <li>개인정보 보호법 제17조 또는 제18조에 해당하는 경우</li>
              </ul>
            </ul>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제6조 (개인정보처리의 위탁)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 향상된 서비스를 제공하기 위해 개인정보 처리를 위탁하여
                처리할 수 있습니다. 회사는 위탁계약 체결 시 개인정보가 안전하게
                관리될 수 있도록 관련법령에 따라 필요한 사항을 규정하고
                있습니다. 또한 개인정보 처리를 위탁하는 경우에는 위탁하는 업무의
                내용과 위탁받아 처리하는 자(이하 ‘수탁업체’) 등을 이용자가
                언제든지 쉽게 확인할 수 있도록 공개하며, 위탁하는 업무의
                내용이나 수탁업체가 변경된 경우에도 동일합니다.
                <br />
              </li>
              <li>
                개인정보처리의 위탁 업무 내용과 수탁업체는 다음과 같습니다.
                <br />
              </li>
              <table className="table-policy">
                <thead>
                  <tr className="tr-policy">
                    <th className="th-policy">수탁업체</th>
                    <th className="th-policy">위탁 업무</th>
                    <th className="th-policy">위탁 정보</th>
                    <th className="th-policy">보유기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tr-policy">
                    <td className="td-policy td-3">카카오, 솔라피</td>
                    <td className="td-policy td-3">SMS, LMS, 알림톡 발송</td>
                    <td className="td-policy td-3">
                      이름, 휴대전화 번호, 이메일
                    </td>
                    <td
                      className="td-policy td-3"
                      rowSpan={5}
                      style={{ verticalAlign: 'middle' }}
                    >
                      이용 목적 달성 및 위탁 계약 종료시까지
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">㈜다날</td>
                    <td className="td-policy">본인인증</td>
                    <td className="td-policy">휴대전화 번호, 이름</td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">AWS</td>
                    <td className="td-policy">
                      클라우드 서버 운영 및 인프라 관리
                    </td>
                    <td className="td-policy">
                      이름, 이메일 주소, 휴대전화 번호, 비밀번호, 서비스 이용
                      기록
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">㈜다날</td>
                    <td className="td-policy">결제 및 환불</td>
                    <td className="td-policy">
                      이름, 주소, 휴대전화 번호, 현금영수증 발행 정보
                    </td>
                  </tr>
                  <tr className="tr-policy">
                    <td className="td-policy">카카오, 구글</td>
                    <td className="td-policy">SNS 가입/로그인</td>
                    <td className="td-policy">
                      이메일 주소, 이름, 휴대전화 번호, 프로필 사진
                    </td>
                  </tr>
                </tbody>
              </table>
            </ol>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제7조 (개인정보의 파기)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 이용자로부터 동의 받은 개인정보 보유기간의 경과, 처리목적
                달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
                개인정보를 파기합니다.
                <br />
              </li>
              <li>
                회사는 회원이 1년간 서비스 이용 기록이 없는 경우 정보통신망
                이용촉진 및 정보보호 등에 관한 법률 제29조 ‘개인정보
                유효기간제’에 따라 회원에게 사전 통지하고 개인정보를 즉시
                파기합니다.
                <br />
              </li>
              <li>
                회사는 제1항 및 제2항에도 불구하고 관련법령(상법,
                전자금융거래법, 신용정보의 이용 및 보호에 관한 법률, 정보통신망
                이용촉진 및 정보보호 등에 관한 법률 등)이나 회사 방침에 따라
                개인정보를 계속 보존하여야 할 사유가 있는 경우에는 제2조
                제5항에서 명시한 기간 동안 보존하며, 명시한 기간이 경과한
                개인정보는 즉시 파기합니다.
                <br />
              </li>
              <li>
                회사는 다음의 방법으로 개인정보를 파기합니다. 종이에 출력된
                개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적
                파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
                방법을 사용하여 삭제합니다.
                <br />
              </li>
            </ol>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제8조 (이용자의 권리와 의무)
            </h2>
            <ol className="ol-policy contents">
              <li>이용자의 권리</li>
              <ul className="ul-policy contents">
                <li>
                  이용자는 언제든지 등록된 자신의 개인정보를 조회하거나 수정할
                  수 있으며 가입 해지를 요청할 수 있습니다.
                  <br />
                </li>
                <li>
                  이용자는 인터넷 사이트 상에서 개인정보 조회, 수정을 위해서는
                  ‘내 정보 변경'을, 가입해지(동의 철회)를 위해서는 ‘회원탈퇴'를
                  클릭하여 본인 확인 절차를 거친 후 열람/수정/탈퇴가 가능합니다.
                  <br />
                </li>
                <li>
                  이용자는 회사에 대해 서면, 전화 또는 이메일 등을 통하여
                  개인정보에 대한 열람, 정정, 삭제 및 파기, 처리정지 등을 요청할
                  수 있으며, 회사는 이에 대해 지체 없이 조치합니다.
                  <br />
                </li>
                <li>
                  다호에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
                  자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
                  보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야
                  합니다.
                  <br />
                </li>
                <li>
                  이용자가 개인정보의 오류에 대한 정정을 요청한 경우에 회사는
                  정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
                  않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한
                  경우에는 필요시 정정 처리결과를 제3자에게 지체 없이 통지하여
                  정정이 이루어지도록 합니다.
                  <br />
                </li>
              </ul>
              <li>이용자의 의무</li>
              <ul className="ul-policy contents">
                <li>
                  이용자는 본인의 개인정보를 최신의 상태로 정확하게 입력하여
                  불의의 사고를 예방하여야 합니다. 이용자가 부정확하게 입력한
                  정보로 발생하는 사고의 책임은 이용자 자신에게 있으며 타인의
                  정보를 도용하거나 허위정보를 입력할 경우 계정의 이용이
                  제한되거나 회원자격이 상실될 수 있습니다.
                  <br />
                </li>
                <li>
                  이용자는 개인정보보호법 등 관련법령을 위반하여 회사가 처리하고
                  있는 정보주체 본인이나 타인의 개인정보 및 사생활을
                  침해하여서는 안됩니다. 이용자는 비밀번호를 포함한 본인의
                  개인정보가 유출되지 않도록 주의하고 타인의 개인정보와 게시물을
                  훼손하지 않도록 유의해 주십시오.
                  <br />
                </li>
                <li>
                  이용자는 정보 통신망 이용촉진 및 정보보호 등에 관한 법률,
                  개인정보보호법, 주민등록법 등 개인정보에 관한 법률을
                  준수하여야 하며, 이를 위반할 경우 관련법령에 의해 처벌받거나,
                  손해배상의 책임을 질 수 있습니다.
                  <br />
                </li>
              </ul>
            </ol>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제9조 (개인정보의 안정성 확보조치)
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난,
                유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                기술적/관리적 대책을 강구하고 있습니다. 단, 이용자 본인의
                부주의나 인터넷 또는 통신상의 문제로 아이디, 비밀번호 등
                개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
                않습니다.
                <br />
              </li>
              <li>
                비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고
                있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에
                의해서만 가능합니다.
                <br />
              </li>
              <li>
                회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
                유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                개인정보의 훼손에 대비해 자료를 수시로 백업하고 있고, 최신 보안
                업데이트 설치 및 기술적 조치를 하여 이용자의 개인정보나 자료가
                유출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을
                통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
                있습니다.
                <br />
              </li>
              <li>
                회사는 침입차단시스템을 이용하여 외부로부터의 무단 접근을
                통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한
                모든 기술적 장치를 갖추려 노력하고 있습니다.
                <br />
              </li>
              <li>
                회사는 개인정보처리 관련 담당자를 한정하고 별도의 비밀번호를
                부여하여 이를 정기적으로 갱신하고 있으며, 담당자 및 직원을
                대상으로 정기적 교육을 실시하여 개인정보처리방침의 준수를 항상
                강조하고 있습니다.
                <br />
              </li>
            </ol>

            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제10조 (개인정보 보호책임자 및 관리자 지정)
              <br />
            </h2>
            <ol className="ol-policy contents">
              <li>
                회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
                처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
                같이 개인정보 보호책임자를 지정하고 있습니다. 단, 회사가 적절한
                안정성 확보조치를 취하였음에도 불구하고 천재지변과 같이 예기치
                못한 사고로 인한 정보의 훼손 및 멸실, 이용자가 회사에 제공한
                정보에 의한 각종 분쟁 등에 관해서는 예외로 합니다.
                <br />
              </li>
              <ul className="ul-policy">
                <li>
                  [ 개인정보 보호책임자 및 담당부서 ]
                  <br />
                  이름 : 서유라
                  <br />
                  소속 : 프로덕트팀
                  <br />
                  직위 : 정보 보호 책임자
                  <br />
                  문의 : 문의하기(채널톡 링크 coding-x.channel.io)
                  <br />
                  전화번호 : 02-2039-9355
                  <br />
                </li>
              </ul>
              <li>
                정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제,
                상담 등을 문의하실 수 있습니다.
                <br />
              </li>
              <ul className="ul-policy">
                <li>
                  {/* crl-privacy-159  */}
                  *아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인
                  개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다
                  자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
                  <br />
                  개인정보침해신고센터 ( privacy.kisa.or.kr / 국번없이 118)
                  <br />
                  대검찰청 사이버수사과 ( spo.go.kr / 국번없이 1301)
                  <br />
                  경찰청 사이버안전국 ( cyberbureau.police.go.kr / 국번없이 182)
                </li>
              </ul>
            </ol>
            {/* </ul> */}
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제11조 (고지의 의무)
              <br />
            </h2>
            <ul className="ul-policy contents">
              <li>
                회사는 관련법령이나 서비스의 변경사항을 반영하기 위한 목적
                등으로 이 개인정보 처리방침을 수정할 수 있습니다. 이 개인정보
                처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일
                전에 회사 홈페이지의 '공지사항'을 통해 사전 공지합니다. 회사는
                개인정보 처리방침에 시행일자 등을 부여하여 개정여부를 쉽게 알 수
                있도록 하고 있습니다.
                <br />
              </li>
            </ul>
            <h2 className="sub-title" style={{ margin: '48px 0px 23px' }}>
              제12조 (시행일)
            </h2>
            <ul className="ul-policy contents">
              <li>
                이 개인정보 처리방침의 시행일은 다음과 같습니다.
                <h1 className="h1-policy">시행일자: 2021.01.01</h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FooterLayout />
    </>
  );
};

export default Privacy;
