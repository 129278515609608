import React from 'react';
import * as XLSX from 'xlsx';
import { RejectButton, RequestButton } from 'components/global';
import classNames from 'classnames';
import styles from 'styles/stylesheets/style.css';
const cn = classNames.bind(styles);

export const TabType = {
  NEW: 'NEW',
  OLD: 'OLD',
};

const TabLabel = {
  [TabType.NEW]: '새 유저',
  [TabType.OLD]: '기존 유저',
};

export const HeadingType = {
  STUDENT_ID: 'STUDENT_ID',
  STUDENT_NAME: 'STUDENT_NAME',
  ID: 'ID',
  PASSWORD: 'PASSWORD',
};

export const HeadingTypeOld = {
  STUDENT_ID: 'STUDENT_ID',
  STUDENT_NAME: 'STUDENT_NAME',
  ID: 'ID',
};

const HeadingLabel = {
  [HeadingType.STUDENT_ID]: '학번',
  [HeadingType.STUDENT_NAME]: '학생이름',
  [HeadingType.ID]: '아이디',
  [HeadingType.PASSWORD]: '비밀번호',
};
// 동일한 양식(학번, 학생이름, 아이디, 비밀번호)으로 들어오는지 확인
function haveSameValues(a, b) {
  const count = (d) => (m, v) => m.set(v, (m.get(v) || 0) + d);
  return Array.from(
    b.reduce(count(-1), a.reduce(count(1), new Map())).values()
  ).every((v) => v === 0);
}

const ExcelInviteModal = ({
  isOpen,
  close,
  classroomId,
}: {
  isOpen: boolean;
  close: () => void;
  classroomId: number;
}): JSX.Element | null => {
  function noClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    return;
  }

  // * : 새 유저, 기존 유저 탭 관련
  const [selectedTab, setSelectedTab] = React.useState(
    TabLabel[Object.keys(TabType)[0]]
  );

  const _getTab = ({ tabType, tabLabel, i }) => (
    <li
      key={i}
      className={tabLabel === selectedTab ? 'active' : ''}
      onClick={() => {
        setSelectedTab(tabLabel);
        initializeData();
        setInviteSuccess(false);
        setInviteIsLoading(false);
        setInviteError('');
      }}
    >
      {tabLabel}
    </li>
  );

  const getTabList = () => (
    <ul className="tabs">
      {Object.values(TabType)
        .map((tabType, i) => ({ tabType, tabLabel: TabLabel[tabType], i }))
        .map(_getTab)}
    </ul>
  );
  // * : 입력 받은 엑셀 데이터 테이블
  const _getTh = ({ hType, headingLabel, i }) => (
    <th key={i} className="th-card excel-list">
      {headingLabel}
    </th>
  );

  const _getThOld = ({ hType, headingLabel, i }) => (
    <>
      {i < 3 && (
        <th key={i} className="th-card excel-list">
          {headingLabel}
        </th>
      )}
    </>
  );

  const getThead = () => (
    <>
      <tr className="tr-card">
        {selectedTab === TabLabel[Object.keys(TabType)[0]]
          ? Object.values(HeadingType)
              .map((hType, i) => ({
                hType,
                headingLabel: HeadingLabel[hType],
                i,
              }))
              .map(_getTh)
          : Object.values(HeadingType)
              .map((hType, i) => ({
                hType,
                headingLabel: HeadingLabel[hType],
                i,
              }))
              .map(_getThOld)}
      </tr>
    </>
  );

  const getTbody = (users) => (
    <>
      {/* & : 로딩중일 때 구현 필요  */}
      {/* {loading && <p>업로드를 하면 리스트를 볼 수 있어요.</p>} */}
      {selectedTab === TabLabel[Object.keys(TabType)[0]]
        ? users.map((item, idx) => (
            <tr className="tr-card td body-scroll" key={idx}>
              <td className="td-card excel-list td-4">{item[0]}</td>
              <td className="td-card excel-list td-4">{item[1]}</td>
              <td className="td-card excel-list td-4">{item[2]}</td>
              <td className="td-card excel-list td-4">{item[3]}</td>
            </tr>
          ))
        : users.map((item, idx) => (
            <tr className="tr-card td body-scroll" key={idx}>
              <td className="td-card excel-list td-4">{item[0]}</td>
              <td className="td-card excel-list td-4">{item[1]}</td>
              <td className="td-card excel-list td-4">{item[2]}</td>
            </tr>
          ))}
    </>
  );
  // & : 엑셀 파일 업로드
  const [fileUploaded, setFileUploaded] = React.useState([]);
  const [fileName, setFileName] = React.useState('');
  const [uploadError, setUploadError] = React.useState(false);
  // * : 데이터 초기화
  const initializeData = () => {
    setFileUploaded([]);
    setFileName('');
    setUploadError(false);
  };
  function handleUpload(e) {
    e.preventDefault();
    const files = e.target.files,
      f = files[0];

    setFileName(files[0]?.name);
    setUploadError(false);
    // 같은 파일을 다시 올렸을 때 인식하지 못하는 문제는 e.target.value를 비워준다.
    e.target.value = '';

    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* * : Convert array to json */
      // const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 }); // 양식 파일의 설명 없을 때 상단 rows 제거 없이 사용
      let dataParse = XLSX.utils
        .sheet_to_json(ws, { header: 1 })
        .filter((d, i) => i > 6);

      let i = 0;
      for (i = 0; i < dataParse.length; i++) {
        if (!dataParse[i][0]) {
          break;
        }
      }
      if (i < dataParse.length) {
        dataParse = dataParse.slice(0, i);
      }

      // console.log('dataParse', dataParse);

      // 양식 파일 새 유저, 기존 유저 일치시킨 유효성 검사
      // if (
      //   (selectedTab === TabLabel[Object.keys(TabType)[0]] &&
      //     haveSameValues(
      //       dataParse[0],
      //       Object.values(HeadingType).map((hType) => HeadingLabel[hType])
      //     )) ||
      //   (selectedTab === TabLabel[Object.keys(TabType)[1]] &&
      //     haveSameValues(
      //       dataParse[0],
      //       Object.values(HeadingTypeOld).map((hType) => HeadingLabel[hType])
      //     ))
      // ) {
      dataParse.shift();

      setFileUploaded(dataParse);
      // } else {
      //   initializeData();
      //   setUploadError(true);
      // }
    };
    if (f) reader.readAsBinaryString(f);
    else initializeData();
  }
  // * : 취소 기능
  const closeModal = () => {
    setInviteSuccess(false);
    initializeData();
    close();
  };
  // * : 초대하기 기능
  const [InviteSuccess, setInviteSuccess] = React.useState(false);
  const [inviteIsLoading, setInviteIsLoading] = React.useState(false);
  const [inviteError, setInviteError] = React.useState('');

  const requestInviteForNewUser = async () => {
    //새 유저
    const list = fileUploaded.map((v) => ({
      id: v['0'],
      name: v['1'],
      email: v['2'],
      password: String(v['3']),
    }));

    // 비밀번호를 입력하지 않은 경우 12345678로 등록하는 처리
    list.forEach((element) => {
      if (element.password == 'undefined') element.password = '12345678';
      // console.log('password는? ' + element.password);
    });

    // console.log(' *************** list *************** : ', list);

    try {
      let savedDatas = [];
      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/many`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({ users: list }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resJson) => {
          if (resJson.duplicated) {
            const newResJson = [];
            for (let i = 0; i < resJson.duplicated.length; i++) {
              newResJson.push(resJson.duplicated[i] + '\n');
            }
            if (resJson.duplicated.length > 0) {
              alert(
                '회원가입 데이터 중복! 실패하였습니다\n\n<중복 데이터>\n\n' +
                  newResJson
              );
              throw new Error();
            } else {
              alert('회원가입에 성공하셨습니다.');
              savedDatas = resJson.saved;
            }
          } else {
            alert(resJson.message);

            throw new Error();
          }
        })
        .finally(() => {
          setInviteIsLoading(false);
        });

      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/members/many`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({ userIds: savedDatas, classroomId }),
      })
        .then((res) => {
          if (res.status === 201) {
            setInviteSuccess(true);
          }
          return res.json();
        })
        .then((resJson) => {
          if (resJson.duplicated) {
            if (resJson.duplicated.length > 0) {
              const newResJson = [];
              for (let i = 0; i < resJson.duplicated.length; i++) {
                newResJson.push(resJson.duplicated[i] + '\n');
              }
              alert(
                '반 가입 데이터 중복! 실패하였습니다\n\n<중복 데이터>\n\n' +
                  newResJson
              );
              throw new Error();
            } else {
              alert('반 가입에 성공하셨습니다.');
            }
          } else {
            alert(resJson.message);
            throw new Error();
          }
        })
        .finally(() => {
          setInviteIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      setInviteError(err);
    }
  };

  const requestInviteForOldUser = async () => {
    // const list = fileUploaded.map((v) => ({
    //   // id: v['0'],
    //   // name: v['1'],
    //   email: v['2']
    //   // password: String(v['3']),
    // }));
    // console.log('list', list);
    const list = [];
    fileUploaded.map((v) => {
      list.push(v['2']);
    });
    try {
      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/members/many`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({ userIds: list, classroomId }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resJson) => {
          if (resJson.duplicated) {
            if (resJson.duplicated.length > 0) {
              const newResJson = [];
              for (let i = 0; i < resJson.duplicated.length; i++) {
                newResJson.push(resJson.duplicated[i] + '\n');
              }
              alert(
                '반 가입 데이터 중복! 실패하였습니다\n\n<중복 데이터>\n\n' +
                  newResJson
              );
              throw new Error();
            } else {
              alert('반 가입에 성공하셨습니다.');
              setInviteSuccess(true);
            }
          } else {
            alert(resJson.message);
            throw new Error();
          }
        })
        .finally(() => {
          setInviteIsLoading(false);
        });
    } catch (err) {
      setInviteError(err);
    }
  };

  // console.log(
  //   fileUploaded.map((v) => ({ ...v })),
  //   uploadError
  // );

  return isOpen ? (
    <div className="excel-invite-modal-wrapper" onClick={closeModal}>
      <div className="excel-invite-modal-box" onClick={noClick}>
        <section className="tab-menu-box">{getTabList()}</section>
        <article className="excel-invite-modal-header">
          <p className="modal-header-text">
            {selectedTab === TabLabel[Object.keys(TabType)[0]] ? '새' : '기존'}{' '}
            유저 엑셀로 초대하기
          </p>
          <p className="modal-header-subtext">
            양식에 맞는 엑셀을 업로드 해주시면 가입과 초대가 동시에 진행됩니다.
          </p>

          <section className="excel-upload-section">
            <a
              className="form-download-btn"
              href={
                selectedTab === TabLabel[Object.keys(TabType)[0]]
                  ? 'https://cdn.coding-x.com/onbook/newuser.xlsx'
                  : 'https://cdn.coding-x.com/onbook/olduser.xlsx'
              }
              download
            >
              엑셀 양식 다운로드
            </a>

            <label
              htmlFor="file-upload"
              className={cn('form-upload-input', InviteSuccess && 'success')}
            >
              {InviteSuccess ? (
                <section>
                  업로드 완료
                  <img
                    src="/assets/icons/ic_checkbox_on_fill_bk.svg"
                    className="bk-check-icon"
                    alt=""
                  />
                </section>
              ) : fileName ? (
                fileName
              ) : uploadError ? (
                '잘못된 양식입니다.'
              ) : (
                '엑셀 양식을 업로드 해주세요'
              )}
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleUpload}
              accept=".xlsx"
            />
          </section>
        </article>
        <article className="excel-invite-modal-body">
          {!fileUploaded.length ? (
            <section className="no-excel-file">
              <p>업로드를 하면 리스트를 볼 수 있어요.</p>
            </section>
          ) : (
            <article>
              <p>미리보기 100명</p>
              <section className="excel-invite-modal-user-table">
                <table className="table-card">
                  <thead>{getThead()}</thead>
                  <tbody>{getTbody(fileUploaded)}</tbody>
                </table>
              </section>
            </article>
          )}
        </article>
        <section className="excel-invite-modal-footer">
          <RejectButton text="취소" width={69} onClick={closeModal} />
          <RequestButton
            text="초대하기"
            width={98}
            onClick={
              selectedTab === TabLabel[Object.keys(TabType)[0]]
                ? requestInviteForNewUser
                : requestInviteForOldUser
            }
          />
        </section>
      </div>
    </div>
  ) : null;
};

export default ExcelInviteModal;
