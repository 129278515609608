import { useEffect, useRef, useState } from 'react';
import { useRunCode } from 'hooks/main';
import { IEditor } from './interfaces';
import Monaco from '@monaco-editor/react';

const Editor = ({ id, content, code }: IEditor): JSX.Element => {
  const [fontSz, setFontSz] = useState<number>(28);
  const options = {
    fontSize: fontSz,
    selectOnLineNumbers: true,
    roundedSelection: false,
    minimap: {
      enabled: false,
    },
    padding: {
      top: 10,
      bottom: 10,
    },
    renderWhitespace: 'none' as
      | 'none'
      | 'boundary'
      | 'selection'
      | 'trailing'
      | 'all',
  };
  const editorRef = useRef(null);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  const handleFontSize = (e) => {
    const fontSz = e.target.value;
    setFontSz(fontSz);
  };

  const handleClear = () => {
    setVal(code);
  };

  useEffect(() => {
    setVal(code);
  }, [code]);

  // run code editor
  // - input
  // data.id : 각 에디터 컴포넌트별 id로 다르게 들어옴
  // cIdArr : 레슨 내의 에디터 컴포넌트의 id 배열
  // val : 입력 받은 code
  // - output
  // runRes : 실행결과
  // graphRes : 그래프 문제 실행결과
  // handleRun : 실행 함수
  // runCodeIsLoading : 실행 결과를 받아오는 동안의 로딩
  const [val, setVal] = useState<string>(code);
  const { runRes, graphRes, handleRun, runCodeIsLoading } = useRunCode(val);
  // fix. unmount component update state
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <form className={'ed-component nte component'} data-id={id}>
      <div className="edit-title">
        <p dangerouslySetInnerHTML={{ __html: content }} />
        <select
          id="select-font-size"
          style={{ marginRight: '4px' }}
          className="ui dropdown langSelect select-font-size"
          onChange={handleFontSize}
          defaultValue="28px"
        >
          <option value="16px">16px</option>
          <option value="20px">20px</option>
          <option value="24px">24px</option>
          <option defaultValue="28px">28px</option>
          <option value="32px">32px</option>
        </select>
      </div>
      <div className="ed" style={{ height: '350px' }}>
        <Monaco
          key={id}
          height="350px"
          defaultLanguage="python"
          defaultValue=""
          theme="vs-dark"
          options={options}
          onMount={handleEditorDidMount}
          value={val}
          onChange={(value) => {
            setVal(value);
          }}
        />
      </div>
      <div className="ed-button-bar">
        <div>
          <button
            className="quiz-button clear"
            type="button"
            onClick={handleClear}
            //  * : test
          >
            <img src="/assets/icons/ic_refresh_normal_line_24.svg" alt="" />
            초기화
          </button>
        </div>
        <div>
          <button className="quiz-button run" type="button" onClick={handleRun}>
            {/* <img src="/assets/icons/ic_play_normal_fill_24.svg" alt="" />
            실행 */}
            {runCodeIsLoading === 1 ? (
              // * : SCSS. 실행 버튼 스피너 스타일 추가
              <div className="is-run-code-div">
                <img
                  src="/assets/icons/ic_spinner_line_24.svg"
                  style={{
                    margin: '0 18px 0 18px',
                    width: '25px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  alt=""
                />
              </div>
            ) : (
              <>
                <img src="/assets/icons/ic_play_normal_fill_24.svg" alt="" />
                실행
              </>
            )}
          </button>
        </div>
      </div>
      <div className="graph-box">
        <img src={graphRes} alt="" />
      </div>
      <div
        className="code-wrap"
        style={{ display: graphRes === '' ? 'block' : 'none' }}
      >
        <div className="code-title">
          <p>실행결과</p>
        </div>
        <div className="area-wrap">
          <textarea
            name="quiz-editor"
            id="mainResultArea"
            className="ed-result"
            readOnly
            value={runRes === '' ? '실행버튼을 눌러주세요 : ) ' : runRes}
          >
            {runRes === '' && '실행버튼을 눌러주세요 : )'}
          </textarea>
        </div>
      </div>
    </form>
  );
};

export default Editor;
