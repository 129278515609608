import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SkeletonCard } from 'components/main';
import { useGetCourse } from 'hooks/main';
import useUser from 'store/modules/user/userHook';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

SwiperCore.use([Navigation]);

const CourseCards = (): JSX.Element => {
  const { courseData, courseDataIsLoading } = useGetCourse();
  const { userData, isLoggedIn } = useUser();

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  return (
    <div className="slide-container">
      {!courseDataIsLoading ? (
        <>
          <Swiper
            slidesPerView="auto"
            centeredSlides={true}
            roundLengths={true}
            loop={true}
            loopAdditionalSlides={1}
            navigation={{
              nextEl: '.swiper-next',
              prevEl: '.swiper-prev',
            }}
            className="swiper-container"
          >
            <div className="swiper-wrapper">
              {courseData.map((course, index) => (
                <SwiperSlide className="swiper-slide" key={course.id}>
                  <img src={course.thumbnailUrl} alt="" />
                  <div className="text-wrap">
                    <h2>
                      {index + 1}. {course.courseName}
                    </h2>
                    <p>{course.courseSummary}</p>
                    {/* 승인 전 유저가 코스 시작 버튼 클릭시 /role 페이지로 이동 */}
                    {isLoggedIn &&
                    userData.userAuth === 2 &&
                    userData.userRole === 0 ? (
                      <Link to="/auth/role">코스 시작</Link>
                    ) : (
                      // course lock 버튼 막기
                      <Link
                        to={'/course/' + course.id}
                        style={course.isLock ? { pointerEvents: 'none' } : null}
                      >
                        코스 시작
                      </Link>
                    )}
                  </div>
                  {/* course lock 전체 자물쇠 이미지로 막기 */}
                  {course.isLock ? (
                    <div className="cover-box">
                      <img src="/assets/images/lock.png" alt="" />
                    </div>
                  ) : null}
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          {/* Add Arrows */}
          <div className="swiper-next">
            <img src="/assets/images/btn_slide_next.png" alt="" />
          </div>
          <div className="swiper-prev">
            <img src="/assets/images/btn_slide_prev.png" alt="" />
          </div>
        </>
      ) : (
        courseDataIsLoading && <SkeletonCard />
      )}
    </div>
  );
};

export default CourseCards;
