import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetProgressResponse } from 'hooks/main/progress/getProgress/interfaces';
import { IStudents } from './userHook';

export type UserState = {
  isLoggedIn: boolean;
  userData: LoginPayload | null;
};

export type LoginPayload = {
  id: number;
  userName: string;
  userEmail: string;
  userRole: number;
  userAuth: number;
  password: string;
  provider: null;
  providerId: null;
  visitCount: number;
  recentVisit: Date;
  classroomId: number;
  createdAt: Date;
  updatedAt: Date;
  classrooms: IGetProgressResponse[];
  progresses: [];
  hideData: number[];
  students: IStudents[];
};

const initialState: UserState = {
  isLoggedIn: false,
  userData: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginAction(state: UserState, action: PayloadAction<LoginPayload>) {
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    logoutAction(state: UserState) {
      state.isLoggedIn = false;
      state.userData = null;
      localStorage.removeItem('token');
      localStorage.removeItem('persist:root');
      if (window.Kakao.Auth.getAccessToken()) {
        // console.log(
        //   '카카오 인증 엑세스 토큰 존재',
        //   window.Kakao.Auth.getAccessToken()
        // );
        window.Kakao.Auth.logout(() => {
          // console.log(
          //   '카카오 로그아웃 완료',
          //   window.Kakao.Auth.getAccessToken()
          // );
        });
      }
    },
    setClassroomIdAction(state, { payload }) {
      const data = state.userData;
      if (data) {
        data.classroomId = payload;
      }
    },
    setHideDataAction(state, { payload }) {
      const data = state.userData;
      if (data) {
        data.hideData = payload;
      }
    },
    setClassroomsAction(state, { payload }) {
      const data = state.userData;
      if (data) {
        data.classrooms = payload;
      }
    },
    setUserRoleAction(state, { payload }) {
      const data = state.userData;
      if (data) {
        data.userRole = payload;
      }
    },
    setUserAuthAction(state, { payload }) {
      const data = state.userData;
      if (data) {
        data.userAuth = payload;
      }
    },
  },
});

const { reducer, actions } = userSlice;
export const {
  loginAction,
  logoutAction,
  setClassroomIdAction,
  setHideDataAction,
  setClassroomsAction,
  setUserRoleAction,
  setUserAuthAction,
} = actions;
export default reducer;
