import React, { useState } from 'react';
import LandingVideo from './landingVideo';

const LandingVideos = ({ infos, openVideoModal }) => {
  return (
    <>
      <div className="landing-videos">
        <ul className="video-list">
          {infos.map((info, index) => (
            <li
              className="video-item"
              key={index}
              onClick={() => openVideoModal(info)}
            >
              <LandingVideo info={info} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LandingVideos;
