import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import {
  fetchAppliers,
  IApplier,
  fetchCustomAppliers,
  fetchNotAppliers,
  fetchCustomNotAppliers,
} from './appliers';

interface IsetAppliers {
  appliers: IApplier[] | undefined;
  notAppliers: IApplier[] | undefined;
  setAppliers: () => void;
  setNotAppliers: () => void;
  setCustomAppliers: (
    userAuth?: number,
    userName?: string,
    userRole?: string,
    searchType?: number
  ) => void;
  setCustomNotAppliers: (userAuth?: number, userName?: string) => void;
  appliersIsLoading: boolean;
  notAppliersIsLoading: boolean;
  appliersNumAll: number;
  appliersNumNoApply: number;
  appliersNumWait: number;
  appliersNumComplete: number;
  appliersNumPause: number;
  appliersNumReject: number;
}

export default function useAppliers(): IsetAppliers {
  const appliers = useSelector(
    (state: RootState) => state['root'].appliers.appliers
  );
  const appliersIsLoading = useSelector(
    (state: RootState) => state['root'].appliers.loading
  );
  const notAppliers = useSelector(
    (state: RootState) => state['root'].appliers.notAppliers
  );
  const notAppliersIsLoading = useSelector(
    (state: RootState) => state['root'].appliers.nAloading
  );

  const appliersNumAll = useSelector(
    (state: RootState) => state['root'].appliers.appliersNumAll
  );
  const appliersNumNoApply = useSelector(
    (state: RootState) => state['root'].appliers.appliersNumNoApply
  );
  const appliersNumWait = useSelector(
    (state: RootState) => state['root'].appliers.appliersNumWait
  );
  const appliersNumComplete = useSelector(
    (state: RootState) => state['root'].appliers.appliersNumComplete
  );
  const appliersNumPause = useSelector(
    (state: RootState) => state['root'].appliers.appliersNumPause
  );
  const appliersNumReject = useSelector(
    (state: RootState) => state['root'].appliers.appliersNumReject
  );

  const dispatch = useDispatch();
  const setAppliers = useCallback(() => {
    dispatch(fetchAppliers());
  }, [dispatch]);
  const setCustomAppliers = useCallback(
    (userAuth, userName, userRole, searchType) => {
      dispatch(fetchCustomAppliers(userAuth, userName, userRole, searchType));
    },
    [dispatch]
  );
  const setNotAppliers = useCallback(() => {
    dispatch(fetchNotAppliers());
  }, [dispatch]);
  const setCustomNotAppliers = useCallback(
    (_, userName) => {
      dispatch(fetchCustomNotAppliers(userName));
    },
    [dispatch]
  );
  return {
    appliers,
    notAppliers,
    setAppliers,
    setNotAppliers,
    setCustomAppliers,
    setCustomNotAppliers,
    appliersIsLoading,
    notAppliersIsLoading,
    appliersNumAll,
    appliersNumNoApply,
    appliersNumWait,
    appliersNumComplete,
    appliersNumPause,
    appliersNumReject,
  };
}
