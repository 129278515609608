import { useEffect, useRef, useState } from 'react';
import { useCodeTest, useRunCode } from 'hooks/main/lesson/editor';
import useUser from 'store/modules/user/userHook';
import { IQuizEditor } from './interfaces';

import Monaco from '@monaco-editor/react';

const QuizEditor = ({
  id,
  title,
  content,
  code,
  answer,
  explanation,
  lessonId,
  index,
}: IQuizEditor): JSX.Element => {
  const { userData } = useUser();

  const [fontSz, setFontSz] = useState<number>(28);
  const options = {
    fontSize: fontSz,
    selectOnLineNumbers: true,
    roundedSelection: false,
    minimap: {
      enabled: false,
    },
    padding: {
      top: 10,
      bottom: 10,
    },
    renderWhitespace: 'none' as
      | 'none'
      | 'boundary'
      | 'selection'
      | 'trailing'
      | 'all',
  };
  const editorRef = useRef(null);
  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  const handleFontSize = (e) => {
    const fontSz = e.target.value;
    setFontSz(fontSz);
  };

  const handleClear = () => {
    setVal(code);
  };

  const [val, setVal] = useState<string>(code);

  // * : run code editor
  // - input
  // val : 입력 받은 code
  // - output
  // runRes : 실행결과
  // graphRes : 그래프 문제 실행결과
  // handleRun : 실행 함수
  // runCodeIsLoading : 실행 결과를 요청 후 받아오는 동안의 로딩
  const { runRes, graphRes, setRunRes, handleRun, runCodeIsLoading } =
    useRunCode(val);
  // * : submit code editor
  const [correct, setCorrect] = useState<boolean>(false);
  const [reqSuccess, setReqSuccess] = useState<boolean>(false);
  const [disabledSumbit, setDisabledSumbit] = useState<boolean>(false);
  // - input
  // data.id : 각 에디터 컴포넌트별 id로 다르게 들어옴
  // val : 입력 받은 code
  // setRunRes : (submit 과정에 포함된)실행에서 받은 결과를 runRes 상탯값에 넣기 위함
  // classroomId : 서버에 제출하기 위해 요구됨
  // lessonId : 서버에 제출하기 위해 요구됨
  // setCorrect : 정답을 맞췄는지에 대한 불린값 변경
  // setReqSuccess : 요청이 성공했는지에 대한 불린값 변경
  // setDisabledSumbit : 요청이 끝나고 버튼이 disabled되도록 하기 위한 불린값 변경
  // - output
  // handleSubmit : 실행 함수
  // codeTestIsLoading : 에디터 코드 제출 후 받아오는 동안의 로딩
  const { codeTestIsLoading, handleSubmit } = useCodeTest({
    cKey: id,
    code: val,
    setRunRes,
    classroomId: Number(userData?.classroomId),
    lessonId: Number(lessonId),
    setCorrect,
    setReqSuccess,
    setDisabledSumbit,
  });

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <form
      className={'ed-component te component'}
      data-id={id}
      onSubmit={handleSubmit}
    >
      <div className="question-text">
        <strong>
          {`${index + 1}. `}
          {title}
        </strong>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className="edit-title">
        <p>코드에디터</p>
        <select
          id="select-font-size"
          style={{ marginRight: '4px' }}
          className="ui dropdown langSelect select-font-size"
          onChange={handleFontSize}
          defaultValue="28px"
        >
          <option value="16px">16px</option>
          <option value="20px">20px</option>
          <option value="24px">24px</option>
          <option defaultValue="28px">28px</option>
          <option value="32px">32px</option>
        </select>
      </div>
      <div className="ed" style={{ height: '300px' }}>
        <Monaco
          height="300px"
          defaultLanguage="python"
          defaultValue=""
          theme="vs-dark"
          options={options}
          onMount={handleEditorDidMount}
          value={val}
          onChange={(value) => {
            setVal(value);
          }}
        />
      </div>
      <div className="ed-button-bar">
        <div>
          <button
            className="quiz-button clear"
            type="button"
            onClick={handleClear}
          >
            <img src="/assets/icons/ic_refresh_normal_line_24.svg" alt="" />
            초기화
          </button>
        </div>
        <div>
          {!disabledSumbit && (
            <button
              className="quiz-button run"
              type="button"
              onClick={handleRun}
              disabled={disabledSumbit}
            >
              {runCodeIsLoading === 1 ? (
                <img
                  src="/assets/icons/ic_spinner_line_24.svg"
                  style={{
                    margin: '0 18px 0 18px',
                    width: '25px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  alt=""
                />
              ) : (
                <>
                  <img src="/assets/icons/ic_play_normal_fill_24.svg" alt="" />
                  실행
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="graph-box">
        <img src={graphRes} alt="" />
      </div>
      <div className="code-wrap">
        <div className="code-title">
          <p>실행결과</p>
        </div>
        <div className="area-wrap">
          <textarea
            name="quiz-editor"
            id="mainResultArea"
            className="ed-result"
            readOnly
            value={runRes === '' ? '실행버튼을 눌러주세요 : )' : runRes}
          >
            {runRes === '' && '실행버튼을 눌러주세요 : )'}
          </textarea>
        </div>
      </div>
      {reqSuccess && (
        <div
          className={correct ? 'etc-text true-answer' : 'etc-text false-answer'}
          style={{
            display: reqSuccess ? 'block' : 'none',
          }}
        >
          {correct ? (
            <strong>
              <img src="/assets/icons/ic_answer_true_fill_24.svg" alt="" />
              정답입니다
            </strong>
          ) : (
            <strong>
              <img src="/assets/icons/ic_answer_wrong_fill_24.svg" alt="" />
              오답입니다
            </strong>
          )}
          <b>정답: {answer}</b>
          <p>{explanation}</p>
        </div>
      )}
      <div className="question-button-bar">
        {codeTestIsLoading === 1 ? (
          <div className="is-submit-div">
            <img
              src="/assets/icons/ic_spinner_line_24.svg"
              style={{
                margin: '16px',
                marginTop: '10px',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
              }}
              alt=""
            />
          </div>
        ) : (
          !disabledSumbit && (
            <input
              className="quiz-button ed-button"
              type="submit"
              value={'제출하기'}
              disabled={disabledSumbit}
            />
          )
        )}
      </div>
    </form>
  );
};

export default QuizEditor;
