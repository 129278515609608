import React, { useState } from 'react';
import axios from 'axios';
import { IgetCodeTest, codeTestSet } from './interfaces';
import useUser from 'store/modules/user/userHook';

const useCodeTest = ({
  cKey,
  code,
  setRunRes,
  classroomId = -1,
  lessonId,
  setCorrect,
  setReqSuccess,
  setDisabledSumbit,
}: IgetCodeTest): codeTestSet => {
  const [codeTestIsLoading, setCodeTestIsLoading] = useState<0 | -1 | 1>(-1);
  const { userData } = useUser();
  let correctAns;
  const [loading, setLoading] = useState(false);
  const getEditorTestResult = async ({
    cKey,
    code,
    setRunRes,
    classroomId,
    lessonId,
    setCorrect,
    setReqSuccess,
    setDisabledSumbit,
  }: IgetCodeTest) => {
    let run = '';
    await axios
      .post('https://code.coding-x.com/lang/python', { code: code })
      .then((res) => {
        if (res.data.stderr === '') {
          run = res.data.stdout.trim();
        } else {
          run = res.data.stderr.trim();

          const startIndex = run.indexOf(' "') + 2;
          const endIndex = run.indexOf('",');
          const filePath = run.substring(startIndex, endIndex);

          run = run.replace(filePath, '/run.py');
        }
        setRunRes(run);
      })
      .catch((err) => {
        console.error(err);
      });
    // # : 채점
    setLoading(true);
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/components/${cKey}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
      }
    );
    const json = await r.json();
    if (r.status === 200) {
      // 컴포넌트 내의 같은 컴포넌트가 가진 답을 받아와서 답인지 확인한 뒤 그 값을 correctAns 변수에 할당
      const userAnswer = run.split('\n');
      const answer = json.answer.split('\\n');

      correctAns = true;
      for (let i = 0; i < userAnswer.length; i++) {
        if (userAnswer[i] !== answer[i]) {
          correctAns = false;
        }
      }

      // correctAns = run === json.answer;
      setLoading(false);
      if (correctAns) {
        setCorrect(true);
      } else {
        setCorrect(false);
      }
    } else {
      alert(r.statusText);
    }
    // # : 제출(승인 전 유저가 제출하기 기능을 사용하는 경우 실제로 제출은 하지 않음)
    if (userData && !loading && userData.userAuth !== 1) {
      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/quizzes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          userId: userData?.id,
          lessonId: lessonId,
          componentId: cKey,
          classroomId: classroomId,
          isCorrect: correctAns,
        }),
      })
        .then(async (res) => {
          if (res.status === 201) {
            setReqSuccess(true);
            setDisabledSumbit(true);
          }
        })
        .finally(() => {
          setCodeTestIsLoading(0);
        });
    } else {
      setReqSuccess(true);
      setDisabledSumbit(true);
      setCodeTestIsLoading(0);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCodeTestIsLoading(1);
    getEditorTestResult({
      cKey,
      code,
      setRunRes,
      classroomId,
      lessonId,
      setCorrect,
      setReqSuccess,
      setDisabledSumbit,
    });
  };

  return { codeTestIsLoading, handleSubmit };
};

export default useCodeTest;
