import { IsetToggleInvite } from './interfaces';

export const getToggleInvite = (
  fetchProgressData: () => Promise<void>
): IsetToggleInvite => {
  const toggleInvite = async (
    classroomId: number,
    state: boolean
  ): Promise<void> => {
    try {
      fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms/${classroomId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify({
            isOpen: !state,
          }),
        }
      ).then((res) => {
        if (res.status === 200) {
          fetchProgressData();
        } else {
          alert(res.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return { toggleInvite };
};
