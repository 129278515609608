import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommonLayout, CourseCards, FooterLayout } from 'components/global';
import useUser from 'store/modules/user/userHook';
import Skeleton from 'react-loading-skeleton';
import { useGetMember, useLessonHistory } from 'hooks/main';
import schoolData from './LandingSchools.json';

import LandingVideos from 'components/main/landing/landingVideos';
import VideoModal from 'components/main/landing/videoModal';
import BackgroundBubble from 'components/main/landing/background_bubble';
import UsageFee from '../components/global/usageFee/usageFee';
import DefaultModal from 'components/global/modal/defaultModal';

const Landing = (): JSX.Element => {
  // 📌 로그인 전 페이지에서 쓰이는 video관련 변수 및 함수
  const infos = [
    {
      title: '힙하고 친절한 AI콘텐츠',
      image: 'onbook_landing_01.png',
      video:
        'https://ai-onbook-react.s3.ap-northeast-2.amazonaws.com/static/media/landing1.mp4',
    },
    {
      title: '실력검증 문제풀이',
      image: 'onbook_landing_02.png',
      video:
        'https://ai-onbook-react.s3.ap-northeast-2.amazonaws.com/static/media/landing2.mov',
    },
    {
      title: '설치없는 실습',
      image: 'onbook_landing_03.png',
      video:
        'https://ai-onbook-react.s3.ap-northeast-2.amazonaws.com/static/media/landing3.mp4',
    },
    {
      title: '학생 관리',
      image: 'onbook_landing_04.png',
      video:
        'https://ai-onbook-react.s3.ap-northeast-2.amazonaws.com/static/media/landing4.mov',
    },
  ];

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoModalInfo, setVideoModalInfo] = useState({});
  const openVideoModal = (info) => {
    setIsVideoModalOpen(true);
    setVideoModalInfo(info);
  };

  // 📌 요금안내 관련 모달
  const [isDefaultModalOpen, setIsDefaultModalOpen] = useState(false);
  const [defaultModalInfo, setDefaultModalInfo] = useState({});
  const openDefaultModal = (contents) => {
    setIsDefaultModalOpen(true);
    setDefaultModalInfo(contents);
  };

  // 최근 열람한 레슨
  // const { lessonHistoryResp, lessonHistoryRespIsLoading } = useLessonHistory();
  // useEffect(() => {
  //   lessonHistoryRespIsLoading || console.log(lessonHistoryResp);
  // }, [lessonHistoryRespIsLoading, lessonHistoryResp]);

  // 📌
  const schoolArray = Object.values(schoolData)[0];

  const { userData, isLoggedIn, setClassroomId } = useUser();
  const history = useHistory();

  // 반 목록을 가지고 있는데 반이 세팅되지 않은 경우 반을 세팅
  // 반 목록 중 첫번째 반을 현재 반으로 설정
  const { memberResp, memberRespIsLoading } = useGetMember();
  useEffect(() => {
    if (isLoggedIn && !memberRespIsLoading && memberResp.length !== 0) {
      if (
        (userData.userRole === 2 || userData.userRole === 3) &&
        (userData.classroomId === null || userData.classroomId === undefined)
      ) {
        setClassroomId(memberResp['0'].classroom.id);
      } else if (userData.userRole === 1) {
        setClassroomId(memberResp['0'].classroom.id);
      }
    }
  }, [isLoggedIn, memberResp, memberRespIsLoading, setClassroomId, userData]);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  return (
    <>
      <CommonLayout buttonHide />
      {/* 📌 로그인 후 랜딩페이지 */}
      {isLoggedIn && (
        <div className="body-inner landing-inner after-login">
          <div className="wave"></div>
          <div className="wave wave2"></div>
          <div className="slogun-container">
            <p>
              세상을 바꾸는 인공지능
              <br />
              AI OnBook
            </p>
            <small>미래를 비추는 인공지능 학습 플랫폼 코딩엑스</small>
            {userData.userAuth === 2 && memberRespIsLoading && (
              <Skeleton
                width={300}
                height={66}
                style={{
                  display: 'inline-block',
                  marginTop: '50px',
                  padding: '14px 54px',
                  color: '#fff',
                  fontSize: '24px',
                  borderRadius: '33px',
                }}
              />
            )}
            {userData.userRole === 1 &&
              !memberRespIsLoading &&
              memberResp.length !== 0 && (
                <div className="btn-fill-student class-content">
                  {memberResp['0'].classroom.classroomName} 학생
                </div>
              )}
            {userData.userRole === 2 && userData.userAuth === 2 && (
              <div
                className="btn-fill class-content"
                onClick={() => history.push(`/classrooms`)}
              >
                <span>{userData.userName} 쌤의 반 관리</span>
                <img src="/assets/icons/ic_people_normal_line_24.svg" alt="" />
              </div>
            )}
            {userData.userRole === 3 && (
              <div
                className="btn-fill class-content"
                onClick={() => history.push(`/admin`)}
              >
                <span>관리자 페이지</span>
                <img src="/assets/icons/ic_people_normal_line_24.svg" alt="" />
              </div>
            )}
            {/* <div>테스트</div> */}
          </div>
          <CourseCards />
        </div>
      )}

      {/* 📌 로그인 전 랜딩페이지 */}
      {!isLoggedIn && (
        <main className="body-inner landing-inner before-login">
          {/* landing 윗 부분 */}
          <section className="landing-top">
            <div className="landing-top-inner">
              <div className="landing-slogun">
                <small>세상을 바꾸는 AI교과서의 정석</small>
                <h1>AI OnBook</h1>
              </div>
              <LandingVideos infos={infos} openVideoModal={openVideoModal} />
            </div>
          </section>

          {/* landing 아랫부분 */}
          <section className="landing-bottom">
            <div className="landing-bottom-inner">
              <div className="landing-bottom-title">
                <h2>
                  온북으로 미래를 앞서가는
                  <br />
                  {schoolArray.length}개의 그룹들
                </h2>
                <div className="scrolling-text">
                  <ul>
                    {schoolArray.map((school, index) => (
                      <li key={index}>{school}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="course-card-container">
                <CourseCards />
              </div>
              <UsageFee openDefaultModal={openDefaultModal} />
            </div>
          </section>
          <BackgroundBubble bubbleCount={10} />
        </main>
      )}
      <FooterLayout />

      {/* default 모달 */}
      {isDefaultModalOpen && (
        <DefaultModal
          modalInfo={defaultModalInfo}
          setOpen={setIsDefaultModalOpen}
        />
      )}

      {/* VideoModal 모달창 */}
      {isVideoModalOpen && (
        <VideoModal modalInfo={videoModalInfo} setOpen={setIsVideoModalOpen} />
      )}

      {/* 팝업 */}
      {/* <Popup /> */}
    </>
  );
};

export default Landing;
