import { LessonSideButtonsProps } from './interfaces';
import { useBookmarkData } from 'hooks/main';

// props
// bookmarkState: 북마크의 상태 (북마크인지 아닌지)
// setBookmarkState: 북마크 상태 설정 (bookmarkState 설정)
// courseId: 레슨 데이터에서 받은 courseId
// lessonId: 레슨 데이터에서 받은 lessonId
// classroomId: userData.classroomId를 넘겨받은 것
// setBookmark: /bookmarks POST 요청을 날리는 함수
const LessonSideButtons = ({
  bookmarkState,
  setBookmarkState,
  courseId,
  lessonId,
  classroomId,
  setBookmark,
}: LessonSideButtonsProps): JSX.Element => {
  useBookmarkData(courseId, classroomId, lessonId, setBookmarkState);

  return (
    <>
      {/* 레슨 편집하기 버튼 주석 처리 */}
      {/* <button
        onClick={() => {
          history.push('/lesson/edit');
        }}
      >
        <img src="/assets/icons/ic_setting_normal_line_24.svg" alt="" />
        <span>편집하기</span>
      </button> */}
      <button
        id="bookmark"
        className={bookmarkState ? 'bookmark-on' : ''}
        onClick={() =>
          setBookmark(courseId, lessonId, classroomId, setBookmarkState)
        }
      >
        <img
          src="/assets/icons/ic_bookmark_normal_line_24.svg"
          className={bookmarkState ? 'icon' : ''}
          alt=""
        />
        <span>북마크</span>
      </button>
    </>
  );
};

export default LessonSideButtons;
