import { IText } from './interfaces';

// props
// data: 해당 컴포넌트의 정보
const Text = ({ id, content }: IText): JSX.Element => {
  return (
    <div className={'tt-component component'} data-id={id}>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default Text;
