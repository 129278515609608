import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from 'components/global/button/Button';
import { FormInput, Spinner } from 'components/global';
import { useHandleLogin } from 'hooks/main';
import useUser from 'store/modules/user/userHook';
import 'styles/stylesheets/common.css';
import 'styles/stylesheets/style.css';
import 'styles/stylesheets/reset.css';
import 'styles/stylesheets/css_set.css';
import 'styles/stylesheets/login.css';

const LoginForm = ({ destination }): JSX.Element => {
  const history = useHistory();
  const { isLoggedIn, userData } = useUser();
  useEffect(() => {
    if (isLoggedIn) {
      if (userData.userAuth === 2 && userData.userRole === 0) {
        history.push('/auth/role');
      } else {
        if (destination) {
          history.push(destination);
        } else {
          history.push('/');
        }
      }
    }
  });

  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setLoginForm({
      ...loginForm,
      [name]: value,
    });
  };

  const { emailErr, pwErr, handleSubmit, loginIsLoading } =
    useHandleLogin(loginForm);

  const { login } = useUser();
  // 카카오 로그인은 운영상 문제로 잠시 내려둠
  const kakaoLoginClickHandler = () => {
    window.Kakao.Auth.login({
      success: async function (authObj) {
        const r = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/user/auth/kakao`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
            },
            body: JSON.stringify({
              token: authObj.access_token,
            }),
          }
        );
        const json = await r.json();
        const status = r.status;
        if (json.jwtToken !== undefined) {
          localStorage.setItem(
            'token',
            JSON.stringify(json.jwtToken).replace(/"/gi, '')
          );
        }
        if (status === 400) {
          alert(json.message);
        } else if (status === 200) {
          login(json.user);
        }
      },
      fail: function (err) {
        alert(JSON.stringify(err));
      },
    });
  };

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <div className="login-body">
      <div className="login-container">
        <div className="login-card">
          <img src="/assets/images/AI_Onbook_login.png" alt="" />
        </div>
        <div className="login-info">
          <form onSubmit={handleSubmit}>
            <div className="Shadow-lt-1 login-box">
              <div className="login-title">
                <img
                  src="/assets/images/onbook-logo.svg"
                  alt="coding-x"
                  onClick={() => {
                    history.push('/');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className="login-desc">
                미래를 비추는 <br />
                인공지능 학습 플랫폼
              </div>
              <FormInput
                type="text"
                divCn="form-label-group email-field"
                inputCn={`text-field-base in-label ${
                  emailErr ? 'input-error' : 'input-self'
                } form-control`}
                placeholder="Email"
                name="email"
                value={loginForm.email}
                onChange={onChange}
                errorTxtId="email-error-text"
                errorTxt="이메일 형식이 올바르지 않습니다."
                error={emailErr}
                isFocus={true}
              >
                Email
              </FormInput>
              <FormInput
                type="password"
                divCn="form-label-group password-field"
                inputCn={`text-field-base in-label ${
                  pwErr ? 'input-error' : 'input-self'
                } form-control`}
                placeholder="Password"
                name="password"
                value={loginForm.password}
                onChange={onChange}
                errorTxtId="pw-error-text"
                errorTxt="비밀번호(8자 이상 자율조합)"
                error={pwErr}
              >
                Password
              </FormInput>
              {emailErr || pwErr ? (
                <div className="auth-error-text">
                  이메일과 비밀번호를 확인해주세요.
                </div>
              ) : null}
              {loginIsLoading === 1 ? (
                // * : SCSS. 로그인 버튼 스피너 스타일 추가
                <button
                  id="btn-login"
                  className="btn btn-fill-accent btn-pd-iconnone-48 btn-login"
                >
                  <Spinner isLogin />
                </button>
              ) : (
                <Button
                  type="normal"
                  id="btn-login"
                  btnCn="btn btn-fill-accent btn-pd-iconnone-48 btn-login"
                >
                  로그인
                </Button>
              )}
              <label className="checkbox-container auto-login">
                {/* <input type="checkbox" defaultChecked />
                  <span className="checkbox-checkmark"></span>
                  <span className="auto-login-text">
                    자동 로그인
                  </span> */}
              </label>
              {/* 카카오 로그인은 운영상 문제로 잠시 내려둠 */}
              {/* <div className="sns-login-btn"> */}
              {/* <Button
                    type="a"
                    url={
                      'https://kauth.kakao.com/oauth/authorize?client_id=8aa2dfe9da36e3ce66d8e8444db67da8&redirect_uri=http://localhost:3500/kakao&response_type=code'
                    }
                    path="/assets/icons/kakao-symbol.png"
                    name="kakao"
                    handleClick={kakaoLoginClickHandler}
                  >
                    카카오 로그인
                  </Button> */}
              {/* <Button
                    type="a"
                    url={'https://gauth.google.com'}
                    path="/assets/icons/ic_google_light_normal.svg"
                    name="google"
                  >
                    구글 로그인
                  </Button> */}
              {/* </div> */}
              <hr className="hr-horizontal" />
              <div>
                <Link to="/signup" className="signup-button">
                  회원가입 할래요
                </Link>
                {/* <Button type="normal" id="btn-login" btnCn="signup-button">
                    회원가입 할래요
                  </Button> */}
                {/* <a href="/auth/passreset" className="reset-password">
                    비밀번호 재설정
                  </a> */}
                {/* <a href="/auth/register" className="sign-link">
                    회원가입할래요
                  </a> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default LoginForm;
