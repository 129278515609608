import React from 'react';

export const mediaBlockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: Media,
      editable: false,
    };
  }

  return null;
};

const Image = (props) => {
  if (props.src) {
    return <img src={props.src} />;
  }
  return null;
};

const Anchor = (props) => {
  const t = true;
  if (props.href) {
    console.log('alias', props.alias);

    return (
      <a href={props.href} target="_blank" download rel="noreferrer">
        {/* {props.href} */}
        {props.alias}
      </a>
    );
  }
  return null;
};

const Media = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  console.log(entity, entity.getData());

  const { src, href, url, alias } = entity.getData();
  console.log(src, href, url, alias);

  const type = entity.getType();

  let media = <></>;

  if (type === 'image' && src !== undefined) {
    media = <Image src={src} />;
  }
  if (type === 'LINK' && url !== undefined) {
    media = <Anchor href={url} />;
  }
  if (type === 'link' && href !== undefined) {
    media = <Anchor href={href} alias={alias} />;
  }

  return media;
};
