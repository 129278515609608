import { useState } from 'react';
import { IApplier } from 'store/modules/appliers/appliers';

const useUserInfo = (): [IApplier, (userId: number) => Promise<void>] => {
  const [userInfo, setUserInfo] = useState();

  const getUserInfo = async (userId: number): Promise<void> => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const status = r.status;
    const json = await r.json();

    if (status === 200) {
      console.log('userInfo', json);

      setUserInfo(json);
    } else {
      alert(r.statusText);
    }
  };

  return [userInfo, getUserInfo];
};

export default useUserInfo;
