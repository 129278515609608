import { Dispatch, SetStateAction, useRef, useState } from 'react';

import useUser from 'store/modules/user/userHook';

const PasswordForm = ({
  setPane,
}: {
  setPane: Dispatch<SetStateAction<number>>;
}): JSX.Element => {
  const prevPassword = useRef(null);
  const newPassword = useRef(null);
  const rePassword = useRef(null);

  const { userData } = useUser();

  const [reset, setReset] = useState('');
  const [prev, setPrev] = useState('');

  const checkPassword = async (password) => {
    const result = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/auth/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          email: userData.userEmail,
          password,
        }),
      }
    );

    const status = result.status;

    if (status === 200) {
      prevPassword.current.style.backgroundColor = '#f5f6ff';
      prevPassword.current.style.borderBottom = '#ffccd2';
      document.getElementById('notmatch').style.visibility = 'hidden';

      setPrev(password);
    } else {
      prevPassword.current.style.backgroundColor = '#ffebee';
      document.getElementById('notmatch').style.visibility = 'visible';
      setPrev('');
    }
  };

  const checkPasswordLength = (length) => {
    if (length < 8) {
      newPassword.current.style.backgroundColor = '#ffebee';
      document.getElementById('morethan').style.visibility = 'visible';
    } else {
      newPassword.current.style.backgroundColor = '#f5f6ff';
      newPassword.current.style.borderBottom = '#ffccd2';
      document.getElementById('morethan').style.visibility = 'hidden';
    }
  };

  const checkPasswordEqual = () => {
    if (newPassword.current.value === rePassword.current.value) {
      rePassword.current.style.backgroundColor = '#f5f6ff';
      document.getElementById('new-notmatch').style.visibility = 'hidden';

      setReset(newPassword.current.value);
    } else {
      rePassword.current.style.backgroundColor = '#ffebee';
      rePassword.current.style.borderBottom = '#ffccd2';
      document.getElementById('new-notmatch').style.visibility = 'visible';
      setReset('');
    }
  };

  const resetPassword = async (password, prePassword) => {
    if (prePassword === '') alert('기존 비밀번호를 정확히 입력해주세요.');
    else if (password === '') alert('새로운 비밀번호를 정확히 입력해주세요.');
    else {
      const result = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users/${userData.id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify({
            prePassword,
            password,
          }),
        }
      );

      const status = result.status;

      if (status === 200) {
        setPane(2);
      } else {
        const json = await result.json();
        alert(json.message);
      }
    }
  };

  return (
    <div className="mypage-inner">
      <h2>비밀번호 재설정</h2>
      <div className="input-form">
        <label htmlFor="prev-password">기존 비밀번호</label>
        <input
          ref={prevPassword}
          id="prev-password"
          type="password"
          onBlur={(e) => {
            checkPassword(e.target.value);
          }}
        />
        <p id="notmatch" style={{ visibility: 'hidden' }}>
          기존 비밀번호와 일치하지 않습니다.
        </p>
      </div>
      <div className="input-form">
        <label htmlFor="new-password">신규 비밀번호 (8자 이상)</label>
        <input
          ref={newPassword}
          id="new-password"
          type="password"
          onChange={(e) => {
            checkPasswordLength(e.target.value.length);
            checkPasswordEqual();
          }}
        />
        <p id="morethan" style={{ visibility: 'hidden' }}>
          8자 이상 입력해주세요.
        </p>
      </div>
      <div className="input-form">
        <label htmlFor="check-password">비밀번호 확인</label>
        <input
          ref={rePassword}
          id="check-password"
          type="password"
          onChange={(e) => {
            checkPasswordEqual();
          }}
        />
        <p id="new-notmatch" style={{ visibility: 'hidden' }}>
          비밀번호가 일치하지 않습니다.
        </p>
      </div>
      <button onClick={() => resetPassword(reset, prev)}>
        비밀번호 재설정
      </button>
    </div>
  );
};

export default PasswordForm;
