import React from 'react';
import { InfoForm } from 'components/main';

const infoPage = (): JSX.Element => {
  return (
    <>
      <InfoForm />
    </>
  );
};

export default infoPage;
