import { createSlice } from '@reduxjs/toolkit';

export type AdminState = {
  menuOpen: boolean;
  modalOpen: boolean;
};

const initialState: AdminState = {
  menuOpen: false,
  modalOpen: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    openMenuAction(state: AdminState) {
      // redux not(!) toggle don't work
      if (state.menuOpen) {
        state.menuOpen = false;
      } else {
        state.menuOpen = true;
      }
    },
    initOpenMenuAction(state: AdminState) {
      state.menuOpen = true;
    },
    offModalAction(state: AdminState) {
      state.modalOpen = false;
    },
    openModalAction(state: AdminState) {
      state.modalOpen = true;
    },
  },
});

const { reducer, actions } = adminSlice;
export const {
  openMenuAction,
  initOpenMenuAction,
  offModalAction,
  openModalAction,
} = actions;
export default reducer;
