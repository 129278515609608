import axios from 'axios';
import { useEffect, useState } from 'react';
import { IsetGetCourseDetailsData } from './interfaces';

const useGetCourseDetails = (courseId: string): IsetGetCourseDetailsData => {
  const [courseDetailsResp, setCourseDetailsResp] = useState();
  const [courseDetailsRespError, setCourseDetailsRespError] =
    useState<string>('');
  const [courseDetailsRespIsLoading, setCourseDetailsRespIsLoading] =
    useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(`${process.env.REACT_APP_SERVER_BASE_URL}/courses/${courseId}`, {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          })
          .then((res) => {
            setCourseDetailsResp(res.data);
          })
          .finally(() => {
            setCourseDetailsRespIsLoading(false);
          });
      } catch (err) {
        setCourseDetailsRespError(err);
      }
    };
    if (courseDetailsRespIsLoading) {
      fetchData();
    }
  }, [courseDetailsRespIsLoading, courseId]);

  return {
    courseDetailsResp,
    courseDetailsRespError,
    courseDetailsRespIsLoading,
  };
};

export default useGetCourseDetails;
