import { useCallback, useState } from 'react';
import useAdmin from 'store/modules/admin/adminHook';
import { CardModal } from 'components/main';

const useAdminModal = (
  type: number,
  setType: React.Dispatch<React.SetStateAction<number>>,
  header: string,
  editInfoInit?: (
    userId: number,
    userAuth: number,
    userRole: number
  ) => Promise<void>,
  editInfoRep?: (
    userId: number,
    userAuth: number,
    userRole: number
  ) => Promise<void>,
  editInfoMemo?: (userId: number, memo: string) => Promise<void>
): [() => void, (viewDetail?) => JSX.Element] => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setModalOff } = useAdmin();
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = useCallback(() => {
    setType(1);
    setIsModalOpen(false);
    setModalOff();
  }, [setModalOff, setType]);

  const handleSubmit = useCallback(
    (ty, userId, userAuth, userRole, memo?) => {
      if (ty === 'init') {
        editInfoInit(userId, userAuth, userRole);
      } else if (ty === 'repeat') {
        editInfoRep(userId, userAuth, userRole);
      } else if (ty === 'updateMemo') {
        editInfoMemo(userId, memo);
      }
    },
    [editInfoInit, editInfoRep, editInfoMemo]
  );

  const renderModal = useCallback(
    (viewDetail?) => (
      <CardModal
        ty={type}
        setTy={setType}
        header={header}
        isOpen={isModalOpen}
        close={closeModal}
        handleReq={handleSubmit}
        detail={viewDetail}
      />
    ),
    [closeModal, handleSubmit, header, isModalOpen, setType, type]
  );

  return [openModal, renderModal];
};

export default useAdminModal;
