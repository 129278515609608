import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ComponentConverter } from 'components/global/component';
import { FooterLayout, LessonSideButtons } from 'components/global';
import { registerProgresses, setBookmark } from 'services/lesson/lesson';
import useUser from 'store/modules/user/userHook';
import Skeleton from 'react-loading-skeleton';
import {
  useComponentsData,
  useLessonData,
  useNextLessonData,
} from 'hooks/main';

const CompoConverter = React.memo(ComponentConverter);

const Lesson = ({ match }): JSX.Element => {
  const { userData, isLoggedIn } = useUser();
  const [bookmarkState, setBookmarkState] = useState(Boolean);

  // 데이터 패치
  const { lessonResp, lessonRespIsLoading } = useLessonData({
    lessonId: match.params.lessonId,
  });
  const { componentsResp, componentsRespIsLoading } = useComponentsData({
    lessonId: match.params.lessonId,
  });
  const { nextLessonResp, nextLessonRespIsLoading } = useNextLessonData({
    lessonId: match.params.lessonId,
  });

  const history = useHistory();

  // 스크롤 페이지 위치를 progress-bar로 표시
  const handleScroll = () => {
    const wintop = window.scrollY;
    const docheight = document.body.clientHeight;
    const winheight = window.innerHeight;
    const totalScroll = (wintop / (docheight - winheight)) * 100;
    const progressBar = document.querySelector<HTMLElement>('.progress-bar');
    progressBar?.setAttribute('style', 'width: ' + totalScroll + '%');
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  // fix. attendLesson 뒤로가기 - dependency only lessonId
  useEffect(() => {
    if (
      isLoggedIn &&
      userData.classroomId !== undefined &&
      userData.classroomId !== null &&
      match.params.lessonId !== null
    )
      registerProgresses(
        userData.classroomId,
        userData.id,
        Number(match.params.lessonId),
        1
      );
  }, [match.params.lessonId]);

  // set 최근 열람한 레슨
  // useEffect(() => {
  //   console.log(
  //     `params=${match.params.lessonId}, userData=${userData.classroomId}`
  //   );
  //   if (isLoggedIn && userData.classroomId) {
  //     setLessonHistory(Number(match.params.lessonId));
  //   }
  // }, [match.params.lessonId]);

  if (!didMount) {
    return null;
  }

  return (
    <div className="lesson-body">
      <div className="lesson-header">
        <div className="header-inner">
          <div
            className="prev-page"
            id="prev-page"
            onClick={() => {
              history.goBack();
            }}
          >
            <img
              src="/assets/icons/ic_arrow_prev_line.svg"
              className="icon"
              alt=""
            />
          </div>
          {lessonRespIsLoading ? (
            <ul>
              <li>
                <Skeleton height={25} width={80} />
              </li>
              <li>
                <img src="/assets/icons/ic_arrow_right_line_24.png" alt="" />
              </li>
              <li>
                <Skeleton height={25} width={100} />
              </li>
              <li>
                <img src="/assets/icons/ic_arrow_right_line_24.png" alt="" />
              </li>
              <li>
                <Skeleton height={25} width={160} />
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to={'/course/' + lessonResp.chapter.course.id}>
                  {lessonResp.chapter.course.courseName}
                </Link>
              </li>
              <li>
                <img src="/assets/icons/ic_arrow_right_line_24.png" alt="" />
              </li>
              <li>
                {/* 챕터 페이지 없으니 임시로 코스 페이지 이동 */}
                <Link to={'/course/' + lessonResp.chapter.course.id}>
                  {lessonResp.chapter.chapterName}
                </Link>
              </li>
              <li>
                <img src="/assets/icons/ic_arrow_right_line_24.png" alt="" />
              </li>
              <li>{lessonResp.lessonName}</li>
            </ul>
          )}
        </div>
        {!lessonRespIsLoading && (
          <div className="progress-container">
            <div className="progress-bar" />
          </div>
        )}
      </div>
      {lessonRespIsLoading ? (
        <Skeleton
          style={{
            paddingTop: '-4px',
            height: '33.333vw',
            maxHeight: '640px',
          }}
        />
      ) : (
        <div
          className="thumbnail-container"
          style={{
            backgroundImage: `url(${lessonResp.thumbnailUrl})`,
            backgroundSize: 'cover',
          }}
        />
      )}
      <div className="container">
        <div className="main-section">
          <div className="container-title">
            {lessonRespIsLoading ? (
              <h1>
                <Skeleton width={500} />
              </h1>
            ) : (
              <h1>{lessonResp.lessonName}</h1>
            )}
          </div>
          <div className="container-body">
            {!componentsRespIsLoading ? (
              <>
                {componentsResp
                  .filter((component) => !component.isQuiz)
                  .map((component: any, index: number) => (
                    // .map(
                    //   (component: any, index: number) =>
                    //     !component.isQuiz && (
                    <div key={index}>
                      <CompoConverter data={component} />
                    </div>
                  ))}
              </>
            ) : (
              <>
                <div>
                  <Skeleton width={350} height={40} />
                </div>
                <div>
                  <Skeleton
                    width={900}
                    height={25}
                    style={{ marginTop: '35px' }}
                  />
                </div>
                <div>
                  <Skeleton
                    width={800}
                    height={25}
                    style={{ marginTop: '8px' }}
                  />
                </div>
                <div>
                  <Skeleton
                    width={600}
                    height={25}
                    style={{ marginTop: '8px' }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {!lessonRespIsLoading &&
          !componentsRespIsLoading &&
          componentsResp.find((element) => element.isQuiz === true) !==
            undefined && (
            <div className="sub-section">
              <div className="container-title">
                <h1>연습문제</h1>
              </div>
              <div className="container-body">
                {componentsResp
                  .filter((component) => component.isQuiz)
                  .map((component: any, index: number) => (
                    <div key={index}>
                      <CompoConverter data={component} index={index} />
                    </div>
                  ))}
              </div>
            </div>
          )}
        {!lessonRespIsLoading && !nextLessonRespIsLoading && (
          <>
            {nextLessonResp ? (
              <div className="footer-section">
                <div className="wave"></div>
                <div className="wave wave2"></div>
                <div className="footer-inner">
                  <div className="lesson-box">
                    <div
                      className="thumbnail-box"
                      style={{
                        backgroundImage: `url(${nextLessonResp.chapter.thumbnailUrl})`,
                        backgroundSize: 'contain',
                      }}
                    >
                      {nextLessonResp.isLock ? (
                        <div
                          style={{
                            width: 'inherit',
                            height: 'inherit',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src="/assets/images/lock.png"
                            alt=""
                            style={{
                              height: '50%',
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="lesson-info">
                      <small>
                        {lessonResp.chapter.course.courseName}&nbsp;&gt;&nbsp;
                        {nextLessonResp.chapter.chapterName}
                      </small>
                      <p>{nextLessonResp.lessonName}</p>
                    </div>
                    {nextLessonResp.isLock ? (
                      <button
                        type="button"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          pointerEvents: 'none',
                        }}
                      >
                        <img
                          src="/assets/images/lock.png"
                          alt=""
                          style={{
                            height: '50%',
                          }}
                        />
                      </button>
                    ) : (
                      <Link to={`/lesson/${nextLessonResp.id}`}>
                        <button type="button">Next</button>
                      </Link>
                    )}
                    <div className="folder-box">
                      <b>다음 레슨에서 배울 수 있는것들!</b>
                      <p>{nextLessonResp.summary}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="last-section">
                <div className="section-inner">
                  <p>마지막 레슨입니다!</p>
                  <Link to={`/course/${lessonResp.chapter.course.id}`}>
                    <button className="btn-fill" type="button">
                      목록으로 돌아가기
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* 선생님or관리자가 반이 있는 상태로 레슨 페이지 접근시 */}
      {/* 북마크의 버튼 상태가 표시 */}
      {isLoggedIn &&
        (userData.userRole === 2 || userData.userRole === 3) &&
        userData.classroomId !== undefined &&
        userData.classroomId !== null && (
          <div className="floating-btns">
            {!lessonRespIsLoading && (
              <LessonSideButtons
                bookmarkState={bookmarkState}
                setBookmarkState={setBookmarkState}
                courseId={lessonResp.chapter.course.id}
                lessonId={lessonResp.id}
                classroomId={userData.classroomId}
                setBookmark={setBookmark}
              />
            )}
          </div>
        )}
      <FooterLayout />
    </div>
  );
};

export default Lesson;
