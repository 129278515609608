import { RouteComponentProps } from 'react-router-dom';

export const signUp = (
  email: string,
  name: string,
  password: string,
  history: RouteComponentProps['history']
): void => {
  try {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
      },
      body: JSON.stringify({
        email,
        name,
        password,
      }),
    }).then(async (response) => {
      if (response.status === 201) {
        history.push('/login');
      } else if (response.status === 400) {
        alert((await response.json()).message);
      }
    });
  } catch (err) {
    console.log(err);
  }
};
