import React, { useEffect } from 'react';

const DefaultModal = ({ modalInfo, setOpen }) => {
  const { title, text, button } = modalInfo;

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;
    `;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <>
      <div className="modal info-modal">
        <div className="modal-header">{title}</div>
        <div className="modal-body">{text}</div>
        <div className="modal-footer">
          {button && (
            <>
              <button
                className="close-button btn-outlined btn-48"
                type="button"
                onClick={() => close()}
              >
                닫기
              </button>
              <a
                className="modal-button btn-primary btn-48"
                href="https://coding-x.channel.io"
              >
                채널톡 문의
              </a>
            </>
          )}
          {button || (
            <button
              className="close-button btn-outlined btn-48"
              type="button"
              onClick={() => close()}
            >
              확인
            </button>
          )}
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default DefaultModal;
