// store/modules/userHook.ts
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { fetchStudents } from './students';
import { StudentsI } from '../../../components/main/tab/interfaces';

interface IsetStudents {
  students: StudentsI[];
  setStudents: (classroomId: number) => void;
  studentsIsLoading: boolean;
}

export default function useStudents(): IsetStudents {
  const students = useSelector(
    (state: RootState) => state['root'].students.students
  );
  const studentsIsLoading = useSelector(
    (state: RootState) => state['root'].students.loading
  );
  const dispatch = useDispatch();
  const setStudents = useCallback(
    (classroomId: number) => {
      dispatch(fetchStudents(classroomId));
    },
    [dispatch]
  );
  return { students, setStudents, studentsIsLoading };
}
