import useAppliers from 'store/modules/appliers/appliersHook';
import { IsetRequestAuth } from './interfaces';

// POST /applications 새 신청서 생성
const useGetRequestAuth = (): IsetRequestAuth => {
  const { setAppliers } = useAppliers();
  const requestAuth = async (
    userRole: string,
    userAuth: number,
    userTel: string,
    groupName: string,
    expectedNumber: string,
    knownPath: string,
    inquiry: string,
    userId: number,
    notify?: () => void,
    teacherPurchase?: number
  ): Promise<void> => {
    try {
      const updatePurchase = () => {
        return fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
            body: JSON.stringify({
              userPurchase: teacherPurchase,
            }),
          }
        );
      };

      await updatePurchase();

      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/applications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          userId,
          userRole,
          userAuth,
          userTel,
          groupName,
          expectedNumber,
          knownPath,
          inquiry,
        }),
      }).then((response) => {
        if (response.status === 201) {
          if (notify !== undefined) {
            setTimeout(() => {
              notify();
            }, 100);
            setAppliers();
          }
        } else {
          alert(response.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return { requestAuth };
};

export default useGetRequestAuth;
