import React from 'react';
import { ISearchBar } from './interface';

const SearchBar = ({
  type,
  searchQuery,
  setSearchQuery,
  userAuth,
  userRole,
  setU,
  setCustomU,
  searchType,
  children,
}: ISearchBar): JSX.Element => {
  const handleKeyDown = (e, searchQuery, userAuth, searchType) => {
    console.log('search', searchQuery, userAuth);

    if (e.key === 'Enter') {
      e.preventDefault();
      if ((userAuth === -2 || userAuth === -1) && searchQuery === '') {
        setU();
      } else {
        if (type === 2)
          setCustomU(userAuth, searchQuery, String(userRole), searchType);
        else if (type === 1 || type === 3)
          setCustomU(userAuth, searchQuery, String(userRole), searchType);
      }
    }
  };

  return (
    <form action="/" method="get" className="search-bar-form">
      <input
        className="search-bar"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e, searchQuery, userAuth, searchType)}
        type="text"
        id="header-search"
        placeholder={children}
        name="s"
        style={{
          width: type === 1 ? '' : '100%',
          marginBottom: type === 1 ? '' : '20px',
        }}
      />
    </form>
  );
};

export default SearchBar;
