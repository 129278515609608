import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

const SkeletonCard = (): JSX.Element => {
  return (
    <>
      <Swiper
        spaceBetween={1}
        slidesPerView="auto"
        centeredSlides={true}
        roundLengths={true}
        loop={true}
        loopAdditionalSlides={30}
        navigation={{
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        }}
        className="swiper-container"
      >
        <div className="swiper-wrapper">
          {Array(9)
            .fill(1)
            .map((_, index) => (
              <SwiperSlide className="swiper-slide" key={index}>
                <Skeleton height={324} />
                <div className="text-wrap">
                  <h2>
                    <Skeleton />
                  </h2>
                  <p>
                    <Skeleton />
                  </p>
                  <Link to="/">
                    <Skeleton width={140} />
                  </Link>
                </div>
              </SwiperSlide>
            ))}
        </div>
      </Swiper>
      <div className="swiper-next">
        <img src="/assets/images/btn_slide_next.png" alt="" />
      </div>
      <div className="swiper-prev">
        <img src="/assets/images/btn_slide_prev.png" alt="" />
      </div>
    </>
  );
};

export default SkeletonCard;
