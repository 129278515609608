import React from 'react';
import ImgButton from './ImgButton';
import { ButtonProps } from './interfaces';

const Button = (props: ButtonProps): JSX.Element => {
  return (
    <>
      {props.type === 'normal' ? (
        <button id={props.id} className={props.btnCn}>
          {props.children}
        </button>
      ) : props.type === 'a' ? (
        <ImgButton
          type={props.type}
          url={props.url}
          path={props.path as string}
          name={props.name as string}
          handleClick={props.handleClick}
        >
          {props.children}
        </ImgButton>
      ) : (
        <ImgButton
          type={'link'}
          url={props.url}
          path={props.path as string}
          name={props.name as string}
        >
          {props.children}
        </ImgButton>
      )}
    </>
  );
};

export default Button;
