import React, { useEffect, useState } from 'react';
import useAdmin from 'store/modules/admin/adminHook';
import useAppliers from 'store/modules/appliers/appliersHook';
import useUser from 'store/modules/user/userHook';
import { useHistory } from 'react-router';
import { fillZero } from 'pages/teacher/studentProgressPage';
import { CSVLink } from 'react-csv';

import { DataTable } from 'components/main';
import LaunchSharpIcon from '@material-ui/icons/LaunchSharp';
import { AdminLayout, FooterLayout, SearchBar } from 'components/global';
import { useAdminModal } from 'hooks/global';

const Admin = (): JSX.Element => {
  const { userData } = useUser();
  const { setModalOff } = useAdmin();
  const headings = [
    '이름(담당역할)',
    '전화번호',
    '그룹명',
    '예상 사용인원',
    '알게 된 경로',
    '이메일',
    '가입일',
    '신청일',
    '승인상태',
    '회원등급',
  ];

  useEffect(() => {
    setModalOff();
  }, [setModalOff]);

  const {
    appliers,
    notAppliers,
    setAppliers,
    setCustomAppliers,
    setNotAppliers,
    appliersNumAll,
    appliersNumNoApply,
    appliersNumWait,
    appliersNumComplete,
    appliersNumPause,
    appliersNumReject,
  } = useAppliers();

  const [userAuth, setUserAuth] = useState(-2);
  const [userRole, setUserRole] = useState('0');
  const [searchType, setSearchType] = useState(0); // 0 : 이름, 1 : 이메일, 2 : 그룹명
  const [searchField, setSearchField] = useState<string>('');

  useEffect(() => {
    setAppliers();
  }, [setAppliers]);

  const [ty, setTy] = useState(1);
  const [openModal, renderModal] = useAdminModal(ty, setTy, '등록할 유저 찾기');
  const { setModalOn } = useAdmin();
  const history = useHistory();
  if (userData.userRole !== 3) {
    history.push('/');
  }
  // 엑셀 다운로드용 유저 데이터
  const xlsxAppliers = appliers.map(
    ({
      userName,
      userEmail,
      userPurchase,
      createdAt,
      application,
      latestLog,
      totalLog,
    }) => {
      const userGrade =
        userPurchase === 1 ? '무료' : userPurchase === 2 ? '유료' : '미신청';
      const ct = new Date(createdAt);
      const joinedAt =
        ct.getFullYear() +
        '.' +
        fillZero(String(ct.getMonth() + 1)) +
        '.' +
        fillZero(String(ct.getDate()));
      let recentLog = '';
      if (latestLog) {
        const lt = new Date(latestLog);
        recentLog =
          lt.getFullYear() +
          '.' +
          fillZero(String(lt.getMonth() + 1)) +
          '.' +
          fillZero(String(lt.getDate()));
      }

      if (application) {
        const userTel = application['userTel'];
        const groupName = application['groupName'];
        const expectedNumber = application['expectedNumber'];
        const knownPath = application['knownPath'];
        const dt = new Date(application['createdAt']);

        const userRole = application['userRole'];
        const appliedAt =
          dt.getFullYear() +
          '.' +
          fillZero(String(dt.getMonth() + 1)) +
          '.' +
          fillZero(String(dt.getDate()));
        const inquiry = application['inquiry'];
        return {
          userName,
          userRole,
          userTel,
          userEmail,
          groupName,
          expectedNumber,
          knownPath,
          joinedAt,
          appliedAt,
          inquiry,
          userGrade,
          recentLog,
          totalLog,
        };
      } else {
        return {
          userName,
          userGrade,
          userEmail,
          joinedAt,
          recentLog,
          totalLog,
        };
      }
    }
  );

  return (
    <>
      <AdminLayout />
      <div className="body-inner admin-wrapper">
        <div className="admin-flex-wrapper">
          <div className="admin-flex-container">
            <div className="admin-container">
              <div className="admin-contents">
                <div className="admin-header-hat">
                  <img src="assets/icons/ic-header-upper.svg" alt="" />
                  <h2>회원 관리</h2>
                </div>
                <section className="state-banner-wrapper">
                  <div className="state-banner-box">
                    <div className="state-banner">
                      <p className="summary-text">
                        문의 전체 {appliersNumAll}개
                      </p>
                      <p className="full-text">
                        승인대기중 {appliersNumWait}, 승인거절{' '}
                        {appliersNumReject}, 이용중 {appliersNumComplete},
                        이용중지 {appliersNumPause}, 미신청 {appliersNumNoApply}
                      </p>
                    </div>
                  </div>
                </section>

                <section className="appliers-wrapper">
                  <article className="applier-filter">
                    <div className="applier-filter-input">
                      <select
                        value={userAuth}
                        onChange={(e) => {
                          const authNum = Number(e.target.value);
                          setUserAuth(authNum);
                          if (authNum == -2 || authNum == -1)
                            setCustomAppliers(-1, '', userRole, searchType);
                          else
                            setCustomAppliers(
                              authNum,
                              '',
                              userRole,
                              searchType
                            );
                        }}
                      >
                        <option value="-2" hidden>
                          응대상태
                        </option>
                        <option value="-1">전체</option>
                        <option value="0">미신청</option>
                        <option value="1">승인대기중</option>
                        <option value="2">이용중</option>
                        <option value="3">이용중지</option>
                        <option value="4">승인거절</option>
                      </select>
                      <select
                        value={userRole}
                        onChange={(e) => {
                          setUserRole(e.target.value);
                          setCustomAppliers(
                            userAuth,
                            '',
                            e.target.value,
                            searchType
                          );
                        }}
                      >
                        <option value="0" hidden>
                          담당역할
                        </option>
                        <option value="전체">전체</option>
                        <option value="선생님">선생님</option>
                        <option value="학생">학생</option>
                      </select>
                      <select
                        value={searchType}
                        onChange={(e) => {
                          const type = Number(e.target.value);
                          setSearchType(type);
                          setCustomAppliers(-1, '', '0', type);
                        }}
                      >
                        {/* <option value="0" hidden>
                          검색
                        </option> */}
                        <option value="0">이름</option>
                        <option value="1">이메일</option>
                        <option value="2">그룹명</option>
                      </select>
                      <SearchBar
                        type={1}
                        searchQuery={searchField}
                        setSearchQuery={setSearchField}
                        userAuth={userAuth}
                        userRole={userRole}
                        setU={setAppliers}
                        setCustomU={setCustomAppliers}
                        searchType={searchType}
                      >
                        이름, 이메일, 그룹명
                      </SearchBar>
                    </div>
                    <div className="applier-filter-button">
                      <button type="button" className="export-btn">
                        <CSVLink
                          data={xlsxAppliers}
                          filename={'onbook-user-info-list.csv'}
                          target="_blank"
                        >
                          Export
                          <LaunchSharpIcon
                            style={{
                              height: '18px',
                              width: '18px',
                              verticalAlign: 'bottom',
                              marginLeft: '2px',
                              marginBottom: '3px',
                            }}
                          />
                        </CSVLink>
                      </button>

                      <button
                        className="not-appliers-btn"
                        onClick={() => {
                          setTy(2);
                          setNotAppliers();
                          openModal();
                          setModalOn();
                        }}
                      >
                        미신청 유저 요청등록
                      </button>
                    </div>
                  </article>
                  <article className="applier-table-wrapper">
                    <DataTable
                      headings={headings}
                      ty={ty}
                      setTy={setTy}
                      order={1}
                      searchData={{
                        authNum: userAuth,
                        searchField,
                        userRole,
                        searchType,
                      }}
                    />
                    {renderModal(notAppliers)}
                  </article>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLayout />
    </>
  );
};

export default Admin;
