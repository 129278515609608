import React, { useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import styles from 'styles/stylesheets/style.css';
import { DropdownProps } from './interfaces';
const cn = classNames.bind(styles);

const Dropdown = ({
  title,
  items,
  multiSelect = false,
}: DropdownProps): JSX.Element => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const toggle = () => {
    setOpen(!open);
  };

  // 드롭다운의 multiSelect는 아직 구현되어 있지 않음
  function handleOnClick(item) {
    if (!selection.some((current) => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item]);
      } else if (multiSelect) {
        setSelection([...selection, item]);
      }
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current) => current.id !== item.id
      );
      setSelection([...selectionAfterRemoval]);
    }
  }

  return (
    <div className="dd-wrapper">
      <div
        tabIndex={0}
        className="dd-header"
        role="button"
        onKeyPress={() => {
          if (!open) {
            toggle();
          }
        }}
        onClick={() => toggle()}
      >
        <div className="dd-header-title">
          <img src="/assets/icons/ic_logo.svg" alt="코딩엑스" />
          <p>{title}</p>
        </div>
      </div>
      <div className={cn('dd-body', !open && 'side-menu-off')}>
        {open && (
          <ul className="dd-list">
            {items.map((item) => (
              <li className="dd-list-item" key={item.id}>
                <button
                  type="button"
                  onClick={() => {
                    handleOnClick(item);
                    history.push(item.url);
                    toggle();
                  }}
                >
                  <span className={selection && 'selected'}>{item.value}</span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
