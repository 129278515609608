import React from 'react';
import {
  CompositeDecorator,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';

export const LinkEditor = () => {
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(decorator)
  );
  const [showURLInput, setShowURLInput] = React.useState(false);
  const [urlValue, setUrlValue] = React.useState('');

  const editor = React.useRef(null);
  const focus = () => editor.current.focus();
  const onChange = (edState) => setEditorState(edState);
  const logState = () => {
    const content = editorState.getCurrentContent();
    console.log(convertToRaw(content));
  };

  const promptForLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      setShowURLInput(true);
      setUrlValue(url);
      // setTimeout(() => focus(), 0);
    }
  };

  const confirmLink = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    setShowURLInput(false);
    setUrlValue('');
    // setTimeout(() => focus(), 0);
  };

  const onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      confirmLink(e);
    }
  };

  const removeLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  const onURLChange = (e) => setUrlValue(e.target.value);

  const url = React.useRef(null);
  let urlInput;
  if (showURLInput) {
    urlInput = (
      <div style={styles.urlInputContainer}>
        <input
          ref={url}
          onChange={onURLChange}
          type="text"
          value={urlValue}
          onKeyDown={onLinkInputKeyDown}
          style={styles.urlInput}
        />
        <button onMouseDown={confirmLink}>Confirm</button>
      </div>
    );
  }

  return (
    <>
      <button onMouseDown={promptForLink} style={{ marginRight: 10 }}>
        Add Link
      </button>
      <button onMouseDown={removeLink}>Remove Link</button>
      {urlInput}
      <div style={styles.editor} onClick={focus}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={onChange}
          placeholder="Enter some text..."
        />
      </div>
      <input
        onClick={logState}
        style={styles.button}
        type="button"
        value="Log State"
      />
    </>
  );
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    // textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};
