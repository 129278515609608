import update from 'immutability-helper';
import { FC, useCallback, useEffect, useState } from 'react';

import { ListItem } from './ListItem';
import { StateProps } from './interface';
import { Modal } from './Modal';

export interface ILock {
  id: number;
  contentId: number;
  contentType: string;
  level: number;
}
export interface ICard {
  id: number;
  text: string;
  lock: ILock;
}

export const ListContainer: FC<StateProps> = ({ setLessonId }) => {
  const [cards, setCards] = useState([]);

  const [modal, setModal] = useState({
    open: false,
    edit: false,
    id: -1,
  });

  const [condition, setCondition] = useState({
    course: null,
    chapter: null,
    lesson: null,
    pos: 'course',
  });

  useEffect(() => {
    const fetchCourse = async () => {
      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/courses`, {
        headers: {
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
      })
        .then((results) => results.json())
        .then((data) => {
          setCards(data);
        });
    };

    const fetchChapter = async (courseId) => {
      await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/chapters?courseId=${courseId}`,
        {
          headers: {
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      )
        .then((results) => results.json())
        .then((data) => {
          setCards(data);
        });
    };

    const fetchLesson = async (chapterId) => {
      await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/lessons?chapterId=${chapterId}`,
        {
          headers: {
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      )
        .then((results) => results.json())
        .then((data) => {
          setCards(data);
        });
    };

    if (condition.pos === 'course') fetchCourse();
    else if (condition.pos === 'chapter') fetchChapter(condition.course);
    else if (condition.pos === 'lesson') fetchLesson(condition.chapter);
  }, [condition]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  const selectCard = (id, backward = false) => {
    if (backward) {
      if (condition.pos === 'lesson') {
        setCondition({
          ...condition,
          pos: 'chapter',
        });
      } else if (condition.pos === 'chapter') {
        setCondition({
          ...condition,
          pos: 'course',
        });
      }
    } else {
      if (condition.pos === 'course') {
        setCondition({
          ...condition,
          course: id,
          pos: 'chapter',
        });
      } else if (condition.pos === 'chapter') {
        setCondition({
          ...condition,
          chapter: id,
          pos: 'lesson',
        });
      } else if (condition.pos === 'lesson') {
        setCondition({
          ...condition,
          lesson: id,
          pos: 'lesson',
        });
        setLessonId(id);
      }
    }
  };

  const handleLevel = async (id, level) => {
    // if (!window.confirm('정말 변경하시겠습니까?')) return;

    let newLevel;

    if (level === 0) newLevel = 1;
    else if (level === 1) newLevel = 2;
    else if (level === 2) newLevel = 100;
    else if (level === 100) newLevel = 0;

    await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/locks/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: JSON.stringify({
        level: newLevel,
      }),
    });

    setCards(
      cards.map((card) =>
        card.lock.id === id
          ? { ...card, lock: { ...card.lock, level: newLevel } }
          : card
      )
    );
  };

  const renderCard = (card: ICard, index: number) => {
    return (
      <ListItem
        key={card.id}
        id={card.id}
        text={card['courseName'] || card['chapterName'] || card['lessonName']}
        lock={card.lock}
        index={index}
        handleLevel={handleLevel}
        moveCard={moveCard}
        selectCard={selectCard}
        setModal={setModal}
      />
    );
  };

  return (
    <div>
      <div className="list-header">
        <button
          className="button-shape"
          onClick={() => {
            selectCard(null, true);
          }}
        >
          뒤로
        </button>
        <button
          className="button-shape flex-center"
          onClick={() => setModal({ open: true, edit: false, id: -1 })}
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <img src="/assets/icons/plus-solid.svg" alt="" />
        </button>
      </div>
      {cards.map((card, i) => renderCard(card, i))}
      {modal.open && (
        <Modal
          modal={modal}
          setModal={setModal}
          condition={condition}
          cards={cards}
          setCards={setCards}
        />
      )}
    </div>
  );
};
