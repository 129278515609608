import { useCallback, useEffect, useState } from 'react';
import { IgetSearchLessonData, IsetSearchLessonData } from './interfaces';
import axios from 'axios';

const useSearchLessonData = ({
  courseId,
}: IgetSearchLessonData): IsetSearchLessonData => {
  const [searchLessonResp, setSearchLessonResp] = useState();
  const [searchLessonError, setSearchLessonError] = useState<string>('');
  const [searchLessonRespIsLoading, setSearchLessonRespIsLoading] =
    useState<boolean>(true);

  const fetchLessonData = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/lessons?courseId=${courseId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setSearchLessonResp(res.data);
        })
        .finally(() => {
          setSearchLessonRespIsLoading(false);
        });
    } catch (err) {
      setSearchLessonError(err);
    }
  }, [courseId]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return { searchLessonResp, searchLessonError, searchLessonRespIsLoading };
};

export default useSearchLessonData;
