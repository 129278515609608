import { CommonLayout, FooterLayout } from 'components/global';

const GuidePage = (): JSX.Element => {
  return (
    <div>
      <CommonLayout />
      <div className="onboard-container">
        <h1>회원가입 및 학생 초대 가이드</h1>
        <div className="video-container">
          <div className="video-inner">
            <iframe
              title="guide"
              src="https://coding-x-service-file.s3.ap-northeast-2.amazonaws.com/ai-x/AIOnBook_Register_Guide.mp4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <FooterLayout />
    </div>
  );
};

export default GuidePage;
