import React, { useState, useEffect, useCallback } from 'react';
import * as styles from 'styles/stylesheets/style.css';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useHandleInfo from 'hooks/main/login/useHandleInfo';
import { GetCreateStudents } from 'services/info/getCreateStudents';
import useUser, { IClassrooms } from 'store/modules/user/userHook';
import { useGetRequestAuth } from 'hooks/main';

const cn = classNames.bind(styles);

const InfoForm = (): JSX.Element => {
  const history = useHistory();
  const [inviteCode, setInviteCode] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const [validInviteCode, setValidInviteCode] = useState(false);
  const [classroomInfo, setclassroomInfo] = useState<IClassrooms[]>();
  const { lookupInviteCode } = useHandleInfo(
    inviteCode,
    setInviteCode,
    setValidInviteCode,
    setclassroomInfo
  );
  const checkCode = useCallback(() => {
    if (inviteCode.length === 6) {
      setDisabled(false);
      lookupInviteCode();
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode.length]);
  useEffect(() => {
    checkCode();
  }, [checkCode]);

  const { requestAuth } = useGetRequestAuth();
  const { createStudents } = GetCreateStudents();
  const { userData } = useUser();
  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      enablecreateStudents();
    }
  };
  const enablecreateStudents = () => {
    if (
      inviteCode !== undefined &&
      inviteCode.length === 6 &&
      validInviteCode === true &&
      classroomInfo
    ) {
      createStudents(classroomInfo, history);
      requestAuth(
        '학생',
        2,
        '-',
        '-',
        '-',
        '-',
        '-',
        userData.id,
        undefined,
        classroomInfo[0].user.userPurchase
      );
    }
  };

  const noClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    return;
  };

  const copyUrl = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      await navigator.clipboard.writeText('https://school.coding-x.com');
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      notify();
    }, 100);
  };
  const notify = () => {
    toast.dark(
      <p style={{ textAlign: 'center', padding: '0px', fontSize: '14px' }}>
        링크가 복사되었습니다.
        <br />
        선생님에게 공유해보세요!
      </p>
    );
  };

  const isNotKorean = (value) => {
    const regExp = /[|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
    return regExp.test(value);
  };

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <div
      className="body-inner student-info-wrapper"
      onClick={() => visible && setVisible(!visible)}
    >
      <div className="student-info-flex-container">
        <div className="student-info-container">
          <div className="student-info-table">
            <section className="student-info-header">
              <h1>
                입장을 위해
                <br />
                초대코드를 입력해주세요!
              </h1>
            </section>
            <section className="student-info-banner">
              <article className="student-info-card">
                <div className="card">
                  <div>
                    <p>
                      요즘 학생들의
                      <br />
                      힙한 온라인공부법
                    </p>
                    <h1>AI OnBook</h1>
                  </div>
                  <img
                    src="/assets/images/student-info-banner-box.svg"
                    alt=""
                  />
                </div>
              </article>
            </section>
            <form>
              <section className="student-info-input-box">
                <div className="student-info-input">
                  <input
                    name="code"
                    required
                    placeholder="교실 배정을 위한 초대코드 입력"
                    maxLength={6}
                    className={cn(
                      'student-invite-code',
                      inviteCode !== undefined &&
                        inviteCode.length === 6 &&
                        validInviteCode === true &&
                        'input-success'
                    )}
                    value={inviteCode}
                    onChange={(e) => {
                      if (e.target.value && isNotKorean(e.target.value)) {
                        e.preventDefault();
                        return null;
                      }
                      setInviteCode(e.target.value);
                    }}
                    onKeyPress={handleKeypress}
                  />
                </div>
              </section>
              <section className="student-info-button-wrapper">
                <button
                  type="button"
                  className={cn(
                    'student-info-button',
                    inviteCode !== undefined &&
                      inviteCode.length === 6 &&
                      validInviteCode === true &&
                      classroomInfo !== undefined &&
                      classroomInfo['0'].isOpen &&
                      'input-success'
                  )}
                  onClick={enablecreateStudents}
                  disabled={disabled}
                >
                  완료
                </button>
              </section>
            </form>
            {/* <div className="student-in">
              <div className="student-info-vertical" />
            </div>
            <section className="student-info-footer">
              <div className="tooltip">
                <div onClick={() => setVisible(!visible)}>
                  <img
                    src="/assets/icons/ic_info_normal_line.png"
                    alt=""
                    style={{ textAlign: 'center' }}
                  />
                </div>
                <span
                  className="tooltip-content"
                  style={{ display: visible && 'block' }}
                  onClick={noClick}
                >
                  <div className="marker-text">
                    <p>담임 선생님에게 초대코드를 요청해주세요!</p>
                    <hr />
                  </div>
                  <section className="invite-tooltip">
                    <article className="invite-teacher">
                      <p>
                        아직도 안 쓰신다면?
                        <br />
                        선생님에게 우리도 쓰자고 추천해주기!
                      </p>
                      <button type="button" onClick={copyUrl}>
                        링크복사해서 선생님께 공유하기!
                      </button>
                    </article>
                    <article className="personal-use">
                      <p>개인적으로 사용해보고 싶다면</p>
                      <button
                        type="button"
                        onClick={() =>
                          history.push({
                            pathname: '/auth/request',
                            state: { role: '학생' },
                          })
                        }
                      >
                        체험 및 사용권한 요청하기
                      </button>
                    </article>
                  </section>
                </span>
              </div>
              <span>혹시 초대코드가 없나요?</span>
            </section> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        className={'copy-url'}
        autoClose={1000}
        transition={Zoom}
        hideProgressBar
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
        toastStyle={{
          width: '182px',
          margin: 'auto',
        }}
      />
    </div>
  );
};

export default InfoForm;
