import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonMemberList = (): JSX.Element => {
  return (
    <>
      {Array(5)
        .fill(1)
        .map((_, key) => (
          <tr key={key}>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td className="align-right">
              {key !== 0 && (
                <button>
                  <Skeleton />
                </button>
              )}
            </td>
          </tr>
        ))}
    </>
  );
};

export default SkeletonMemberList;
