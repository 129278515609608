import React from 'react';
import { useHistory } from 'react-router';

const Role = (): JSX.Element => {
  const history = useHistory();
  const hidden = {
    display: 'none',
  };
  return (
    <>
      <div className="body-inner role-wrapper">
        <div className="role-flex-container">
          <div className="role-container">
            <div className="card-table">
              <section className="card-header">
                <h1>당신의 역할은 무엇인가요?</h1>
              </section>

              <section className="card-body">
                <article className="card-col">
                  <div
                    className="card"
                    onClick={() => history.push('/auth/info')}
                  >
                    <img src="/assets/images/thumbnail_student.png" alt="" />
                    <p>학생</p>
                  </div>
                </article>
                <article className="card-col">
                  <div
                    className="card"
                    onClick={() =>
                      history.push({
                        pathname: '/auth/request',
                        state: { role: '선생님' },
                      })
                    }
                  >
                    <img src="/assets/images/thumbnail_teacher.png" alt="" />
                    <p>선생님 / 기관</p>
                  </div>
                </article>
              </section>

              <section className="card-footer" style={hidden}>
                <article className="card-footer-col">
                  <div className="card-footer-cell">
                    <div
                      className="bottom-card"
                      onClick={() =>
                        history.push({
                          pathname: '/auth/request',
                          state: { role: '학교/기관 및 기타' },
                        })
                      }
                    >
                      <p>학교/기관 및 기타</p>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;
