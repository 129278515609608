import { useState } from 'react';

const useGetMembers = (): any => {
  const [members, setMembers] = useState([]);

  const getMembers = async (userId: number): Promise<void> => {
    const result = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/members?userId=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );

    const status = result.status;
    const json = await result.json();

    if (status === 200) {
      setMembers(json);
    } else {
      setMembers([]);
    }
  };

  return [members, getMembers];
};

export default useGetMembers;
