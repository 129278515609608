import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { IsetGetClassroomsData } from './interfaces';
import useUser from 'store/modules/user/userHook';

// GET /classrooms 모든 반 조회 혹은 검색한 반 조회
const useGetClassrooms = (): IsetGetClassroomsData => {
  const { userData, setClassrooms, isLoggedIn } = useUser();
  const [classroomResp, setClassroomResp] = useState();
  const [classroomRespError, setClassroomRespError] = useState<string>('');
  const [classroomRespIsLoading, setClassroomRespIsLoading] =
    useState<boolean>(true);

  const fetchData = useCallback(async () => {
    if (isLoggedIn && (userData.userRole === 2 || userData.userRole === 3)) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms?tutorId=${userData.id}`,
            {
              headers: {
                'Content-Type': 'application/json',
                accept: 'text/html; charset=utf-8',
                authorization: ('Bearer ' +
                  localStorage.getItem('token')) as string,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // console.log(res.data);
              setClassrooms(res.data);
              setClassroomResp(res.data);
            } else {
              alert(res.statusText);
            }
          })
          .finally(() => {
            setClassroomRespIsLoading(false);
          });
      } catch (err) {
        console.log(err);
        setClassroomRespError(err);
      }
    }
  }, [isLoggedIn, setClassrooms, userData]);

  useEffect(() => {
    if (classroomRespIsLoading) {
      fetchData();
    }
  }, [classroomRespIsLoading, setClassrooms, fetchData]);

  return {
    classroomResp,
    classroomRespError,
    classroomRespIsLoading,
    fetchData,
  };
};

export default useGetClassrooms;
