import { useCallback, useEffect, useState } from 'react';
import { IgetExerciseProblemData, IsetExerciseProblemData } from './interfaces';
import axios from 'axios';

// GET /components 모든 컴포넌트 조회 혹은 검색한 컴포넌트 조회
const useExerciseProblemData = ({
  courseId,
}: IgetExerciseProblemData): IsetExerciseProblemData => {
  const [exerciseProblemResp, setExerciseProblemResp] = useState();
  const [exerciseProblemRespError, setExerciseProblemRespError] =
    useState<string>('');
  const [exerciseProblemRespIsLoading, setExerciseProblemRespIsLoading] =
    useState<boolean>(true);

  const fetchLessonData = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/components?courseId=${courseId}&isQuiz=true`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setExerciseProblemResp(res.data);
        })
        .finally(() => {
          setExerciseProblemRespIsLoading(false);
        });
    } catch (err) {
      setExerciseProblemRespError(err);
    }
  }, [courseId]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return {
    exerciseProblemResp,
    exerciseProblemRespError,
    exerciseProblemRespIsLoading,
  };
};

export default useExerciseProblemData;
