import { combineReducers } from 'redux';
import user, { UserState } from './user/user';
import component, { componentState } from './componentState/componentState';
import { CombinedState } from 'redux';
import { AnyAction } from 'redux';
import { logoutAction } from './user/user';
import students, { studentsState } from './students/students';
import admin, { AdminState } from './admin/admin';
import appliers, { appliersState } from './appliers/appliers';
import users, { usersState } from './users/users';

interface IgetRootReducer {
  user: UserState;
  users: usersState;
  component: componentState;
  students: studentsState;
  admin: AdminState;
  appliers: appliersState;
}

const appReducer = combineReducers({
  user,
  users,
  component,
  students,
  admin,
  appliers,
});

const rootReducer = (
  state: CombinedState<IgetRootReducer>,
  action: AnyAction
): CombinedState<IgetRootReducer> => {
  if (action.type === logoutAction()) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
