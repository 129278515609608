import moment from 'moment';
import useAppliers from 'store/modules/appliers/appliersHook';
import useUserInfo from './useUsers';

const useEditApplierInfo = (): {
  editInfoInit: (
    userId: number,
    userAuth: number,
    userRole: number,
    userPurchase?: number
  ) => Promise<void>;
  editInfoRep: (
    userId: number,
    userAuth: number,
    userRole: number
  ) => Promise<void>;
  editInfoMemo: (userId: number, memo: string) => Promise<void>;
} => {
  const { setAppliers } = useAppliers();

  const editInfoInit = async (
    userId: number,
    userAuth: number,
    userRole: number,
    userPurchase?: number
  ): Promise<void> => {
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    try {
      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          userAuth: userAuth,
          userRole: userRole,
          approvedAt: date,
          userPurchase: userPurchase,
        }),
      }).then((res) => {
        if (res.status === 200) {
          setAppliers();
          window.location.reload();
        } else {
          alert(res.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editInfoRep = async (
    userId: number,
    userAuth: number,
    userRole: number
  ): Promise<void> => {
    const auth = userAuth === 2 ? 3 : 2;
    try {
      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          userAuth: auth,
          userRole: userRole,
        }),
      }).then((res) => {
        if (res.status === 200) {
          console.log('rep');
          setAppliers();
        } else {
          alert(res.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editInfoMemo = async (userId: number, memo: string): Promise<void> => {
    try {
      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/applications/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          memo: memo,
        }),
      }).then((res) => {
        if (res.status === 200) {
          // setAppliers();
        } else {
          alert(res.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return { editInfoInit, editInfoRep, editInfoMemo };
};

export default useEditApplierInfo;
