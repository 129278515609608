import { useState } from 'react';
import { Modal } from 'components/global';

const useModal = (
  handleReq: (
    InputText: string,
    closeModal: () => void,
    Id?: number,
    fetchProgressData?: () => Promise<void>
  ) => Promise<void>,
  header: string,
  label: string,
  placeholder: string,
  Id?: number,
  fetchProgressData?: () => Promise<void>
): [() => void, () => JSX.Element] => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [inputName, setInputName] = useState<string>(
    header == '교실 수정하기' ? placeholder : ''
  );
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fetchProgressData !== undefined) {
      handleReq(inputName, closeModal, Id, fetchProgressData); // 교실명 수정
      window.location.reload(false);
    } else {
      handleReq(inputName, closeModal, Id);
    }
  };

  const renderModal = () => (
    <Modal
      isOpen={isModalOpen}
      close={closeModal}
      handleReq={handleSubmit}
      inputName={inputName}
      setInputName={setInputName}
      header={header}
      label={label}
      placeholder={placeholder}
    />
  );

  return [openModal, renderModal];
};

export default useModal;
