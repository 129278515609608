import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { ChapterCards } from 'components/global';
import {
  useCreateClassroom,
  useExerciseProblemData,
  useGetCourseDetails,
  useSearchLessonData,
} from 'hooks/main';
import { useModal } from 'hooks/global';
import useUser from 'store/modules/user/userHook';
import { NoAdminClassroomCourseProps } from './interfaces';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';

interface IState {
  from?: string;
}

// 반이 없는 유저 (반을 생성X 선생님, 로그아웃 유저, 승인 대기중, 승인 거절, 이용 중지 유저 .. )
// props
// courseId: coursePage url 뒤에 있는 courseId 값을 넘겨받은 것
const NoAdminClassroomCourse = ({
  courseId,
}: NoAdminClassroomCourseProps): JSX.Element => {
  const { userData, isLoggedIn } = useUser();

  // 데이터 패치
  const { courseDetailsResp, courseDetailsRespIsLoading } =
    useGetCourseDetails(courseId);
  const { searchLessonResp, searchLessonRespIsLoading } = useSearchLessonData({
    courseId,
  });
  const { exerciseProblemResp, exerciseProblemRespIsLoading } =
    useExerciseProblemData({ courseId });
  const { createClassroom } = useCreateClassroom();

  const [openModal, renderModal] = useModal(
    createClassroom,
    '교실 추가하기',
    '교실이름 (16자 이하)',
    '예시) XX 고등학교 1학년 3반 등 알아보기 쉬운 이름을 작성해주세요.',
    Number(courseId)
  ); // 완료함수, header, label, placeholder, id

  const history = useHistory();
  const location = useLocation();
  const goClassroom = () => {
    history.push(`/classrooms`);
  };

  // 승인 요청 완료한 유저가 코스 페이지로 바로 입장시
  useEffect(() => {
    if (
      (location.state as IState)?.from !== undefined &&
      (location.state as IState)?.from === 'requestAuthPage'
    ) {
      // toast('요청완료되었습니다. 확인후 연락드리겠습니다');
      history.replace({
        ...location,
        state: undefined,
      });
    }
  }, [history, location]);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  return (
    <div className="body-inner chapter-inner" id="default-body">
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        autoClose={3000}
        hideProgressBar
        closeOnClick={false}
        draggable={false}
        pauseOnHover={false}
        transition={Zoom}
        closeButton={false}
        toastStyle={{
          height: '48px',
          fontSize: '14px',
          fontWeight: 400,
          color: '#242E64',
          textAlign: 'center',
          marginTop: '65px',
          padding: 0,
        }}
      />
      <div className="student-info">
        <div className="info-inner">
          <div className="course-title">
            {courseDetailsRespIsLoading ? (
              <h1>
                <Skeleton
                  width={250}
                  style={{ background: 'rgb(81,88,128)' }}
                />
              </h1>
            ) : (
              <h1>{courseDetailsResp.courseName}</h1>
            )}
          </div>
          {isLoggedIn ? (
            userData.userAuth === 2 ? (
              (userData.userRole === 2 || userData.userRole === 3) && (
                // 코스 페이지 내에서의 반 생성 기능 (선생님or관리자)
                <>
                  <div className="course-discription">
                    <p>학급을 만들면 콘텐츠를 모두 열람할 수 있어요</p>
                  </div>
                  <button
                    className="course-in-login-button"
                    type="button"
                    onClick={() => {
                      openModal();
                    }}
                  >
                    교실 만들기
                  </button>
                </>
              )
            ) : (
              // 로그인 했으나 userAuth가 2가 아닌 유저
              <div className="course-discription">
                <p className="main-message">
                  {userData.userAuth === 3
                    ? '이용 중지'
                    : userData.userAuth === 4 && '승인 거절'}
                </p>
                <p className="sub-message">
                  {userData.userAuth === 3
                    ? '다시 승인요청하시려면 고객센터에 문의해주세요'
                    : userData.userAuth === 4 &&
                      '다시 이용요청하시려면 고객센터에 문의해주세요'}
                </p>
              </div>
            )
          ) : (
            // 비로그인 유저
            <>
              <div className="course-discription">
                <p>로그인 하시면 서비스를 체험해 볼 수 있어요</p>
              </div>
              <Link to="/login">
                <button className="course-in-login-button" type="button">
                  로그인
                </button>
              </Link>
            </>
          )}
          <ul>
            <li>
              <img src="/assets/images/course_item01.png" alt="연습문제" />
              <p>연습문제</p>
              {exerciseProblemRespIsLoading ? (
                <Skeleton
                  width={100}
                  height={25}
                  style={{ background: 'rgb(38,46,95)' }}
                />
              ) : (
                <>{exerciseProblemResp.length}개</>
              )}
            </li>
            <li>
              <img src="/assets/images/course_item02.png" alt="레슨" />
              <p>레슨</p>
              {searchLessonRespIsLoading ? (
                <Skeleton
                  width={100}
                  height={25}
                  style={{ background: 'rgb(38,46,95)' }}
                />
              ) : (
                <>{searchLessonResp.length}개</>
              )}
            </li>
            <li>
              <img src="/assets/images/course_item03.png" alt="커리큘럼" />
              <p>커리큘럼</p>
              {courseDetailsRespIsLoading ? (
                <Skeleton
                  width={100}
                  height={25}
                  style={{ background: 'rgb(38,46,95)' }}
                />
              ) : (
                <>총 {courseDetailsResp.courseTime}시간</>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="banner-container">
        <h1>
          <img src="/assets/images/logo-codingx-3.png" alt="" />
          About this course
        </h1>
        {courseDetailsRespIsLoading ? (
          <Skeleton height={22} />
        ) : (
          <p>{courseDetailsResp.courseDetail}</p>
        )}
      </div>
      {!courseDetailsRespIsLoading && (
        <ChapterCards
          course_id={courseId}
          classroomCourseResp={courseDetailsResp}
          bookmarkResp={undefined}
          progressesResp={undefined}
        />
      )}
      {isLoggedIn &&
        (userData.userRole === 2 || userData.userRole === 3) &&
        userData.userAuth === 2 && (
          <div className="floating-btns">
            <button onClick={goClassroom}>
              <img src="/assets/icons/ic_people_normal_line_24.svg" alt="" />
              <span>반 관리</span>
            </button>
          </div>
        )}
      {renderModal()}
    </div>
  );
};

export default NoAdminClassroomCourse;
