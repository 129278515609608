import { IsetCreateClassroom } from 'services/classroom/interfaces';
import { useGetClassrooms } from '../course';

const useCreateClassroom = (): IsetCreateClassroom => {
  const { fetchData } = useGetClassrooms();

  const createClassroom = async (
    classroomName: string,
    closeModal: () => void
  ): Promise<void> => {
    if (classroomName !== '') {
      const r = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify({
            classroomName: classroomName,
          }),
        }
      );
      const status = r.status;

      if (status === 201) {
        fetchData();
        closeModal();
      } else {
        alert(r.statusText);
      }
    }
  };

  return { createClassroom };
};

export default useCreateClassroom;
