const getConfirm = (
  message = '',
  onConfirm: (studentId: number) => Promise<void>,
  onCancel: () => void
): ((studentId: number, userName: string) => Promise<void>) => {
  if (!onConfirm || typeof onConfirm !== 'function') {
    return;
  }
  if (onCancel && typeof onCancel !== 'function') {
    return;
  }

  const confirmDelete = async (studentId: number, userName: string) => {
    if (window.confirm(userName + message)) {
      await onConfirm(studentId);
    } else {
      onCancel();
    }
  };
  return confirmDelete;
};

export default getConfirm;
