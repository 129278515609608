import React from 'react';
import { Dropdown } from 'components/global';
import useAdmin from 'store/modules/admin/adminHook';
import { useScrollDirection } from 'hooks/global';

const items = [
  {
    id: 1,
    value: '회원 관리',
    url: '/admin',
  },
  {
    id: 2,
    value: '콘텐츠 관리',
    url: '/admin/content',
  },
];

const AdminLayout = (): JSX.Element => {
  const ScrollDirection = useScrollDirection();
  const { modalOpen } = useAdmin();

  return (
    <div
      className={`admin-layout-header ${
        ScrollDirection === 'down' ? 'hasScroll' : ''
      }`}
      style={{ display: modalOpen ? 'none' : 'flex' }}
    >
      <Dropdown title="School.coding-x" items={items} />
    </div>
  );
};

export default AdminLayout;
