import { CommonLayout, FooterLayout } from 'components/global';
import DefaultModal from 'components/global/modal/defaultModal';
import UsageFee from 'components/global/usageFee/usageFee';
import React, { useState } from 'react';

const UsageFeePage = () => {
  // 📌 요금안내 관련 모달
  const [isDefaultModalOpen, setIsDefaultModalOpen] = useState(false);
  const [defaultModalInfo, setDefaultModalInfo] = useState({});
  const openDefaultModal = (contents) => {
    setIsDefaultModalOpen(true);
    setDefaultModalInfo(contents);
  };

  return (
    <>
      <CommonLayout buttonHide />
      <section className="usage-fee-page body-inner">
        <UsageFee openDefaultModal={openDefaultModal} />
      </section>
      <FooterLayout />

      {/* default 모달 */}
      {isDefaultModalOpen && (
        <DefaultModal
          modalInfo={defaultModalInfo}
          setOpen={setIsDefaultModalOpen}
        />
      )}
    </>
  );
};

export default UsageFeePage;
