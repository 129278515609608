import { IBox } from './interfaces';

const Box = ({ id, title, content, color }: IBox): JSX.Element => {
  return (
    <div className="box-component component" data-id={id}>
      <span style={{ backgroundColor: color }}>{title}</span>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default Box;
