import React, { useState } from 'react';
import axios from 'axios';
import { runCodeSet } from './interfaces';

const useRunCode = (code: string): runCodeSet => {
  const [runRes, setRunRes] = useState<string>('');
  const [graphRes, setGraphRes] = useState<string>('');
  const [runCodeIsLoading, setRunCodeIsLoading] = useState<number>(-1);

  const handleRun = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!code) return;

    setRunCodeIsLoading(1);
    if (code.includes('matplotlib')) {
      runGraphCode(code, setGraphRes, setRunCodeIsLoading);
    } else {
      runCode(code, setRunRes, setRunCodeIsLoading);
    }
  };

  const runCode = async (
    code: string,
    setRunRes: React.Dispatch<React.SetStateAction<string>>,
    setRunCodeIsLoading: React.Dispatch<React.SetStateAction<number>>
  ): Promise<void> => {
    try {
      const result = await axios.post(
        'https://code.coding-x.com/lang/python',
        {
          code,
        }
      );

      let text;
      if (result.status === 200) {
        if (result.data.stderr === '') {
          text = result.data.stdout;
        } else {
          text = result.data.stderr;

          const startIndex = text.indexOf(' "') + 2;
          const endIndex = text.indexOf('",');
          const filePath = text.substring(startIndex, endIndex);

          text = text.replace(filePath, '/run.py');
        }
        setRunRes(text);
        setRunCodeIsLoading(0);
      }
      setRunCodeIsLoading(0);
    } catch {
      console.error('run code error!');
    }
  };

  const runGraphCode = async (
    code: string,
    setGraphRes: React.Dispatch<React.SetStateAction<string>>,
    setRunCodeIsLoading: React.Dispatch<React.SetStateAction<number>>
  ): Promise<void> => {
    const result = await axios.post(
      'https://code.coding-x.com/lang/python/matplot',
      {
        code: code,
      }
    );
    if (result.status === 200) {
      const date = new Date().getTime();
      setGraphRes(
        'https://code.coding-x.com/' + result.data + '?date=' + date
      );

      setRunCodeIsLoading(0);
    }
  };

  return {
    runRes,
    setRunRes,
    graphRes,
    handleRun,
    runCodeIsLoading,
  };
};

export default useRunCode;
