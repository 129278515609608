import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from 'hooks/global';
import { getEditClassroom } from 'services/classroom/getEditClassroom';

import { IgetProgressBannerData } from './interfaces';

const ProgressBanner = ({
  classroomName,
  lessonId,
  lessonName,
  classroomId,
  fetchProgressData,
}: IgetProgressBannerData): JSX.Element => {
  const { editClassroom } = getEditClassroom();
  // useModal은 교실 생성, 교실 수정하기용 모달에 사용됨(디자인적으로 비슷함)
  // 요청 함수, 제목, 라벨, 입력값 + 기타 데이터 동기화를 위해 필요한 함수나 아이디를 파라미터로 받음
  // Modal을 보여주는 함수와 모달을 여는 함수를 return 해주어 이를 사용한다.
  const [openModal, renderModal] = useModal(
    editClassroom,
    '교실 수정하기',
    '교실이름 (16자 이하)',
    classroomName,
    classroomId,
    fetchProgressData
  );

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <div className="banner-container student-banner">
      <h1>
        {classroomName}
        <button className="btn-edit-title" type="button" onClick={openModal}>
          <img src="/assets/icons/ic_edit_normal_circle_24.svg" alt="" />
        </button>
      </h1>

      {lessonId !== undefined && lessonId !== null ? (
        <Link to={`/lesson/${lessonId}`}>
          <img
            src="/assets/icons/ic_bookmark_active_ye_24.svg"
            className="icon-bookmark"
            alt=""
          />
          최근 "{lessonName}"를 수업했어요
          <img
            src="/assets/icons/ic_arrow_forward_line_24.svg"
            className="icon"
            alt=""
          />
        </Link>
      ) : (
        <Link to="/lesson/1" style={{ display: 'inline-block' }}>
          첫 레슨 수업하기
          <img
            src="/assets/icons/ic_arrow_forward_line_24.svg"
            className="icon"
            alt=""
          />
        </Link>
      )}
      {renderModal()}
    </div>
  );
};

export default ProgressBanner;
