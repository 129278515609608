import { RouteComponentProps } from 'react-router-dom';

export const setBookmark = async (
  courseId: number,
  lessonId: number,
  classroomId: number,
  setBookmarkState: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  try {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/bookmarks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: JSON.stringify({
        courseId: courseId,
        lessonId: lessonId,
        classroomId: classroomId,
      }),
    }).then((response) => {
      if (response.status === 201) {
        setBookmarkState(true);
      } else {
        alert(response.statusText);
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateLesson = (
  classroomId: number,
  lessonId: number,
  hideData: number[],
  history: RouteComponentProps['history']
): void => {
  try {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/lesson/edit/${lessonId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: JSON.stringify({
        classroomId: classroomId,
        hideDatas: hideData,
      }),
    }).then((response) => {
      if (response.status === 200) {
        history.push(`/lesson`);
      } else {
        alert(response.statusText);
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const registerProgresses = async (
  classroomId: number,
  userId: number,
  lessonId: number,
  completeLesson: number
): Promise<void> => {
  try {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/progresses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: JSON.stringify({
        userId: userId,
        lessonId: lessonId,
        classroomId: classroomId,
        completeLesson: completeLesson,
      }),
    }).then((response) => {
      if (response.status === 201) {
        // console.log('attendLesson 성공');
      } else {
        alert(response.statusText);
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const setLessonHistory = async (lessonId: number): Promise<void> => {
  try {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/histories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: JSON.stringify({
        lessonId: lessonId,
      }),
    }).then((response) => {
      if (response.status === 201) {
        // console.log('setLessonHistory 성공');
      } else {
        alert(response.statusText);
      }
    });
  } catch (err) {
    console.log(err);
  }
};
