import { useCallback, useEffect, useState } from 'react';
import { IgetNextLessonData, IsetNextLessonData } from './interfaces';
import axios from 'axios';

const useNextLessonData = ({
  lessonId,
}: IgetNextLessonData): IsetNextLessonData => {
  const [nextLessonResp, setNextLessonResp] = useState();
  const [nextLessonError, setNextLessonError] = useState<string>('');
  const [nextLessonRespIsLoading, setNextLessonRespIsLoading] =
    useState<boolean>(true);

  const fetchNextLessonData = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/lessons/${lessonId}/next`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setNextLessonResp(res.data);
        })
        .finally(() => {
          setNextLessonRespIsLoading(false);
        });
    } catch (err) {
      setNextLessonError(err);
    }
  }, [lessonId]);
  useEffect(() => {
    fetchNextLessonData();
  }, [fetchNextLessonData]);

  return { nextLessonResp, nextLessonError, nextLessonRespIsLoading };
};

export default useNextLessonData;
