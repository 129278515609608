import { createSlice } from '@reduxjs/toolkit';
import { Action, Dispatch } from 'redux';

export type usersState = {
  loading: boolean;
  hasErrors: boolean;
  users: IApplier[] | undefined;
};

export interface IApplier {
  id: number;
  userName: string;
  userEmail: string;
  userAuth: number;
  userRole: number;
  visitCount: number;
  recentVisit: Date | '';
  approvedAt: string | Date | '';
  createdAt: Date | '';
  updatedAt: Date | '';
  application: IApplication;
  memo: IMemo | null | undefined;
}

interface IMemo {
  id: number;
  content: string;
}

interface IApplication {
  id: number;
  userRole: string;
  userTel: string;
  groupName: string;
  expectedNumber: string;
  knownPath: string;
  inquiry: string;
  createdAt: Date | '';
  updatedAt: Date | '';
}

const initialState: usersState = {
  loading: false,
  hasErrors: false,
  users: [
    {
      id: -1,
      userName: '',
      userEmail: '',
      userAuth: -1,
      userRole: 0,
      visitCount: 11,
      recentVisit: '',
      approvedAt: '',
      createdAt: '',
      updatedAt: '',
      application: {
        id: -1,
        userRole: '',
        userTel: '',
        groupName: '',
        expectedNumber: '',
        knownPath: '',
        inquiry: '',
        createdAt: '',
        updatedAt: '',
      },
      memo: {
        id: -1,
        content: '',
      },
    },
  ],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: (state) => {
      state.loading = true;
    },
    getUsersSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getUsersFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

const { reducer, actions } = usersSlice;
export const { getUsers, getUsersSuccess, getUsersFailure } = actions;

export function fetchUsers() {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getUsers());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        dispatch(getUsersSuccess(data));
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(getUsersFailure());
    }
  };
}

export function fetchCustomUsers(userAuth: number, userName: string) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getUsers());

    let query = '';
    if (userName === '') {
      query = `userAuth=${userAuth}`;
    } else {
      // userAuth가 응대상태(기본값, 0)거나 전체(-1)이면 전체에서 이름으로 검색
      if (userAuth === 0 || userAuth === -1) {
        query = `userName=${userName}`;
      } else {
        query = `userAuth=${userAuth}&userName=${userName}`;
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users?${query}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        dispatch(getUsersSuccess(data));
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(getUsersFailure());
    }
  };
}

export default reducer;
