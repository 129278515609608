import { XYCoord } from 'dnd-core';
import { FC, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { Create, Lock, LockOpen, Block, Money } from '@material-ui/icons';

export interface ILock {
  id: number;
  contentId: number;
  contentType: string;
  level: number;
}
export interface CardProps {
  id: number;
  text: string;
  lock: ILock;
  index: number;
  handleLevel: (id: number, level: number) => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  selectCard: (id: number) => void;
  setModal: ({ open, edit, id }) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ListItem: FC<CardProps> = ({
  id,
  text,
  lock,
  index,
  handleLevel,
  moveCard,
  selectCard,
  setModal,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'list',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'list',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const levelRender = (level) => {
    if (level === 0) return <LockOpen />;
    else if (level === 1) return <Lock />;
    else if (level === 2) return <Money />;
    else if (level === 100) return <Block />;
  };

  return (
    <div
      className="list-item"
      ref={ref}
      style={{
        opacity,
      }}
      data-handler-id={handlerId}
    >
      {text}
      <div className="list-item-tool-bar">
        <button
          className="edit-button"
          onClick={() => setModal({ open: true, edit: true, id })}
        >
          <Create />
        </button>
        <button
          onClick={() => handleLevel(lock.id, lock.level)}
          style={{
            backgroundColor: 'transparent',
            color: '#242E64',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {levelRender(lock.level)}
        </button>
        <button className="move-button" onClick={() => selectCard(id)}>
          이동
        </button>
      </div>
    </div>
  );
};
