import { useCallback, useEffect, useState } from 'react';
import { IsetMemberData } from './interfaces';
import axios from 'axios';
import useUser from 'store/modules/user/userHook';

// GET /members 모든 멤버 조회 혹은 검색한 멤버 조회
const useGetMember = (): IsetMemberData => {
  const [memberResp, setMemberResp] = useState();
  const [memberRespError, setMemberRespError] = useState<string>('');
  const [memberRespIsLoading, setMemberRespIsLoading] = useState<boolean>(true);
  const { userData, isLoggedIn } = useUser();

  const fetchLessonData = useCallback(() => {
    if (isLoggedIn && userData.userAuth === 2) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/members?userId=${userData.id}`,
            {
              headers: {
                'Content-Type': 'application/json',
                accept: 'text/html; charset=utf-8',
                authorization: ('Bearer ' +
                  localStorage.getItem('token')) as string,
              },
            }
          )
          .then((res) => {
            setMemberResp(res.data);
          })
          .finally(() => {
            setMemberRespIsLoading(false);
          });
      } catch (err) {
        setMemberRespError(err);
      }
    }
  }, [isLoggedIn, userData]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return { memberResp, memberRespError, memberRespIsLoading };
};

export default useGetMember;
