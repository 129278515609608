import { MyPageFormInterface } from './interface';

const MyPageForm = ({ user, setPane }: MyPageFormInterface): JSX.Element => {
  const roleRender = (userRole) => {
    if (userRole === 0) return '없음';
    else if (userRole === 1) return '학생';
    else if (userRole === 2) return '선생님';
    else if (userRole === 3) return '관리자';
  };

  return (
    <>
      <div className="mypage-inner">
        <h2>내정보 수정</h2>
        {/* <div className="upper-body">
          <label htmlFor="name-input">이름*</label>
          <input id="name-input" value={user.userName} readOnly />
          <label htmlFor="tel-input">전화번호</label>
          <input
            id="tel-input"
            value={user.application ? user.application.userTel : '-'}
            readOnly
          />
          <label htmlFor="group-input">그룹명</label>
          <input
            id="group-input"
            value={user.application ? user.application.groupName : '-'}
            readOnly
          />
        </div> */}
        <div className="lower-body">
          <div>
            <span>이름</span>
            <span>{user.userName}</span>
          </div>
          <div>
            <span>전화번호</span>
            <span>{user.application ? user.application.userTel : '-'}</span>
          </div>
          <div>
            <span>그룹명</span>
            <span>{user.application ? user.application.groupName : '-'}</span>
          </div>
          <div>
            <span>역할</span>
            <span>{roleRender(user.userRole)}</span>
          </div>
          <div>
            <span>이메일</span>
            <span>{user.userEmail}</span>
          </div>
        </div>
        <button onClick={() => setPane(1)}>비밀번호 재설정</button>
      </div>
      <p>
        회원탈퇴는{' '}
        <a
          href="https://coding-x.channel.io/"
          style={{ color: '#394DD1', textDecorationLine: 'underline' }}
        >
          고객센터
        </a>
        에 문의 부탁드립니다
      </p>
    </>
  );
};

export default MyPageForm;
