import { createBrowserHistory } from 'history';
import PrivateRoute from 'PrivateRoute';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { EditPage } from 'pages/edit/editPage';
import GuidePage from 'pages/guide/guidePage';
import MyPage from 'pages/user/myPage';
import AdminPage from './pages/admin/adminPage';
import CoursePage from './pages/course/coursePage';
import NotFoundErrorPage from './pages/errors/notFoundErrorPage';
import RolePage from './pages/info/rolePage';
import LandingPage from './pages/landingPage';
import LessonPage from './pages/lesson/lessonPage';
import PrivacyPage from './pages/policy/privacyPage';
import TermsPage from './pages/policy/termsPage';
import ClassroomsPage from './pages/teacher/classroomsPage';
import StudentProgressPage from './pages/teacher/studentProgressPage';
import InfoPage from './pages/user/infoPage';
import LoginPage from './pages/user/loginPage';
import RequestAuthPage from './pages/user/requestAuthPage';
import SignupPage from './pages/user/signupPage';
import UserPage from './pages/users/userPage';
import usageFeePage from './pages/usageFee/usageFeePage';
import { ScrollToTop } from 'components/global';

import edit from 'components/admin/ComponentEdit/edit';
import { LinkEditor } from 'components/admin/ComponentEdit/entities/linkEditor';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

const history = createBrowserHistory();
history.listen((location: RouteComponentProps['location']) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = (): JSX.Element => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact={true} component={LandingPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/guide" component={GuidePage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/usagefee" component={usageFeePage} />

          <Route path="/edit" component={LinkEditor} />

          <PrivateRoute path="/mypage" component={MyPage} />
          <PrivateRoute path="/signup" component={SignupPage} />
          <PrivateRoute path="/auth/role" component={RolePage} />
          <PrivateRoute path="/auth/info" component={InfoPage} />
          <PrivateRoute path="/auth/request" component={RequestAuthPage} />
          <PrivateRoute path="/course/:courseId" component={CoursePage} />
          <PrivateRoute path="/lesson/:lessonId" component={LessonPage} />
          <PrivateRoute
            path="/classrooms/:classroomId"
            component={StudentProgressPage}
          />
          <PrivateRoute path="/classrooms" component={ClassroomsPage} />
          <PrivateRoute
            path="/admin/users/:userId"
            component={(props) => <UserPage {...props} />}
          />
          <PrivateRoute
            path="/admin/content"
            component={(props) => <EditPage {...props} />}
          />
          <PrivateRoute
            path="/admin"
            component={(props) => <AdminPage {...props} />}
          />
          {/* Not Found Error Page */}
          <Route render={() => <NotFoundErrorPage />} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
