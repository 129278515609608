import React from 'react';
import { SignupForm } from 'components/main';

const signupPage = (): JSX.Element => {
  return (
    <>
      <SignupForm />
    </>
  );
};

export default signupPage;
