import { useState } from 'react';

const useUserExist = (): any => {
  const [existUser, setExistUser] = useState(null);

  const getExistUser = async (userEmail: string): Promise<void> => {
    const result = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/users?userEmail=${userEmail}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );

    const status = result.status;
    const json = await result.json();

    if (status === 200) {
      setExistUser(json.length !== 0);
    } else {
      setExistUser(null);
    }
  };

  return [existUser, getExistUser];
};

export default useUserExist;
