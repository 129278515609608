import React from 'react';
import { Link } from 'react-router-dom';
import { ImgButtonProps } from './interfaces';

const ImgButton = (props: ImgButtonProps): JSX.Element => {
  const { type, name, url, path, children } = props;

  return type === 'a' ? (
    <div
      className={`btn btn-pd-iconnone-48 btn-${name}-login`}
      id={`${name}-login-btn`}
      onClick={props.handleClick}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <img src={path} alt="" className={`sns-symbol-${name}`} />
      {children}
    </div>
  ) : (
    <Link to={url}>
      <div
        className={`btn btn-pd-iconnone-48 btn-${name}-login`}
        id={`${name}-login-btn`}
      >
        <img src={path} alt="" className={`sns-symbol-${name}`} />
        {children}
      </div>
    </Link>
  );
};

export default ImgButton;
