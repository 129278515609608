import React from 'react';

const ApplyButton = ({ onclick, text }) => {
  return (
    <button className="apply-button" type="button" onClick={onclick}>
      {text}
    </button>
  );
};

export default ApplyButton;
