export const informApplyData = async (
  isLimit: string,
  period: string,
  amount: string
): Promise<void> => {
  try {
    fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/notice?isLimit=${isLimit}&period=${period}&amount=${amount},`,
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
      }
    ).then((response) => {
      if (response.ok) {
        // alert('신청되었습니다😆 ');
      } else {
        alert(response.statusText);
      }
    });
  } catch (err) {
    console.log(err);
  }
};
