import { useState } from 'react';
import { StudentsI } from '../../../../components/main/tab/interfaces';

interface ManageLogsI {
  action: string;
  createdAt: string;
  id: number;
  ipAddress: string;
  user: UserDataI;
}
interface UserDataI {
  approvedAt: Date;
  createdAt: Date;
  deletedAt: Date;
  id: number;
  students: StudentsI;
  updatedAt: Date;
  userAuth: number;
  userEmail: string;
  userName: string;
  userRole: number;
}

const useManageLogs = (): [
  ManageLogsI[],
  (classroomId: number) => Promise<void>
] => {
  const [manageLogs, setManageLogs] = useState();
  const getManageLogs = async (classroomId: number): Promise<void> => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/logs?classroomId=${classroomId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const status = r.status;
    const json = await r.json();
    if (status === 200) {
      setManageLogs(json);
    } else {
      alert(r.statusText);
    }
  };

  return [manageLogs, getManageLogs];
};

export default useManageLogs;
