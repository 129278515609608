import React from 'react';

const BackgroundBubble = ({ bubbleCount }) => {
  const rendering = () => {
    const result = [];
    for (let i = 0; i < bubbleCount; i++) {
      result.push(<div key={i} className="circle"></div>);
    }
    return result;
  };
  return (
    <div className="background">
      <div className="wave"></div>
      <div className="wave wave2"></div>
      {rendering()}
    </div>
  );
};

export default BackgroundBubble;
