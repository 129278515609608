import classNames from 'classnames';
import { fillZero, formDate } from 'pages/teacher/studentProgressPage';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useAdmin from 'store/modules/admin/adminHook';
import useAppliers from 'store/modules/appliers/appliersHook';
import useUsers from 'store/modules/users/usersHook';
import styles from 'styles/stylesheets/style.css';

// import useEditApplierInfo from 'hooks/admin/EditApplierInfo';
// import { useAdminModal } from 'hooks/global';
import Cell from './Cell';
import CellSelect from './CellSelect';

const cn = classNames.bind(styles);

const DataTable = React.memo(
  ({
    headings,
    ty,
    setTy,
    order,
    userAuth,
    searchData,
  }: {
    headings: string[];
    ty: number;
    setTy: React.Dispatch<React.SetStateAction<number>>;
    order: number;
    userAuth?: number;
    searchData?: any;
  }): JSX.Element => {
    const { setModalOn } = useAdmin();
    const { appliers, setAppliers, setCustomAppliers, appliersIsLoading } =
      useAppliers();
    const { users, setUsers } = useUsers();
    const onChange = () => {
      setCustomAppliers(
        searchData.authNum,
        searchData.searchField,
        searchData.userRole,
        searchData.searchType
      );
      setAppliers();
    };

    useEffect(() => {
      if (order === 0) {
        setUsers();
      } else if (order === 1) {
        // if (userAuth == 0) {
        //   setNotAppliers();
        // } else
        setAppliers();
      }
    }, [setUsers, setAppliers, order]);

    const history = useHistory();
    const handleRowClick = (row) => {
      history.push(`/admin/users/${row['id']}`);
    };

    // 회원 관리용 유저 데이터
    const usersData = users.map(
      ({ id, userName, userEmail, createdAt, userRole, userAuth }) => {
        const dt = new Date(createdAt);
        const ca =
          dt.getFullYear() +
          '.' +
          fillZero(String(dt.getMonth() + 1)) +
          '.' +
          fillZero(String(dt.getDate()));
        return {
          id,
          userName: userName,
          userEmail,
          createdAt: ca,
          userRole,
          userAuth,
        };
      }
    );

    // const { editInfoInit, editInfoRep, editInfoMemo } = useEditApplierInfo();

    // - input
    // ty : 모달의 type 식별
    // setTy : ty 2(미신청유저목록) 모달 > ty 3(미신청유저요청하기) 모달로 넘기는 등 type 변경
    // '상세 내용' : 헤더에 넣을 텍스트
    // editInfoInit : 실행할 함수, 승인하기 버튼
    // editInfoRep : 실행할 함수, 이용중/이용중지 버튼
    // editInfoMemo : 실행할 함수, 메모
    // - output
    // openModal : 모달창 열기
    // renderModal : 모달
    // const [openModal, renderModal] = useAdminModal(
    //   ty,
    //   setTy,
    //   '상세 내용',
    //   editInfoInit,
    //   editInfoRep,
    //   editInfoMemo
    // );
    // const [chkIdx, setChkIdx] = useState<number>(0);
    // 테이블 헤더 로우(thead > tr > th(Cell))
    const renderHeadingRow = (_cell, cellIndex) => {
      return (
        <Cell
          key={`heading-${cellIndex}`}
          content={headings[cellIndex]}
          header={true}
        />
      );
    };
    // 그룹관리 테이블 로우(tbody > tr > td(Cell))
    const renderRowForAppliers = (_row, rowIndex) => {
      if (_row['id'] === -1) {
        return null;
      }
      let userNameRole = '';
      if (_row['userRole'] === 0) {
        userNameRole = _row['userName'] + '(없음)';
      } else if (_row['userRole'] === 1) {
        userNameRole = _row['userName'] + '(학생)';
      } else if (_row['userRole'] === 2) {
        userNameRole = _row['userName'] + '(선생님)';
      } else if (_row['userRole'] === 3) {
        userNameRole = _row['userName'] + '(관리자)';
      }

      let userAuth = '';
      if (_row['userAuth'] === 0) {
        userAuth = '미신청';
      } else if (_row['userAuth'] === 1) {
        userAuth = '승인대기중';
      } else if (_row['userAuth'] === 2) {
        userAuth = '이용중';
      } else if (_row['userAuth'] === 3) {
        userAuth = '이용중지';
      } else if (_row['userAuth'] === 4) {
        userAuth = '승인거절';
      }

      let knownPath = '';
      if (_row['application']) {
        if (_row['application']['knownPath'].substr(0, 1) === '1') {
          knownPath = 'SNS';
        } else {
          knownPath = _row['application']['knownPath'].slice(
            2,
            _row['application']['knownPath'].length
          );
        }
      }

      return (
        <tr
          key={`row-${rowIndex}`}
          className="row-tr"
          // onClick={() => {
          //   openModal();
          //   setModalOn();
          //   setChkIdx(rowIndex);
          // }}
          // onClick={() => handleRowClick(_row)}
        >
          <Cell
            key={`${rowIndex}-${1}`}
            content={userNameRole}
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${2}`}
            content={_row['application'] ? _row['application']['userTel'] : ''}
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${3}`}
            content={
              _row['application'] ? _row['application']['groupName'] : ''
            }
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${4}`}
            content={
              _row['application'] ? _row['application']['expectedNumber'] : ''
            }
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${5}`}
            idx={7}
            content={knownPath}
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${6}`}
            content={_row['userEmail']}
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${7}`}
            content={_row['application'] ? formDate(_row['createdAt']) : ''}
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${8}`}
            content={
              _row['application']
                ? formDate(_row['application']['createdAt'])
                : ''
            }
            cln="appliers"
            userId={_row['id']}
          />
          <Cell
            key={`${rowIndex}-${9}`}
            idx={9}
            content={userAuth}
            cln="appliers"
            userId={_row['id']}
          />
          <CellSelect
            key={`${rowIndex}-${10}`}
            idx={10}
            content={_row['userPurchase']}
            cln="appliers"
            userId={_row['id']}
            onChange={onChange}
            appliersIsLoading={appliersIsLoading}
          />
        </tr>
      );
    };
    // 회원관리 테이블 로우 (tbody > tr > td(Cell))
    const renderRowForUsers = (_row, rowIndex) => {
      if (_row['id'] === -1) {
        return null;
      }
      let role = '';
      if (_row['userRole'] === 0) {
        role = '없음';
      } else if (_row['userRole'] === 1) {
        role = '학생';
      } else if (_row['userRole'] === 2) {
        role = '선생님';
      } else if (_row['userRole'] === 3) {
        role = '관리자';
      }

      let auth = '';
      if (_row['userAuth'] === 0) {
        auth = '승인전';
      } else if (_row['userAuth'] === 1) {
        auth = '승인대기중';
      } else if (_row['userAuth'] === 2) {
        auth = '이용중';
      } else if (_row['userAuth'] === 3) {
        auth = '이용중지';
      } else if (_row['userAuth'] === 4) {
        auth = '승인거절';
      }

      return (
        <tr
          key={`row-${rowIndex}`}
          className={cn('row-tr', 'origin')}
          onClick={() => handleRowClick(_row)}
        >
          <Cell key={`${rowIndex}-${1}`} content={_row['userEmail']} />
          <Cell key={`${rowIndex}-${2}`} content={_row['userName']} />
          <Cell key={`${rowIndex}-${3}`} content={_row['createdAt']} />
          <Cell key={`${rowIndex}-${4}`} content={role} />
          <Cell key={`${rowIndex}-${5}`} idx={9} content={auth} />
          {/* <Cell key={`${rowIndex}-${6}`} action content={_row['id']} /> */}
        </tr>
      );
    };

    // thead > tr
    const theadMarkup = (
      <>
        <tr key="heading" className="heading-tr">
          {headings.map(renderHeadingRow)}
        </tr>
      </>
    );
    const tbodyMarkupForAppliers = appliers.map(renderRowForAppliers);
    const tbodyMarkupForUsers = usersData.map(renderRowForUsers);

    return (
      <>
        <table className="applier-table">
          <thead>{theadMarkup}</thead>
          <tbody>
            {order === 0 && tbodyMarkupForUsers}
            {order === 1 && tbodyMarkupForAppliers}
          </tbody>
        </table>
        {/* {appliers.length !== 0 && <>{renderModal(appliers[chkIdx])}</>} */}
      </>
    );
  }
);

export default DataTable;
