import NotFoundErrorPage from 'pages/errors/notFoundErrorPage';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useUser from 'store/modules/user/userHook';

interface PrivateRouteProps extends RouteProps {
  component: any;
  path: string;
  match?: any;
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component: Component, path, ...rest } = props;
  const { isLoggedIn, userData } = useUser();

  const isAdmin = userData ? userData.userRole === 3 : false;
  const isTeacher = userData ? userData.userRole === 2 : false;

  const RouteFilter = (props) => {
    if (isLoggedIn) {
      // 로그인
      if (path.indexOf('admin') !== -1) {
        if (isAdmin) return <Component {...props} />;
        else return <Route render={() => <NotFoundErrorPage />} />;
      } else if (path.indexOf('classrooms') !== -1) {
        if (isAdmin || isTeacher) return <Component {...props} />;
        else
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
      } else if (path.indexOf('auth') !== -1) {
        if (userData.userAuth === 2 && userData.userRole === 0)
          return <Component {...props} />;
        else
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
      } else {
        return <Component {...props} />;
      }
    } else {
      // 비로그인
      if (path.indexOf('admin') !== -1) {
        return <Route render={() => <NotFoundErrorPage />} />;
      } else if (
        path.indexOf('/signup') !== -1 ||
        path.indexOf('/course/:courseId') !== -1 ||
        path.indexOf('/lesson/:lessonId') !== -1
      ) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }
    }
  };

  return <Route {...rest} render={RouteFilter} />;
};

export default PrivateRoute;
