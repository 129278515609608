import React from 'react';
import { ISearchBarForDate } from './interface';

const SearchBarForDate = ({
  searchQuery = '',
  setSearchQuery,
  setU,
  setCustomU,
  children,
}: ISearchBarForDate): JSX.Element => {
  const handleKeyDown = (e, searchQuery) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchQuery === '') {
        setU();
      } else {
        setCustomU(String(searchQuery));
      }
    }
  };

  return (
    <form action="/" method="get" className="search-bar-form">
      <input
        className="search-bar"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e, searchQuery)}
        type="text"
        id="header-search"
        placeholder={children}
        style={{ width: '100%' }}
        name="s"
      />
    </form>
  );
};

export default SearchBarForDate;
