import CopyrightIcon from '@material-ui/icons/Copyright';

const FooterLayout = (): JSX.Element => {
  return (
    <footer className="global-footer">
      <div className="footer-content">
        <div className="f_linkmsg">
          <ul>
            <li>
              <b>서비스</b>
            </li>
            <li>
              <a className="link title f_link" href="/guide">
                <span>초대 가이드</span>
              </a>
            </li>
            <li>
              <a className="link title f_link" href="/usagefee">
                <span>요금 안내</span>
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <b>비즈니스</b>
            </li>
            <li>
              <a className="link title f_link" href="http://www.theailab.co/">
                <span>회사소개</span>
              </a>
            </li>
            <li>
              <a
                className="link title f_link"
                href="https://coding-x.com/landingpage"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>그룹교육</span>
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <b>패밀리사이트</b>
            </li>
            <li>
              <a className="link title f_link" href="https://www.coding-x.com">
                <span>코딩엑스</span>
              </a>
            </li>
            <li>
              <a
                className="link title f_link"
                href="https://school.coding-x.com"
              >
                <span>AI온북</span>
              </a>
            </li>
          </ul>
          {/* <a className="link title f_link" href="/notice">
            <span>공지사항</span>
          </a> */}
        </div>
        <div className="f_detailmsg">
          <div className="detail color-blk">
            <span>더에이아이랩 ㈜, 대표: 최영준</span>
            <span> | </span>
            <span>
              <a className="link title f_link" href="/terms">
                <span>이용약관</span>
              </a>
            </span>
            <span> | </span>
            <span>
              <a className="link title f_link" href="/privacy">
                <span>개인정보 처리방침</span>
              </a>
            </span>
          </div>
          <div className="detail color-blk">
            사무실: 서울시 강남구 선릉로 108길 9, 5층
          </div>
          <div className="detail color-blk">
            통신판매신고번호 제 2020-서울강남-02380 | 사업자등록번호
            439-87-00757
          </div>
          <div className="detail color-blk">
            © The AI Lab. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLayout;
