import { useState } from 'react';
import { IStudent } from 'hooks/main/progress/getProgress/interfaces';

interface ManageLogsI {
  action: string;
  createdAt: string;
  id: number;
  ipAddress: string;
  user: UserDataI;
}
interface UserDataI {
  approvedAt: Date;
  createdAt: Date;
  deletedAt: Date;
  id: number;
  students: IStudent;
  updatedAt: Date;
  userAuth: number;
  userEmail: string;
  userName: string;
  userRole: number;
}

const useLoginLogs = (
  userId: number
): [ManageLogsI[], () => Promise<void>, (date: string) => Promise<void>] => {
  const [loginLogs, setLoginLogs] = useState();
  const getLoginLogs = async (): Promise<void> => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/logs?userId=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const status = r.status;
    const json = await r.json();

    if (status === 200) {
      json.map((item) => item.action === 'login' && item);
      setLoginLogs(json);
    } else {
      alert(r.statusText);
    }
  };

  const getCustomLoginLogs = async (date: string): Promise<void> => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/logs?userId=${userId}&createdAt=${date}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const status = r.status;
    const json = await r.json();

    if (status === 200) {
      json.map((item) => item.action === 'login' && item);
      setLoginLogs(json);
    } else {
      if (status === 400) {
        alert('날짜 형식에 맞게 입력해주세요.');
      } else {
        alert(r.statusText);
      }
    }
  };

  return [loginLogs, getLoginLogs, getCustomLoginLogs];
};

export default useLoginLogs;
