export const LessonPane = ({
  content,
  dispatch,
  handleImageUpload,
}): JSX.Element => {
  return (
    <>
      <input
        className="pane-item pane-input"
        value={content.title}
        onChange={(e) => {
          dispatch({ type: 'TITLE', title: e.target.value });
        }}
        placeholder="이름"
      />
      <input
        className="pane-item pane-input"
        value={content.summary}
        onChange={(e) => {
          dispatch({ type: 'SUMMARY', summary: e.target.value });
        }}
        placeholder="요약"
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '10px',
        }}
      >
        {content.image !== '' && (
          <img
            src={content.image}
            alt=""
            style={{
              height: '300px',
              objectFit: 'contain',
              marginBottom: '10px',
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
          }}
        >
          <input
            className="pane-item pane-input upload-name"
            style={{
              flex: 1,
              marginRight: '10px',
              marginBottom: '0px',
            }}
            value={content.image}
            placeholder="이미지"
            disabled
          />
          <label className="label-pane" htmlFor="image_file">
            업로드
          </label>
          <input
            style={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: '0px',
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0, 0, 0, 0)',
              border: '0',
            }}
            type="file"
            onChange={(e) => handleImageUpload(e)}
            id="image_file"
          />
        </div>
      </div>
    </>
  );
};
