import { createSlice } from '@reduxjs/toolkit';
import { Action, Dispatch } from 'redux';

export type appliersState = {
  loading: boolean;
  hasErrors: boolean;
  nAloading: boolean;
  nAhasErrors: boolean;
  appliers: IApplier[] | undefined;
  notAppliers: IApplier[] | undefined;
  appliersNumAll: number;
  appliersNumNoApply: number;
  appliersNumWait: number;
  appliersNumComplete: number;
  appliersNumPause: number;
  appliersNumReject: number;
};

export interface IApplier {
  id: number;
  userName: string;
  userEmail: string;
  userAuth: number;
  userRole: number;
  userPurchase: number;
  approvedAt: string | Date | '';
  createdAt: Date | '';
  updatedAt: Date | '';
  application: IApplication;
  latestLog: Date | '';
  totalLog: number;
  memo: IMemo | null | undefined;
}

interface IMemo {
  id: number;
  content: string;
}

interface IApplication {
  id: number;
  userRole: string;
  userTel: string;
  groupName: string;
  expectedNumber: string;
  knownPath: string;
  inquiry: string;
  createdAt: Date | '';
  updatedAt: Date | '';
}

const initialState: appliersState = {
  loading: false,
  hasErrors: false,
  nAloading: false,
  nAhasErrors: false,
  appliers: [
    {
      id: -1,
      userName: '',
      userEmail: '',
      userAuth: -1,
      userRole: 0,
      userPurchase: 0,
      approvedAt: '',
      createdAt: '',
      updatedAt: '',
      application: {
        id: -1,
        userRole: '',
        userTel: '',
        groupName: '',
        expectedNumber: '',
        knownPath: '',
        inquiry: '',
        createdAt: '',
        updatedAt: '',
      },
      memo: {
        id: -1,
        content: '',
      },
      latestLog: '',
      totalLog: -1,
    },
  ],
  notAppliers: [],
  appliersNumAll: 0,
  appliersNumNoApply: 0,
  appliersNumWait: 0,
  appliersNumComplete: 0,
  appliersNumPause: 0,
  appliersNumReject: 0,
};

const appliersSlice = createSlice({
  name: 'appliers',
  initialState,
  reducers: {
    getAppliers: (state) => {
      state.loading = true;
    },
    getNotAppliers: (state) => {
      state.nAloading = true;
    },
    getAppliersSuccess: (state, { payload }) => {
      state.appliers = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getNotAppliersSuccess: (state, { payload }) => {
      state.notAppliers = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getApplierNumSuccess: (state, { payload }) => {
      state.appliersNumAll = 0;
      state.appliersNumNoApply = 0;
      state.appliersNumWait = 0;
      state.appliersNumComplete = 0;
      state.appliersNumPause = 0;
      state.appliersNumReject = 0;
      payload.forEach((item) => {
        if (item['userAuth'] === 0) {
          state.appliersNumNoApply += 1;
        } else if (item['userAuth'] === 1) {
          state.appliersNumWait += 1;
        } else if (item['userAuth'] === 2) {
          state.appliersNumComplete += 1;
        } else if (item['userAuth'] === 3) {
          state.appliersNumPause += 1;
        } else if (item['userAuth'] === 4) {
          state.appliersNumReject += 1;
        }
      });
      state.appliersNumAll =
        state.appliersNumNoApply +
        state.appliersNumWait +
        state.appliersNumComplete +
        state.appliersNumPause +
        state.appliersNumReject;
    },
    getAppliersFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getNotAppliersFailure: (state) => {
      state.nAloading = false;
      state.nAhasErrors = true;
    },
  },
});

const { reducer, actions } = appliersSlice;
export const {
  getAppliers,
  getNotAppliers,
  getAppliersSuccess,
  getNotAppliersSuccess,
  getApplierNumSuccess,
  getAppliersFailure,
  getNotAppliersFailure,
} = actions;

export function fetchAppliers() {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getAppliers());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        dispatch(getAppliersSuccess(data));
        dispatch(getApplierNumSuccess(data));
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(getAppliersFailure());
    }
  };
}

export function fetchCustomAppliers(
  userAuth: number,
  userName: string,
  userRole: string,
  searchType?: number
) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getAppliers());
    // console.log(
    //   'fetchCustomAppliers',
    //   'auth',
    //   userAuth,
    //   'name',
    //   userName,
    //   'role',
    //   userRole,
    //   'searchType',
    //   searchType
    // );

    const name =
      (searchType === 0 && !(userName === '') ? `userName=${userName}` : '') ||
      (searchType === 1 && !(userName === '') ? `userEmail=${userName}` : '') ||
      (searchType === 2 && !(userName === '') ? `groupName=${userName}` : '');
    const auth = !(userAuth === -2 || userAuth === -1)
      ? `&userAuth=${userAuth}`
      : '';
    const role = !(
      userRole === '0' ||
      userRole === '전체' ||
      userRole === undefined
    )
      ? userRole === '선생님'
        ? `&userRole=${2}`
        : `&userRole=${1}`
      : '';

    const query = name + auth + role;
    // console.log('query', query);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users?${query}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        // console.log('data', data);
        dispatch(getAppliersSuccess(data));
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(getAppliersFailure());
    }
  };
}

export function fetchNotAppliers() {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getNotAppliers());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users?userAuth=0`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        dispatch(getNotAppliersSuccess(data));
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(getNotAppliersFailure());
    }
  };
}

export function fetchCustomNotAppliers(userName: string) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getNotAppliers());

    let query = '';
    if (userName === '') {
      query = ``;
    } else {
      query = `userName=${userName}`;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users?userAuth=0&${query}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        dispatch(getNotAppliersSuccess(data));
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(getNotAppliersFailure());
    }
  };
}

export default reducer;
