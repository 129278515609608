import { useEffect, useState } from 'react';
import { IsetCourseOption } from '../getProgress/interfaces';
import axios from 'axios';

const useCourseOption = (): IsetCourseOption => {
  const [courses, setCourses] = useState([]);
  const [coursesError, setCoursesError] = useState<string>('');
  const [coursesIsLoading, setCoursesIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(`${process.env.REACT_APP_SERVER_BASE_URL}/courses`, {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          })
          .then((res) => {
            setCourses(res.data);
          })
          .finally(() => {
            setCoursesIsLoading(false);
          });
      } catch (err) {
        setCoursesError(err);
      }
    };
    if (coursesIsLoading) {
      fetchData();
    }
    if (coursesError || !Array.isArray(courses)) {
      return console.log('There was an error loading your courses!');
    }
  }, [coursesIsLoading, courses, coursesError]);

  return { courses, coursesError, coursesIsLoading };
};

export default useCourseOption;
