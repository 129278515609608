import { Link } from 'react-router-dom';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { IgetClassroomData } from './interface';

export const ClassroomTable = ({
  classrooms,
}: IgetClassroomData): JSX.Element => {
  const copyCode = async (code) => {
    await navigator.clipboard.writeText(code);
    toast.dark(
      <p style={{ textAlign: 'center', padding: '0px', fontSize: '14px' }}>
        초대 코드가 복사되었습니다.
      </p>
    );
  };

  return (
    <>
      {classrooms && classrooms[0] && classrooms[0].id !== undefined && (
        <tbody>
          {classrooms?.map((classroom) => (
            <tr key={classroom.id}>
              <td>{classroom.classroomName}</td>
              <td>
                {classroom.bookmarks !== undefined &&
                  classroom.bookmarks?.length !== 0 && (
                    <Link
                      to={`/lesson/${classroom?.bookmarks[0]['lesson'].id}`}
                    >
                      {classroom.bookmarks[0].lesson &&
                        classroom.bookmarks[0].lesson['lessonName']}
                    </Link>
                  )}
              </td>
              <td>{classroom['memberCount']}</td>
              <td>
                <Link to={`/classrooms/${classroom.id}`}>관리</Link>
              </td>
              <td onClick={() => copyCode(classroom.classroomCode)}>
                {classroom.classroomCode}
              </td>
            </tr>
          ))}
        </tbody>
      )}
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        className={'copy-url'}
        autoClose={1000}
        transition={Zoom}
        hideProgressBar
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
        toastStyle={{
          width: '200px',
          margin: 'auto',
        }}
      />
    </>
  );
};
