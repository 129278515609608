import { useState } from 'react';
import UsageFeeItem from './usageFeeItem';
import SwiperCore, { Navigation, EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

SwiperCore.use([Navigation, EffectCoverflow, Pagination]);

const UsageFee = ({ openDefaultModal }) => {
  const freeFeeData = {
    period: '모든 회원',
    amount: '무료',
    contentsRignts: '데이터분석 입문 레슨13 \n (47개의 레슨)',
    invitedStudent: '',
    extraCharge: ' ',
    teacherAccount: '1개',
    buttonText: '클래스 듣기',
  };
  const limitFeeData = [
    {
      isLimit: '학생수 제한',
      period: '방학 내',
      amount: '400,000원',
      contentsRignts: '머신러닝까지 모든 클래스 \n (115개의 레슨)',
      invitedStudent: '최대 20명',
      extraCharge: '명당 1.5만원',
      teacherAccount: '1개',
      buttonText: '신청하기',
    },
    {
      isLimit: '학생수 제한',
      period: '한 학기',
      amount: '500,000원',
      contentsRignts: '머신러닝까지 모든 클래스 \n (115개의 레슨)',
      invitedStudent: '최대 30명',
      extraCharge: '명당 3만원',
      teacherAccount: '1개',
      buttonText: '신청하기',
    },
    {
      isLimit: '학생수 제한',
      period: '두 학기',
      amount: '900,000원',
      contentsRignts: '머신러닝까지 모든 클래스 \n (115개의 레슨)',
      invitedStudent: '최대 80명',
      extraCharge: '명당 2만원',
      teacherAccount: '1개',
      buttonText: '신청하기',
    },
  ];
  const unlimitFeeData = [
    {
      isLimit: '학생수 무제한',
      period: '방학 내',
      amount: '1,050,000원',
      contentsRignts: '머신러닝까지 모든 클래스 \n (115개의 레슨)',
      invitedStudent: '제한 없음',
      extraCharge: '',
      teacherAccount: '3개',
      buttonText: '신청하기',
    },
    {
      isLimit: '학생수 무제한',
      period: '한 학기',
      amount: '1,350,000원',
      contentsRignts: '머신러닝까지 모든 클래스 \n (115개의 레슨)',
      invitedStudent: '제한 없음',
      extraCharge: '',
      teacherAccount: '3개',
      buttonText: '신청하기',
    },
    {
      isLimit: '학생수 무제한',
      period: '두 학기',
      amount: '2,400,000원',
      contentsRignts: '머신러닝까지 모든 클래스 \n (115개의 레슨)',
      invitedStudent: '제한 없음',
      extraCharge: '',
      teacherAccount: '3개',
      buttonText: '신청하기',
    },
  ];

  const [isStudentLimit, setIsStudentLimit] = useState(true);

  const toggleOption = (isLimit) => {
    isLimit ? setIsStudentLimit(true) : setIsStudentLimit(false);
  };

  return (
    <section className="usage-fee">
      <h1 className="usage-fee-title">합리적인 가격을 제공합니다</h1>

      {/* 1) 데스크톱 */}
      <div className="usage-fee-table sm-hidden">
        <ul className="usage-fee-table-header">
          <li className="monthly-fee">
            <h3>이용방식</h3>
            <strong>금액</strong>
          </li>
          <li>콘텐츠 권한</li>
          <li>학생 초대</li>
          <li>최대 인원 초과시</li>
          <li>교사 계정</li>
        </ul>

        <div className="usage-fee-table-body">
          <div className="option-buttons">
            <button
              className={`option-button ${isStudentLimit && 'is-active'}`}
              type="button"
              onClick={() => toggleOption(true)}
            >
              학생수 제한
            </button>
            <button
              className={`option-button ${isStudentLimit || 'is-active'}`}
              type="button"
              onClick={() => toggleOption(false)}
            >
              학생수 무제한
            </button>
          </div>

          <div className="contents">
            <div className="usage-fee-item-container">
              <UsageFeeItem
                openDefaultModal={openDefaultModal}
                feeData={freeFeeData}
                isMobile={false}
              />
              {isStudentLimit &&
                limitFeeData.map((feeData, index) => (
                  <UsageFeeItem
                    openDefaultModal={openDefaultModal}
                    key={`dl_${index}`}
                    feeData={feeData}
                    isMobile={false}
                  />
                ))}

              {isStudentLimit ||
                unlimitFeeData.map((feeData, index) => (
                  <UsageFeeItem
                    openDefaultModal={openDefaultModal}
                    key={`du_${index}`}
                    feeData={feeData}
                    isMobile={false}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="usage-fee-table-footer">
          <a className="btn-outlined" href="https://coding-x.channel.io">
            추가 문의 및 고객센터
          </a>
        </div>
      </div>

      {/* 2) 모바일 */}
      <div className="usage-fee-card-container sm-only">
        <Swiper
          loop={true}
          slidesPerView={'auto'}
          centeredSlides={true}
          navigation={{
            nextEl: '.fee-swiper-next',
            prevEl: '.fee-swiper-prev',
          }}
          pagination={{
            el: '.swiper-pagination',
            type: 'fraction',
            clickable: false,
          }}
        >
          <SwiperSlide>
            <UsageFeeItem
              openDefaultModal={openDefaultModal}
              feeData={freeFeeData}
              isMobile={true}
            />
          </SwiperSlide>

          {limitFeeData.map((feeData, index) => (
            <SwiperSlide key={`ml_${index}`}>
              <UsageFeeItem
                openDefaultModal={openDefaultModal}
                feeData={feeData}
                isMobile={true}
              />
            </SwiperSlide>
          ))}

          {unlimitFeeData.map((feeData, index) => (
            <SwiperSlide key={`mu_${index}`}>
              <UsageFeeItem
                openDefaultModal={openDefaultModal}
                feeData={feeData}
                isMobile={true}
              />
            </SwiperSlide>
          ))}

          {/* Add Arrows */}
          <ul className="swiper-buttons">
            <div className="fee-swiper-prev">
              <img
                src="/assets/icons/ic-arrow-left-line-dk-24.svg"
                alt="요금안내 이전으로"
              />
            </div>
            <div className="swiper-pagination"></div>
            <div className="fee-swiper-next">
              <img
                src="/assets/icons/ic-arrow-right-line-dk-24.svg"
                alt="요금안내 다음으로"
              />
            </div>
          </ul>
        </Swiper>
      </div>
    </section>
  );
};

export default UsageFee;
