import { useState } from 'react';
import { ContentEditor } from 'components/admin/ComponentEdit/ContentEditor';
import { List } from 'components/admin/Pane/List';

export const EditPage = (): JSX.Element => {
  const [lessonId, setLessonId] = useState(0);

  return (
    <div className="edit-container">
      <List setLessonId={setLessonId} />
      <ContentEditor lessonId={lessonId} />
    </div>
  );
};
