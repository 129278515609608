import React from 'react';
import { useHistory } from 'react-router';
import { IsetInfo, IgetHandleInfo } from './interfaces';

const handleInfo = async ({
  inviteCode,
  setInviteCode,
  setValidInviteCode,
  setclassroomInfo,
  history,
}: IgetHandleInfo): Promise<void> => {
  const r = await fetch(
    `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms?classroomCode=${inviteCode}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
      },
    }
  );
  const json = await r.json();
  if (r.status === 200) {
    if (json.length === 0) {
      alert('존재하지 않는 반 입니다.');
      setValidInviteCode(false);
      setclassroomInfo(undefined);
    } else {
      if (json['0'].isOpen === false) {
        alert('닫혀있는 반 입니다.');
        setValidInviteCode(false);
        setclassroomInfo(undefined);
      } else {
        setValidInviteCode(true);
        setclassroomInfo(json);
      }
    }
  } else {
    alert(json.message);
  }
};

const useHandleInfo = (
  inviteCode: string,
  setInviteCode: React.Dispatch<React.SetStateAction<string>>,
  setValidInviteCode: React.Dispatch<React.SetStateAction<boolean>>,
  setclassroomInfo: React.Dispatch<(prevState: undefined) => undefined>
): IsetInfo => {
  const history = useHistory();

  const lookupInviteCode = (): void => {
    handleInfo({
      inviteCode,
      history,
      setInviteCode,
      setValidInviteCode,
      setclassroomInfo,
    });
  };

  return { lookupInviteCode };
};

export default useHandleInfo;
