import * as React from 'react';
import { ExcelInviteModal } from 'components/main';

const useExcelInviteModal = (): {
  openModal: () => void;
  renderModal: (classroom_id) => JSX.Element;
} => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderModal = (classroom_id) => (
    <ExcelInviteModal
      isOpen={isModalOpen}
      close={closeModal}
      classroomId={classroom_id}
    />
  );

  return { openModal, renderModal };
};

export default useExcelInviteModal;
