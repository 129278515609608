import { RouteComponentProps } from 'react-router-dom';
import useUser from 'store/modules/user/userHook';
import { IsetCreateStudents } from './interfaces';

export const GetCreateStudents = (): IsetCreateStudents => {
  const { setUserRole, setUserAuth, setClassrooms } = useUser();
  const createStudents = (
    classroomInfo: any,
    history: RouteComponentProps['history']
  ): void => {
    try {
      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/members`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          classroomId: classroomInfo['0'].id,
        }),
      }).then((response) => {
        if (response.status === 201) {
          history.push(`/`);
          setUserRole(1);
          setUserAuth(2);
          setClassrooms(classroomInfo);
        } else {
          alert(response.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return { createStudents };
};
