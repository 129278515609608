import React, { useRef } from 'react';
import { LandingVideoProps } from './interfaces';

const LandingVideo = (infoprops: LandingVideoProps) => {
  const { info } = infoprops;
  const { title, image, video } = info;

  // hover됐을 때 video재생
  const vidRef = useRef(null);
  const handlePlayVideo = (isplay) => {
    if (isplay) {
      vidRef.current.currentTime = 0;
      vidRef.current.play();
    } else {
      // vidRef.current.pause();
      vidRef.current.currentTime = 0;
    }
  };

  return (
    <div
      className="video-inner"
      onMouseEnter={() => handlePlayVideo(true)}
      onMouseOut={() => handlePlayVideo(false)}
    >
      <div className="video-image">
        <img src={`/assets/images/${image}`} alt="" />
      </div>
      <p>{title}</p>
      <div className="video">
        <video ref={vidRef} src={`${video}`} muted playsInline loop></video>
      </div>
    </div>
  );
};

export default LandingVideo;
