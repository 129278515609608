import { useEffect, useRef, useState } from 'react';

interface User {
  id: number;
  userEmail: string;
  userName: string;
  userAuth: number;
  userRole: number;
  approvedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

const PasswordModal = ({
  user,
  setUserEdit,
}: {
  user: User;
  setUserEdit: (id) => void;
}): JSX.Element => {
  const setButton = useRef(null);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [rePasswordError, setRePasswordError] = useState(false);

  useEffect(() => {
    if (password.length < 8 && password.length > 0) setPasswordError(true);
    else setPasswordError(false);

    if (password !== '' && rePassword !== '') {
      if (password !== rePassword) setRePasswordError(true);
      else setRePasswordError(false);
    } else setRePasswordError(false);
  }, [password, rePassword]);

  useEffect(() => {
    if (!passwordError && !rePasswordError) {
      setButton.current.disabled = false;
      setButton.current.style.backgroundColor = '#394dd1';
    } else {
      setButton.current.disabled = true;
      setButton.current.style.backgroundColor = '#c2c2c2';
    }
  }, [passwordError, rePasswordError]);

  const changePasswordHandler = async () => {
    const result = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/users/${user.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
        body: JSON.stringify({
          password,
        }),
      }
    );

    if (result.status === 200) alert('비밀번호가 변경되었습니다.');
    else alert('비밀번호를 변경할 수 없습니다.');

    setUserEdit({ isOpen: false, user: null });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,16,96,0.3)',
        overflow: 'hidden',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: '420px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          padding: '48px',
          borderRadius: '8px',
          boxShadow: '0 2 16 #131830',
        }}
      >
        <h2 style={{ color: '#242E64', marginBottom: '24px' }}>
          비밀번호 재설정
        </h2>
        <div
          style={{
            color: '#656C92',
            fontSize: '14px',
            marginBottom: '4px',
          }}
        >
          비밀번호(8자 이상)
        </div>
        <input
          type="password"
          placeholder="기억하기 쉬운 조합(숫자,영문,특문) 자유"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            padding: '12px 16px',
            backgroundColor: '#F5F6FF',
            borderRadius: '4px',
            fontSize: '16px',
          }}
        />
        {passwordError ? (
          <p
            style={{
              visibility: 'visible',
              marginBottom: '24px',
              color: '#FF474A',
              fontSize: '12px',
            }}
          >
            8자 이상 입력해주세요.
          </p>
        ) : (
          <p style={{ marginBottom: '24px' }}></p>
        )}
        <div
          style={{
            color: '#656C92',
            fontSize: '14px',
            marginBottom: '4px',
          }}
        >
          비밀번호 확인
        </div>
        <input
          type="password"
          placeholder="비밀번호 확인"
          value={rePassword}
          onChange={(e) => setRePassword(e.target.value)}
          style={{
            padding: '12px 16px',
            backgroundColor: '#F5F6FF',
            borderRadius: '4px',
            fontSize: '16px',
          }}
        />
        {rePasswordError ? (
          <p
            style={{
              visibility: 'visible',
              marginBottom: '24px',
              color: '#FF474A',
              fontSize: '12px',
            }}
          >
            비밀번호와 일치하지 않습니다.
          </p>
        ) : (
          <p style={{ marginBottom: '24px' }}></p>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={() => setUserEdit({ isOpen: false, user: null })}
            style={{
              backgroundColor: '#FFF',
              border: '1px solid #DCDFEE',
              borderRadius: '4px',
              padding: '12px 19.5px',
            }}
          >
            취소
          </button>
          <button
            ref={setButton}
            onClick={() => changePasswordHandler()}
            style={{
              color: '#fff',
              backgroundColor: '#394DD1',
              border: 'none',
              borderRadius: '4px',
              padding: '12px 77px',
            }}
          >
            비밀번호 재설정
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
