import { useCallback, useEffect, useState } from 'react';
import {
  IgetGetProgressData,
  IsetGetProgressData,
  IGetProgressResponse,
} from './interfaces';
import axios from 'axios';

const useGetProgress = ({
  classroomId,
  setApproveInvCode,
}: IgetGetProgressData): IsetGetProgressData => {
  const [progressResp, setProgressResp] = useState<IGetProgressResponse[]>();
  const [error, setError] = useState<string>('');
  const [progressRespIsLoading, setProgressRespIsLoading] =
    useState<boolean>(true);

  const fetchProgressData = useCallback(async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms/${classroomId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setProgressResp(res.data);
          setApproveInvCode(res.data['isOpen']);
        })
        .finally(() => {
          setProgressRespIsLoading(false);
        });
    } catch (err) {
      setError(err);
    }
  }, [classroomId, setApproveInvCode]);

  useEffect(() => {
    if (progressRespIsLoading) {
      fetchProgressData();
    }
  }, [
    classroomId,
    progressRespIsLoading,
    setApproveInvCode,
    fetchProgressData,
  ]);

  return { progressResp, error, progressRespIsLoading, fetchProgressData };
};

export default useGetProgress;
