import React, { useEffect, useRef } from 'react';
import { FormInputProps } from './interfaces';

const FormInput = (props: FormInputProps): JSX.Element => {
  const formRef = useRef<HTMLInputElement>(null);
  const {
    name,
    type,
    placeholder,
    divCn,
    inputCn,
    value,
    onChange,
    errorTxtId,
    errorTxt,
    error,
    children,
    isFocus,
  } = props;

  // 이메일 폼에 focus
  useEffect(() => {
    if (isFocus) {
      formRef.current?.focus();
    }
  }, [isFocus]);

  return (
    <>
      <div className={divCn}>
        <input
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={inputCn}
          ref={formRef}
        />
        <label
          id="in-label"
          htmlFor="in-label"
          style={{
            color: error ? '#ff474a' : '#394dd1',
          }}
        >
          {children}
        </label>
        <div
          className="error-text"
          id={errorTxtId}
          style={{ display: error ? 'block' : 'none' }}
        >
          {errorTxt}
        </div>
      </div>
    </>
  );
};

export default FormInput;
