import React from 'react';
import Box from './Box';
import Editor from './Editor';
import Image from './Image';
import { IComponent } from './interfaces';
import QuizChoice from './QuizChoice';
import QuizEditor from './QuizEditor';
import Text from './Text';

const EditorComponent = React.memo(Editor);
// ComponentConverter는 props로 들어온 컴포넌트를 확인해서
// componentName에 맞는 컴포넌트로 뿌려주는 로직

// data: 컴포넌트 정보 데이터
const ComponentConverter = ({
  data,
  index,
}: {
  data: IComponent;
  index?: number;
}): JSX.Element => {
  return (
    <>
      {data.componentName === 'text' && (
        <Text id={data.id} content={data.content} />
      )}
      {data.componentName === 'box' && (
        <Box
          id={data.id}
          title={data.title}
          content={data.content}
          color={data.color}
        />
      )}
      {data.componentName === 'image' && (
        <Image id={data.id} imageUrl={data.imageUrl} />
      )}
      {data.componentName === 'editor' && (
        <EditorComponent id={data.id} content={data.content} code={data.code} />
      )}
      {data.componentName === 'editorTest' && (
        <QuizEditor
          key={data.id}
          id={data.id}
          title={data.title}
          content={data.content}
          code={data.code}
          answer={data.answer}
          explanation={data.explanation}
          lessonId={data.lesson.id}
          index={index}
        />
      )}
      {data.componentName === 'multipleTest' && (
        <QuizChoice
          key={data.id}
          id={data.id}
          title={data.title}
          content={data.content}
          questionChoice={data.questionChoice}
          explanation={data.explanation}
          lessonId={data.lesson.id}
          index={index}
        />
      )}
    </>
  );
};

export default ComponentConverter;
