import React, { useEffect } from 'react';

const VideoModal = ({ modalInfo, setOpen }) => {
  const { title, video } = modalInfo;

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;
    `;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <>
      <div className="video-modal">
        <article>
          <div className="modal-content">
            <video src={`${video}`} muted playsInline autoPlay controls></video>
            <h1>{title}</h1>
          </div>
          <footer className="modal-footer">
            <button type="button" onClick={() => close()}>
              닫기
            </button>
          </footer>
        </article>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default VideoModal;
