import { useCallback, useEffect, useState } from 'react';
import { IgetComponentsData, IsetComponentsData } from './interfaces';
import axios from 'axios';

// GET /components 모든 컴포넌트 조회 혹은 검색한 컴포넌트 조회
const useComponentsData = ({
  lessonId,
}: IgetComponentsData): IsetComponentsData => {
  const [componentsResp, setComponentsResp] = useState();
  const [componentsError, setComponentsError] = useState<string>('');
  const [componentsRespIsLoading, setComponentsRespIsLoading] =
    useState<boolean>(true);

  const fetchComponentsData = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/components?lessonId=${lessonId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setComponentsResp(res.data);
        })
        .finally(() => {
          setComponentsRespIsLoading(false);
        });
    } catch (err) {
      setComponentsError(err);
    }
  }, [lessonId]);
  useEffect(() => {
    fetchComponentsData();
  }, [fetchComponentsData]);

  return {
    componentsResp,
    componentsError,
    componentsRespIsLoading,
  };
};

export default useComponentsData;
