import React, { useMemo } from 'react';
import Editor from '@draft-js-plugins/editor';
import createSideToolbarPlugin from '@draft-js-plugins/side-toolbar';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import {
  EditorState,
  DraftEditorCommand,
  RichUtils,
  CompositeDecorator,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { mediaBlockRenderer } from './entities/mediaBlockRenderer';
import {
  HeadlineOneButton,
  HeadlineTwoButton,
  BlockquoteButton,
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import '@draft-js-plugins/side-toolbar/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import {
  Create,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatStrikethrough,
  Link,
} from '@material-ui/icons';
import editorStyles from './editorStyles.module.css';

const blockButtonOptions = [
  { name: <FormatAlignLeft />, action: 'left' },
  { name: <FormatAlignCenter />, action: 'center' },
  { name: <FormatAlignRight />, action: 'right' },
  { name: <Create />, action: 'unstyled' },
];

const toggleButtonOptions = [
  { name: <FormatStrikethrough />, action: 'STRIKETHROUGH' },
  { name: <Link />, action: 'link' },
];

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'left':
      return 'align-left';
    case 'center':
      return 'align-center';
    case 'right':
      return 'align-right';
    case 'header-one':
      return 'h1';
    case 'header-two':
      return 'h2';
    default:
      return null;
  }
};

export const TextTest = ({ id, component, dispatch }): JSX.Element => {
  const editorRef = React.useRef(null);
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: LinkE,
    },
  ]);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(decorator)
  );

  const { plugins, SideToolbar, InlineToolbar } = useMemo(() => {
    const sideToolbarPlugin = createSideToolbarPlugin();
    const inlineToolbarPlugin = createInlineToolbarPlugin();
    const { SideToolbar } = sideToolbarPlugin;
    const { InlineToolbar } = inlineToolbarPlugin;
    const plugins = [sideToolbarPlugin, inlineToolbarPlugin];

    return { plugins, SideToolbar, InlineToolbar };
  }, []);

  // 기존
  // const focusEditor = () => editorRef.current.focus();

  const focusEditor = () => {
    if (editorRef) {
      editorRef.current.focus();
    }
  };

  // & : link-plugin issue : Solved with draft-js entity
  React.useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        stateFromHTML(component.data.content ? component.data.content : '')
      )
    );
    // & : sideToolbar focus issue
    if (!component.data.content) focusEditor();
  }, []);

  const onChange = (es) => {
    setEditorState(es);
  };

  React.useEffect(() => {
    const options = {
      blockStyleFn: (block) => {
        if (
          block.getType() === 'left' ||
          block.getType() === 'center' ||
          block.getType() === 'right'
        ) {
          return {
            style: {
              'text-align': block.getType(),
            },
          };
        }
      },
    };

    dispatch({
      type: 'EDIT',
      id: id,
      data: {
        ...component.data,
        content: stateToHTML(editorState.getCurrentContent(), options),
      },
    });
  }, [editorState]);

  const [toggleButton, setToggleButton] = React.useState({
    STRIKETHROUGH: false,
  });
  const [blockButton, setBlockButton] = React.useState('');
  React.useEffect(() => {
    const inlineStyle = editorState.getCurrentInlineStyle();

    const STRIKETHROUGH = inlineStyle.has('STRIKETHROUGH');

    setToggleButton({ ...toggleButton, STRIKETHROUGH });

    const currentSelection = editorState.getSelection();
    const currentKey = currentSelection.getStartKey();
    const currentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(currentKey);

    setBlockButton(currentBlock.getType());
  }, [editorState]);

  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    if (inlineStyle === null) return;
    if (inlineStyle === 'link') {
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
        let url = '';

        if (linkKey) {
          const linkInstance = contentState.getEntity(linkKey);
          url = linkInstance.getData().url;
        }

        setShowURLInput(true);
        setUrlValue(url);
      }
    } else
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    if (blockType === null) return;
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const [btnHover, setBtnHover] = React.useState({});

  const confirmLink = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: urlValue, target: '_blank', rel: 'noreferrer' }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    setShowURLInput(false);
    setUrlValue('');
  };

  const removeLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
    setShowURLInput(false);
  };

  const onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      confirmLink(e);
    }
  };

  const [showURLInput, setShowURLInput] = React.useState(false);
  const [urlValue, setUrlValue] = React.useState('');
  const url = React.useRef(null);
  const onURLChange = (e) => setUrlValue(e.target.value);
  let urlInput;

  if (showURLInput) {
    urlInput = (
      <div style={styles.urlInputContainer}>
        <input
          ref={url}
          onChange={onURLChange}
          type="text"
          defaultValue={urlValue}
          placeholder="https://"
          onKeyDown={onLinkInputKeyDown}
          autoFocus
          style={styles.urlInput}
        />
        <button style={styles.urlConfirmButton} onMouseDown={confirmLink}>
          저장
        </button>
        <button style={styles.urlCancelButton} onMouseDown={removeLink}>
          취소
        </button>
      </div>
    );
  }

  return (
    <div className="text-component">
      {urlInput}
      <div className={editorStyles.editor} onClick={focusEditor}>
        <Editor
          ref={(editor: any) => (editorRef.current = editor)}
          editorState={editorState}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          blockRendererFn={mediaBlockRenderer}
          plugins={plugins}
          placeholder="내용을 입력하세요..."
          blockStyleFn={getBlockStyle}
        />
        <SideToolbar>
          {(externalProps: any) => (
            <>
              <div>
                <section className="toolbar-menu">
                  <HeadlineOneButton {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <BlockquoteButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  {toggleButtonOptions.map((buttonOption, index) => (
                    <button
                      onMouseOver={() => setBtnHover(buttonOption.action)}
                      onMouseOut={() => setBtnHover(-1)}
                      onMouseDown={(e) =>
                        handleTogggleClick(e, buttonOption.action)
                      }
                      key={index}
                      style={
                        toggleButton[buttonOption.action] === true
                          ? {
                              backgroundColor: 'rgba(222,222,222, 0.7)',
                              padding: '3px 6px 0px',
                              verticalAlign: 'bottom',
                            }
                          : {
                              backgroundColor:
                                buttonOption.action === btnHover
                                  ? 'rgb(241,241,241)'
                                  : '#fbfbfb',
                              color: 'gray',
                              padding: '3px 6px 0px',
                              verticalAlign: 'bottom',
                            }
                      }
                    >
                      {buttonOption.name}
                    </button>
                  ))}
                  {blockButtonOptions.map((buttonOption, index) => (
                    <button
                      onMouseOver={() => setBtnHover(index)}
                      onMouseOut={() => setBtnHover(-1)}
                      onMouseDown={(e) =>
                        handleBlockClick(e, buttonOption.action)
                      }
                      key={index}
                      style={{
                        backgroundColor:
                          index !== 4
                            ? index === btnHover
                              ? 'rgb(241,241,241)'
                              : '#fbfbfb'
                            : '#fbfbfb',
                        color: 'gray',
                        padding: '3px 6px 0px',
                        verticalAlign: 'bottom',
                      }}
                    >
                      {buttonOption.name}
                    </button>
                  ))}
                </section>
              </div>
            </>
          )}
        </SideToolbar>
      </div>
    </div>
  );
};

export const TextEditor = React.memo(TextTest);

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const LinkE = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer" style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    padding: 10,
    width: 255,
    border: '1px solid rgb(244, 244, 244)',
    marginBottom: 10,
  },
  urlConfirmButton: {
    padding: 5,
    backgroundColor: 'rgb(14, 110, 81)',
    color: 'white',
    borderRadius: 4,
  },
  urlCancelButton: {
    padding: 5,
    borderRadius: 4,
  },
  urlInput: {
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    // textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};
