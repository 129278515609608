import React, { useState } from 'react';
import useUser from 'store/modules/user/userHook';
import { IgetTabPannel, StudentsI } from './interfaces';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import styles from 'styles/stylesheets/style.css';
import Accordion from 'components/global/accordion/Accordion';
const cn = classNames.bind(styles);

const TabPannelLesson = ({
  handleMenuToggle,
  toggle,
  btnTitle,
  selectMenuOption,
  courses,
  coursesIsLoading,
  handleCourse,
  chapters,
  chaptersIsLoading,
  handleChapter,
  closeMenuOption,
  manageLesson,
  manageLessonIsLoading,
  lessonComp,
  students,
}: IgetTabPannel): JSX.Element => {
  const { userData } = useUser();
  const [colHover, setColHover] = useState({});

  const histOfProgress = (s, comp) => {
    const arr = [];
    comp?.forEach((item) => {
      let flag = 0;
      manageLesson?.forEach((lesson) => {
        if (
          s.student.id === lesson['user'].id &&
          item.id === lesson.lesson.id
        ) {
          if (lesson['completeLesson'] === 1) {
            arr.push('att');
          } else if (lesson['completeLesson'] === 2) {
            arr.push('unfin');
          } else if (lesson['completeLesson'] === 3) {
            arr.push('fin');
          }
          flag = 1;
        }
      });
      if (!flag) arr.push('abs');
    });

    const percent =
      (arr.filter((item) => item === 'fin').length / arr.length) * 100;

    return (
      <>
        <td>{Number.isInteger(percent) ? percent : percent.toFixed(1)}%</td>
        {arr.map((item, key) => {
          return item === 'fin' ? (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/lesson_status04.png" alt="" />
            </td>
          ) : item === 'unfin' ? (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/lesson_status03.png" alt="" />
            </td>
          ) : item === 'att' ? (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/lesson_status02.png" alt="" />
            </td>
          ) : (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/lesson_status01.png" alt="" />
            </td>
          );
        })}
      </>
    );
  };

  const nums = [1, 2];

  return (
    <div className="tab-target" key={'진도체크'}>
      <div className="tab-selector">
        <div
          className={cn('btn-toggle', toggle && 'open')}
          onClick={handleMenuToggle}
        >
          <p>
            <span>{btnTitle[0] === '코스명' ? '코스명' : btnTitle[0]}</span>
            &gt;
            <span>{btnTitle[1] === '챕터명' ? '챕터명' : btnTitle[1]}</span>
          </p>
          <img
            src="/assets/icons/ic_arrow_down_fill_24.svg"
            className="icon"
            alt=""
          />
        </div>
        <form className="accordion-wrap" onSubmit={selectMenuOption}>
          <Accordion
            type={'lesson'}
            courses={courses}
            handleCourse={handleCourse}
            coursesIsLoading={coursesIsLoading}
            chaptersIsLoading={chaptersIsLoading}
            chapters={chapters}
            handleChapter={handleChapter}
          />
          <div className="accordion-footer">
            <button className="btn-fill" type="submit">
              선택
            </button>
            <button
              className="btn-line"
              type="button"
              onClick={() => closeMenuOption()}
            >
              취소
            </button>
          </div>
        </form>
      </div>
      {/* * : 진도체크 Table */}
      <div className="tab-content">
        <table>
          <thead>
            {manageLessonIsLoading !== 1 && (
              <tr>
                <th style={{ width: '160px' }}>이름</th>
                <th style={{ width: '100px' }}>진도율</th>
                {/* 퀴즈가 아예 없거나 초깃값인 경우 */}
                {(lessonComp?.length === 0 && manageLesson?.length === 0) ||
                manageLesson === undefined ? (
                  <>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                  </>
                ) : (
                  <>
                    {lessonComp?.map((_, key: number) => (
                      <th key={key}>{key + 1}</th>
                    ))}
                  </>
                )}
              </tr>
            )}
            {manageLessonIsLoading === 1 && (
              <tr>
                <th style={{ width: '160px' }}>이름</th>
                <th style={{ width: '100px' }}>진도율</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
              </tr>
            )}
          </thead>
          <tbody>
            <>
              {(manageLessonIsLoading !== 1 &&
                manageLesson &&
                manageLesson[0]?.id === -1) ||
              manageLesson === undefined ||
              manageLesson?.length === 0 ? (
                <>
                  {lessonComp === undefined || lessonComp?.length === 0 ? (
                    <tr>
                      <td>{userData.userName}</td>
                      <td>0%</td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {manageLesson?.length === 0 ? (
                        <>
                          {students?.map((s: StudentsI, sKey: number) => (
                            <tr key={sKey}>
                              <td>{s.student['userName']}</td>
                              <td>0%</td>
                              {lessonComp?.map((_) => (
                                <td>
                                  <img
                                    src="/assets/images/ic_answer_null_fill_lt.png"
                                    alt=""
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </>
                      ) : manageLesson === undefined ? (
                        <tr>
                          <td>{userData.userName}</td>
                          <td>0%</td>
                          <td>
                            <img
                              src="/assets/images/ic_answer_null_fill_lt.png"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/images/ic_answer_null_fill_lt.png"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/images/ic_answer_null_fill_lt.png"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/images/ic_answer_null_fill_lt.png"
                              alt=""
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{userData.userName}</td>
                          <td>0%</td>
                          {lessonComp?.map((_) => (
                            <td>
                              <img
                                src="/assets/images/ic_answer_null_fill_lt.png"
                                alt=""
                              />
                            </td>
                          ))}
                        </tr>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {students.map((s: StudentsI, sKey: number) => (
                    <tr key={sKey}>
                      <td>{s.student['userName']}</td>
                      {histOfProgress(s, lessonComp)}
                    </tr>
                  ))}
                </>
              )}
            </>

            {manageLessonIsLoading === 1 &&
              nums.map((_, key: number) => (
                <tr key={key}>
                  <td>
                    <Skeleton width={100} height={25} />
                  </td>
                  <td>
                    <Skeleton width={45} height={25} />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={25}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={25}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={25}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={25}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabPannelLesson;
