import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { fetchUsers, IApplier, fetchCustomUsers } from './users';

interface IsetUsers {
  users: IApplier[] | undefined;
  setUsers: () => void;
  setCustomUsers: (userAuth?: number, userName?: string) => void;
  usersIsLoading: boolean;
}

export default function useUsers(): IsetUsers {
  const users = useSelector((state: RootState) => state['root'].users?.users);
  const usersIsLoading = useSelector(
    (state: RootState) => state['root'].appliers.loading
  );
  const dispatch = useDispatch();
  const setUsers = useCallback(() => {
    dispatch(fetchUsers());
  }, [dispatch]);
  const setCustomUsers = useCallback(
    (userAuth, userName) => {
      dispatch(fetchCustomUsers(userAuth, userName));
    },
    [dispatch]
  );
  return {
    users,
    setUsers,
    setCustomUsers,
    usersIsLoading,
  };
}
