import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { CommonLayout, FooterLayout } from 'components/global';

const SkeletonStudentProgressPage = (): JSX.Element => {
  return (
    <div>
      <CommonLayout />
      <div className="body-inner manage-inner">
        {/* * : progressBanner */}
        <div className="banner-container student-banner">
          <div className="skeleton-wrap class-title">
            <Skeleton />
            {/* ex) 1반, OOO반 */}
          </div>
          <div className="skeleton-wrap class-bookmark">
            <Skeleton />
            {/* ex)  "첫 레슨 수업하기" or "북마크 레슨명" */}
          </div>
        </div>

        <div className="container tab-container">
          <div className="skeleton-wrap class-table">
            <Skeleton />
          </div>
        </div>
      </div>
      <FooterLayout />
    </div>
  );
};

export default SkeletonStudentProgressPage;
