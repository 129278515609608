import { IGetProgressResponse } from 'hooks/main/progress/getProgress/interfaces';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '..';
import { ILesson } from '../../../components/main/table/interfaces';
import {
  loginAction,
  logoutAction,
  setClassroomIdAction,
  setHideDataAction,
  setClassroomsAction,
  setUserRoleAction,
  setUserAuthAction,
} from './user';

interface IsetData {
  isLoggedIn: boolean;
  userData: IUserData | null;
  login: (data: IUserData) => void;
  logout: () => void;
  setClassroomId: (data: number) => void;
  setHideData: (data: number[]) => void;
  setClassrooms: (data: IClassrooms[]) => void;
  setUserRole: (data: number) => void;
  setUserAuth: (data: number) => void;
}
export interface IUserData {
  id: number;
  userName: string;
  userEmail: string;
  userRole: number;
  userAuth: number;
  userPurchase: number;
  password: string;
  provider: null;
  providerId: null;
  visitCount: number;
  recentVisit: Date;
  classroomId: number;
  createdAt: Date;
  updatedAt: Date;
  classrooms: IGetProgressResponse[];
  progresses: [];
  hideData: number[];
  students: IStudents[];
}

export interface IStudents {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  classroom: IClassrooms;
}

export interface IClassrooms {
  id: number;
  classroomName: string;
  classroomCode: string;
  createdAt: Date;
  updatedAt: Date;
  students: [];
  progresses: [];
  lesson: ILesson;
  quizs: [];
  hideData: [];
  lessonName: string;
  lessonId: string;
  students_count: string;
  user: IUserData;
  isOpen: boolean;
}

export default function useUser(): IsetData {
  const isLoggedIn = useSelector(
    (state: RootState) => state['root'].user.isLoggedIn
  );
  const userData = useSelector(
    (state: RootState) => state['root'].user.userData
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const login = useCallback(
    (data: IUserData) => {
      dispatch(loginAction(data));
      history.push('/');
    },
    [dispatch, history]
  );
  const logout = useCallback(() => {
    dispatch(logoutAction());
    history.push('/');
  }, [dispatch, history]);
  const setClassroomId = useCallback(
    (data: number) => {
      dispatch(setClassroomIdAction(data));
    },
    [dispatch]
  );
  const setHideData = useCallback(
    (data: number[]) => {
      dispatch(setHideDataAction(data));
    },
    [dispatch]
  );
  const setClassrooms = useCallback(
    (data: any) => {
      dispatch(setClassroomsAction(data));
    },
    [dispatch]
  );
  const setUserRole = useCallback(
    (data: number) => {
      dispatch(setUserRoleAction(data));
    },
    [dispatch]
  );
  const setUserAuth = useCallback(
    (data: number) => {
      dispatch(setUserAuthAction(data));
    },
    [dispatch]
  );
  return {
    isLoggedIn,
    userData,
    login,
    logout,
    setClassroomId,
    setHideData,
    setClassrooms,
    setUserRole,
    setUserAuth,
  };
}
