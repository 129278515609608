import classNames from 'classnames';
import { MouseEventHandler, useState } from 'react';
import styles from 'styles/stylesheets/style.css';

const cn = classNames.bind(styles);
const nameUserPurchase = (userPurchase) =>
  userPurchase == 1 ? '무료' : userPurchase == 2 ? '유료' : '미신청';

const CellSelect = ({
  idx,
  content,
  cln = '',
  userId,
  onChange, // 22.01.04 DAN 수정
  appliersIsLoading,
}: {
  idx?: number;
  content?: number;
  cln?: string;
  userId: number;
  onChange: () => void;
  // 22.01.04 DAN 수정
  appliersIsLoading: boolean;
}): JSX.Element => {
  const [purchase, setPurchase] = useState(content); // 내부 render링을 하지 못해서 변경

  const handleChangePurchase = async (e, userId) => {
    const fetchPurchase = async () => {
      const response = fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html, application/json; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
          body: JSON.stringify({
            userPurchase: Number(e.target.value),
          }),
        }
      )
        .then((res) => {
          // 여기 onChange는 DataTable에서 받은 함수임.
          onChange();
          return res.json();
        })
        .then((json) => {
          const { userName, beforePurchase, afterPurchase } = json;
          setPurchase(afterPurchase);

          const namedPurchaseBefore = nameUserPurchase(beforePurchase);
          const namedPurchaseAfter = nameUserPurchase(afterPurchase);

          alert(
            `'${userName}'님의 회원등급이 '${namedPurchaseAfter}'로 변경되었습니다. \n (${namedPurchaseBefore} 👉 ${namedPurchaseAfter})`
          );
        });
    };

    await fetchPurchase();
  };

  return (
    <td
      className={cn('Cell', cln !== '' && cln)}
      style={{ position: 'relative' }}
    >
      <div className="Cell-fixed" style={{ width: '100%' }}>
        <select
          onChange={async (e) => {
            await handleChangePurchase(e, userId);
          }}
          value={purchase}
        >
          <option value={0}>미신청</option>
          <option value={1}>무료</option>
          <option value={2}>유료</option>
        </select>
      </div>
      {appliersIsLoading && <div className="loader" />}
    </td>
  );
};

export default CellSelect;
