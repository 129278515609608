import { useEffect, useState } from 'react';
import { IsetGetStudentsData } from './interfaces';
import axios from 'axios';
import { IStudents, IUserData } from 'store/modules/user/userHook';
import { IApplier } from 'store/modules/appliers/appliers';
import useStudents from 'store/modules/students/studentsHook';
import useUser from 'store/modules/user/userHook';

const useGetStudents = (
  userInfo: IUserData | IApplier,
  classroomId?: number
): IsetGetStudentsData => {
  const [studentsResp, setStudentsResp] = useState<IStudents[]>(null);
  const [studentsRespError, setStudentsRespError] = useState<string>('');
  const [studentsRespIsLoading, setStudentsRespIsLoading] =
    useState<boolean>(true);
  const { userData } = useUser();
  const { setStudents } = useStudents();

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null && userInfo !== undefined) {
        if (classroomId === undefined && userData.userRole !== 3) {
          try {
            axios
              .get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/members?userId=${userInfo.id}`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    accept: 'text/html; charset=utf-8',
                    authorization: ('Bearer ' +
                      localStorage.getItem('token')) as string,
                  },
                }
              )
              .then((response) => {
                if (response.status === 200) {
                  setStudentsResp(response.data);
                  setStudents(response.data);
                } else {
                  alert(response.statusText);
                }
              })
              .finally(() => {
                setStudentsRespIsLoading(false);
              });
          } catch (err) {
            alert(err);
            setStudentsRespError(err);
          }
        } else {
          if (userData.userRole !== 3) {
            try {
              axios
                .get(
                  `${process.env.REACT_APP_SERVER_BASE_URL}/members?classroomId=${classroomId}`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      accept: 'text/html; charset=utf-8',
                      authorization: ('Bearer ' +
                        localStorage.getItem('token')) as string,
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    setStudentsResp(response.data);
                  } else {
                    alert(response.statusText);
                  }
                })
                .finally(() => {
                  setStudentsRespIsLoading(false);
                });
            } catch (err) {
              alert(err);
              setStudentsRespError(err);
            }
          }
        }
      }
    };
    fetchData();
  }, [userInfo]);

  return { studentsResp, studentsRespError, studentsRespIsLoading };
};

export default useGetStudents;
