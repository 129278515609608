// requestAuthPage
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import NotFoundErrorPage from 'pages/errors/notFoundErrorPage';
import useUser from 'store/modules/user/userHook';
import { useGetRequestAuth } from 'hooks/main';

interface IState {
  role?: string;
}

const RequestAuth = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const { requestAuth } = useGetRequestAuth();
  const { userData, setUserRole, setUserAuth } = useUser();

  // rolePage에서 넘겨받은 state로 role 지정
  const [role, setRole] = useState('');
  useEffect(() => {
    if ((location.state as IState)?.role !== undefined) {
      setRole((location.state as IState).role);
    }
  }, [location.state]);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [groupName, setGroupName] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('');
  const [path, setPath] = useState('');
  const [questions, setQuestions] = useState('');
  const [disabled, setDisabled] = useState(true);

  const handlePhoneNumberValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value.replace(/[^0-9]/g, '');
    if (str.length < 3) {
      setPhoneNumber(str);
    } else if (str.length < 4) {
      setPhoneNumber(str.substr(0, 2) + '-' + str.substr(2));
    } else if (str.length < 7) {
      setPhoneNumber(str.substr(0, 3) + '-' + str.substr(3));
    } else if (str.length < 11) {
      setPhoneNumber(
        str.substr(0, 3) + '-' + str.substr(3, 3) + '-' + str.substr(6)
      );
    } else if (str.length < 12) {
      setPhoneNumber(
        str.substr(0, 3) + '-' + str.substr(3, 4) + '-' + str.substr(7)
      );
    }
  };
  const handleGroupNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };
  const handleNumberOfPeopleValue = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNumberOfPeople(e.target.value);
  };
  const handlePathValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPath(e.target.value);
  };
  const handleQuestionsValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestions(e.target.value);
  };
  const handleClick = () => {
    if (!disabled) {
      requestAuth(
        role,
        2, // 허용값 변경
        phoneNumber,
        groupName,
        numberOfPeople,
        path,
        questions,
        userData.id
      );
      if (role === '선생님' || role === '학교/기관 및 기타') {
        setUserRole(2);
      } else if (role === '학생') {
        setUserRole(1);
      }
      setUserAuth(2);
      history.push({
        pathname: '/',
        state: { from: 'requestAuthPage' },
      });
    }
  };

  useEffect(() => {
    if (
      role !== '' &&
      phoneNumber !== '' &&
      groupName !== '' &&
      numberOfPeople !== '' &&
      path !== ''
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [groupName, numberOfPeople, path, phoneNumber, role]);

  if ((location.state as IState)?.role === undefined) {
    return <Route render={() => <NotFoundErrorPage />} />;
  }

  return (
    <>
      <div className="join-body">
        <div className="onbook-container">
          <div className="onbook-card">
            <div className="gradient">
              <div className="card-container">
                <div className="card-header">
                  <div className="card-title">AI·코딩 OnBook</div>
                  <div className="card-subtitle">
                    AI·코딩 온라인교과서와
                    <br />
                    학습관리를 한 곳에서
                  </div>
                </div>
                <img src="/assets/images/AI_Onbook_login.png" alt="" />
              </div>
            </div>
          </div>
          <div className="onbook-info">
            <div className="info-container">
              <div className="info-content">
                <div className="info-message">
                  <span>무료체험 및 이용</span>
                  을 원하신다면
                  <br />
                  정보를 입력해주세요!
                </div>
                <div className="move-info">
                  <div className="des-input-set">
                    <p>담당역할*</p>
                    <input defaultValue={role} readOnly />
                  </div>
                  <div className="des-input-set">
                    <p>전화번호*</p>
                    <input
                      value={phoneNumber}
                      placeholder="010-0000-0000"
                      onChange={handlePhoneNumberValue}
                    />
                  </div>
                  <div className="des-input-set">
                    <p>그룹명*</p>
                    <input
                      value={groupName}
                      placeholder="학교, 기관, 단체"
                      onChange={handleGroupNameValue}
                    />
                  </div>
                  <div className="des-input-set">
                    <p>예상 사용 인원*</p>
                    <select
                      className={numberOfPeople === '' ? 'not-select' : ''}
                      value={numberOfPeople}
                      onChange={handleNumberOfPeopleValue}
                    >
                      <option value="" hidden>
                        몇명 정도가 이용하실 생각인가요?
                      </option>
                      <option value="1~50">1~50</option>
                      <option value="51~100">51~100</option>
                      <option value="101~200">101~200</option>
                      <option value="201~300">201~300</option>
                      <option value="301~500">301~500</option>
                      <option value="501~1,000">501~1,000</option>
                      <option value="1,001이상">1,001이상</option>
                      <option value="사용문의 아님">사용문의 아님</option>
                    </select>
                  </div>
                </div>
                <div className="set">
                  <p>Coding-X를 어떻게 알게 되셨나요?*</p>
                  <select
                    className={path === '' ? 'not-select' : ''}
                    value={path}
                    onChange={handlePathValue}
                  >
                    <option value="" hidden>
                      알게 된 경로를 선택해주세요
                    </option>
                    <option value="1.SNS(유튜브,페이스북,인스타 등)">
                      1.SNS(유튜브,페이스북,인스타 등)
                    </option>
                    <option value="2.온라인 광고">2.온라인 광고</option>
                    <option value="3.오프라인 광고">3.오프라인 광고</option>
                    <option value="4.서비스 관계자 추천">
                      4.서비스 관계자 추천
                    </option>
                    <option value="5.지인 추천">5.지인 추천</option>
                    <option value="6.기타">6.기타</option>
                  </select>
                </div>
                <div className="set">
                  <p>용도 및 문의사항</p>
                  <textarea
                    value={questions}
                    placeholder="200자 이하 작성 가능합니다"
                    maxLength={200}
                    onChange={handleQuestionsValue}
                  />
                </div>
                <button disabled={disabled} onClick={handleClick}>
                  요청하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestAuth;
