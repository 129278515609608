import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  PasswordModal,
  SkeletonMemberList,
  SkeletonStudentProgressPage,
  TabPannel,
  TabPannelLesson,
} from 'components/main';
import { CommonLayout, FooterLayout, ProgressBanner } from 'components/global';
import { useExcelInviteModal } from 'hooks/global';
import {
  useChapterOption,
  useCourseOption,
  useGetProgress,
  useLessonOption,
  useManageLesson,
  useManageLogs,
  useManageQuiz,
} from 'hooks/main';
import { Ids } from 'hooks/main/progress/getProgress/interfaces';
import getConfirm from 'services/common/getConfirm';
import { getToggleInvite } from 'services/progress/progress';
import useStudents from 'store/modules/students/studentsHook';
import useUser from 'store/modules/user/userHook';
import moment from 'moment';
import classNames from 'classnames';
import styles from 'styles/stylesheets/style.css';
const cn = classNames.bind(styles);

// * : 멤버관리 탭 - 최근 방문 날짜
export const formDate = (date: Date | string): JSX.Element => {
  if (date === undefined) {
    return <> - </>;
  }
  const data = moment(date);
  const y = String(data.year());
  const m = fillZero(String(data.month() + 1));
  const d = fillZero(String(data.date()));
  return (
    <>
      {y}.{m}.{d}
    </>
  );
};
export function fillZero(str: string): string {
  return str.length >= 2 ? str : new Array(2 - str.length + 1).join('0') + str; //남는 길이만큼 0으로 채움
}

const StudentProgressPage = ({ match }): JSX.Element => {
  const { classroomId } = useParams<Ids>();
  const { userData } = useUser();
  const history = useHistory();
  // * : 선생님이 자신이 만든 교실이 아닌 경우 랜딩페이지로 리다이렉트(접근 제한)
  const [classroomsNumIsLoading, setClassroomsNumIsLoading] = useState(false);
  const loadClassroomsNum = async (userId): Promise<void> => {
    setClassroomsNumIsLoading(true);
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms?tutorId=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const json = await r.json();
    if (r.status === 200) {
      if (!json.some((v, i) => v.id === Number(match.params.classroomId))) {
        setClassroomsNumIsLoading(false);
        history.push('/');
      } else {
        setClassroomsNumIsLoading(false);
      }
    } else {
      alert(json.message);
    }
  };
  useEffect(() => {
    if (userData.userRole !== 3) loadClassroomsNum(userData.id);
  }, []);

  // & : 엑셀 초대 기능 모달
  const {
    openModal: openExcelInviteModal,
    renderModal: renderExcelInviteModal,
  } = useExcelInviteModal();

  // * : students(멤버 관리 테이블 데이터)
  const { students, setStudents, studentsIsLoading } = useStudents();
  useEffect(() => {
    setStudents(Number(classroomId));
  }, [classroomId, setStudents]);
  // * : 초대 코드 관련
  const [copyText, setCopyText] = useState(false);
  const handleCopyText = useCallback(() => {
    setCopyText(!copyText);
  }, [copyText]);

  const [selectInvCode, setSelectInvCode] = useState(false);
  const [approveInvCode, setApproveInvCode] = useState(false);
  const { fetchProgressData } = useGetProgress({
    classroomId,
    setApproveInvCode,
  });
  const { toggleInvite } = getToggleInvite(fetchProgressData);
  const handleApproveInvCode = useCallback(() => {
    setApproveInvCode(!approveInvCode);
    toggleInvite(Number(classroomId), approveInvCode);
  }, [approveInvCode, toggleInvite, classroomId]);
  // 현재 교실이 열려있는지 확인하기 위한 데이터
  const { progressResp } = useGetProgress({
    classroomId,
    setApproveInvCode,
  });
  useEffect(() => {
    progressResp && setApproveInvCode(progressResp['isOpen']);
  }, [setApproveInvCode, progressResp]);
  // 초대코드를 클립보드로 복사
  const copy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(progressResp['classroomCode']);
    } catch (error) {
      console.error(error);
    }
  }, [progressResp]);

  const handleSelectInvCode = useCallback(() => {
    setSelectInvCode(!selectInvCode);
    setTimeout(() => {
      setSelectInvCode((selectInvCode) => !selectInvCode);
    }, 900);
    copy();
  }, [copy, selectInvCode]);

  // * : Select menuOptions
  const [courseOptName, setCourseOptName] = useState<string>('');
  const [chapterOptName, setChapterOptName] = useState<string>('');
  const [lessonOptName, setLessonOptName] = useState<string>('');
  const [lessonOption, setLessonOption] = useState<number>();
  const { getManageQuiz, manageQuiz, manageQuizIsLoading } = useManageQuiz();
  const [courseOptNameLsTab, setCourseOptNameLsTab] = useState<string>('');
  const [chapterOptNameLsTab, setChapterOptNameLsTab] = useState<string>('');
  const { getManageLesson, manageLesson, manageLessonIsLoading } =
    useManageLesson();

  const [userEdit, setUserEdit] = useState({ isOpen: false, user: null });

  const selectMenuOption = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (check.includes(false)) {
      return;
    } else {
      getManageQuiz(12, Number(classroomId), lessonOption);
      setBtnTitle(() => [courseOptName, chapterOptName, lessonOptName]);
      setToggle(false);
    }
  };

  const selectMenuOptionLsTab = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkLsTab.includes(false)) {
      return;
    } else {
      getManageLesson(Number(classroomId), chId, lessonOption);
      setBtnTitleLsTab(() => [courseOptNameLsTab, chapterOptNameLsTab]);
      setToggle(false);
    }
  };

  const closeMenuOption = useCallback(() => {
    setToggle(false);
  }, []);
  // * : Tab & Table
  const TabEl = ['연습문제', '진도체크', '멤버 ' + students.length + '명'];
  const [activeTab, setActiveTab] = useState(TabEl[0]);
  const { courses, coursesIsLoading } = useCourseOption();
  const {
    getChapterOption,
    chapters,
    chaptersIsLoading,
    setChaptersIsLoading,
  } = useChapterOption();
  const { getLessonOption, lessons, lessonsIsLoading, setLessonsIsLoading } =
    useLessonOption();
  const clickTabItem = useCallback(
    (item) => {
      closeMenuOption();
      setActiveTab(item);
      // Tab 변경할 때마다 Options 초기화
      getChapterOption(-1);
      getLessonOption(-1);
    },
    [closeMenuOption, setActiveTab, getChapterOption, getLessonOption]
  );

  const [toggle, setToggle] = useState(false);
  const handleMenuToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  const [btnTitle, setBtnTitle] = useState(['코스명', '챕터명', '레슨명']);
  const [btnTitleLsTab, setBtnTitleLsTab] = useState(['코스명', '챕터명']);
  const [check, setCheck] = useState([false, false, false]);
  const [checkLsTab, setCheckLsTab] = useState([false, false]);
  const [chId, setChId] = useState<number>();
  // * : 코스명 변경
  const handleCourse = (tab = 'quiz', id: number, name: string) => {
    if (tab === 'quiz') {
      setCheck(() => [true, false, false]);
      setChaptersIsLoading(1);
      getChapterOption(id);

      getLessonOption(-1);
      setCourseOptName(name);
      setChapterOptName(name);
    } else {
      setCheckLsTab(() => [true, false]);
      setChaptersIsLoading(1);
      getChapterOption(id);

      setCourseOptNameLsTab(name);
      setChapterOptNameLsTab(name);
    }
  };
  // * : 챕터명 변경
  const handleChapter = (tab = 'quiz', id: number, name: string) => {
    if (tab === 'quiz') {
      setCheck(() =>
        check[0] === true ? [true, true, false] : [false, true, false]
      );
      setLessonsIsLoading(1);
      getLessonOption(id);
      setChapterOptName(name);
    } else {
      setCheckLsTab(() =>
        checkLsTab[0] === true ? [true, true] : [false, true]
      );
      setChId(id);
      getLessonComp(id);
      setChapterOptNameLsTab(name);
    }
  };
  // * : 레슨명 변경
  const handleLesson = (id: number, name: string) => {
    setCheck(() =>
      check[0] === true && check[1] === true
        ? [true, true, true]
        : [false, true, true]
    );
    setLessonOption(id);
    setLessonOptName(name);
  };
  useEffect(() => {
    getTestComponents();
  }, [lessonOptName]);

  // @ : 퀴즈 컴포넌트들의 배열 요청 << handleLesson(lessonOption)
  const [testComp, setTestComp] = useState();
  const getTestComponents = async () => {
    if (lessonOption !== undefined) {
      const r = await fetch(
        `${
          process.env.REACT_APP_SERVER_BASE_URL
        }/components/?lessonId=${lessonOption}&isQuiz=${true}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const res = await r.json();

      if (r.status === 200) {
        setTestComp(res);
      }
    }
  };
  // * : 멤버관리 탭 - 내보내기 기능
  const deleteConfirm = async (studentId: number) => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/members/${studentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
    }).then((res) => {
      res.text();
      setStudents(Number(classroomId));
    }); // or res.json()
  };
  const cancelConfirm = () => console.log('');
  const confirmDelete = getConfirm(
    '를 정말로 내보내시겠습니까?',
    deleteConfirm,
    cancelConfirm
  );

  // // @ : 레슨 컴포넌트들의 배열 요청
  const [lsComp, setLsComp] = useState([
    {
      chapter: null,
      createdAt: null,
      id: -1,
      lessonName: '',
      thumbnailUrl: '',
      updatedAt: null,
    },
  ]);
  const getLessonComp = async (id: number) => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/lessons?chapterId=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
          authorization: ('Bearer ' + localStorage.getItem('token')) as string,
        },
      }
    );
    const json = await r.json();
    setLsComp(json);
  };

  // * : 멤버관리 탭의 최근방문, 방문횟수
  const [manageLogs, getManageLogs] = useManageLogs();

  useEffect(() => {
    getManageLogs(Number(classroomId));
  }, []);

  const getVisitHist = (id) => {
    const rv = [];
    manageLogs.forEach((item) => {
      item.user.id === id && rv.push(item.createdAt);
    });
    const rvLen = rv.length;
    const temp = rv[rv.length - 1];
    return (
      <>
        <td>{formDate(temp)}</td>
        <td>{rvLen}회</td>
      </>
    );
  };

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  // console.log(students);

  // 멤버 관리 탭의 멤버 목록
  const MemberList = () => {
    return (
      <>
        {students?.map((student, key) => (
          <tr key={key}>
            <td>{student.student.userName}</td>
            <td>{student.student.userEmail}</td>
            {/* <td>211010</td> */}
            {getVisitHist(student.student.id)}
            <td
              style={{
                textAlign: 'right',
              }}
            >
              {key !== 0 && (
                <button
                  className="option-button"
                  onClick={() =>
                    setUserEdit({
                      ...userEdit,
                      isOpen: true,
                      user: student.student,
                    })
                  }
                >
                  비밀번호 재설정
                </button>
              )}
            </td>
            <td className="align-right">
              {key !== 0 && (
                <button
                  onClick={() =>
                    confirmDelete(student.student.id, student.student.userName)
                  }
                >
                  내보내기
                </button>
              )}
            </td>
          </tr>
        ))}
      </>
    );
  };

  const hidden = {
    display: 'none',
  };
  return (
    <>
      {!classroomsNumIsLoading && progressResp ? (
        <>
          <div>
            <CommonLayout />
            {userEdit.isOpen && (
              <PasswordModal user={userEdit.user} setUserEdit={setUserEdit} />
            )}
            <div className="body-inner manage-inner">
              {progressResp && (
                <ProgressBanner
                  classroomName={students[0].classroom.classroomName}
                  lessonId={
                    progressResp['bookmarks'][0]
                      ? progressResp['bookmarks'][0].lesson['id']
                      : undefined
                  }
                  lessonName={
                    progressResp['bookmarks'][0]
                      ? progressResp['bookmarks'][0].lesson['lessonName']
                      : undefined
                  }
                  classroomId={Number(classroomId)}
                  fetchProgressData={fetchProgressData}
                />
              )}
              <div className="container tab-container">
                <div className="tab-container-header">
                  <ul className="tab-item-wrap">
                    {TabEl.map((item) => (
                      <li
                        key={item}
                        className={activeTab === item ? 'active' : ''}
                        onClick={() => clickTabItem(item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>

                  <div className="toggle-btn-wrap tooltip-box">
                    {activeTab === '진도체크' && (
                      <>
                        <p>
                          진도율 가이드
                          <img
                            src="/assets/icons/ic_info_normal_line.png"
                            alt=""
                          />
                        </p>
                        <div className="tooltip-item">
                          <img src="/assets/images/tool_box.png" alt="" />
                        </div>
                      </>
                    )}
                    <>
                      {activeTab.substr(0, 2) === '멤버' && (
                        <>
                          <button
                            style={hidden}
                            className={'btn-excel-invite'}
                            onClick={() => {
                              openExcelInviteModal();
                            }}
                          >
                            엑셀 초대
                          </button>
                          <button
                            className={cn(
                              'btn-code',
                              selectInvCode && 'btn-copy'
                            )}
                            onClick={() => handleSelectInvCode()}
                            onMouseOver={() => {
                              handleCopyText();
                            }}
                            onMouseOut={() => {
                              handleCopyText();
                            }}
                          >
                            {selectInvCode ? (
                              <>
                                복사 되었습니다
                                <img
                                  src="/assets/icons/ic_check_normal_line_24.svg"
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                {copyText ? '코드복사' : '초대코드'} '
                                {progressResp && progressResp['classroomCode']}'
                              </>
                            )}
                          </button>
                          <button
                            className={cn(
                              'btn-invite',
                              !approveInvCode && 'btn-disabled'
                            )}
                            onClick={() => handleApproveInvCode()}
                          >
                            <span className="invite-content">
                              {approveInvCode
                                ? '초대코드로 입장 가능'
                                : '초대코드로 입장 불가'}
                            </span>
                            <span className="white-circle"></span>
                          </button>
                        </>
                      )}
                    </>
                  </div>
                </div>

                <div className="tab-container-body">
                  {activeTab === '연습문제' && (
                    <TabPannel
                      handleMenuToggle={handleMenuToggle}
                      toggle={toggle}
                      btnTitle={btnTitle}
                      selectMenuOption={selectMenuOption}
                      courses={courses}
                      coursesIsLoading={coursesIsLoading}
                      handleCourse={handleCourse}
                      chapters={chapters}
                      chaptersIsLoading={chaptersIsLoading}
                      handleChapter={handleChapter}
                      lessons={lessons}
                      lessonsIsLoading={lessonsIsLoading}
                      handleLesson={handleLesson}
                      closeMenuOption={closeMenuOption}
                      manageQuiz={manageQuiz}
                      manageQuizIsLoading={manageQuizIsLoading}
                      testComp={testComp}
                      students={students}
                    />
                  )}
                  {activeTab === '진도체크' && (
                    <TabPannelLesson
                      handleMenuToggle={handleMenuToggle}
                      toggle={toggle}
                      btnTitle={btnTitleLsTab}
                      selectMenuOption={selectMenuOptionLsTab}
                      courses={courses}
                      coursesIsLoading={coursesIsLoading}
                      handleCourse={handleCourse}
                      chapters={chapters}
                      chaptersIsLoading={chaptersIsLoading}
                      handleChapter={handleChapter}
                      closeMenuOption={closeMenuOption}
                      manageLesson={manageLesson}
                      manageLessonIsLoading={manageLessonIsLoading}
                      lessonComp={lsComp}
                      testComp={testComp}
                      students={students}
                    />
                  )}
                  <>
                    {activeTab.substr(0, 2) === '멤버' && (
                      <div className="tab-target">
                        <div className="tab-content">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ width: '160px' }}>이름</th>
                                <th style={{ width: '160px' }}>아이디</th>
                                {/* <th style={{ width: '100px' }}>학번</th> */}
                                <th style={{ width: '160px' }}>최근 방문</th>
                                <th style={{ width: '100px' }}>방문 횟수</th>
                                <th />
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <MemberList />
                              {studentsIsLoading && <SkeletonMemberList />}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>

                  <>
                    {students.length === 1 && (
                      <>
                        <div className="invite-wrap">
                          <p>초대 코드를 공유해서 학생을 초대해보세요!</p>
                          <button
                            className={cn(
                              'btn-code',
                              selectInvCode && 'btn-copy'
                            )}
                            onClick={() => handleSelectInvCode()}
                            onMouseOver={() => {
                              handleCopyText();
                            }}
                            onMouseOut={() => {
                              handleCopyText();
                            }}
                          >
                            {selectInvCode ? (
                              <>
                                복사 되었습니다
                                <img
                                  src="/assets/icons/ic_check_normal_line_24.svg"
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                {copyText ? '코드복사' : '초대코드'} '
                                {progressResp && progressResp['classroomCode']}'
                              </>
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                </div>
                {renderExcelInviteModal(match.params.classroomId)}
              </div>
            </div>
            <FooterLayout />
          </div>
        </>
      ) : (
        <SkeletonStudentProgressPage />
      )}
    </>
  );
};

export default StudentProgressPage;
