import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ClassroomTable, CommonLayout, FooterLayout } from 'components/global';
import { useCreateClassroom } from 'hooks/main/classroom';
import { useModal } from 'hooks/global';
import { useGetClassrooms } from 'hooks/main';
import useUser from 'store/modules/user/userHook';
import Skeleton from 'react-loading-skeleton';

const ClassroomsPage = (): JSX.Element => {
  const { courseId } = useParams<{ courseId: string }>();
  const { classroomRespIsLoading } = useGetClassrooms();
  const { createClassroom } = useCreateClassroom();
  const [openModal, renderModal] = useModal(
    createClassroom,
    '교실 추가하기',
    '교실이름 (16자 이하)',
    '예시) XX 고등학교 1학년 3반 등 알아보기 쉬운 이름을 작성해주세요.',
    Number(courseId)
  ); // 완료함수, header, label, placeholder, id

  const { isLoggedIn, userData } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (history.location.state !== undefined && isLoggedIn) {
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  return (
    <>
      <div>
        <CommonLayout />
        <div className="body-inner manage-inner">
          <div className="title-container">
            <h1>교실 관리</h1>
            <button className="btn-fill" type="button" onClick={openModal}>
              교실 추가하기
            </button>
            <p>교실마다 멤버를 별도로 관리할 수 있어요</p>
          </div>
          {userData.classrooms?.length !== 0 && (
            <>
              {isLoggedIn && (
                <div className="list-container">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '150px' }}>교실이름</th>
                        <th>진도</th>
                        <th style={{ width: '120px' }}>멤버수</th>
                        <th style={{ width: '120px' }}>멤버관리</th>
                        <th style={{ width: '150px' }}>초대코드</th>
                      </tr>
                    </thead>
                    {!classroomRespIsLoading && (
                      <ClassroomTable classrooms={userData.classrooms} />
                    )}
                  </table>
                  {classroomRespIsLoading && (
                    <>
                      <Skeleton height={20} style={{ marginTop: '15px' }} />
                      <Skeleton height={20} style={{ marginTop: '15px' }} />
                      <Skeleton height={20} style={{ marginTop: '15px' }} />
                      <Skeleton height={20} style={{ marginTop: '15px' }} />
                      <Skeleton height={20} style={{ marginTop: '15px' }} />
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {/* 교실 없는경우 */}
          <>
            {userData.classrooms?.length === 0 && (
              <>
                {!classroomRespIsLoading && isLoggedIn && (
                  <div className="list-container none-list">
                    <img src="/assets/images/none_list.png" alt="" />
                    <p>교실이 없어요</p>
                  </div>
                )}
                {classroomRespIsLoading && isLoggedIn && (
                  <div className="list-container none-list">
                    <Skeleton height={245} />
                  </div>
                )}
              </>
            )}
          </>
        </div>
        {renderModal()}
        <FooterLayout />
      </div>
    </>
  );
};

export default ClassroomsPage;
