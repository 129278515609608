import React, { useState } from 'react';
import { useHistory } from 'react-router';
import useUser from '../../../store/modules/user/userHook';
import {
  IgetHandleLogin,
  IsetHandleLogin,
  IgetUseHandleLogin,
} from './interfaces';

const checkEmail = (
  email: string,
  setEmailErr: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    setEmailErr(true);
    return false;
  } else {
    setEmailErr(false);
    return true;
  }
};

const checkPassword = (
  password: string,
  setPwErr: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (password.length < 8) {
    setPwErr(true);
    return false;
  } else {
    setPwErr(false);
    return true;
  }
};

const handleLogin = async ({
  email,
  password,
  setLoginIsLoading,
  history,
  login,
}: IgetHandleLogin): Promise<void> => {
  const r = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'text/html; charset=utf-8',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });
  const json = await r.json();
  const status = r.status;

  setLoginIsLoading(0);

  if (status === 200) {
    login(json.user);
    localStorage.setItem(
      'token',
      JSON.stringify(json.jwtToken).replace(/"/gi, '')
    );
  } else {
    alert(json.message);
  }
};

const useHandleLogin = ({
  email,
  password,
}: IgetUseHandleLogin): IsetHandleLogin => {
  const { login } = useUser();
  const history = useHistory();
  const [loginIsLoading, setLoginIsLoading] = useState<number>(-1);
  const [emailErr, setEmailErr] = useState(false);
  const [pwErr, setPwErr] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailErr(false);
    setPwErr(false);
    if (!checkPassword(password, setPwErr)) {
      return;
    }
    setLoginIsLoading(1);
    handleLogin({ email, password, setLoginIsLoading, history, login });
  };

  return { emailErr, pwErr, handleSubmit, loginIsLoading };
};

export default useHandleLogin;
