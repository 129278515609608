import { useEffect, useRef, useState } from 'react';

export const QuizChoice = ({
  id,
  number,
  component,
  dispatch,
}): JSX.Element => {
  const [choice, setChoice] = useState('');

  const content = useRef(null);
  const handleContentResize = () => {
    content.current.style.height = 1 + 'px';
    content.current.style.height = 4 + content.current.scrollHeight + 'px';
  };

  useEffect(() => {
    handleContentResize();
  }, []);

  return (
    <div className="multiple-choice-component">
      <div className="multiple-choice-title-container">
        <span>{number + 1}.</span>
        <input
          value={component.data.title}
          onChange={(e) =>
            dispatch({
              type: 'EDIT',
              id: id,
              data: { ...component.data, title: e.target.value },
            })
          }
          placeholder="객관식 퀴즈 제목을 입력하세요..."
        />
      </div>
      <textarea
        className="multiple-choice-content"
        ref={content}
        value={component.data.content}
        onChange={(e) =>
          dispatch({
            type: 'EDIT',
            id: id,
            data: { ...component.data, content: e.target.value },
          })
        }
        onKeyDown={handleContentResize}
        onKeyUp={handleContentResize}
        placeholder="객관식 퀴즈 본문을 입력하세요..."
      />
      <div className="multiple-choice-selection">
        {component.data.questionChoice.map((choice, index) =>
          choice.id !== null ? (
            <div
              key={index}
              style={
                index === Number(component.data.answer)
                  ? {
                      backgroundColor: '#5263FF',
                      color: '#fff',
                    }
                  : {
                      backgroundColor: '#fff',
                    }
              }
              onClick={() =>
                dispatch({
                  type: 'EDIT',
                  id: id,
                  data: { ...component.data, answer: String(index) },
                })
              }
            >
              <div>{index + 1 + ') ' + choice}</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: 'EDIT',
                    id: id,
                    data: {
                      ...component.data,
                      questionChoice: component.data.questionChoice.filter(
                        (choice, choiceIndex) => choiceIndex !== index
                      ),
                    },
                  });
                }}
              >
                <img src="/assets/icons/minus-solid.svg" alt="" />
              </button>
            </div>
          ) : null
        )}
      </div>
      <div className="multiple-choice-add-selection">
        <input
          value={choice}
          onChange={(e) => setChoice(e.target.value)}
          placeholder={'보기를 입력하세요...'}
          style={{
            flex: 1,
            fontSize: '20px',
          }}
        />
        <button
          onClick={() => {
            dispatch({
              type: 'EDIT',
              id: id,
              data: {
                ...component.data,
                questionChoice: [...component.data.questionChoice, choice],
              },
            });
            setChoice('');
          }}
        >
          <img src="/assets/icons/plus-solid.svg" alt="" />
        </button>
      </div>
      <div className="multiple-choice-explanation">
        <div>💡</div>
        <input
          value={component.data.explanation}
          onChange={(e) =>
            dispatch({
              type: 'EDIT',
              id: id,
              data: { ...component.data, explanation: e.target.value },
            })
          }
          placeholder="객관식 퀴즈 설명을 입력하세요..."
        />
      </div>
    </div>
  );
};
