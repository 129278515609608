import React from 'react';
import 'styles/stylesheets/css_set.css';
import 'styles/stylesheets/common.css';
import 'styles/stylesheets/style.css';
import { useHistory } from 'react-router-dom';

const NotFoundErrorPage = (): JSX.Element => {
  const history = useHistory();
  return (
    <>
      <div>
        <div className="error-box">
          <div className="img-box">
            <img
              src="/assets/images/default.png"
              className="error-img"
              alt=""
            />
          </div>
          <div className="error-text-box">
            <div className="error-text-1">
              <span>앗! 현재 찾을 수 없는 페이지를 요청하셨어요.</span>
            </div>
            <div className="error-text-2">
              <p>존재하지 않는 주소를 입력하셨거나,</p>
              <p>요청하신 페이지 주소가 변경, 삭제되어 찾을 수 없어요.</p>
              <p>궁금하신 점은 언제든 고객센터로 요청해 주세요!</p>
              <p>감사합니다 :)</p>
            </div>
            <div className="error-btns">
              <button
                className="btn btn-fill-accent"
                onClick={() => {
                  history.push('/');
                }}
              >
                메인으로
              </button>
              <button
                className="btn btn-line-normal"
                onClick={() => {
                  history.goBack();
                }}
              >
                이전 페이지
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundErrorPage;
