import { useEffect, useRef } from 'react';

import Monaco from '@monaco-editor/react';

export const QuizEditor = ({
  id,
  number,
  component,
  dispatch,
}): JSX.Element => {
  const content = useRef(null);
  const answer = useRef(null);

  const options = {
    fontSize: 20,
    selectOnLineNumbers: true,
    roundedSelection: false,
    minimap: {
      enabled: false,
    },
    padding: {
      top: 10,
      bottom: 10,
    },
    renderWhitespace: 'none' as
      | 'none'
      | 'boundary'
      | 'selection'
      | 'trailing'
      | 'all',
  };

  const handleEditorDidMount = (editor) => {
    editor.current = editor;
  };

  const handleContentResize = () => {
    content.current.style.height = 1 + 'px';
    content.current.style.height = 4 + content.current.scrollHeight + 'px';
  };

  const handleAnswerResize = () => {
    answer.current.style.height = 1 + 'px';
    answer.current.style.height = 4 + answer.current.scrollHeight + 'px';
  };

  useEffect(() => {
    handleContentResize();
    handleAnswerResize();
  }, []);

  return (
    <div className="code-editor-quiz-component">
      <div className="code-editor-quiz-title">
        <span>{number + 1}.</span>
        <input
          value={component.data.title}
          onChange={(e) =>
            dispatch({
              type: 'EDIT',
              id: id,
              data: { ...component.data, title: e.target.value },
            })
          }
          placeholder="에디터 제목을 입력하세요..."
        />
      </div>
      <textarea
        className="code-editor-quiz-content"
        ref={content}
        value={component.data.content}
        onChange={(e) =>
          dispatch({
            type: 'EDIT',
            id: id,
            data: { ...component.data, content: e.target.value },
          })
        }
        onKeyDown={handleContentResize}
        onKeyUp={handleContentResize}
        placeholder="에디터 내용을 입력하세요..."
      />
      <div className="code-editor-quiz-header">코드 에디터</div>
      <Monaco
        height="360px"
        defaultLanguage="python"
        theme="vs-dark"
        options={options}
        onMount={handleEditorDidMount}
        value={component.data.code}
        onChange={(value) =>
          dispatch({
            type: 'EDIT',
            id: id,
            data: { ...component.data, code: value },
          })
        }
      />
      <div className="code-editor-quiz-footer"></div>
      <div className="code-editor-quiz-answer">
        <div>정답</div>
        <textarea
          ref={answer}
          value={component.data.answer}
          onChange={(e) =>
            dispatch({
              type: 'EDIT',
              id: id,
              data: { ...component.data, answer: e.target.value },
            })
          }
          onKeyDown={handleAnswerResize}
          onKeyUp={handleAnswerResize}
          placeholder="에디터 정답을 입력하세요..."
        />
      </div>
      <div className="code-editor-quiz-explantion">
        <div>💡</div>
        <input
          value={component.data.explanation}
          onChange={(e) =>
            dispatch({
              type: 'EDIT',
              id: id,
              data: { ...component.data, explanation: e.target.value },
            })
          }
          placeholder="객관식 퀴즈 설명을 입력하세요..."
        />
      </div>
    </div>
  );
};
