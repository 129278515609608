import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { IsetChapterOption } from '../getProgress/interfaces';

const useChapterOption = (): IsetChapterOption => {
  const [chapters, setChapters] = useState([]);
  const [chaptersError, setChaptersError] = useState<string>('');
  const [chaptersIsLoading, setChaptersIsLoading] = useState<number>(-1);

  const fetchData = useCallback(async (courseId: number) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/chapters?courseId=${courseId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setChapters(res.data);
        })
        .finally(() => {
          setChaptersIsLoading(0);
        });
    } catch (err) {
      setChaptersError(err);
    }
  }, []);
  // 처음 한 번 courseId = 1 로 요청
  useEffect(() => {
    if (chaptersError || !Array.isArray(chapters)) {
      return console.log('There was an error loading your chapters!');
    }
    // 맨처음 한번 수행(dependency X)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChapterOption = (courseId: number) => {
    fetchData(courseId);
  };

  return {
    getChapterOption,
    chapters,
    chaptersError,
    chaptersIsLoading,
    setChaptersIsLoading,
  };
};

export default useChapterOption;
