import { useCallback, useEffect, useState } from 'react';
import { IsetLessonOption } from '../getProgress/interfaces';
import axios from 'axios';

const useLessonOption = (): IsetLessonOption => {
  const [lessons, setLessons] = useState([]);
  const [lessonsError, setLessonsError] = useState<string>('');
  const [lessonsIsLoading, setLessonsIsLoading] = useState<number>(-1);

  const fetchData = useCallback(async (chapterId: number) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/lessons?chapterId=${chapterId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setLessons(res.data);
        })
        .finally(() => {
          setLessonsIsLoading(0);
        });
    } catch (err) {
      setLessonsError(err);
    }
  }, []);
  // 처음 한 번 chapterId = 1 로 요청
  useEffect(() => {
    if (lessonsError || !Array.isArray(lessons)) {
      return console.log('There was an error loading your lessons!');
    }
    // 맨처음 한번 수행(dependency [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLessonOption = (chapterId: number) => {
    fetchData(chapterId);
  };

  return {
    getLessonOption,
    lessons,
    lessonsError,
    lessonsIsLoading,
    setLessonsIsLoading,
  };
};

export default useLessonOption;
