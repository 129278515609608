import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLoginLogs from 'hooks/admin/useLoginLogs';
import useUserInfo from 'hooks/admin/useUsers';
import useGetMembers from 'hooks/main/members/useGetMembers';
import {
  AdminLayout,
  SearchBarForDate,
  SearchBarForUserId,
  TextInput,
} from 'components/global';
import { fillZero, formDate } from '../teacher/studentProgressPage';

import moment from 'moment';
import classNames from 'classnames';
import styles from 'styles/stylesheets/style.css';
import {
  CalendarToday,
  DeleteOutline,
  Info,
  MailOutline,
  PhoneAndroid,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import EditIcon from '@material-ui/icons/Edit';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import FormatAlignLeftOutlinedIcon from '@material-ui/icons/FormatAlignLeftOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { useGetStudents } from 'hooks/main';
import useEditApplierInfo from 'hooks/admin/EditApplierInfo';
import useAppliers from 'store/modules/appliers/appliersHook';
const cn = classNames.bind(styles);

export const formDateAndTime = (date: Date | string): JSX.Element => {
  const data = moment(date);
  const y = String(data.year());
  const m = fillZero(String(data.month() + 1));
  const d = fillZero(String(data.date()));
  const h = fillZero(String(data.hour()));
  const mn = fillZero(String(data.minute()));
  const s = fillZero(String(data.second()));
  return (
    <>
      {y}.{m}.{d} {h}:{mn}:{s}
    </>
  );
};

const UserPage = ({ match }): JSX.Element => {
  const history = useHistory();
  const [userInfo, getUserInfo] = useUserInfo();
  const [members, setMembers] = useGetMembers();
  const { studentsResp } = useGetStudents(userInfo);

  useEffect(() => {
    getUserInfo(match.params.userId);
  }, []);

  const [card, setCard] = useState(0);
  const handleCard = (num) => {
    setCard(num);
  };

  const [searchField, setSearchField] = useState();

  const [classrooms, setClassrooms] = useState([]);
  const loadClassrooms = async (userId): Promise<void> => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms?tutorId=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const json = await r.json();
    if (r.status === 200) {
      setClassrooms(json);
    } else {
      alert(json.message);
    }
  };
  const loadCustomClassrooms = async (
    userId: number,
    classroomName: string
  ): Promise<void> => {
    const r = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms?tutorId=${userId}&classroomName=${classroomName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/html; charset=utf-8',
        },
      }
    );
    const json = await r.json();
    if (r.status === 200) {
      setClassrooms(json);
    } else {
      alert(json.message);
    }
  };

  useEffect(() => {
    userInfo && loadClassrooms(userInfo.id);
    userInfo && setMembers(userInfo.id);
  }, [userInfo]);

  const [openDd, setOpenDd] = useState(true);

  const [loginLogs, getLoginLogs, getCustomLoginLogs] = useLoginLogs(
    match.params.userId
  );

  useEffect(() => {
    getLoginLogs();
  }, []);

  const getRecentVisit = () => {
    const rv = [];
    loginLogs?.forEach((item) => {
      rv.push(item.createdAt);
    });
    const temp = rv[rv.length - 1];
    return <span>{formDate(temp)}</span>;
  };

  const [searchClassroomNameField, setSearchClassroomNameField] = useState('');

  const handleDelete = (userId) => {
    const isConfirm = window.confirm('정말로 회원을 삭제하시겠습니까?');

    if (isConfirm) {
      try {
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }).then((res) => {
          if (res.status === 200) {
            history.goBack();
          } else {
            alert(res.statusText);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  // # : 그룹관리 기존 모달 기능
  const qna = () => {
    if ((userInfo['application']['inquiry'] as string)?.length > 59) {
      return (
        <>
          <p className="application-info-value-full">
            {userInfo['application']['inquiry']}
          </p>
        </>
      );
    } else {
      return (
        <p className="application-info-value-full">
          {userInfo['application']['inquiry']}
        </p>
      );
    }
  };

  const { editInfoInit, editInfoRep, editInfoMemo } = useEditApplierInfo();

  const [submitStatus, setSubmitStatus] = useState(false);
  const handleSubmit = useCallback(
    (ty, userId, userAuth, userRole, memo?) => {
      if (ty === 'init') {
        let userPurchase = 0;
        if (userAuth === 2) userPurchase = 1;
        editInfoInit(userId, userAuth, userRole, userPurchase);
      }
      if (ty === 'repeat') editInfoRep(userId, userAuth, userRole);
      if (ty === 'updateMemo') editInfoMemo(userId, memo);
    },
    [editInfoInit, editInfoRep, editInfoMemo]
  );

  const handleAdmitReject = (e) => {
    handleSubmit('init', userInfo['id'], 4, userInfo['userRole']);
  };

  const handleCheck = useCallback(async () => {
    setSubmitStatus(true);
    await handleSubmit(
      'repeat',
      userInfo['id'],
      userInfo['userAuth'],
      userInfo['userRole']
    );
    setSubmitStatus(false);
    await getUserInfo(userInfo['id']);
  }, [handleSubmit, userInfo, getUserInfo]);

  const confirmAdmit = async (
    ty: string,
    userId: number,
    userAuth: number,
    userRole: number
  ) => {
    if (
      window.confirm(
        '승인하시면 승인대기 및 거절 상태로 되돌릴 수 없습니다. 승인하시겠습니까?'
      )
    ) {
      await handleSubmit(ty, userId, userAuth, userRole);
    }
  };

  const handleUseAdmit = (e) => {
    confirmAdmit('init', userInfo['id'], 2, userInfo['userRole']);
  };

  const [text, setText] = useState('');
  const [id, setId] = useState(-1);
  useEffect(() => {
    // userInfo(문의한 유저 정보가 변경될 때마다 id와 메모를 바꾸어 보여줌)
    if (userInfo && userInfo['application']) {
      setId(userInfo['application']['id']);

      userInfo['application']['memo'] &&
        setText(
          userInfo['application']['memo'] !== null
            ? userInfo['application']['memo']
            : ''
        );
    }
  }, [setId, userInfo]);

  if (userInfo && userInfo['userAuth']) console.log(userInfo['userAuth']);

  return (
    <>
      <AdminLayout />
      <div className="body-inner admin-wrapper">
        <div className="admin-flex-wrapper">
          <div className="admin-flex-container">
            <div className="users-flex-container">
              <div className="users-container">
                <section className="user-detail">
                  <article className="userTitleContainer">
                    <div>
                      <button
                        className="userBackBtn"
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </button>
                      <h1 className="userTitle">상세 내용</h1>
                    </div>
                    {/* <Link to="/newUser">
                      <button className="userAddButton">Create</button>
                    </Link> */}
                  </article>
                  <article className="userContainer">
                    <div className="userShow">
                      <div className="userShowTop">
                        {userInfo && (
                          <>
                            {userInfo.userRole === 1 && (
                              <img
                                src="/assets/images/thumbnail_student.png"
                                alt=""
                                className="userShowImg"
                              />
                            )}
                            {userInfo.userRole === 2 && (
                              <img
                                src="/assets/images/thumbnail_teacher.png" // "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                alt=""
                                className="userShowImg"
                              />
                            )}
                          </>
                        )}
                        <div className="userShowTopTitle">
                          <span className="userShowUsername">
                            {userInfo && userInfo.userName}
                          </span>
                          <span className="userShowUserTitle">
                            {userInfo && (
                              <>
                                {userInfo.userRole === 1 && '학생'}
                                {userInfo.userRole === 2 && '선생님'}
                                {userInfo.userRole === 3 && '관리자'}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="userShowBottom">
                        <div className="userShowInfo">
                          <MailOutline className="userShowIcon" />
                          <span className="userShowInfoTitle">
                            {userInfo && userInfo.userEmail}
                          </span>
                        </div>
                        <div className="userShowInfo">
                          <PhoneAndroid className="userShowIcon" />
                          <span className="userShowInfoTitle">
                            {userInfo &&
                              (userInfo['application']?.userTel
                                ? userInfo['application'].userTel
                                : '미입력')}
                          </span>
                        </div>
                        {/* <div className="userShowInfo">
                          <CalendarToday className="userShowIcon" />
                          <span className="userShowInfoTitle">
                            {getRecentVisit()}
                            (최근 방문일)
                          </span>
                        </div> */}
                        {userInfo && userInfo.userRole === 1 && (
                          <div className="userShowInfo">
                            <Info className="userShowIcon" />
                            <span className="userShowInfoTitle">
                              {members.length !== 0
                                ? `${members[0].classroom.classroomName}(${members[0]?.classroom.user.userName} 선생님)`
                                : ''}
                            </span>
                          </div>
                        )}

                        {/* & : 그룹관리 Modal을 회원관리 페이지에 이식 */}
                        <div className="application-info-wrapper">
                          <div className="application-info-box">
                            {userInfo && (
                              <div className="application-info-scroll-box">
                                <div className="application-info-header">
                                  <p className="created-at">신청일 : </p>
                                  {userInfo['application']
                                    ? formDate(
                                        userInfo['application']['createdAt']
                                      )
                                    : ''}
                                </div>

                                <section className="application-info-body">
                                  <article className="application-info-row">
                                    <p className="application-info-field">
                                      그룹명 :
                                    </p>
                                    <p className="application-info-value">
                                      {userInfo['application']
                                        ? userInfo['application']['groupName']
                                        : ''}
                                    </p>
                                  </article>
                                  <article className="application-info-row">
                                    <p className="application-info-field">
                                      예상 사용 인원 :
                                    </p>
                                    <p className="application-info-value">
                                      {userInfo['application']
                                        ? userInfo['application'][
                                            'expectedNumber'
                                          ]
                                        : ''}
                                    </p>
                                  </article>
                                  <article className="application-info-row-full">
                                    <p className="application-info-field-full">
                                      Coding-X를 어떻게 알게 되셨나요? :
                                    </p>
                                    <p className="application-info-value-full">
                                      {userInfo['application']
                                        ? userInfo['application']['knownPath']
                                        : ''}
                                    </p>
                                  </article>
                                  <article className="application-info-row-full">
                                    <p className="application-info-field-full">
                                      용도 및 문의사항 :
                                    </p>
                                    {userInfo['application'] && qna()}
                                  </article>
                                  <article className="application-info-row">
                                    <p className="application-info-field">
                                      최근 방문일 :
                                    </p>
                                    <p className="application-info-value">
                                      {getRecentVisit()}
                                    </p>
                                  </article>
                                </section>

                                <hr />
                                <div className="application-info-admit">
                                  <div className="application-info-admit-state">
                                    <p>
                                      {userInfo['userAuth'] === 0 &&
                                        '미신청 유저(승인전)입니다.'}
                                      {userInfo['userAuth'] === 1 &&
                                        '승인대기중입니다.'}
                                      {userInfo['userAuth'] === 2 &&
                                        `승인완료(${
                                          userInfo['approvedAt']
                                            ? typeof userInfo['approvedAt'] ===
                                                'string' &&
                                              userInfo['approvedAt']?.substring(
                                                0,
                                                4
                                              ) +
                                                '.' +
                                                userInfo[
                                                  'approvedAt'
                                                ]?.substring(5, 7) +
                                                '.' +
                                                userInfo[
                                                  'approvedAt'
                                                ]?.substring(8, 10)
                                            : ''
                                        })`}
                                      {userInfo['userAuth'] === 3 &&
                                        '승인중지되었습니다.'}
                                      {userInfo['userAuth'] === 4 &&
                                        '승인거절되었습니다.'}
                                    </p>
                                  </div>
                                  <div className="application-info-admit-control-buttons">
                                    {userInfo['userAuth'] === 1 && (
                                      <button
                                        className="admit-reject-button"
                                        onClick={handleAdmitReject}
                                      >
                                        승인거절
                                      </button>
                                    )}
                                    {(userInfo['userAuth'] === 1 ||
                                      userInfo['userAuth'] === 4) && (
                                      <button
                                        className="use-admit-button"
                                        onClick={handleUseAdmit}
                                      >
                                        이용승인
                                      </button>
                                    )}
                                    {(userInfo['userAuth'] === 2 ||
                                      userInfo['userAuth'] === 3) && (
                                      <button
                                        className={cn(
                                          'btn-invite',
                                          userInfo['userAuth'] === 3 &&
                                            'btn-disabled'
                                        )}
                                        onClick={handleCheck}
                                      >
                                        <span className="invite-content">
                                          {userInfo['userAuth'] === 2
                                            ? '이용중'
                                            : '이용중지'}
                                        </span>
                                        <span className="white-circle"></span>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div className="application-info-footer">
                                  <div className="application-info-footer-header">
                                    <p>
                                      운영하면서 공유해야하는 부분을 모두
                                      메모해주세요
                                    </p>
                                  </div>
                                  <div className="application-info-footer-card">
                                    <TextInput
                                      userId={id}
                                      text={text}
                                      setText={setText}
                                      postText={handleSubmit}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <ToastContainer
                            position={toast.POSITION.BOTTOM_CENTER}
                            className={'copy-url'}
                            autoClose={1000}
                            transition={Zoom}
                            hideProgressBar
                            closeOnClick={false}
                            rtl={false}
                            draggable={false}
                            pauseOnHover={false}
                            closeButton={false}
                          />
                        </div>

                        <div
                          className="userShowTitle"
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                            fontSize: '16px',
                            paddingTop: '15px',
                          }}
                          onClick={() => setOpenDd(!openDd)}
                        >
                          {openDd ? (
                            <ArrowDropDownOutlinedIcon
                              viewBox="4 0 24 24"
                              className="userShowIcon"
                            />
                          ) : (
                            <ArrowRightOutlinedIcon
                              viewBox="4 0 24 24"
                              className="userShowIcon"
                            />
                          )}
                          Menu
                        </div>
                        <div
                          className="user-dd-menu"
                          style={{ display: !openDd && 'none' }}
                        >
                          <button
                            className={cn(
                              'userShowInfoBtn',
                              card === 0 && 'active'
                            )}
                            onClick={() => handleCard(0)}
                          >
                            <ExitToAppOutlinedIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">
                              <p>로그인 횟수 조회</p>
                            </span>
                          </button>
                          <>
                            {userInfo && userInfo.userRole === 1 && (
                              <>
                                <button
                                  className={cn(
                                    'userShowInfoBtn',
                                    card === 3 && 'active'
                                  )}
                                  onClick={() => handleCard(3)}
                                >
                                  <EventNoteIcon className="userShowIcon" />
                                  <span className="userShowInfoTitle">
                                    <p>학습 현황</p>
                                  </span>
                                </button>
                              </>
                            )}
                          </>
                          <>
                            {userInfo && userInfo.userRole === 2 && (
                              <>
                                <button
                                  className={cn(
                                    'userShowInfoBtn',
                                    card === 1 && 'active'
                                  )}
                                  onClick={() => handleCard(1)}
                                >
                                  <PageviewOutlinedIcon className="userShowIcon" />
                                  <span className="userShowInfoTitle">
                                    <p>생성한 반 조회</p>
                                  </span>
                                </button>
                                <button
                                  className={cn(
                                    'userShowInfoBtn',
                                    card === 2 && 'active'
                                  )}
                                  onClick={() => handleCard(2)}
                                >
                                  <FormatAlignLeftOutlinedIcon className="userShowIcon" />
                                  <span className="userShowInfoTitle">
                                    <p>반 활동 기록</p>
                                  </span>
                                </button>
                              </>
                            )}
                          </>
                          <button
                            className={cn(
                              'userShowInfoBtn',
                              card === 4 && 'active'
                            )}
                            onClick={() => handleCard(4)}
                          >
                            <EditIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">
                              <p>편집하기</p>
                            </span>
                          </button>
                          <button
                            className={cn('userShowInfoBtn')}
                            onClick={() => handleDelete(userInfo.id)}
                          >
                            <DeleteOutline
                              className="userShowIcon"
                              style={{ color: 'red ' }}
                            />
                            <span className="userShowInfoTitle">
                              <p>삭제하기</p>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="userUpdate user-check-wrapper"
                      style={{ display: card !== 0 && 'none' }}
                    >
                      <section className="user-check-header">
                        <article>
                          <span className="user-check-title">
                            <h2>로그인 횟수 조회</h2>
                          </span>
                        </article>
                        <div className="user-check-total-num">
                          <p>로그인 총 횟수</p>
                          <p>{loginLogs && loginLogs.length}</p>
                        </div>
                      </section>
                      <section>
                        <SearchBarForDate
                          searchQuery={searchField}
                          setSearchQuery={setSearchField}
                          setU={getLoginLogs}
                          setCustomU={getCustomLoginLogs}
                        >
                          YYYY-MM-DD
                        </SearchBarForDate>
                      </section>
                      <table className="table-card">
                        <thead>
                          <tr className="tr-card">
                            <th className="th-card">번호</th>
                            <th className="th-card">날짜 및 시간</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loginLogs?.map((item, idx) => (
                            <tr className="tr-card td" key={idx}>
                              <td className="td-card td-5">{idx + 1}</td>
                              <td className="td-card td-5">
                                {formDateAndTime(item.createdAt)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="userUpdate user-check-wrapper"
                      style={{ display: card !== 1 && 'none' }}
                    >
                      <section className="user-check-header">
                        <article>
                          <span className="user-check-title">
                            <h2>생성한 반 조회</h2>
                          </span>
                        </article>
                        <div className="user-check-total-num">
                          <p>생성한 반 수</p>
                          <p>{classrooms.length}</p>
                        </div>
                      </section>
                      <section>
                        <SearchBarForUserId
                          userId={userInfo?.id}
                          searchQuery={searchClassroomNameField}
                          setSearchQuery={setSearchClassroomNameField}
                          setU={loadClassrooms}
                          setCustomU={loadCustomClassrooms}
                        >
                          이름
                        </SearchBarForUserId>
                      </section>
                      <table className="table-card">
                        <thead>
                          <tr className="tr-card">
                            <th className="th-card">번호</th>
                            <th className="th-card">이름</th>
                            <th className="th-card">멤버 수</th>
                            <th className="th-card">초대코드</th>
                            <th className="th-card">생성일</th>
                          </tr>
                        </thead>
                        <tbody>
                          {classrooms.map((classroom, idx) => (
                            <tr
                              className="tr-card td"
                              key={idx}
                              onClick={() =>
                                console.log(
                                  classroom.id + '학생관리페이지로이동(미구현)'
                                )
                              }
                            >
                              <td className="td-card td-5">{idx + 1}</td>
                              <td className="td-card td-5">
                                {classroom.classroomName}
                              </td>
                              <td className="td-card td-5">
                                {classroom.students.length - 1}
                              </td>
                              <td className="td-card td-5">
                                {classroom.classroomCode}
                              </td>
                              <td className="td-card td-5">
                                {formDate(classroom.createdAt)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="userUpdate"
                      style={{ display: card !== 2 && 'none' }}
                    >
                      <span className="userUpdateTitle">반 활동 기록</span>
                      <form className="userUpdateForm">
                        <div className="userUpdateLeft">
                          <div className="userUpdateItem">미구현</div>
                        </div>
                        <div className="userUpdateRight"></div>
                      </form>
                    </div>

                    <div
                      className="userUpdate user-check-wrapper"
                      style={{ display: card !== 3 && 'none' }}
                    >
                      <section className="user-check-header check">
                        <article className="user-check-title-box">
                          <span className="user-check-title">
                            <h2>학습 현황</h2>
                          </span>
                        </article>
                        {userInfo &&
                        userInfo.userAuth === 2 &&
                        studentsResp?.length !== 0 ? (
                          <button
                            className="move-btn"
                            onClick={() =>
                              // * : 유저의 classroomId로 학생관리페이지 이동
                              history.push({
                                pathname: `/classrooms/${studentsResp[0].classroom['id']}`,
                                state: { id: userInfo.id },
                              })
                            }
                          >
                            이동하기
                          </button>
                        ) : (
                          <p>이용불가 상태입니다.</p>
                        )}
                      </section>
                    </div>
                    <div
                      className="userUpdate"
                      style={{ display: card !== 4 && 'none' }}
                    >
                      <span className="userUpdateTitle">편집하기</span>
                      <form className="userUpdateForm">
                        <div className="userUpdateLeft">
                          <div className="userUpdateItem">
                            <label>이름</label>
                            <input
                              type="text"
                              placeholder="미구현"
                              className="userUpdateInput"
                            />
                          </div>
                          <div className="userUpdateItem">
                            <label>이메일</label>
                            <input
                              type="text"
                              placeholder="미구현"
                              className="userUpdateInput"
                            />
                          </div>
                          <div className="userUpdateItem">
                            <label>전화번호</label>
                            <input
                              type="text"
                              placeholder="미구현"
                              className="userUpdateInput"
                            />
                          </div>
                        </div>
                        <div className="userUpdateRight">
                          {/* * : userProfileImage */}
                          {/* <div className="userUpdateUpload">
                              <img
                                src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                alt=""
                                className="userUpdateImg"
                              />
                              <label htmlFor="file">
                                <Publish className="userUpdateIcon" />
                              </label>
                              <input
                                type="file"
                                id="file"
                                style={{ display: 'none' }}
                              />
                            </div> */}
                          <div className="userUpdateUpload"></div>
                          <button className="userUpdateButton">Update</button>
                        </div>
                      </form>
                    </div>
                  </article>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPage;
