export const getEditClassroom = (): {
  editClassroom: (
    classroomName: string,
    closeModal: () => void,
    classroomId: number,
    fetchProgressData: () => Promise<void>
  ) => Promise<void>;
} => {
  const editClassroom = async (
    classroomName: string,
    closeModal: () => void,
    classroomId: number,
    fetchProgressData: () => Promise<void>
  ): Promise<void> => {
    if (classroomName !== '') {
      try {
        fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/classrooms/${classroomId}`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
            body: JSON.stringify({
              classroomName: classroomName,
            }),
          }
        ).then((res) => {
          if (res.status === 200) {
            fetchProgressData();
            closeModal();
          } else {
            alert(res.statusText);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return { editClassroom };
};
