import { RouteComponentProps } from 'react-router-dom';

const GoBackButton = ({
  history,
}: RouteComponentProps['history']): JSX.Element => {
  return (
    <>
      <button
        id="prev-page"
        className="prev-page"
        onClick={() => history.goBack()}
      >
        <img
          src="/assets/icons/ic_arrow_prev_line.svg"
          className="icon"
          alt="뒤로가기"
        />
      </button>
    </>
  );
};

export default GoBackButton;
