import { useCallback, useState } from 'react';
import useHandleMultipleTest from 'hooks/main/lesson/test/useMultipleTest';
import { IQuizChoice } from './interfaces';
import styles from 'styles/stylesheets/style.css';
import classNames from 'classnames';
const cn = classNames.bind(styles);

const QuizChoice = ({
  id,
  title,
  content,
  questionChoice,
  explanation,
  lessonId,
  index,
}: IQuizChoice): JSX.Element => {
  const [checkedAnswer, setCheckedAnswer] = useState<number>(-1);
  const updateCheckedAnswer = useCallback((e) => {
    if (e.target.checked) {
      setCheckedAnswer(e.target.value);
    }
  }, []);

  // submit mulitipleTest
  // - input
  // lessonId : 해당 퀴즈가 들어있는 레슨의 id
  // id : 해당 퀴즈 컴포넌트의 id
  // checkedAnswer : 유저가 체크한 답
  // - output
  // reqSuccess : 요청 성공에 대한 불린 값
  // correct : 유저의 답이 맞았을 때와 틀렸을 때에 대한 불린 값, 스타일 분기 처리용 변수
  // answer : 유저가 문제 맞췄을 때 실제 답을 받는 상탯값
  // wrAnswer : 유저가 문제 틀렸을 때의 실제 답을 받는 상탯값
  // disabledSubmit : 제출하기 버튼 클릭 후 disabled 처리용 변수
  // handleSubmit : 제출하기 실행 함수
  // multipleTestIsLoading : 답 제출 후 결과를 받기까지 로딩을 확인하는 변수
  const {
    reqSuccess,
    correct,
    answer,
    wrAnswer,
    disabledSubmit,
    handleSubmit,
    multipleTestIsLoading,
  } = useHandleMultipleTest(lessonId, id, checkedAnswer);

  const choiceArr = questionChoice.split('||');

  return (
    <form
      className={'mc-component component'}
      data-id={id}
      onSubmit={handleSubmit}
    >
      <div className="question-text">
        <strong>
          {/* {tIdArr &&
            tIdArr
              .map((item, idx) => item === id && idx)
              .filter((item) => item === 0 || item)[0] + 1}
          . */}
          {`${index + 1}. `}
          {title}
        </strong>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      {/* 요청 성공 시  */}
      {reqSuccess ? (
        // 유저의 답이 맞았다면
        correct === true ? (
          <div className="questions clear-question">
            {choiceArr.map((choice, cKey) => (
              <label
                key={cKey}
                className={
                  cKey === answer
                    ? 'question select-true'
                    : 'question unselect-false'
                }
                htmlFor={`quiz-test ${id}-${cKey}`}
              >
                <input
                  id={`quiz-test ${id}-${cKey}`}
                  name="quiz-test"
                  className="quiz-checkbox"
                  type="radio"
                  value={cKey}
                  onChange={updateCheckedAnswer}
                />
                <div
                  className={
                    cKey === answer
                      ? 'question-clear-wrap'
                      : 'question-radio-wrap'
                  }
                  id={`quiz-test ${id}-${cKey}`}
                >
                  {cKey === answer ? (
                    <span className="checked-answer"></span>
                  ) : (
                    <>
                      <label htmlFor={`quiz-test ${id}-${cKey}`}>
                        <span></span>
                      </label>
                    </>
                  )}
                </div>
                {`${cKey + 1})`} {choice}
              </label>
            ))}
          </div>
        ) : (
          // 유저의 답이 틀렸다면
          <div className="questions clear-question">
            {choiceArr.map((choice, cKey) => (
              // @ : cKey, checkedAnswer, wrAnswer는 값(숫자)에 의한 비교이므로 "==","!="
              <label
                key={cKey}
                className={
                  cKey == checkedAnswer // eslint-disable-line
                    ? 'question select-false'
                    : cKey == wrAnswer // eslint-disable-line
                    ? 'question unselect-true'
                    : 'question unselect-false'
                }
                htmlFor={`quiz-test ${id}-${cKey}`}
              >
                <input
                  id={`quiz-test ${id}-${cKey}`}
                  name="quiz-test"
                  className="quiz-checkbox"
                  type="radio"
                  value={cKey}
                />
                {/* (문제를 틀린 경우) 선택한 답도 아니고 (틀려서 받아온)실제 답도 아닐 때 따로 표시 보여줄 것은 없음*/}
                {cKey != checkedAnswer && cKey != wrAnswer ? ( // eslint-disable-line
                  <></>
                ) : (
                  <>
                    {/* (틀려서 받아온) 실제 답인 경우 보여줄 디자인 */}
                    {cKey == wrAnswer ? ( // eslint-disable-line
                      <div
                        className="question-clear-wrap"
                        id={`quiz-test ${id}-${cKey}`}
                      >
                        <div></div>
                      </div>
                    ) : (
                      <>
                        {/* 유저가 선택한 답 */}
                        <div
                          className="question-clear-wrap"
                          id={`quiz-test ${id}-${cKey}`}
                        >
                          <span className="checked-answer"></span>
                        </div>
                      </>
                    )}
                  </>
                )}
                {cKey + 1}) {choice}
              </label>
            ))}
          </div>
        )
      ) : (
        // 요청 실패시
        <div className="questions">
          {choiceArr.map((choice, cKey) => (
            <label
              key={cKey}
              className={'question'}
              htmlFor={`quiz-test ${id}-${cKey}`}
            >
              <input
                id={`quiz-test ${id}-${cKey}`}
                name="quiz-test"
                className="quiz-checkbox"
                type="radio"
                value={cKey}
                onChange={updateCheckedAnswer}
              />
              <>
                <div className={'question-radio-wrap'}>
                  <label htmlFor={`quiz-test ${id}-${cKey}`}>
                    <span></span>
                  </label>
                </div>
              </>
              {cKey + 1}) {choice}
            </label>
          ))}
        </div>
      )}

      <div
        className={cn(
          'etc-text',
          reqSuccess && correct ? 'true-answer' : 'false-answer'
        )}
        style={{
          display: reqSuccess ? 'block' : 'none',
        }}
      >
        {reqSuccess && correct ? (
          <strong>
            <img src="/assets/icons/ic_answer_true_fill_24.svg" alt="" />
            정답입니다
          </strong>
        ) : (
          <strong>
            <img src="/assets/icons/ic_answer_wrong_fill_24.svg" alt="" />
            오답입니다
          </strong>
        )}
        <p>{explanation}</p>
      </div>
      <div className="question-button-bar">
        {multipleTestIsLoading === 1 ? (
          <div className="is-submit-div">
            <img
              src="/assets/icons/ic_spinner_line_24.svg"
              style={{
                margin: '16px',
                marginTop: '10px',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
              }}
              alt=""
            />
          </div>
        ) : (
          !disabledSubmit && (
            <input
              className="quiz-button mc-button"
              disabled={disabledSubmit}
              type="submit"
              value={'제출하기'}
            />
          )
        )}
      </div>
    </form>
  );
};

export default QuizChoice;
