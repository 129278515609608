import React, { useState } from 'react';
import Accordion from 'components/global/accordion/Accordion';
import useUser from 'store/modules/user/userHook';
import { IgetTabPannel, StudentsI } from './interfaces';
import Skeleton from 'react-loading-skeleton';
import styles from 'styles/stylesheets/style.css';
import classNames from 'classnames';
const cn = classNames.bind(styles);

const TabPannel = ({
  handleMenuToggle,
  toggle,
  btnTitle,
  selectMenuOption,
  courses,
  coursesIsLoading,
  handleCourse,
  chapters,
  chaptersIsLoading,
  handleChapter,
  lessons,
  lessonsIsLoading,
  handleLesson,
  closeMenuOption,
  manageQuiz,
  manageQuizIsLoading,
  testComp,
  students,
}: IgetTabPannel): JSX.Element => {
  const { userData } = useUser();
  const [colHover, setColHover] = useState({});
  // 정답률과 연습문제 채점 결과를 보여줌
  const histOfQuizzes = (s, comp) => {
    const arr = [];
    // comp(퀴즈 관련 컴포넌트의 배열)를 forEach로 한 개의 컴포넌트마다 비교해줌
    // 해당 컴포넌트와 같은 id를 가진 컴포넌트가 ManageQuiz의 배열(퀴즈를 틀리던 맞던 풀기만 하면 배열에 추가됨) 내에
    // 존재하고 유저의 id와 같은 것이라면 퀴즈를 풀었다고 판단, isCorrect 필드를 확인해서 t or f를 arr에 추가시킴
    comp?.forEach((item) => {
      let flag = 0;
      manageQuiz?.forEach((quiz) => {
        if (s.student.id === quiz['user'].id && item.id === quiz.component.id) {
          quiz['isCorrect'] === true ? arr.push('t') : arr.push('f');
          flag = 1;
        }
      });
      if (!flag) arr.push('n');
    });

    const percent =
      (arr.filter((item) => item === 't').length / arr.length) * 100;

    return (
      <>
        <td>{Number.isInteger(percent) ? percent : percent.toFixed(1)}%</td>
        {arr.map((item, key) => {
          return item === 't' ? (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/ic_answer_true_fill_lt.png" alt="" />
            </td>
          ) : item === 'f' ? (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/ic_answer_false_fill_lt.png" alt="" />
            </td>
          ) : (
            <td
              key={key}
              onMouseOver={() => setColHover(key)}
              onMouseOut={() => setColHover(-1)}
              style={{
                backgroundColor: key === colHover && 'rgb(223,226,248)',
                transition: 'background-color 0.6s',
              }}
            >
              <img src="/assets/images/ic_answer_null_fill_lt.png" alt="" />
            </td>
          );
        })}
      </>
    );
  };

  const nums = [1, 2];

  return (
    <div className="tab-target">
      <div className="tab-selector">
        <div
          className={cn('btn-toggle', toggle && 'open')}
          onClick={handleMenuToggle}
        >
          <p>
            <span>{btnTitle[0] === '코스명' ? '코스명' : btnTitle[0]}</span>
            &gt;
            <span>{btnTitle[1] === '챕터명' ? '챕터명' : btnTitle[1]}</span>
            &gt;
            <span>{btnTitle[2] === '레슨명' ? '레슨명' : btnTitle[2]}</span>
          </p>
          <img
            src="/assets/icons/ic_arrow_down_fill_24.svg"
            className="icon"
            alt=""
          />
        </div>
        <form className="accordion-wrap" onSubmit={selectMenuOption}>
          <Accordion
            type={'quiz'}
            courses={courses}
            handleCourse={handleCourse}
            coursesIsLoading={coursesIsLoading}
            chaptersIsLoading={chaptersIsLoading}
            chapters={chapters}
            handleChapter={handleChapter}
            lessonsIsLoading={lessonsIsLoading}
            lessons={lessons}
            handleLesson={handleLesson}
          />
          <div className="accordion-footer">
            <button className="btn-fill" type="submit">
              선택
            </button>
            <button
              className="btn-line"
              type="button"
              onClick={() => closeMenuOption()}
            >
              취소
            </button>
          </div>
        </form>
      </div>
      {/* * : 연습문제 Table */}
      <div className="tab-content">
        <table>
          <thead>
            {manageQuizIsLoading !== 1 && (
              <tr>
                <th style={{ width: '160px' }}>이름</th>
                <th style={{ width: '100px' }}>정답률</th>
                {/* 퀴즈가 아예 없거나 초깃값인 경우 */}
                {(testComp?.length === 0 && manageQuiz?.length === 0) ||
                manageQuiz === undefined ? (
                  <>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                  </>
                ) : (
                  <>
                    {testComp?.map((_, key: number) => (
                      <th key={key}>{key + 1}</th>
                    ))}
                  </>
                )}
              </tr>
            )}
            {manageQuizIsLoading === 1 && (
              <tr>
                <th style={{ width: '160px' }}>이름</th>
                <th style={{ width: '100px' }}>정답률</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
              </tr>
            )}
          </thead>
          <tbody>
            <>
              {(manageQuizIsLoading !== 1 &&
                manageQuiz &&
                manageQuiz[0]?.id === -1) ||
              manageQuiz === undefined ||
              manageQuiz?.length === 0 ? (
                <>
                  {testComp === undefined || testComp?.length === 0 ? (
                    <tr>
                      <td>{userData.userName}</td>
                      <td>0%</td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                      <td>
                        <img
                          src="/assets/images/ic_answer_null_fill_lt.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {manageQuiz?.length === 0 ? (
                        <>
                          {students?.map((s: StudentsI, sKey: number) => (
                            <tr key={sKey}>
                              <td>{s.student['userName']}</td>
                              <td>0%</td>
                              {testComp?.map((_) => (
                                <td>
                                  <img
                                    src="/assets/images/ic_answer_null_fill_lt.png"
                                    alt=""
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td>{userData.userName}</td>
                          <td>0%</td>
                          {testComp?.map((_) => (
                            <td>
                              <img
                                src="/assets/images/ic_answer_null_fill_lt.png"
                                alt=""
                              />
                            </td>
                          ))}
                        </tr>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {students.map((s: StudentsI, sKey: number) => (
                    <tr key={sKey}>
                      <td>{s.student['userName']}</td>
                      {histOfQuizzes(s, testComp)}
                    </tr>
                  ))}
                </>
              )}
            </>

            {manageQuizIsLoading === 1 &&
              nums.map((_, key: number) => (
                <tr key={key}>
                  <td>
                    <Skeleton width={100} height={27} />
                  </td>
                  <td>
                    <Skeleton width={45} height={27} />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={27}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={27}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={27}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                  <td>
                    <Skeleton
                      width={27}
                      height={27}
                      style={{ borderRadius: '50%' }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabPannel;
