import update from 'immutability-helper';
import { FC, useCallback, useState } from 'react';

import { ComponentItem } from './ComponentItem';

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export const ComponentContainer: FC<any> = ({
  components,
  dispatch,
  isQuiz,
}) => {
  const [showContext, setShowContext] = useState(-1);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = components[dragIndex];

      const newCard = update(components, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      dispatch({
        type: 'ORDER',
        components: newCard,
      });
    },
    [components, dispatch]
  );

  const renderCard = (
    component: { id: number; item: string; isQuiz: boolean; data: any },
    index: number
  ) => {
    if (component.isQuiz !== isQuiz) return;

    return (
      <ComponentItem
        key={component.id}
        index={index}
        id={component.id}
        item={component.item}
        component={component}
        number={
          index - components.filter((component) => !component.isQuiz).length
        }
        dispatch={dispatch}
        moveCard={moveCard}
        showContext={showContext}
        setShowContext={setShowContext}
      />
    );
  };

  return <>{components.map((component, i) => renderCard(component, i))}</>;
};
