import { useCallback, useEffect, useState } from 'react';
import { IgetLessonData, IsetLessonData } from './interfaces';
import axios from 'axios';

const useLessonData = ({ lessonId }: IgetLessonData): IsetLessonData => {
  const [lessonResp, setLessonResp] = useState();
  const [lessonError, setLessonError] = useState<string>('');
  const [lessonRespIsLoading, setLessonRespIsLoading] = useState<boolean>(true);

  const fetchLessonData = useCallback(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_SERVER_BASE_URL}/lessons/${lessonId}`, {
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        })
        .then((res) => {
          window.scrollTo(0, 0);
          setLessonResp(res.data);
        })
        .finally(() => {
          setLessonRespIsLoading(false);
        });
    } catch (err) {
      setLessonError(err);
    }
  }, [lessonId]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return { lessonResp, lessonError, lessonRespIsLoading };
};

export default useLessonData;
