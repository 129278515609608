import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { offModalAction, openModalAction } from './admin';

interface IsetData {
  menuOpen: boolean;
  modalOpen: boolean;
  setModalOff: () => void;
  setModalOn: () => void;
}

export default function useAdmin(): IsetData {
  const menuOpen = useSelector(
    (state: RootState) => state['root'].admin.menuOpen
  );
  const modalOpen = useSelector(
    (state: RootState) => state['root'].admin.modalOpen
  );
  const dispatch = useDispatch();
  const setModalOff = useCallback(() => {
    dispatch(offModalAction());
  }, [dispatch]);
  const setModalOn = useCallback(() => {
    dispatch(openModalAction());
  }, [dispatch]);
  return {
    menuOpen,
    modalOpen,
    setModalOff,
    setModalOn,
  };
}
