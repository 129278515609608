import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IOptions } from '../../main/tab/interfaces';
import { IgetAccordion } from './interfaces';

const Accordion = ({
  type,
  courses,
  handleCourse,
  coursesIsLoading,
  chaptersIsLoading,
  chapters,
  handleChapter,
  lessonsIsLoading = undefined,
  lessons = undefined,
  handleLesson = undefined,
}: IgetAccordion): JSX.Element => {
  return (
    <>
      <div className="accordion">
        <div className="table-piece">
          <div className="tp tp-y-scroll">
            <ul>
              {courses.map((course: IOptions) => (
                <li key={course['courseName']}>
                  <input
                    id={course['courseName']}
                    className="none"
                    type="radio"
                    name="courses"
                    onChange={() =>
                      handleCourse(
                        type === 'quiz' ? 'quiz' : 'lesson',
                        course['id'],
                        course['courseName']
                      )
                    }
                  />
                  <label htmlFor={course['courseName']}>
                    {course['courseName']}
                  </label>
                </li>
              ))}
              {/* skeleton */}
              {coursesIsLoading && (
                <>
                  {Array(6)
                    .fill(1)
                    .map((_, index) => (
                      <li key={index}>
                        <input
                          id="courseName"
                          className="none"
                          name="courses"
                        />
                        <label htmlFor="courseName">
                          <Skeleton />
                        </label>
                      </li>
                    ))}
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="table-piece">
          <div className="tp tp-y-scroll">
            <ul>
              {chaptersIsLoading !== 1 &&
                chapters.map((chapter: IOptions) => (
                  <li key={chapter['chapterName']}>
                    <input
                      id={chapter['chapterName']}
                      className="none"
                      type="radio"
                      name="lessons"
                      onChange={() =>
                        handleChapter(
                          type === 'quiz' ? 'quiz' : 'lesson',
                          chapter['id'],
                          chapter['chapterName']
                        )
                      }
                    />
                    <label htmlFor={chapter['chapterName']}>
                      {chapter['chapterName']}
                    </label>
                  </li>
                ))}
              {/* skeleton */}
              {chaptersIsLoading === 1 && (
                <>
                  {Array(6)
                    .fill(1)
                    .map((_, index) => (
                      <li key={index}>
                        <input
                          id="chapterName"
                          className="none"
                          name="chapters"
                        />
                        <label htmlFor="chapterName">
                          <Skeleton />
                        </label>
                      </li>
                    ))}
                </>
              )}
            </ul>
          </div>
        </div>
        {lessonsIsLoading !== undefined ? (
          <div className="table-piece">
            <div className="tp tp-y-scroll">
              <ul>
                {lessonsIsLoading !== 1 &&
                  lessons.map((lesson: IOptions) => (
                    <li key={lesson['lessonName']}>
                      <input
                        id={lesson['lessonName']}
                        className="none"
                        type="radio"
                        name="chapters"
                        onChange={() =>
                          handleLesson(lesson['id'], lesson['lessonName'])
                        }
                      />
                      <label htmlFor={lesson['lessonName']}>
                        {lesson['lessonName']}
                      </label>
                    </li>
                  ))}
                {/* skeleton */}
                {lessonsIsLoading === 1 && (
                  <>
                    {Array(6)
                      .fill(1)
                      .map((_, index) => (
                        <li key={index}>
                          <input
                            id="lessonName"
                            className="none"
                            name="chapters"
                          />
                          <label htmlFor="chapterName">
                            <Skeleton />
                          </label>
                        </li>
                      ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Accordion;
