import classNames from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from 'styles/stylesheets/style.css';

import { DeleteOutline } from '@material-ui/icons';

const cn = classNames.bind(styles);

const Cell = ({
  idx,
  content,
  header = false,
  action = false,
  cln = '',
  userId,
}: {
  idx?: number;
  content?: string | JSX.Element;
  header?: boolean;
  action?: boolean;
  cln?: string;
  userId?: number;
}): JSX.Element => {
  const handleDelete = (id) => {
    console.log('삭제(미구현)', id);
  };

  const history = useHistory();
  const handleLink = () => {
    history.push(`/admin/users/${userId}`);
  };

  const cellMarkup = header ? (
    <th className={cn('Cell Cell-header ', cln !== '' && cln)}>
      <div>{content}</div>
    </th>
  ) : (
    <td
      className={cn('Cell', cln !== '' && cln)}
      onClick={() => {
        handleLink();
      }}
    >
      <div className="Cell-fixed" style={{ width: '100%' }}>
        {action ? (
          <>
            <Link to={'/user/' + content}>
              <button className="userListDetailBtn">Detail</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(content)}
            />
          </>
        ) : (
          <p
            className={cn(
              idx === 9 && 'admit-color',
              content === '승인대기중' && 'wait',
              content === '이용중' && 'complete',
              content === '이용중지' && 'pause',
              content === '승인거절' && 'reject'
            )}
            style={{
              color:
                typeof content === 'string' &&
                (content.slice(-5, content.length) === '(관리자)' ||
                  content.slice(-5, content.length) === '관리자') &&
                'crimson',
              width: '100%',
            }}
          >
            {content}
          </p>
        )}
      </div>
    </td>
  );

  return cellMarkup;
};

export default Cell;
