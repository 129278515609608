import { useEffect, useRef, useState } from 'react';

export const Box = ({ id, component, dispatch }): JSX.Element => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const title = useRef(null);
  const content = useRef(null);
  const handleTitleResize = () => {
    title.current.style.width = 1 + 'px';
    title.current.style.width = 12 + title.current.scrollWidth + 'px';
  };
  const handleContentResize = () => {
    content.current.style.height = 1 + 'px';
    content.current.style.height = 4 + content.current.scrollHeight + 'px';
  };

  useEffect(() => {
    handleTitleResize();
    handleContentResize();
  }, []);

  return (
    <div
      className="box-component"
      onMouseEnter={() => {
        handleMouseEnter();
      }}
      onMouseLeave={() => {
        handleMouseLeave();
      }}
    >
      <div className="hat-container">
        <input
          ref={title}
          value={component.data.title}
          onChange={(e) =>
            dispatch({
              type: 'EDIT',
              id: id,
              data: { ...component.data, title: e.target.value },
            })
          }
          onKeyDown={handleTitleResize}
          onKeyUp={handleTitleResize}
          style={{
            backgroundColor: component.data.color || '#FFE200',
          }}
        />
        {hover && (
          <div className="color-palette">
            <div
              onClick={() =>
                dispatch({
                  type: 'EDIT',
                  id: id,
                  data: { ...component.data, color: '#FFE200' },
                })
              }
            ></div>
            <div
              onClick={() =>
                dispatch({
                  type: 'EDIT',
                  id: id,
                  data: { ...component.data, color: '#C8C9FF' },
                })
              }
            ></div>
            <div
              onClick={() =>
                dispatch({
                  type: 'EDIT',
                  id: id,
                  data: { ...component.data, color: '#FF9900' },
                })
              }
            ></div>
          </div>
        )}
      </div>
      <textarea
        ref={content}
        value={component.data.content
          ?.replaceAll('<br>', '\n')
          .replaceAll('<br/>', '\n')
          .replaceAll('<br />', '\n')}
        onChange={(e) =>
          dispatch({
            type: 'EDIT',
            id: id,
            data: {
              ...component.data,
              content: e.target.value.replaceAll('\n', '<br/>'),
            },
          })
        }
        onKeyDown={handleContentResize}
        onKeyUp={handleContentResize}
      ></textarea>
    </div>
  );
};
