import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useUser from 'store/modules/user/userHook';
import { IsetGetCorrectQuizData } from './interfaces';

const useGetCorrectQuiz = (courseId: string): IsetGetCorrectQuizData => {
  const [correctQuizResp, setCorrectQuizResp] = useState();
  const [correctQuizRespError, setCorrectQuizRespError] = useState<string>('');
  const [correctQuizRespIsLoading, setCorrectQuizRespIsLoading] =
    useState<boolean>(true);

  const { userData } = useUser();

  const fetchLessonData = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/quizzes?userId=${userData.id}&classroomId=${userData.classroomId}&courseId=${courseId}&isCorrect=true`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setCorrectQuizResp(res.data);
        })
        .finally(() => {
          setCorrectQuizRespIsLoading(false);
        });
    } catch (err) {
      setCorrectQuizRespError(err);
    }
  }, [courseId, userData.classroomId, userData.id]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return {
    correctQuizResp,
    correctQuizRespError,
    correctQuizRespIsLoading,
  };
};

export default useGetCorrectQuiz;
