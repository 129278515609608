import React from 'react';

import { XYCoord } from 'dnd-core';
import { FC, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { OptionBar } from './OptionBar';
import { Image } from './Image';
import { Text } from './Text';
import { TextTest } from './TextTest';
import { QuizEditor } from './QuizEditor';
import { QuizChoice } from './QuizChoice';
import { Editor } from './Editor';
import { Box } from './Box';

// const TextTestComponent = React.memo(TextEditor);

export interface CardProps {
  id: any;
  item: string;
  index: number;
  component: any;
  number: number;
  dispatch: () => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  showContext: number;
  setShowContext: (number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ComponentItem: FC<CardProps> = ({
  id,
  item,
  index,
  component,
  number,
  dispatch,
  moveCard,
  showContext,
  setShowContext,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'component',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'component',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      className="component-item"
      ref={ref}
      data-handler-id={handlerId}
      key={id}
      style={{ opacity: opacity }}
    >
      <OptionBar
        index={index}
        dispatch={dispatch}
        isQuiz={component.isQuiz}
        showContext={showContext}
        setShowContext={setShowContext}
      />
      {(item === 'text' || item === 'textBold') && (
        <TextTest id={id} component={component} dispatch={dispatch} />
      )}
      {(item === 'box' || item === 'point') && (
        <Box id={id} component={component} dispatch={dispatch} />
      )}
      {item === 'image' && (
        <Image id={id} component={component} dispatch={dispatch} />
      )}
      {item === 'editor' && (
        <Editor id={id} component={component} dispatch={dispatch} />
      )}
      {item === 'multipleTest' && (
        <QuizChoice
          id={id}
          number={number}
          component={component}
          dispatch={dispatch}
        />
      )}
      {item === 'editorTest' && (
        <QuizEditor
          id={id}
          number={number}
          component={component}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};
