import React, { useCallback, useEffect, useState } from 'react';
import { formDate } from 'pages/teacher/studentProgressPage';
import useAppliers from 'store/modules/appliers/appliersHook';
import { isEmptyObj } from 'services/common/check';
import { ExitButton, SearchBar, TextInput } from 'components/global';
import { CardModalProps } from './interfaces';

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from 'styles/stylesheets/style.css';
import classNames from 'classnames';
import { useGetRequestAuth } from 'hooks/main';
const cn = classNames.bind(styles);

const CardModal = ({
  ty,
  setTy,
  header,
  handleReq,
  isOpen,
  close,
  detail,
}: CardModalProps): JSX.Element | null => {
  const noClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    return;
  };
  // # : type 1 (그룹 관리)문의한 유저 정보 모달
  const qna = () => {
    if ((detail['application']['inquiry'] as string)?.length > 59) {
      return (
        <>
          <p className="card-modal-body-value">
            {detail['application']['inquiry']}
          </p>
        </>
      );
    } else {
      return (
        <p className="card-modal-body-value">
          {detail['application']['inquiry']}
        </p>
      );
    }
  };

  const handleAdmitReject = (e) => {
    handleReq('init', detail['id'], 4, detail['userRole']);
  };

  const handleCheck = useCallback(() => {
    if (detail['userAuth'] === 2) {
      handleReq('repeat', detail['id'], 3, detail['userRole']);
    } else {
      handleReq('repeat', detail['id'], 2, detail['userRole']);
    }
  }, [handleReq, detail]);

  const confirmAdmit = async (
    ty: string,
    userId: number,
    userAuth: number,
    userRole: number
  ) => {
    if (
      window.confirm(
        '승인하시면 승인대기 및 거절 상태로 되돌릴 수 없습니다. 승인하시겠습니까?'
      )
    ) {
      await handleReq(ty, userId, userAuth, userRole);
    }
  };

  const handleUseAdmit = (e) => {
    confirmAdmit('init', detail['id'], 2, detail['userRole']);
  };
  // # : type 2 미신청 유저 목록
  const { notAppliers, setNotAppliers, setCustomNotAppliers } = useAppliers();
  const [searchField, setSearchField] = useState<string>('');

  useEffect(() => {
    setNotAppliers();
  }, [setNotAppliers]);

  const [selectUser, setSelectUser] = useState({});

  const closeModal = (e) => {
    close(e);
    setSearchField('');
    setSelectUser({});
    setRole('');
    setPhoneNumber('');
    setGroupName('');
    setNumberOfPeople('');
    setPath('');
    setQuestions('');
  };
  // type 2 -> type 3 모달 라우팅
  const routeModalT3 = () => {
    if (!isEmptyObj(selectUser)) {
      setTy(3);
    }
  };
  // # : type 3 미신청 유저 요청하기
  const [phoneNumber, setPhoneNumber] = useState('');
  const [groupName, setGroupName] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('');
  const [path, setPath] = useState('');
  const [questions, setQuestions] = useState('');
  const [disabled, setDisabled] = useState(true);
  const handleRoleValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(e.target.value);
  };
  const handlePhoneNumberValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value.replace(/[^0-9]/g, '');
    if (str.length < 3) {
      setPhoneNumber(str);
    } else if (str.length < 4) {
      setPhoneNumber(str.substr(0, 2) + '-' + str.substr(2));
    } else if (str.length < 7) {
      setPhoneNumber(str.substr(0, 3) + '-' + str.substr(3));
    } else if (str.length < 11) {
      setPhoneNumber(
        str.substr(0, 3) + '-' + str.substr(3, 3) + '-' + str.substr(6)
      );
    } else if (str.length < 12) {
      setPhoneNumber(
        str.substr(0, 3) + '-' + str.substr(3, 4) + '-' + str.substr(7)
      );
    }
  };
  const handleGroupNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };
  const handleNumberOfPeopleValue = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNumberOfPeople(e.target.value);
  };
  const handlePathValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPath(e.target.value);
  };
  const handleQuestionsValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestions(e.target.value);
  };
  const { requestAuth } = useGetRequestAuth();
  const [role, setRole] = useState('');
  const handleClick = (e) => {
    if (!disabled) {
      try {
        requestAuth(
          role,
          1,
          phoneNumber,
          groupName,
          numberOfPeople,
          path,
          questions,
          selectUser['id'],
          notify
        );
      } catch (error) {
        console.error(error);
      }
      setTimeout(() => {
        closeModal(e);
      }, 1500);
    }
  };

  useEffect(() => {
    if (
      role !== '' &&
      phoneNumber !== '' &&
      groupName !== '' &&
      numberOfPeople !== '' &&
      path !== ''
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [groupName, numberOfPeople, path, phoneNumber, role]);

  const notify = () => {
    toast.dark(<p className="toast-complete">요청완료되었습니다.</p>);
  };
  // type 1 (그룹 관리)문의한 유저 정보 모달 - Memo 기능 관련
  const [text, setText] = useState('');
  const [id, setId] = useState(-1);
  useEffect(() => {
    // detail(문의한 유저 정보가 변경될 때마다 id와 메모를 바꾸어 보여줌)
    if (detail) {
      detail.application && setId(detail.application['id']);
      setText(
        detail['application']?.memo !== null ? detail['application']?.memo : ''
      );
    }
  }, [setId, detail]);

  return isOpen ? (
    <div
      className="card-modal-wrapper"
      id="code-modal"
      style={{ display: 'flex' }}
      onClick={closeModal}
    >
      <div className="card-modal-box" onClick={noClick}>
        <div className="modal-scroll-box">
          <div className="card-modal-header">
            <div className="card-modal-header-bar">
              <p className="card-modal-header-name">
                {ty === 1 && detail['userName']}
                {ty === 2 && header}
                {ty === 3 && 'AI-X 미신청 유저 사용승인하기'}
              </p>
              <ExitButton close={closeModal} />
            </div>
            <p
              className={cn(
                ty === 1 && 'card-modal-header-date',
                ty === 2 && 'card-modal-header-subtext'
              )}
            >
              {ty === 1 && (
                <>
                  신청일{' '}
                  {detail['application']
                    ? formDate(detail['application']['createdAt'])
                    : ''}
                </>
              )}
              {(ty === 2 || ty === 3) &&
                '우리가 관리할 수 있도록 세부정보를 입력해주세요.'}
            </p>
          </div>
          {ty === 1 && (
            <section className="card-modal-body">
              <article className="card-modal-row">
                <p className="card-modal-body-field">그룹명 : </p>
                <p className="card-modal-body-value">
                  {detail['application']
                    ? detail['application']['groupName']
                    : ''}
                </p>
              </article>
              <article className="card-modal-row">
                <p className="card-modal-body-field">전화번호 : </p>
                <p className="card-modal-body-value">
                  {detail['application']
                    ? detail['application']['userTel']
                    : ''}
                </p>
              </article>
              <article className="card-modal-row">
                <p className="card-modal-body-field">이메일 : </p>
                <p className="card-modal-body-value">{detail['userEmail']}</p>
              </article>
              <article className="card-modal-row">
                <p className="card-modal-body-field">예상 사용 인원 : </p>
                <p className="card-modal-body-value">
                  {detail['application']
                    ? detail['application']['expectedNumber']
                    : ''}
                </p>
              </article>
              <article className="card-modal-row">
                <p className="card-modal-body-field">
                  Coding-X를 어떻게 알게 되셨나요? :
                </p>
                <p className="card-modal-body-value">
                  {detail['application']
                    ? detail['application']['knownPath']
                    : ''}
                </p>
              </article>
              <article className="card-modal-row">
                <p className="card-modal-body-field">용도 및 문의사항 : </p>
                {detail['application'] && qna()}
              </article>
            </section>
          )}
          <hr />
          {ty === 1 && (
            <div className="card-modal-admit">
              <div className="admit-state">
                <p>
                  {detail['userAuth'] === 0 && '승인전입니다.'}
                  {detail['userAuth'] === 1 && '승인대기중입니다.'}
                  {detail['userAuth'] === 2 &&
                    `승인완료(${
                      detail['approvedAt']
                        ? typeof detail['approvedAt'] === 'string' &&
                          detail['approvedAt']?.substring(0, 4) +
                            '.' +
                            detail['approvedAt']?.substring(5, 7) +
                            '.' +
                            detail['approvedAt']?.substring(8, 10)
                        : ''
                    })`}
                  {detail['userAuth'] === 3 && '승인중지되었습니다.'}
                  {detail['userAuth'] === 4 && '승인거절되었습니다.'}
                </p>
              </div>
              <div className="admit-control-buttons">
                {detail['userAuth'] === 1 && (
                  <button
                    className="admit-reject-button"
                    onClick={handleAdmitReject}
                  >
                    승인거절
                  </button>
                )}
                {(detail['userAuth'] === 1 || detail['userAuth'] === 4) && (
                  <button className="use-admit-button" onClick={handleUseAdmit}>
                    이용승인
                  </button>
                )}
                {(detail['userAuth'] === 2 || detail['userAuth'] === 3) && (
                  <button
                    className={cn(
                      'btn-invite',
                      detail['userAuth'] === 3 && 'btn-disabled'
                    )}
                    onClick={handleCheck}
                  >
                    <span className="invite-content">
                      {detail['userAuth'] === 2 ? '이용중' : '이용중지'}
                    </span>
                    <span className="white-circle"></span>
                  </button>
                )}
              </div>
            </div>
          )}
          {ty === 2 && (
            <>
              <SearchBar
                type={2}
                searchQuery={searchField}
                setSearchQuery={setSearchField}
                userAuth={-1}
                setU={setNotAppliers}
                setCustomU={setCustomNotAppliers}
              >
                유저 이름 또는 아이디(이메일)
              </SearchBar>
              <section className="card-modal-user-list">
                <ul>
                  {notAppliers.map((item, key) => (
                    <li key={key}>
                      <input
                        id={item['userEmail']}
                        className="user-list-radio-btn"
                        type="radio"
                        name="users"
                        onChange={() => setSelectUser(item)}
                      />
                      <label htmlFor={item['userEmail']}>
                        <p className="user-list-name">{item['userName']}</p>
                        <p className="user-list-email">{item['userEmail']}</p>
                      </label>
                    </li>
                  ))}
                </ul>
              </section>
            </>
          )}
          {ty === 3 && (
            <section className="card-modal-body">
              <article className="card-modal-row">
                <p className="card-modal-body-value">
                  {selectUser['userName']}
                </p>
              </article>
              <article className="card-modal-row">
                <p className="card-modal-body-value">
                  {selectUser['userEmail']}
                </p>
              </article>
            </section>
          )}
          <div className="card-modal-footer">
            {ty === 1 && (
              <>
                <div className="card-modal-footer-header">
                  <p>운영하면서 공유해야하는 부분을 모두 메모해주세요</p>
                </div>
                <div className="card-modal-footer-card">
                  <TextInput
                    userId={id}
                    text={text}
                    setText={setText}
                    postText={handleReq}
                  />
                </div>
              </>
            )}
            {ty === 2 && (
              <button
                className={cn(
                  'use-admit-button',
                  isEmptyObj(selectUser) && 'disabled'
                )}
                style={{ width: '100%' }}
                disabled={isEmptyObj(selectUser)}
                onClick={routeModalT3}
              >
                다음
              </button>
            )}
            {ty === 3 && (
              <div className="onbook-infor">
                <div className="info-container">
                  <div className="info-content">
                    <div className="move-info">
                      <div className="des-input-set">
                        <p>담당역할*</p>
                        <select
                          className={role === '' ? 'not-select' : ''}
                          value={role}
                          onChange={handleRoleValue}
                        >
                          <option value="" hidden>
                            선생님/학생
                          </option>
                          <option value="선생님">선생님</option>
                          <option value="학생">학생</option>
                        </select>
                      </div>
                      <div className="des-input-set">
                        <p>전화번호*</p>
                        <input
                          value={phoneNumber}
                          placeholder="010-0000-0000"
                          onChange={handlePhoneNumberValue}
                        />
                      </div>
                      <div className="des-input-set">
                        <p>그룹명*</p>
                        <input
                          value={groupName}
                          placeholder="학교, 기관, 단체"
                          onChange={handleGroupNameValue}
                        />
                      </div>
                      <div className="des-input-set">
                        <p>예상 사용 인원*</p>
                        <select
                          className={numberOfPeople === '' ? 'not-select' : ''}
                          value={numberOfPeople}
                          onChange={handleNumberOfPeopleValue}
                        >
                          <option value="" hidden>
                            몇명 정도가 이용하실 생각인가요?
                          </option>
                          <option value="1~50">1~50</option>
                          <option value="51~100">51~100</option>
                          <option value="101~200">101~200</option>
                          <option value="201~300">201~300</option>
                          <option value="301~500">301~500</option>
                          <option value="501~1,000">501~1,000</option>
                          <option value="1,001이상">1,001이상</option>
                          <option value="사용문의 아님">사용문의 아님</option>
                        </select>
                      </div>
                    </div>
                    <div className="set">
                      <p>Coding-X를 어떻게 알게 되셨나요?*</p>
                      <select
                        className={path === '' ? 'not-select' : ''}
                        value={path}
                        onChange={handlePathValue}
                      >
                        <option value="" hidden>
                          알게 된 경로를 선택해주세요
                        </option>
                        <option value="1.SNS(유튜브,페이스북,인스타 등)">
                          1.SNS(유튜브,페이스북,인스타 등)
                        </option>
                        <option value="2.온라인 광고">2.온라인 광고</option>
                        <option value="3.오프라인 광고">3.오프라인 광고</option>
                        <option value="4.서비스 관계자 추천">
                          4.서비스 관계자 추천
                        </option>
                        <option value="5.지인 추천">5.지인 추천</option>
                        <option value="6.기타">6.기타</option>
                      </select>
                    </div>
                    <div className="set">
                      <p>용도 및 문의사항</p>
                      <textarea
                        value={questions}
                        placeholder="200자 이하 작성 가능합니다"
                        maxLength={200}
                        onChange={handleQuestionsValue}
                      />
                    </div>
                    <button disabled={disabled} onClick={handleClick}>
                      요청하기
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        className={'copy-url'}
        autoClose={1000}
        transition={Zoom}
        hideProgressBar
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
      />
    </div>
  ) : null;
};

export default CardModal;
