import { useCallback, useEffect, useState } from 'react';
import { IsetProgressesData } from './interfaces';
import axios from 'axios';

const useGetProgresses = (
  userId: number,
  classroomId: number,
  courseId: string
): IsetProgressesData => {
  const [progressesResp, setProgressesResp] = useState();
  const [progressesRespError, setProgressesRespError] = useState<string>('');
  const [progressesRespIsLoading, setProgressesRespIsLoading] =
    useState<boolean>(true);

  const fetchLessonData = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/progresses?userId=${userId}&classroomId=${classroomId}&courseId=${courseId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setProgressesResp(res.data);
        })
        .finally(() => {
          setProgressesRespIsLoading(false);
        });
    } catch (err) {
      setProgressesRespError(err);
    }
  }, [classroomId, courseId, userId]);
  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  return { progressesResp, progressesRespError, progressesRespIsLoading };
};

export default useGetProgresses;
