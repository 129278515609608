import React from 'react';
import classNames from 'classnames';
import styles from 'styles/stylesheets/style.css';
const cn = classNames.bind(styles);

const ExitButton = ({
  close,
  popup,
}: {
  close: any; //(e: React.MouseEvent<HTMLButtonElement>) => void;
  popup?: any;
}): JSX.Element => {
  return (
    <button
      type="button"
      onClick={close}
      className={cn('exit-btn', popup && 'popup')}
    >
      <img src="./assets/icons/ic-close-normal-line-dk.svg" alt="" />
    </button>
  );
};

export default ExitButton;
