import { useEffect } from 'react';
import useUser from 'store/modules/user/userHook';

const Confirm = (): JSX.Element => {
  const { logout } = useUser();

  useEffect(() => {
    setTimeout(() => logout(), 2000);
  }, [logout]);

  return (
    <div className="mypage-inner">
      <img
        src="/assets/icons/ic_checkbox_on_fill_bl@2x.png"
        alt=""
        width={80}
      />
      <h2>비밀번호 변경완료!</h2>
      <p>잠시후 페이지를 벗어나면 보안을 위해</p>
      <p>로그아웃 되며 다시 로그인을 진행합니다.</p>
      <button onClick={() => logout()}>확인</button>
    </div>
  );
};

export default Confirm;
