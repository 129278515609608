import React, { useEffect, useRef } from 'react';
import { ModalProps } from './interfaces';

const Modal = ({
  isOpen,
  close,
  handleReq,
  inputName,
  setInputName,
  header,
  label,
  placeholder,
}: ModalProps): JSX.Element | null => {
  const handleinputName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputName(e.target.value);
  };

  const closeModal = (e) => {
    close();
    setInputName(header == '교실 수정하기' ? placeholder : '');
  };

  const noClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    return;
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleReq(e);
      setInputName(header == '교실 수정하기' ? placeholder : '');
    }
  };

  const textInput = useRef(null);

  useEffect(() => {
    isOpen && textInput.current.focus();
  });

  return isOpen ? (
    <div
      className="modal-wrapper"
      id="code-modal"
      style={{ display: 'flex' }}
      onClick={close}
    >
      <div className="modal-box" onClick={noClick}>
        <div className="modal-header">
          <p>{header}</p>
        </div>
        <div className="modal-body">
          <label>{label}</label>
          <input
            ref={textInput}
            id="classroom-name"
            type="text"
            name={inputName}
            value={inputName}
            placeholder={placeholder}
            onChange={handleinputName}
            onKeyPress={handleKeypress}
            maxLength={16}
          />
        </div>
        <div className="modal-footer">
          <button className="btn-fill" onClick={handleReq}>
            완료
          </button>
          <button className="btn-line" id="modal-btn-exit" onClick={closeModal}>
            취소
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
