import { useState } from 'react';

export const Image = ({ id, component, dispatch }): JSX.Element => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/components/image`, {
      method: 'POST',
      headers: {
        accept: 'text/html; charset=utf-8',
        authorization: ('Bearer ' + localStorage.getItem('token')) as string,
      },
      body: formData,
    }).then(async (res) => {
      if (res.status === 201) {
        const { imageUrl } = await res.json();
        dispatch({
          type: 'EDIT',
          id: id,
          data: { ...component.data, image: imageUrl },
        });
      } else {
        console.log('fail');
      }
    });
  };

  return (
    <div
      className="image-component"
      onMouseEnter={() => {
        handleMouseEnter();
      }}
      onMouseLeave={() => {
        handleMouseLeave();
      }}
    >
      <div
        style={{
          visibility: hover ? 'visible' : 'hidden',
        }}
      >
        <label htmlFor={`image_file${id}`}>업로드</label>
        <input
          type="file"
          onChange={(e) => handleImageUpload(e)}
          id={`image_file${id}`}
        />
      </div>
      <img src={component.data.image} alt="" />
    </div>
  );
};
