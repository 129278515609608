import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { GoBackButton } from '../button';
import useUser from 'store/modules/user/userHook';
import { useScrollDirection } from 'hooks/global';

const CommonLayout = ({
  buttonHide = false,
}: {
  buttonHide?: boolean;
}): JSX.Element => {
  const { isLoggedIn, logout } = useUser();
  const ScrollDirection = useScrollDirection();
  const history = useHistory();
  const scrollY = window.scrollY;
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [showsMobileNav, setShowsMobileNav] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  const toggleMenu = () => {
    setShowsMobileNav((showsMobileNav) => !showsMobileNav);
  };

  return (
    <>
      <div
        className={`ai-header ${
          ScrollDirection === 'down' && scrollY > 50 ? 'hasScroll' : ''
        }`}
      >
        <button className="nav-mobile-btn sm-only" onClick={() => toggleMenu()}>
          <img src="/assets/icons/ic_alignbothside_editor_line_dk.svg" alt="" />
        </button>
        {(innerWidth >= 900 || showsMobileNav) && (
          <div className="gnb">
            <div className="gnb-left">
              <div className="logo-box">
                <Link to="/">
                  <img src="/assets/images/onbook-logo.svg" alt="코딩엑스" />
                </Link>
              </div>
              <div className="link-box">
                <Link to="/">소개</Link>
                <Link to="/usagefee">이용요금</Link>
              </div>
            </div>
            <div className="gnb-right">
              {isLoggedIn ? (
                <>
                  <Link to="/mypage">
                    <button className="btn-line" type="button">
                      내정보 수정
                    </button>
                  </Link>
                  <button className="btn-line" type="button" onClick={logout}>
                    로그아웃
                  </button>
                </>
              ) : (
                <>
                  {/* 현재는 회원가입 버튼이 로그인 페이지 안으로 이동됨 */}
                  {/* <button className="btn-line" type="button" onClick={goJoin}>
                회원가입
              </button> */}
                  <Link to="/login">
                    <button className="btn-fill" type="button">
                      로그인
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        )}

        {!buttonHide && <GoBackButton history={history} />}
      </div>
      {showsMobileNav && (
        <div className="overlay sm-only" onClick={() => toggleMenu()}></div>
      )}
    </>
  );
};

export default CommonLayout;
