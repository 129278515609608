import React from 'react';
import { LoginForm } from 'components/main';
import { useLocation } from 'react-router';

const loginPage = (props): JSX.Element => {
  const destination = props?.location?.state?.destination;
  return (
    <>
      <LoginForm destination={destination} />
    </>
  );
};

export default loginPage;
