import axios from 'axios';
import { useState } from 'react';
import { IsetManageLesson, ManageLessonI } from '../getProgress/interfaces';

const useManageLesson = (): IsetManageLesson => {
  const [manageLesson, setManageLesson] = useState<ManageLessonI[]>();
  const [manageLessonError, setManageLessonError] = useState<string>('');
  const [manageLessonIsLoading, setManageLessonIsLoading] =
    useState<number>(-1);

  const fetchData = async (classroomId: number, chapterId: number) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/progresses?classroomId=${classroomId}&chapterId=${chapterId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'text/html; charset=utf-8',
              authorization: ('Bearer ' +
                localStorage.getItem('token')) as string,
            },
          }
        )
        .then((res) => {
          setManageLesson(res.data);
        })
        .finally(() => {
          setManageLessonIsLoading(0);
        });
    } catch (err) {
      setManageLessonError(err);
    }
  };

  const getManageLesson = (classroomId: number, chapterId: number) => {
    setManageLessonIsLoading(1);
    fetchData(classroomId, chapterId);
  };

  return {
    getManageLesson,
    manageLesson,
    manageLessonError,
    manageLessonIsLoading,
  };
};

export default useManageLesson;
