import { IImage } from './interfaces';

const Image = ({ id, imageUrl }: IImage): JSX.Element => {
  return (
    <div className={'img-component component'} data-id={id}>
      <img src={imageUrl} alt="" />
    </div>
  );
};

export default Image;
