import { ListContainer } from './ListContainer';
import { StateProps } from './interface';

export const List = ({ setLessonId }: StateProps): JSX.Element => {
  return (
    <div className="list-container">
      <ListContainer setLessonId={setLessonId} />
    </div>
  );
};
