import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useUser from 'store/modules/user/userHook';
import { ChapterCardsProps } from './interfaces';

// props
// classroomCourseResp : 특정 코스 조회 데이터 (/courses/courseId GET 요청 데이터)
// bookmarkResp: 해당 코스의 북마크 정보 (하나의 북마크 정보)
// progressesResp: 해당 코스에 있는 레슨들의 진도율 정보
const ChapterCards = ({
  classroomCourseResp,
  bookmarkResp,
  progressesResp,
}: ChapterCardsProps): JSX.Element => {
  const { isLoggedIn } = useUser();

  // 레슨 클릭시 redux 내의 inIds내의 lessonId를 업데이트
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);
  if (!didMount) {
    return null;
  }

  const lockedURL = isLoggedIn ? '/usagefee' : '/login';

  return (
    <div className="container chapter-container">
      {classroomCourseResp.chapters.map((chapter, index) => (
        <div className="card" key={chapter.id}>
          <div className="card-inner">
            <div className="card-header">
              <img
                src={chapter.thumbnailUrl}
                alt=""
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            <div className="card-body">
              <div className="card-body-title">
                <div className="content-subtitle">
                  <p>Chapter {index + 1}</p>
                </div>
                <h1 className="content-title">{chapter.chapterName}</h1>
              </div>
              <div className="card-body-content">
                <p>Lesson</p>
                <ul>
                  {chapter.lessons.map((lesson, index) => (
                    <li key={index}>
                      <Link
                        to={`/lesson/${lesson.id}`}
                        // lesson lock 버튼 막기
                        style={lesson.isLock ? { pointerEvents: 'none' } : null}
                      >
                        <div>
                          <p>
                            {index + 1}. {lesson.lessonName}
                          </p>
                          <span className="mark-area">
                            {/* lesson lock 자물쇠 이미지로 막기 */}
                            {lesson.isLock ? (
                              <img
                                src="/assets/icons/ic_lock_lesson.svg"
                                alt=""
                              />
                            ) : (
                              bookmarkResp !== undefined &&
                              bookmarkResp.length !== 0 && (
                                <>
                                  {lesson.id ===
                                    bookmarkResp['0'].lesson.id && (
                                    <img
                                      src="/assets/icons/ic_bookmark_active_ye_24.svg"
                                      alt=""
                                    />
                                  )}
                                </>
                              )
                            )}
                          </span>
                          {/* 진도 현황이 존재하는 경우 */}
                          {progressesResp !== undefined &&
                          progressesResp.find(
                            (progress) => progress.lesson.id === lesson.id
                          ) !== undefined ? (
                            progressesResp.find(
                              (progress) => progress.lesson.id === lesson.id
                            ).completeLesson === 1 ? (
                              <img
                                src="/assets/images/lesson_status02.png"
                                alt=""
                              />
                            ) : progressesResp.find(
                                (progress) => progress.lesson.id === lesson.id
                              ).completeLesson === 2 ? (
                              <img
                                src="/assets/images/lesson_status03.png"
                                alt=""
                              />
                            ) : (
                              progressesResp.find(
                                (progress) => progress.lesson.id === lesson.id
                              ).completeLesson === 3 && (
                                <img
                                  src="/assets/images/lesson_status04.png"
                                  alt=""
                                />
                              )
                            )
                          ) : (
                            // 진도 현황이 존재하지 않는 경우 (빈 동그라미)
                            <img
                              src="/assets/images/lesson_status01.png"
                              alt=""
                            />
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* chapter lock 전체 자물쇠 이미지로 막기 */}
          {chapter.isLock ? (
            <Link to={lockedURL}>
              <div className="cover-box">
                <img src="/assets/images/lock.png" alt="" />
              </div>
            </Link>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default ChapterCards;
