import { createSlice } from '@reduxjs/toolkit';
import { Action, Dispatch } from 'redux';

export type studentsState = {
  loading: boolean;
  hasErrors: boolean;
  students: IStudent[] | undefined;
};

export interface IStudent {
  id?: number;
  student_id?: number;
  recent_visit: Date;
  user_name: string;
  visit_count: number;
}

const initialState: studentsState = {
  loading: false,
  hasErrors: false,
  students: [],
};

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    getStudents: (state) => {
      state.loading = true;
    },
    getStudentsSuccess: (state, { payload }) => {
      state.students = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getStudentsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

const { reducer, actions } = studentsSlice;
export const { getStudents, getStudentsSuccess, getStudentsFailure } = actions;

// Asynchronous thunk action
export function fetchStudents(classroomId: number) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    // eslint-disable-line
    dispatch(getStudents());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/members?classroomId=${classroomId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/html; charset=utf-8',
            authorization: ('Bearer ' +
              localStorage.getItem('token')) as string,
          },
        }
      );
      const data = await response.json();
      dispatch(getStudentsSuccess(data));
    } catch (error) {
      dispatch(getStudentsFailure());
    }
  };
}

export default reducer;
